// import AddMultipleAssigneeDropdown from '../AddMultipleAssigneeDropdown';
import AddMultipleAssigneeDropdown from "components/CustomDropdown/AddMultipleAssigneeDropdown";
import { workOrderShortListRequestFailureHandler } from "custom_modules/WorkOrderNew/workOrderReducers";
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import durationIcon from "../../../assets/img/icons/clock-in-duration.svg";
import Plus from "../../../assets/img/icons/plus.svg";
import tick from "../../../assets/img/onboardingImg/tick-black.svg";
import { formatDurationToHoursMinutes } from "../../../utils/helper";
import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import AddSingleMemberDropdown from "../../../components/CustomDropdown/AddSingleMemberDropdown";
import CreateProjectDropdown from "../../../components/DropdownStyle/CreateProjectDropdown";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";
import Select from "../../../components/ReactSelectDropdown";
import { filterOption } from "../../../components/ReactSelectDropdown/ReactSelectDropdown";
import { ClearSearchButton } from "../../../components/Search/searchStyles";
import DatePicker from "../../../components/SingleDatePicker/SingleDatePicker";
import PricingPlan from "../../../components/PricingPlan/PricingPlan";
import SingleDatePicker from "../../../components/SingleDatePicker/SingleDatePicker";
import {
  ButtonIcon,
  PrimaryButton,
  RadiusButton,
} from "../../../styledComponents/buttons";
import {
  TabButton,
  TabButtonContainer,
} from "../../../styledComponents/buttons";
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
  Container,
  ContentHeader,
  FormAlert,
  InputField,
  InputLabel,
  InputTextArea,
  InputWithLabelSection,
  PageTitle,
  RedStar,
} from "../../../styledComponents/common";
import {
  ToggleButton,
  ToggleButtonRound,
} from "../../../styledComponents/invoice";
import { RadioButton } from "../../../styledComponents/members";
import { checkHtml, checkUrl } from "../../../utils/helper";
import AddLocationModal from "../../ClientsNew/ClientDetails/AddLocationModal";
import {
  AdditionalOptionText,
  DurationContainer,
  DurationText,
  MonthDateBox,
  MonthDateContainer,
  RepeatSection,
  ScheduleText,
  ThemeBox,
  ThemesContainer,
  WeekDaysContainer,
  WeekNumberBox,
  WeekdayBox,
} from "../../ShiftSchedule/CreateShift/createShiftStyles";
import TimeRangeComponent from "../../Timesheet/Daily/TimeRangeComponent";
import {
  AssigneeSection,
  InputSection,
  TimeRangeSection,
} from "./createTaskStyles";

const CreateJob = ({
  selectedOrganization,
  isLoading,
  orgMembersList,
  orgMembersShortList,
  addTaskLocationLoading,
  createdTaskInfo,
  locationsList,
  createdTaskLocationInfo,
  memberShortListIsLoading,
  getOrganizationMembersList,
  getOrganizationMembersShortList,
  getTaskLocation,
  addTaskLocation,
  getOfficesShortList,
  getTaskType,
  taskTypeIsLoading,
  taskTypeList,
  history,
  location,
  //workorder short
  getWorkOrderShortList,
  workOrderShortList,

  //customer location
  clientLocationList,
  getclientLocalMapLoading,
  addOrUpdateclientLocalMapLoading,
  createdClientLocationInfo,

  getClientLocation,
  addClientLocation,
  updateClientLocation,

  //job
  createJobRequest,
  createdJob,
  createOrUpdateJobIsLoading,
  isJobLimitFull,
}) => {
  const { t, i18n } = useTranslation();

  const [jobName, setJobName] = useState("");
  const [selectedWorkOrder, setSelectedWorkOrder] = useState("");
  const [officeOptions, setOfficeOptions] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [requiresApproval, setRequiresApproval] = useState(false);
  const [taskDescription, setTaskDescription] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [jobLocationOptions, setJobLocationOptions] = useState([]);
  // const [radiusInput, setRadiusInput] = useState(100);
  // const [isCustomRadius, setIsCustomRadius] = useState(false);
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [isRepeat, setIsRepeat] = useState(false);
  const [selectedRepeatType, setSelectedRepeatType] = useState("");
  const [defaultRepeatOptions, setDefaultRepeatOptions] = useState([]);
  const [interval, setInterval] = useState(1);
  const [activeDays, setActiveDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const weekParse = {
    monday: 0,
    tuesday: 1,
    wednesday: 2,
    thursday: 3,
    friday: 4,
    saturday: 5,
    sunday: 6,
  };
  const [isWeekViewOfMonth, setIsWeekViewOfMonth] = useState(false);
  const [activeMonthDates, setActiveMonthDates] = useState({});
  const [activeMonthDays, setActiveMonthDays] = useState({});
  const [scheduleDescription, setScheduleDescription] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [selectedTaskType, setSelectedTaskType] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [pricingPopup, setPricingPopup] = useState(false);
  const [saveErrors, setSaveErrors] = useState({});
  const radiusOptions = [20, 50, 100];
  const [statusOptions, setStatusOptions] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [workOrderOptions, setWorkOrderOptions] = useState([]);
  const [clientId, setClientId] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);
  const [isRecurringDisabled, setIsRecurringDisabled] = useState(false);

  const [addLocationButton, setAddLocationButton] = useState(false);
  const [themeColor, setThemeColor] = useState("");
  const scrollName = useRef(null);
  const scrollDescription = useRef(null);
  const scrollTime = useRef(null);
  const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const dateText = {
    1: "1st",
    2: "2nd",
    3: "3rd",
    21: "21st",
    22: "22nd",
    23: "23rd",
    31: "31st",
    "Last Day": "last",
  };

  const jobStatusOptions = [
    {
      value: "unscheduled",
      label: t("unscheduled"),
    },
    {
      value: "ready to dispatch",
      label: t("ready_to_dispatch"),
    },
  ];

  useEffect(() => {
    if (createdClientLocationInfo && createdClientLocationInfo.id) {
      setSelectedLocation({
        value: createdClientLocationInfo.id,
        label: createdClientLocationInfo.name,
      });
    }
  }, [createdClientLocationInfo]);

  useEffect(() => {
    setAddLocationButton(false);
    if (selectedOrganization && selectedOrganization.id) {
      if (location.state && location.state.jobStatus === "ready-to-dispatch") {
        setSelectedTaskType({
          label: t("ready_to_dispatch"),
          value: "ready to dispatch",
        });
      }
      if (location.state && location.state.startDate) {
        setStartDate(moment(location.state.startDate));
      }
      else{
        const roundedMinsStartDate = Math.ceil(moment().minutes() / 5) * 5;

        const newStartDate = moment().minutes(roundedMinsStartDate + 10);
        setStartDate(newStartDate);
      }
      getWorkOrderShortList({ organization_id: selectedOrganization.id });

      getOrganizationMembersShortList({
        organization_id: selectedOrganization.id,
      });
      let defaultOptions = [];
      defaultOptions.push({
        label: `Daily`,
        value: "daily",
      });
      defaultOptions.push({
        label: `Weekly on ${startDate.format("dddd")}`,
        value: "weekly",
      });
      defaultOptions.push({
        label: `Bi-Weekly on ${startDate.format("dddd")}`,
        value: "biweekly",
      });
      defaultOptions.push({
        label: `Monthly on ${startDate.format("D")} of the month`,
        value: "monthly",
      });
      defaultOptions.push({
        label: `Custom Weekly...`,
        value: "custom_weekly",
      });
      defaultOptions.push({
        label: `Custom Monthly...`,
        value: "custom_monthly",
      });
      setDefaultRepeatOptions(defaultOptions);

      let tempActiveMonthDates = {};
      for (let i = 1; i <= 31; i++) {
        tempActiveMonthDates[i] = false;
      }
      tempActiveMonthDates["Last Day"] = false;
      setActiveMonthDates(tempActiveMonthDates);

      let tempActiveMonthDays = {};
      for (let i = 0; i <= 27; i++) {
        tempActiveMonthDays[i] = false;
      }
      setActiveMonthDays(tempActiveMonthDays);
    }

    return () => {
      clearState();
    };
  }, [selectedOrganization]);

  useEffect(() => {
    if (
      interval &&
      interval > 0 &&
      selectedRepeatType &&
      selectedRepeatType.value
    ) {
      let typeText = "";
      let activeDaysText = "";
      let finalText = "";

      if (
        selectedRepeatType.value === "weekly" ||
        selectedRepeatType.value === "biweekly" ||
        selectedRepeatType.value === "monthly"
      ) {
        finalText = selectedRepeatType.label;
        setScheduleDescription(finalText);
      } else if (selectedRepeatType.value === "daily") {
        interval === 1
          ? (finalText = "Daily ")
          : (finalText = `Every ${interval} days `);
        setScheduleDescription(finalText);
      } else if (selectedRepeatType.value === "custom_weekly") {
        interval === 1
          ? (typeText = "Weekly ")
          : (typeText = `Every ${interval} weeks `);
        let tempActiveDaysArray = [];
        for (let key in activeDays) {
          if (activeDays[key]) {
            tempActiveDaysArray.push(
              key.charAt(0).toUpperCase() + key.slice(1)
            );
          }
        }
        if (tempActiveDaysArray.length === 1) {
          activeDaysText = `on ${tempActiveDaysArray[0]}.`;
        } else if (tempActiveDaysArray.length > 1) {
          let lastDay = tempActiveDaysArray.pop();
          activeDaysText = `on ${tempActiveDaysArray.join(
            ", "
          )} and ${lastDay}.`;
        }
        finalText = typeText + activeDaysText;
        setScheduleDescription(finalText);
      } else if (selectedRepeatType.value === "custom_monthly") {
        activeDaysText = "on the ";
        interval === 1
          ? (typeText = "Monthly ")
          : (typeText = `Every ${interval} months `);
        if (isWeekViewOfMonth) {
          for (let i = 0; i < 7; i++) {
            for (let n = 0; n < 4; n++) {
              if (activeMonthDays[n * 7 + i]) {
                activeDaysText += `${findDateText(n + 1)} ${
                  Object.keys(weekParse)[i].charAt(0).toUpperCase() +
                  Object.keys(weekParse)[i].slice(1)
                } and `;
              }
            }
          }
          activeDaysText = activeDaysText.slice(0, -5) + ".";
          finalText = typeText + activeDaysText;
          setScheduleDescription(finalText);
        } else {
          let tempActiveDaysArray = [];
          for (let key in activeMonthDates) {
            if (activeMonthDates[key]) {
              tempActiveDaysArray.push(findDateText(key));
            }
          }
          if (tempActiveDaysArray.length === 1) {
            activeDaysText = `on ${tempActiveDaysArray[0]} day of the month.`;
          } else if (tempActiveDaysArray.length > 1) {
            let lastDay = tempActiveDaysArray.pop();
            activeDaysText = `on ${tempActiveDaysArray.join(
              ", "
            )} and ${lastDay} day of the month.`;
          }
          finalText = typeText + activeDaysText;
          setScheduleDescription(finalText);
        }
      }
    }
  }, [
    selectedRepeatType,
    interval,
    activeDays,
    isWeekViewOfMonth,
    activeMonthDates,
    activeMonthDays,
  ]);

  useEffect(() => {
    if (startDate) {
      let defaultOptions = [];
      defaultOptions.push({
        label: `Daily`,
        value: "daily",
      });
      defaultOptions.push({
        label: `Weekly on ${startDate.format("dddd")}`,
        value: "weekly",
      });
      defaultOptions.push({
        label: `Bi-Weekly on ${startDate.format("dddd")}`,
        value: "biweekly",
      });
      defaultOptions.push({
        label: `Monthly on ${startDate.format("D")} of the month`,
        value: "monthly",
      });
      defaultOptions.push({
        label: `Custom Weekly...`,
        value: "custom_weekly",
      });
      defaultOptions.push({
        label: `Custom Monthly...`,
        value: "custom_monthly",
      });
      setDefaultRepeatOptions(defaultOptions);

      let tempActiveDays = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      };
      let tempActiveMonthDates = {};
      for (let i = 1; i <= 31; i++) {
        tempActiveMonthDates[i] = false;
      }
      tempActiveMonthDates["Last Day"] = false;

      switch (selectedRepeatType && selectedRepeatType.value) {
        case "weekly":
          tempActiveDays[startDate.format("dddd").toLocaleLowerCase()] = true;
          setActiveDays(tempActiveDays);
          setSelectedRepeatType({
            label: `Weekly on ${startDate.format("dddd")}`,
            value: "weekly",
          });
          break;
        case "biweekly":
          tempActiveDays[startDate.format("dddd").toLocaleLowerCase()] = true;
          setActiveDays(tempActiveDays);
          setSelectedRepeatType({
            label: `Bi-Weekly on ${startDate.format("dddd")}`,
            value: "biweekly",
          });
          break;
        case "monthly":
          tempActiveMonthDates[
            startDate.format("D").toLocaleLowerCase()
          ] = true;
          setActiveMonthDates(tempActiveMonthDates);
          setSelectedRepeatType({
            label: `Monthly on ${startDate.format("D")} of the month`,
            value: "monthly",
          });
          break;
        default:
      }
    }
  }, [startDate]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id && clientId) {
      getClientLocation({
        organization_id: selectedOrganization.id,
        client_id: clientId,
      });
    }
  }, [clientId]);

  // useEffect(() => {
  //   if (officesShortList && officesShortList.length === 1) {
  //     getOrganizationMembersList({ office_id: officesShortList[0].id });
  //     getTaskLocation({ office_id: officesShortList[0].id });
  //   }
  //   if (officesShortList && officesShortList.length > 0) {
  //     setSelectedOffice({
  //       label: officesShortList[0].name,
  //       value: officesShortList[0].id,
  //     });
  //     let newArr = [];
  //     officesShortList.forEach((office, i) => {
  //       newArr.push({
  //         label: office.name,
  //         value: office.id,
  //       });
  //     });
  //     setOfficeOptions(newArr);
  //   }
  // }, [officesShortList]);

  useEffect(() => {
    if (workOrderShortList && workOrderShortList.length > 0) {
      let newArr = [];
      workOrderShortList.forEach((workorder, i) => {
        newArr.push({
          label: workorder.name,
          value: workorder.id,
          client_id: workorder.client_id,
          client_name: workorder.client_name,
        });
      });
      setWorkOrderOptions(newArr);
    }
  }, [workOrderShortList]);

  // useEffect(() => {
  //   if (
  //     selectedOffice &&
  //     selectedOffice.value &&
  //     officesShortList &&
  //     officesShortList.length > 1
  //   ) {
  //     getOrganizationMembersList({ office_id: selectedOffice.value });
  //     // getTaskLocation({ office_id: selectedOffice.value });
  //   }
  // }, [selectedOffice]);

  // useEffect(() => {
  //   if (locationsList && locationsList.length > 0) {
  //     let newArr = [];
  //     locationsList.forEach(location => {
  //       newArr.push({
  //         label: location.name,
  //         value: location.id,
  //         ...location,
  //       });
  //     });
  //     setJobLocationOptions(newArr);
  //   }
  // }, [locationsList]);

  useEffect(() => {
    if (clientLocationList && clientLocationList.length > 0) {
      const options = [];
      clientLocationList.map((item) => {
        options.push({
          value: item.id,
          label: item.name,
        });
      });
      // options.unshift({ label: 'Select Location...', value: '' });
      setJobLocationOptions(options);
    } else if (clientLocationList && clientLocationList.length === 0) {
      setSelectedLocation("");
      setJobLocationOptions([]);
    }
  }, [clientLocationList]);

  useEffect(() => {
    if (taskTypeList && taskTypeList.length > 0) {
      let newArr = [];
      if (i18n.language && i18n.language === "en") {
        taskTypeList.forEach((type) => {
          newArr.push({
            label: type.name,
            value: type.id,
          });
        });
      } else {
        taskTypeList.forEach((type) => {
          newArr.push({
            label: type.arabic_name,
            value: type.id,
          });
        });
      }
      setStatusOptions(newArr);
    }
  }, [taskTypeList]);

  // useEffect(() => {
  //   if (
  //     selectedOffice &&
  //     selectedOffice.label &&
  //     jobLocationOptions &&
  //     jobLocationOptions.length > 0
  //   ) {
  //     jobLocationOptions.forEach(location => {
  //       if (
  //         location.label === selectedOffice.label &&
  //         location.office &&
  //         location.office.id === selectedOffice.value
  //       ) {
  //         setSelectedLocation(location);
  //       }
  //     });
  //   }
  // }, [selectedOffice, jobLocationOptions]);

  useEffect(() => {
    if (createdJob && isSubmitted) {
      history.push({
        pathname: "/user/jobs",
        state: { from: "create" },
      });
    }
  }, [createdJob]);

  useEffect(() => {
    if (isJobLimitFull && isSubmitted) {
      setPricingPopup(true);
    }
  }, [isJobLimitFull]);

  const locationInputOpen = () => {
    setAddLocationButton(true);
  };

  const onTaskNameChange = (e) => {
    let errors = { ...saveErrors };
    delete errors["jobName"];
    setSaveErrors(errors);
    setJobName(e.target.value);
  };

  const onOfficeChange = (e) => {
    setSelectedOffice(e);
  };

  const onWorkOrderChange = (e) => {
    setSelectedWorkOrder(e);
    setClientId(e.client_id);
  };

  const approvalToggle = () => {
    setRequiresApproval(!requiresApproval);
  };

  const handleAssignOrRemoveMember = (user, action) => {
    if (user && user.id) {
      let newArr = [...selectedAssignees];
      if (action === "remove") {
        newArr = newArr.filter((member) => member.id !== user.id);
      } else {
        newArr.push(user);
      }
      setSelectedAssignees(newArr);
    }
  };

  const handleAssignMember = (user) => {
    handleAssignOrRemoveMember(user, "assign");
  };

  const handleRemoveMember = (user) => {
    handleAssignOrRemoveMember(user, "remove");
  };

  const onTaskDescriptionChange = (e) => {
    let errors = { ...saveErrors };
    delete errors["taskDescription"];
    setSaveErrors(errors);
    setTaskDescription(e.target.value);
  };

  const onLocationChange = (e) => {
    setSelectedLocation(e);
  };

  const onDateChange = (date) => {
    setStartDate(date);
  };

  const onRepeatTypeChange = (e) => {
    let errors = { ...saveErrors };
    delete errors["selectedRepeatType"];
    setSaveErrors(errors);
    let tempActiveDays = {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    };
    let tempActiveMonthDates = {};
    for (let i = 1; i <= 31; i++) {
      tempActiveMonthDates[i] = false;
    }
    tempActiveMonthDates["Last Day"] = false;

    switch (e.value) {
      case "weekly":
        tempActiveDays[startDate.format("dddd").toLocaleLowerCase()] = true;
        setActiveDays(tempActiveDays);
        setInterval(parseInt(1));
        break;
      case "biweekly":
        tempActiveDays[startDate.format("dddd").toLocaleLowerCase()] = true;
        setActiveDays(tempActiveDays);
        setInterval(parseInt(2));
        break;
      case "monthly":
        tempActiveMonthDates[startDate.format("D").toLocaleLowerCase()] = true;
        setActiveMonthDates(tempActiveMonthDates);
        setInterval(parseInt(1));
        break;
      default:
    }
    setSelectedRepeatType(e);
  };

  const onMonthDateSelect = (date) => {
    let errors = { ...saveErrors };
    delete errors["activeMonthDates"];
    setSaveErrors(errors);
    let tempActiveMonthDates = { ...activeMonthDates };
    tempActiveMonthDates[date] = !tempActiveMonthDates[date];
    setActiveMonthDates(tempActiveMonthDates);
  };

  const onMonthDaySelect = (day) => {
    let errors = { ...saveErrors };
    delete errors["activeMonthDays"];
    setSaveErrors(errors);
    let tempActiveMonthDays = { ...activeMonthDays };
    tempActiveMonthDays[day] = !tempActiveMonthDays[day];
    setActiveMonthDays(tempActiveMonthDays);
  };

  const findDateText = (date) => {
    if (dateText[date]) {
      return dateText[date];
    } else {
      return `${date}th`;
    }
  };

  const onEndDateSelect = (date) => {
    let errors = { ...saveErrors };
    delete errors["endDate"];
    setSaveErrors(errors);
    setEndDate(date);
  };

  const onActiveDaysSelect = (day) => {
    let errors = { ...saveErrors };
    delete errors["activeDays"];
    setSaveErrors(errors);
    let tempActiveDays = { ...activeDays };
    tempActiveDays[day] = !tempActiveDays[day];
    setActiveDays(tempActiveDays);
  };

  const onIntervalChange = (value) => {
    let errors = { ...saveErrors };
    delete errors["interval"];
    setSaveErrors(errors);
    setInterval(parseInt(value));
  };

  const clearState = () => {
    setSelectedAssignees([]);
    setStartDate(moment(new Date()));
    setStartTime("");
    setEndTime("");
    setIsRepeat(false);
    setSelectedRepeatType("");
    setDefaultRepeatOptions([]);
    setInterval(1);
    setActiveDays({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    });
    setIsWeekViewOfMonth(false);
    setActiveMonthDates({});
    setActiveMonthDays({});
    setScheduleDescription("");
    setEndDate(null);
    setTaskDescription("");
    setThemeColor("");
    setSaveErrors({});
    setIsSubmitted(false);
  };

  const handleStartTime = (time) => {
    if (time) {
      let errors = { ...saveErrors };
      delete errors["timeDifference"];
      setSaveErrors(errors);
      const start_time = time.replace(/ /g, "");
      setStartTime(moment(start_time, "h:mmA").format("HH:mm:ss"));
    } else {
      setStartTime("");
    }
  };
  const handleEndTime = (time) => {
    if (time) {
      let errors = { ...saveErrors };
      delete errors["timeDifference"];
      setSaveErrors(errors);
      const end_time = time.replace(/ /g, "");
      setEndTime(moment(end_time, "h:mmA").format("HH:mm:ss"));
    } else {
      setEndTime("");
    }
  };

  const onTaskTypeChange = (e) => {
    setSelectedTaskType(e);
  };

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const pricingPopupToggle = () => {
    setPricingPopup(!pricingPopup);
  };

  const handleThemeColor = (color) => {
    setThemeColor(color);
  };

  const handleDisable = () => {
    let isValid = false;
    if (
      jobName.trim() &&
      selectedWorkOrder &&
      selectedWorkOrder.value &&
      selectedTaskType &&
      selectedTaskType.value
    ) {
      if (selectedTaskType.value === "unscheduled") {
        isValid = true;
      } else if (
        selectedTaskType.value === "ready to dispatch" &&
        selectedAssignees.length > 0
      ) {
        if (!isRepeat) {
          isValid = true;
        } else if (
          isRepeat &&
          selectedRepeatType &&
          selectedRepeatType.value &&
          endDate
        ) {
          isValid = true;
        }
      }
    }
    return !isValid;
  };

  const handleSave = () => {
    if (checkError() && checkPlan() && !createOrUpdateJobIsLoading) {
      if (
        selectedOrganization &&
        selectedOrganization.id &&
        selectedWorkOrder &&
        selectedWorkOrder.value
      ) {
        let assigneeIds = [];
        if (selectedAssignees && selectedAssignees.length > 0) {
          selectedAssignees.forEach((element) => {
            assigneeIds.push(element.id);
          });
        }
        let payload = {
          organization_id: selectedOrganization.id,
          name: jobName,
          workOrder_id: selectedWorkOrder.value,
          status: selectedTaskType.value,
        };

        if (assigneeIds && assigneeIds.length > 0) {
          payload.assignees = assigneeIds;
        }

        if (taskDescription && taskDescription !== "") {
          payload.description = taskDescription;
        }

        if (themeColor) {
          payload.theme_color = themeColor;
        }

        if (selectedLocation && selectedLocation !== "") {
          payload.location = selectedLocation.value;
        }

        if (
          selectedTaskType &&
          selectedTaskType.value === "ready to dispatch"
        ) {
          payload.required_start_date = startDate.format("YYYY-MM-DDTHH:mm:ss");
          payload.start_time = moment(
            startDate.format("YYYY-MM-DD") + " " + startTime,
            "YYYY-MM-DD h:mmA"
          ).format();
          payload.end_time = moment(
            startDate.format("YYYY-MM-DD") + " " + endTime,
            "YYYY-MM-DD h:mmA"
          ).format();
          payload.recurring = isRepeat;
          if (!isRepeat) {
            payload.required_end_date = startDate.format("YYYY-MM-DDTHH:mm:ss");
          }
          if (isRepeat) {
            if (
              selectedRepeatType.value === "biweekly" ||
              selectedRepeatType.value === "custom_weekly"
            ) {
              payload.schedule_type = "weekly";
            } else if (selectedRepeatType.value === "custom_monthly") {
              payload.schedule_type = "monthly";
            } else {
              payload.schedule_type = selectedRepeatType.value;
            }
            payload.interval = interval;
            payload.required_end_date = endDate.format("YYYY-MM-DDTHH:mm:ss");
          }
          if (
            isRepeat &&
            (selectedRepeatType.value === "weekly" ||
              selectedRepeatType.value === "biweekly" ||
              selectedRepeatType.value === "custom_weekly")
          ) {
            let tempActiveDaysArray = [];
            for (let key in activeDays) {
              if (activeDays[key]) {
                tempActiveDaysArray.push(weekParse[key]);
              }
            }
            payload.days_of_week = tempActiveDaysArray;
          } else if (
            isRepeat &&
            (selectedRepeatType.value === "monthly" ||
              (selectedRepeatType.value === "custom_monthly" &&
                !isWeekViewOfMonth))
          ) {
            let tempMonthDatesArray = [];
            for (let i = 1; i <= 31; i++) {
              if (activeMonthDates[i]) {
                tempMonthDatesArray.push(i);
              }
            }
            payload.days_of_month = tempMonthDatesArray;
            if (activeMonthDates["Last Day"]) {
              payload.is_last_day_month = true;
            }
            payload.is_weekdays_of_month = false;
          } else if (
            isRepeat &&
            selectedRepeatType.value === "custom_monthly" &&
            isWeekViewOfMonth
          ) {
            let tempMonthDaysArray = [];
            for (let i = 0; i <= 27; i++) {
              if (activeMonthDays[i]) {
                tempMonthDaysArray.push(i);
              }
            }
            payload.weekdays_of_month = tempMonthDaysArray;
            payload.is_weekdays_of_month = true;
          }
        }
        createJobRequest(payload);
        setIsSubmitted(true);
      }
    }
  };

  const checkPlan = () => {
    if (
      isRepeat &&
      selectedOrganization &&
      selectedOrganization.plan &&
      selectedOrganization.plan.name === "Free"
    ) {
      setIsRecurringDisabled(true);
      pricingPopupToggle();
      return false;
    } else {
      return true;
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let position = "";
    let formIsValid = true;
    if (!jobName.trim()) {
      formIsValid = false;
      saveErrors["jobName"] = "Job name is required";
      position = "jobName";
    } else if (checkUrl(jobName)) {
      formIsValid = false;
      saveErrors["jobName"] = "Job name can not contain url.";
      position = "jobName";
    } else if (checkHtml(jobName)) {
      formIsValid = false;
      saveErrors["jobName"] = "Invalid Job name";
      position = "jobName";
    } else if (jobName.length > 255) {
      formIsValid = false;
      saveErrors["jobName"] = "Please enter title within 255 characters";
      window.scrollTo(0, 0, "smooth");
      position = "jobName";
    }
    if (!selectedWorkOrder || !selectedWorkOrder.value) {
      formIsValid = false;
      saveErrors["selectedWorkOrder"] = "Work order is required";
    }
    if (checkUrl(taskDescription)) {
      formIsValid = false;
      saveErrors["taskDescription"] = "Job description can not contain url.";
      position = "taskDescription";
    } else if (checkHtml(taskDescription)) {
      formIsValid = false;
      saveErrors["taskDescription"] = "Invalid Job description";
      position = "taskDescription";
    } else if (taskDescription.length > 512) {
      formIsValid = false;
      saveErrors["taskDescription"] =
        "Please enter description within 512 characters";
      position = "taskDescription";
    }
    if (selectedTaskType && selectedTaskType.value === "ready to dispatch") {
      if (selectedAssignees.length === 0) {
        formIsValid = false;
        saveErrors["selectedAssignees"] = "Assignee is required";
      }
      if (!startDate) {
        formIsValid = false;
      }
      if (!startTime || !endTime) {
        formIsValid = false;
        position = "time";
      }
      if (
        moment(startDate.format("YYYY-MM-DD") + " " + endTime).diff(
          moment(startDate.format("YYYY-MM-DD") + " " + startTime)
        ) < 1
      ) {
        formIsValid = false;
        saveErrors["timeDifference"] = "End time must be after start time";
        position = "time";
      } else if (
        moment(startDate.format("YYYY-MM-DD") + " " + endTime).diff(
          moment(startDate.format("YYYY-MM-DD") + " " + startTime)
        ) < 1800000
      ) {
        formIsValid = false;
        saveErrors["timeDifference"] =
          "Minmium 30 minutes time difference required";
        position = "time";
      }
      if (
        moment(startDate.format("YYYY-MM-DD") + " " + startTime).diff(
          moment()
        ) < 1
      ) {
        formIsValid = false;
        saveErrors["timeDifference"] = "Past time can't be set";
        position = "time";
      }
      if (isRepeat && !selectedRepeatType) {
        formIsValid = false;
        saveErrors["selectedRepeatType"] = "Shift repeat type must be set";
      }
      if (isRepeat && !endDate) {
        formIsValid = false;
        saveErrors["endDate"] = "Ending date is must";
      }
      if (
        isRepeat &&
        selectedRepeatType &&
        selectedRepeatType.value === "custom_weekly"
      ) {
        let haveActiveDays = false;
        Object.keys(activeDays).forEach((key) => {
          if (activeDays[key] === true) {
            haveActiveDays = true;
          }
        });
        if (!haveActiveDays) {
          formIsValid = false;
          saveErrors["activeDays"] = "At least one day needs to be selected";
        }
      }
      if (isRepeat && selectedRepeatType && interval < 1) {
        formIsValid = false;
        saveErrors["interval"] = "Interval is required";
      } else if (
        isRepeat &&
        selectedRepeatType &&
        ((selectedRepeatType.value === "daily" && interval > 150) ||
          (selectedRepeatType.value === "custom_weekly" && interval > 25) ||
          (selectedRepeatType.value === "custom_monthly" && interval > 10))
      ) {
        formIsValid = false;
        saveErrors["interval"] = `Interval maximum limit is ${
          selectedRepeatType.value === "daily"
            ? `150 for daily`
            : selectedRepeatType.value === "custom_weekly"
            ? `25 for weekly`
            : selectedRepeatType.value === "custom_monthly" && `10 for monthly`
        } schedule`;
      }
      if (
        isRepeat &&
        selectedRepeatType &&
        selectedRepeatType.value === "custom_monthly" &&
        !isWeekViewOfMonth
      ) {
        let haveActiveDays = false;
        Object.keys(activeMonthDates).forEach((key) => {
          if (activeMonthDates[key] === true) {
            haveActiveDays = true;
          }
        });
        if (!haveActiveDays) {
          formIsValid = false;
          saveErrors["activeMonthDates"] =
            "At least one day needs to be selected";
        }
      }
      if (
        isRepeat &&
        selectedRepeatType &&
        selectedRepeatType.value === "custom_monthly" &&
        isWeekViewOfMonth
      ) {
        let haveActiveDays = false;
        Object.keys(activeMonthDays).forEach((key) => {
          if (activeMonthDays[key] === true) {
            haveActiveDays = true;
          }
        });
        if (!haveActiveDays) {
          formIsValid = false;
          saveErrors["activeMonthDays"] =
            "At least one day needs to be selected";
        }
      }
    }
    setSaveErrors(saveErrors);
    if (position) {
      handleScroll(position);
    }
    return formIsValid;
  };

  const handleScroll = (position) => {
    if (position === "jobName") {
      scrollName.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (position === "taskDescription") {
      scrollDescription.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (position === "time") {
      scrollTime.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const ThemeColorComp = ({ color }) => (
    <ThemeBox color={color} onClick={() => handleThemeColor(color)}>
      {themeColor === color && <img src={tick} alt="" width="18px" />}
    </ThemeBox>
  );
  return (
    <div className="content">
      {clientId && selectedOrganization && selectedOrganization.id ? (
        <AddLocationModal
          isOpen={popupOpen}
          toggle={popupToggle}
          addClientLocation={addClientLocation}
          addOrUpdateclientLocalMapLoading={addOrUpdateclientLocalMapLoading}
          createdClientLocationInfo={createdClientLocationInfo}
          organization_id={selectedOrganization.id}
          client_id={clientId ? clientId : null}
          action="add"
        />
      ) : null}
      <PricingPlan
        isOpen={pricingPopup}
        toggle={pricingPopupToggle}
        popupText={
          isRecurringDisabled
            ? "You can't create recurring jobs in your current plan"
            : "You have reached the limit of creating jobs."
        }
        popupTitle={
          isRecurringDisabled
            ? "Upgrade to Elite to create recurring jobs"
            : "Upgrade to Elite to create unlimited jobs"
        }
        history={history}
        backRoute="/user/jobs"
        onlyElite
      />
      <PageTitle>{t("create_job")}</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() =>
            history.push(
              `${
                location.state &&
                location.state.jobStatus === "ready-to-dispatch"
                  ? "/user/shift-and-schedule"
                  : "/user/jobs"
              }`
            )
          }
          subTitle={
            location.state && location.state.jobStatus === "ready-to-dispatch"
              ? t("back_to_schedule")
              : t("back_to_all_jobs")
          }
        />
      </ContentHeader>
      <Container padding="30px" gap="20px">
        <CommonText name title fontSize="16px">
          {t("add_job_details")}
        </CommonText>
        <InputSection>
          <InputWithLabelSection ref={scrollName}>
            <InputLabel>
              {t("job_name")} <RedStar>*</RedStar>
            </InputLabel>
            <InputField
              type="text"
              placeholder={t("type_job_name")}
              value={jobName}
              onChange={(e) => onTaskNameChange(e)}
              style={
                saveErrors &&
                saveErrors[`jobName`] && {
                  border: `1px solid #fe5969`,
                  backgroundColor: `rgba(252, 87, 104, 0.05)`,
                }
              }
            />
            {saveErrors && saveErrors["jobName"] && (
              <FormAlert>{saveErrors["jobName"]}</FormAlert>
            )}
          </InputWithLabelSection>

          <InputWithLabelSection>
            <InputLabel>
              {t("job_status")} <RedStar>*</RedStar>
            </InputLabel>
            <Select
              isSearchable
              value={selectedTaskType}
              options={jobStatusOptions}
              placeholder={t("select_type")}
              onChange={(e) => {
                onTaskTypeChange(e);
              }}
              styles={CreateProjectDropdown()}
            />
          </InputWithLabelSection>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "6px",
            }}
          >
            <InputLabel>
              {t("work_order")} <RedStar>*</RedStar>
            </InputLabel>
            <Select
              isSearchable
              value={selectedWorkOrder}
              options={workOrderOptions}
              placeholder={t("select_work_order")}
              //isDisabled={officesShortList && officesShortList.length === 0}
              onChange={(e) => {
                onWorkOrderChange(e);
              }}
              styles={CreateProjectDropdown()}
              filterOption={filterOption}
            />
            {saveErrors && saveErrors["selectedWorkOrder"] && (
              <FormAlert>{saveErrors["selectedWorkOrder"]}</FormAlert>
            )}
          </div>
          <div />

          {/* <InputWithLabelSection>
            <InputLabel>Requires Approval</InputLabel>
            <CommonFlex>
              <ToggleButton
                onClick={() => approvalToggle()}
                active={requiresApproval}
              >
                <ToggleButtonRound active={requiresApproval} />
              </ToggleButton>
              <InputLabel>{requiresApproval ? `Yes` : `No`}</InputLabel>
            </CommonFlex>
          </InputWithLabelSection> */}

          <InputWithLabelSection
            style={{ gridColumn: `1 / span 2` }}
            ref={scrollDescription}
          >
            <InputLabel>{t("description")}</InputLabel>
            <InputTextArea
              text="Description"
              cols="35"
              rows="3"
              value={taskDescription}
              placeholder={t("type_description_here")}
              onChange={(e) => onTaskDescriptionChange(e)}
              height="auto"
              style={
                saveErrors &&
                saveErrors[`taskDescription`] && {
                  border: `1px solid #fe5969`,
                  backgroundColor: `rgba(252, 87, 104, 0.05)`,
                }
              }
            ></InputTextArea>
            {saveErrors &&
              saveErrors["taskDescription"] &&
              saveErrors["taskDescription"] !== undefined && (
                <FormAlert>{saveErrors["taskDescription"]}</FormAlert>
              )}
          </InputWithLabelSection>
          {!addLocationButton ? (
            <>
              <InputSection marginLeft="30px">
                <div style={{ width: "60%" }}>
                  <InputLabel style={{ marginBottom: "10px" }}>
                    {t("location")}{" "}
                  </InputLabel>

                  <CommonText
                    fontSize="13px"
                    color="#2266EF"
                    margin="20px 0 0 0"
                    fontWeight="550"
                    style={{ cursor: "pointer" }}
                    onClick={() => locationInputOpen()}
                  >
                    <ButtonIcon src={Plus} alt="" /> Add Location
                  </CommonText>
                </div>
              </InputSection>
            </>
          ) : (
            <InputWithLabelSection>
              <CommonGrid>
                <InputLabel>{t("location")}</InputLabel>
                {selectedWorkOrder && (
                  <ColoredText
                    fontSize="12px"
                    type="success"
                    hover
                    semibold
                    style={{ cursor: `pointer` }}
                    onClick={() => popupToggle()}
                  >
                    {t("add_new_location")}
                  </ColoredText>
                )}
              </CommonGrid>
              <Select
                isSearchable
                value={selectedLocation}
                options={jobLocationOptions}
                placeholder={t("select_location")}
                onChange={(e) => {
                  onLocationChange(e);
                }}
                styles={CreateProjectDropdown()}
                filterOption={filterOption}
              />
            </InputWithLabelSection>
          )}

          <InputWithLabelSection>
            <AssigneeSection>
              <InputLabel>
                {t("assignee")}{" "}
                {selectedTaskType &&
                  selectedTaskType.value === "ready to dispatch" && (
                    <RedStar>*</RedStar>
                  )}{" "}
              </InputLabel>

              <AddMultipleAssigneeDropdown
                numberOfVisibleMembers={5}
                isLoading={memberShortListIsLoading}
                assigneesList={selectedAssignees}
                membersList={orgMembersShortList}
                handleAssign={handleAssignMember}
                handleRemove={handleRemoveMember}
              />
            </AssigneeSection>
            {saveErrors && saveErrors["selectedAssignees"] && (
              <FormAlert>{saveErrors["selectedAssignees"]}</FormAlert>
            )}
          </InputWithLabelSection>

          {selectedTaskType.value === "ready to dispatch" && (
            <>
              <InputWithLabelSection>
                <InputLabel>
                  {isRepeat ? <>{t("shift_start_date")}</> : <>{t("date")}</>}{" "}
                  <RedStar>*</RedStar>
                </InputLabel>
                <DatePicker
                  id="createIssuedDate"
                  date={startDate}
                  onDateChange={onDateChange}
                  dateDisplayFormat={"DD/MM/YYYY"}
                  placeholder={"Select date"}
                />
              </InputWithLabelSection>

              <InputWithLabelSection ref={scrollTime}>
                <TimeRangeSection>
                  <InputLabel>
                    {t("start_time")}
                    <RedStar>*</RedStar>
                  </InputLabel>
                  <InputLabel>
                    {t("end_time")}
                    <RedStar>*</RedStar>
                  </InputLabel>
                </TimeRangeSection>
                <TimeRangeComponent
                  setStartTime={handleStartTime}
                  setEndTime={handleEndTime}
                  startTimeError={
                    saveErrors &&
                    saveErrors["timeDifference"] !== undefined &&
                    saveErrors["timeDifference"] &&
                    saveErrors["timeDifference"]
                  }
                  endTimeError={
                    saveErrors &&
                    saveErrors["timeDifference"] !== undefined &&
                    saveErrors["timeDifference"] &&
                    saveErrors["timeDifference"]
                  }
                />
                {saveErrors &&
                  saveErrors["timeDifference"] &&
                  saveErrors["timeDifference"] !== undefined && (
                    <FormAlert>{saveErrors["timeDifference"]}</FormAlert>
                  )}
                <DurationContainer>
                  <DurationText>
                    <img src={durationIcon} alt="duration icon" width="15px" />{" "}
                    {t("job_duration")}:{" "}
                    {formatDurationToHoursMinutes(
                      moment(
                        startDate.format("YYYY-MM-DD") + " " + endTime
                      ).diff(
                        moment(startDate.format("YYYY-MM-DD") + " " + startTime)
                      ) / 1000
                    )}
                  </DurationText>
                </DurationContainer>
              </InputWithLabelSection>

              <div style={{ gridColumn: `1 / span 2` }}>
                <CommonGrid justifyContent="start" alignItem="center">
                  <TabButtonContainer columns="auto auto">
                    <TabButton
                      onClick={() => setIsRepeat(false)}
                      selected={isRepeat}
                      index={false}
                    >
                      Single Schedule
                    </TabButton>
                    <TabButton
                      onClick={() => setIsRepeat(true)}
                      selected={isRepeat}
                      index={true}
                    >
                      Recurring Schedule
                    </TabButton>
                  </TabButtonContainer>
                </CommonGrid>

                {isRepeat && (
                  <RepeatSection>
                    <InputWithLabelSection>
                      <InputLabel>
                        {t("repeats")} <RedStar>*</RedStar>
                      </InputLabel>
                      <Select
                        value={selectedRepeatType}
                        options={defaultRepeatOptions}
                        placeholder={t("select_type")}
                        onChange={(e) => {
                          onRepeatTypeChange(e);
                        }}
                        styles={CreateProjectDropdown({
                          error: saveErrors["selectedRepeatType"],
                        })}
                      />

                      {saveErrors &&
                        saveErrors["selectedRepeatType"] !== undefined &&
                        saveErrors["selectedRepeatType"] && (
                          <FormAlert>
                            {saveErrors["selectedRepeatType"]}
                          </FormAlert>
                        )}
                    </InputWithLabelSection>

                    {selectedRepeatType &&
                      (selectedRepeatType.value === "daily" ||
                        selectedRepeatType.value === "custom_weekly" ||
                        selectedRepeatType.value === "custom_monthly") && (
                        <InputWithLabelSection>
                          <CommonFlex gap="10px">
                            <InputLabel>Every</InputLabel>
                            <InputField
                              height="40px"
                              width="70px"
                              type="number"
                              value={interval}
                              onChange={(e) => onIntervalChange(e.target.value)}
                              onWheel={(e) => e.target.blur()}
                              style={
                                saveErrors &&
                                saveErrors[`interval`] && {
                                  border: `1px solid #fe5969`,
                                  backgroundColor: `rgba(252, 87, 104, 0.05)`,
                                }
                              }
                            />
                            <InputLabel>
                              {selectedRepeatType &&
                              selectedRepeatType.value === "daily"
                                ? "day(s)"
                                : selectedRepeatType.value === "custom_weekly"
                                ? "week(s)"
                                : selectedRepeatType.value ===
                                    "custom_monthly" && "month(s)"}
                            </InputLabel>
                          </CommonFlex>
                          {saveErrors &&
                            saveErrors["interval"] !== undefined &&
                            saveErrors["interval"] && (
                              <FormAlert>{saveErrors["interval"]}</FormAlert>
                            )}
                        </InputWithLabelSection>
                      )}

                    {selectedRepeatType &&
                      selectedRepeatType.value === "custom_weekly" && (
                        <>
                          <InputWithLabelSection>
                            <InputLabel
                              error={
                                saveErrors &&
                                saveErrors["activeDays"] !== undefined &&
                                saveErrors["activeDays"] &&
                                saveErrors["activeDays"]
                              }
                            >
                              {t("on_these_days")} <RedStar>*</RedStar>
                            </InputLabel>
                            <WeekDaysContainer>
                              <WeekdayBox
                                active={activeDays.monday}
                                onClick={() => onActiveDaysSelect("monday")}
                              >
                                {t("mon")}
                              </WeekdayBox>
                              <WeekdayBox
                                active={activeDays.tuesday}
                                onClick={() => onActiveDaysSelect("tuesday")}
                              >
                                {t("tue")}
                              </WeekdayBox>
                              <WeekdayBox
                                active={activeDays.wednesday}
                                onClick={() => onActiveDaysSelect("wednesday")}
                              >
                                {t("wed")}
                              </WeekdayBox>
                              <WeekdayBox
                                active={activeDays.thursday}
                                onClick={() => onActiveDaysSelect("thursday")}
                              >
                                {t("thu")}
                              </WeekdayBox>
                              <WeekdayBox
                                active={activeDays.friday}
                                onClick={() => onActiveDaysSelect("friday")}
                              >
                                {t("fri")}
                              </WeekdayBox>
                              <WeekdayBox
                                active={activeDays.saturday}
                                onClick={() => onActiveDaysSelect("saturday")}
                              >
                                {t("sat")}
                              </WeekdayBox>
                              <WeekdayBox
                                active={activeDays.sunday}
                                onClick={() => onActiveDaysSelect("sunday")}
                              >
                                {t("sun")}
                              </WeekdayBox>
                            </WeekDaysContainer>
                          </InputWithLabelSection>
                          {saveErrors &&
                            saveErrors["activeDays"] !== undefined &&
                            saveErrors["activeDays"] && (
                              <FormAlert>{saveErrors["activeDays"]}</FormAlert>
                            )}
                        </>
                      )}

                    {selectedRepeatType &&
                      selectedRepeatType.value === "custom_monthly" && (
                        <>
                          <InputWithLabelSection style={{ minWidth: `400px` }}>
                            <CommonFlex>
                              <CommonFlex
                                gap="10px"
                                margin="0 auto 0 0"
                                style={{ cursor: `pointer` }}
                                onClick={() => setIsWeekViewOfMonth(false)}
                              >
                                <RadioButton active={!isWeekViewOfMonth} />
                                <CommonText paddingLeft="45px" fontWeight="500">
                                  Dates of Month
                                </CommonText>
                              </CommonFlex>
                              <CommonFlex
                                gap="10px"
                                margin="0 auto 0 0"
                                style={{ cursor: `pointer` }}
                                onClick={() => setIsWeekViewOfMonth(true)}
                              >
                                <RadioButton active={isWeekViewOfMonth} />
                                <CommonText fontWeight="500">
                                  Week Days of Month
                                </CommonText>
                              </CommonFlex>
                            </CommonFlex>
                          </InputWithLabelSection>

                          {!isWeekViewOfMonth ? (
                            <>
                              <CommonFlex>
                                <MonthDateContainer>
                                  {activeMonthDates &&
                                    Object.keys(activeMonthDates).length > 0 &&
                                    Object.keys(activeMonthDates).map(
                                      (date, i) => (
                                        <MonthDateBox
                                          key={i}
                                          active={activeMonthDates[date]}
                                          style={{
                                            width:
                                              date === "Last Day" && "105px",
                                          }}
                                          onClick={() =>
                                            onMonthDateSelect(date)
                                          }
                                        >
                                          {date}
                                        </MonthDateBox>
                                      )
                                    )}
                                </MonthDateContainer>
                              </CommonFlex>
                              {saveErrors &&
                                saveErrors["activeMonthDates"] !== undefined &&
                                saveErrors["activeMonthDates"] && (
                                  <FormAlert>
                                    {saveErrors["activeMonthDates"]}
                                  </FormAlert>
                                )}
                            </>
                          ) : (
                            <>
                              <CommonFlex gap="10px">
                                <CommonGrid gap="10px" columns="35px">
                                  <WeekNumberBox>1st</WeekNumberBox>
                                  <WeekNumberBox>2nd</WeekNumberBox>
                                  <WeekNumberBox>3rd</WeekNumberBox>
                                  <WeekNumberBox>4th</WeekNumberBox>
                                </CommonGrid>
                                <MonthDateContainer>
                                  {activeMonthDays &&
                                    Object.keys(activeMonthDays).length > 0 &&
                                    Object.keys(activeMonthDays).map(
                                      (day, index) => (
                                        <MonthDateBox
                                          key={index}
                                          active={activeMonthDays[day]}
                                          onClick={() => onMonthDaySelect(day)}
                                        >
                                          {weekDays[day % 7]}
                                        </MonthDateBox>
                                      )
                                    )}
                                </MonthDateContainer>
                              </CommonFlex>
                              {saveErrors &&
                                saveErrors["activeMonthDays"] !== undefined &&
                                saveErrors["activeMonthDays"] && (
                                  <FormAlert>
                                    {saveErrors["activeMonthDays"]}
                                  </FormAlert>
                                )}
                            </>
                          )}
                        </>
                      )}

                    {scheduleDescription &&
                      selectedRepeatType &&
                      (selectedRepeatType.value === "custom_weekly" ||
                        selectedRepeatType.value === "custom_monthly") && (
                        <ScheduleText>*{scheduleDescription}</ScheduleText>
                      )}

                    <InputWithLabelSection>
                      <InputLabel>
                        {t("ending_on")} <RedStar>*</RedStar>
                      </InputLabel>
                      <SingleDatePicker
                        dateDisplayFormat="DD/MM/YYYY"
                        placeholder="Select End Date"
                        date={endDate ? endDate : null}
                        onDateChange={onEndDateSelect}
                        disablePreviousDatesFrom={startDate}
                        error={
                          saveErrors &&
                          saveErrors["endDate"] !== undefined &&
                          saveErrors["endDate"] &&
                          saveErrors["endDate"]
                        }
                      />
                      {saveErrors &&
                        saveErrors["endDate"] !== undefined &&
                        saveErrors["endDate"] && (
                          <FormAlert>{saveErrors["endDate"]}</FormAlert>
                        )}
                    </InputWithLabelSection>
                  </RepeatSection>
                )}
              </div>
            </>
          )}

          <InputWithLabelSection>
            <InputLabel>{t("choose_theme")}</InputLabel>
            <ThemesContainer>
              <ThemeColorComp color="#D4F4EE" />
              <ThemeColorComp color="#D2E4FE" />
              <ThemeColorComp color="#D9D7FC" />
              <ThemeColorComp color="#FDF3E4" />
            </ThemesContainer>
          </InputWithLabelSection>

          <div />

          <PrimaryButton
            disabled={handleDisable()}
            onClick={() => handleSave()}
            margin="20px 0 0 0"
          >
            {createOrUpdateJobIsLoading ? (
              <ButtonTextLoader loadingText={t("creating")} fontSize="13px" />
            ) : (
              t("create_job")
            )}
          </PrimaryButton>
          <div />
        </InputSection>
      </Container>
    </div>
  );
};

export default CreateJob;

import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { getRandomColor } from 'utils/getRandomColor';

import avatar5 from '../../../assets/img/avatar_5.svg';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import RightSlidingPanel from '../../../components/RightSlidingPanel/RightSlidingPanel';
import { CommonGrid, PageTitle } from '../../../styledComponents/common';
import {
  DailyTime,
  TableBodyContainer,
  TableBodyItem,
  TableContainer,
  TableHeadContainer,
  TableHeadItem,
  TimeRangeText,
  TimesheetContainer,
  TimesheetDate,
  TotalTimeContainer,
  TotalTimeDiv,
  TotalTimeLabel,
  TotalTimeText,
  WeeklyTotal,
} from '../../../styledComponents/timesheet';
import { getHours, toHHMMSS } from '../../../utils/helper';
import TimesheetDetails from '../TimesheetDetailsPanel';
import TimesheetFilter from '../TimesheetFilter';

const getTimes = date => {
  const times = [];
  let startDate = moment(date).subtract(13, 'days');
  while (moment(startDate).isSameOrBefore(moment(date))) {
    times.push({
      date: startDate,
      hours: '0 h 00 m',
    });
    startDate = moment(startDate).add(1, 'days');
  }
  return times;
};

const Biweekly = props => {
  const [selectedProject, setSelectedProject] = useState('');
  const [endDate, setEndDate] = useState(
    moment().endOf('isoWeek').format('YYYY-MM-DD'),
  );
  const [selectedMember, setSelectedMember] = useState('');
  const [selectedTask, setSelectedTask] = useState('');

  const [selectedTimezone, setSelectedTimezone] = useState(null);

  const [timesheetsData, setTimesheetsData] = useState(null);

  // details panel
  const [detailsUserData, setDetailsUserData] = useState(null);
  const [detailsPanelIsOpen, setDetailsPanelIsOpen] = useState(false);

  const [bgColors, setBgColors] = useState({});

  const [selectedOffice, setSelectedOffice] = useState({
    label: '(All Offices)',
    value: null,
  });

  const {
    isLoading,

    selectedOrganization,
    profileData,

    clearTimesheetList,
    getOtherTimesheets,
    timesheetsList,

    getDailyTimesheets,
    dailyTimesheetLoading,
    dailyTimesheets,
    //member short list
    memberShortListIsLoading,
    orgMembersShortList,
    getOrganizationMembersShortList,

    //Office
    getOfficeDetails,
    officeDetails,
    getOfficesShortList,
    officesShortList,
  } = props;

  useEffect(() => {
    return () => {
      clearTimesheetList();
    };
  }, []);

  useEffect(() => {
    const startDate = moment(endDate).subtract(13, 'days').format('YYYY-MM-DD');
    if (selectedOrganization && selectedOrganization.id) {
      // if (selectedOrganization.timezone) {
      //   setSelectedTimezone({
      //     type: "Organization",
      //     timezone: selectedOrganization.timezone,
      //     offset: selectedOrganization.timezoneoffset,
      //   });
      // }

      // setSelectedProject("");
      // setSelectedTask("");
      setSelectedMember('');

      const payload = {
        organization_id: selectedOrganization.id,
        start_date: startDate,
        end_date: endDate,
      };
      getOtherTimesheets(payload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    const durations = [0];
    const firstWeekDurations = [0];
    const secondWeekDurations = [0];
    const times = getTimes(endDate);
    if (timesheetsList && timesheetsList.length > 0) {
      timesheetsList.forEach(timesheet => {
        durations.push(timesheet.duration);
        times.forEach((item, index) => {
          if (moment(item.date).format('YYYY-MM-DD') === timesheet.date) {
            if (timesheet.duration !== 0) {
              item.hours = getHours(toHHMMSS(timesheet.duration));
            }
            if (index < 7) {
              firstWeekDurations.push(timesheet.duration);
            } else {
              secondWeekDurations.push(timesheet.duration);
            }
          }
        });
        if (
          detailsUserData &&
          detailsUserData.id === timesheet.user_id &&
          detailsUserData.date &&
          moment(detailsUserData.date).format('YYYY-MM-DD') === timesheet.date
        ) {
          setDetailsUserData({
            ...detailsUserData,
            totalTime: getHours(toHHMMSS(timesheet.duration)),
          });
        }
      });
      setTimesheetsData({
        durations,
        firstWeekDurations,
        secondWeekDurations,
        timesheets: [...times],
      });
    } else if (timesheetsList && timesheetsList.length === 0) {
      setTimesheetsData({
        durations,
        firstWeekDurations,
        secondWeekDurations,
        timesheets: times,
      });
    }
  }, [timesheetsList]);

  // const selectProject = (e) => {
  //   let organizationId = selectedOrganization ? selectedOrganization.id : "";
  //   const startDate = moment(endDate).subtract(13, "days").format("YYYY-MM-DD");
  //   if (organizationId) {
  //     const payload = {
  //       organization_id: organizationId,
  //       start_date: startDate,
  //       end_date: endDate,
  //     };
  //     if (selectedMember) {
  //       payload.user_id = selectedMember.id;
  //     }
  //     if (e && e.value) {
  //       payload.project_id = e.value;
  //     }
  //     if (selectedTimezone && selectedTimezone.type === "Member") {
  //       payload.timezone = selectedTimezone.timezone;
  //     }
  //     getOtherTimesheets(payload);
  //   }

  //   if (
  //     e &&
  //     e.value &&
  //     selectedProject &&
  //     selectedProject.value &&
  //     e.value !== selectedProject.value
  //   ) {
  //     setSelectedProject(e);
  //     setSelectedTask("");
  //   } else if ((e && !e.value) || e == null) {
  //     setSelectedMember("");
  //     setSelectedTask("");
  //     setSelectedProject(e);
  //   } else {
  //     setSelectedProject(e);
  //   }
  // };
  // const selectTask = (e) => {
  //   let organizationId = selectedOrganization ? selectedOrganization.id : "";
  //   const startDate = moment(endDate).subtract(13, "days").format("YYYY-MM-DD");
  //   if (organizationId) {
  //     const payload = {
  //       organization_id: organizationId,
  //       start_date: startDate,
  //       end_date: endDate,
  //     };
  //     if (selectedMember) {
  //       payload.user_id = selectedMember.id;
  //     }
  //     if (e && e.value) {
  //       payload.task_id = e.value;
  //     }
  //     if (selectedTimezone && selectedTimezone.type === "Member") {
  //       payload.timezone = selectedTimezone.timezone;
  //     }
  //     getOtherTimesheets(payload);
  //   }
  //   setSelectedTask(e);
  // };
  const selectTeamMember = e => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    const startDate = moment(endDate).subtract(13, 'days').format('YYYY-MM-DD');
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
        user_id: e.id,
      };
      // if (selectedProject && selectedProject.value) {
      //   payload.project_id = selectedProject.value;
      // }
      // if (selectedTask && selectedTask.value) {
      //   payload.task_id = selectedTask.value;
      // }
      // if (
      //   selectedTimezone &&
      //   selectedTimezone.type === "Member" &&
      //   e.timezone &&
      //   e.timezone.tz
      // ) {
      //   payload.timezone = e.timezone.tz;
      //   setSelectedTimezone({
      //     type: "Member",
      //     timezone: e.timezone.tz,
      //     offset: e.timezone.offset,
      //   });
      // }
      setSelectedMember(e);
      getOtherTimesheets(payload);
    }
  };

  const clearMember = () => {
    const organizationId = selectedOrganization ? selectedOrganization.id : '';
    const startDate = moment(endDate).subtract(13, 'days').format('YYYY-MM-DD');
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
      };
      // if (selectedProject && selectedProject.value) {
      //   payload.project_id = selectedProject.value;
      // }
      // if (selectedTask && selectedTask.value) {
      //   payload.task_id = selectedTask.value;
      // }
      // if (selectedOrganization.timezone) {
      //   setSelectedTimezone({
      //     type: "Organization",
      //     timezone: selectedOrganization.timezone,
      //     offset: selectedOrganization.timezoneoffset,
      //   });
      // }
      getOtherTimesheets(payload);
    }
    setSelectedMember('');
  };

  const selectDate = dateRange => {
    const date = dateRange.endDate;
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    setEndDate(moment(date).endOf('isoWeek').format('YYYY-MM-DD'));
    const startDate = moment(moment(date).endOf('isoWeek'))
      .subtract(13, 'days')
      .format('YYYY-MM-DD');
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate,
        end_date: moment(date).endOf('isoWeek').format('YYYY-MM-DD'),
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedMember && selectedMember.id !== '') {
        payload.user_id = selectedMember.id;
      }
      if (selectedTimezone && selectedTimezone.type === 'Member') {
        payload.timezone = selectedTimezone.timezone;
      }
      getOtherTimesheets(payload);
    }
  };

  // const onTimezoneSelect = (timezone) => {
  //   let organizationId = selectedOrganization ? selectedOrganization.id : "";
  //   const startDate = moment(endDate).subtract(13, "days").format("YYYY-MM-DD");
  //   setSelectedTimezone(timezone);
  //   if (organizationId) {
  //     const payload = {
  //       organization_id: organizationId,
  //       start_date: startDate,
  //       end_date: endDate,
  //     };
  //     if (selectedProject && selectedProject.value) {
  //       payload.project_id = selectedProject.value;
  //     }
  //     if (selectedTask && selectedTask.value) {
  //       payload.task_id = selectedTask.value;
  //     }
  //     if (selectedMember && selectedMember.id !== "") {
  //       payload.user_id = selectedMember.id;
  //     }
  //     if (timezone && timezone.type === "Member") {
  //       payload.timezone = timezone.timezone;
  //     }
  //     getOtherTimesheets(payload);
  //   }
  // };

  const toggleDetailsPanel = value => {
    setDetailsPanelIsOpen(value);
    if (!value) {
      setDetailsUserData(null);
    }
  };

  const getTimesheetDetails = (date, userId) => {
    if (selectedOrganization && selectedOrganization.id && date && userId) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: date.format('YYYY-MM-DD'),
        user_id: userId,
      };
      // if (selectedProject && selectedProject.value) {
      //   payload.project_id = selectedProject.value;
      // }
      // if (selectedTask && selectedTask.value) {
      //   payload.task_id = selectedTask.value;
      // }
      // if (selectedTimezone && selectedTimezone.timezone) {
      //   payload.timezone = selectedTimezone.timezone;
      // }
      getDailyTimesheets(payload);
      toggleDetailsPanel(true);
    }
  };

  const handleSetUserData = (date, hours) => {
    if (date && hours) {
      setDetailsUserData({
        id: selectedMember
          ? selectedMember.id
          : localStorage.getItem('user_id'),
        name: selectedMember
          ? selectedMember.name
          : profileData && profileData.id
          ? `${profileData.first_name}${
              profileData.last_name ? ` ${profileData.last_name}` : ''
            }`
          : null,
        avatar: selectedMember
          ? selectedMember.avatar
            ? selectedMember.avatar
            : avatar5
          : profileData && profileData.thumbnail
          ? profileData.thumbnail
          : avatar5,
        color: selectedMember
          ? selectedMember.color
          : profileData && profileData.color
          ? profileData.color
          : null,
        date: date,
        totalTime: hours,
      });
    }
  };
  const selectOffice = e => {
    const payload = {
      organization_id: selectedOrganization ? selectedOrganization.id : '',
    };
    if (e && e.value) {
      payload.office_id = e.value;
      getOfficeDetails(payload);
    }

    setSelectedMember('');
    // getDailyTimesheets(payload);
    setSelectedOffice(e);
  };

  return (
    <div className="content">
      <RightSlidingPanel
        isOpen={detailsPanelIsOpen}
        closePanel={() => toggleDetailsPanel(false)}
        width="500px"
      >
        {dailyTimesheetLoading ? (
          <ComponentCircleLoader padding="35vh 0" />
        ) : (
          <>
            <TimesheetDetails
              timesheetsList={dailyTimesheets}
              userData={detailsUserData}
              // selectedProject={selectedProject}
              // selectedTask={selectedTask}
              // timezone={selectedTimezone && selectedTimezone.timezone}
              fromPage="other"
            />
          </>
        )}
      </RightSlidingPanel>
      <CommonGrid alignItem="center">
        <PageTitle>Bi-Weekly Timesheet</PageTitle>
      </CommonGrid>
      <TimesheetFilter
        page="biweekly"
        startDate={moment(endDate).subtract(13, 'days').format('YYYY-MM-DD')}
        endDate={moment(endDate)}
        changeDateRange={selectDate}
        // selectedProject={selectedProject}
        // selectProject={selectProject}
        selectedMember={selectedMember}
        selectMember={selectTeamMember}
        clearMember={clearMember}
        // selectedTask={selectedTask}
        // selectTask={selectTask}
        //Member Short List
        getOrganizationMembersList={getOrganizationMembersShortList}
        organizationMembersList={orgMembersShortList}
        selectedOrganization={selectedOrganization}
        // selectTimezone={onTimezoneSelect}
        // selectedTimezone={selectedTimezone}
        //office
        officeDetails={officeDetails}
        getOfficesShortList={getOfficesShortList}
        officesShortList={officesShortList}
        selectedOffice={selectedOffice}
        selectOffice={selectOffice}
        showWeekPickerFooter={false}
      />
      <TimesheetContainer>
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            <TotalTimeContainer>
              <TimeRangeText>{`${moment(endDate)
                .subtract(13, 'days')
                .format('MMMM DD')} - ${moment(endDate).format(
                'MMMM DD',
              )}`}</TimeRangeText>
              <TotalTimeDiv>
                <TotalTimeLabel>Total Hour</TotalTimeLabel>{' '}
                <TotalTimeText>
                  {timesheetsData &&
                  timesheetsData.durations &&
                  timesheetsData.durations.length > 0
                    ? getHours(
                        toHHMMSS(
                          timesheetsData.durations
                            .slice(1)
                            .reduce(
                              (prev, cur) => cur + prev,
                              timesheetsData.durations[0],
                            ),
                        ),
                      )
                    : '0 h 00 m'}
                </TotalTimeText>
              </TotalTimeDiv>
            </TotalTimeContainer>

            <TableContainer>
              <TableHeadContainer>
                {timesheetsData &&
                  timesheetsData.timesheets &&
                  timesheetsData.timesheets.length > 0 &&
                  timesheetsData.timesheets
                    .slice(0, 7)
                    .map((time, index) => (
                      <TableHeadItem key={index}>
                        {moment(time.date).format('ddd')}
                      </TableHeadItem>
                    ))}
                <TableHeadItem total>Total</TableHeadItem>
              </TableHeadContainer>
              <TableBodyContainer>
                {timesheetsData &&
                  timesheetsData.timesheets &&
                  timesheetsData.timesheets.length > 0 && (
                    <>
                      {timesheetsData.timesheets
                        .slice(0, 7)
                        .map((time, index) => {
                          if (!bgColors[time.date])
                            bgColors[time.date] = getRandomColor();
                          return (
                            <TableBodyItem key={index}>
                              {time.hours === '0 h 00 m' ? (
                                <DailyTime
                                  backgroundColor={bgColors[time.date]}
                                >
                                  -
                                </DailyTime>
                              ) : (
                                <DailyTime
                                  backgroundColor={bgColors[time.date]}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    getTimesheetDetails(
                                      time.date,
                                      selectedMember
                                        ? selectedMember.id
                                        : localStorage.getItem('user_id'),
                                    );
                                    handleSetUserData(time.date, time.hours);
                                  }}
                                >
                                  {time.hours}
                                </DailyTime>
                              )}
                              <TimesheetDate>
                                {moment(time.date).format('MMM DD')}
                              </TimesheetDate>
                            </TableBodyItem>
                          );
                        })}
                      <TableBodyItem>
                        <WeeklyTotal>
                          {getHours(
                            toHHMMSS(
                              timesheetsData.firstWeekDurations
                                .slice(1)
                                .reduce(
                                  (prev, cur) => cur + prev,
                                  timesheetsData.firstWeekDurations[0],
                                ),
                            ),
                          )}
                        </WeeklyTotal>
                      </TableBodyItem>
                    </>
                  )}
              </TableBodyContainer>
              <TableBodyContainer>
                {timesheetsData &&
                  timesheetsData.timesheets &&
                  timesheetsData.timesheets.length > 0 && (
                    <>
                      {timesheetsData.timesheets
                        .slice(7, 14)
                        .map((time, index) => {
                          if (!bgColors[time.date])
                            bgColors[time.date] = getRandomColor();
                          return (
                            <TableBodyItem key={index}>
                              {time.hours === '0 h 00 m' ? (
                                <DailyTime
                                  backgroundColor={bgColors[time.date]}
                                >
                                  -
                                </DailyTime>
                              ) : (
                                <DailyTime
                                  backgroundColor={bgColors[time.date]}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    getTimesheetDetails(
                                      time.date,
                                      selectedMember
                                        ? selectedMember.id
                                        : localStorage.getItem('user_id'),
                                    );
                                    handleSetUserData(time.date, time.hours);
                                  }}
                                >
                                  {time.hours}
                                </DailyTime>
                              )}
                              <TimesheetDate>
                                {moment(time.date).format('MMM DD')}
                              </TimesheetDate>
                            </TableBodyItem>
                          );
                        })}
                      <TableBodyItem>
                        <WeeklyTotal>
                          {getHours(
                            toHHMMSS(
                              timesheetsData.secondWeekDurations
                                .slice(1)
                                .reduce(
                                  (prev, cur) => cur + prev,
                                  timesheetsData.secondWeekDurations[0],
                                ),
                            ),
                          )}
                        </WeeklyTotal>
                      </TableBodyItem>
                    </>
                  )}
              </TableBodyContainer>
            </TableContainer>
          </>
        )}
      </TimesheetContainer>
    </div>
  );
};

export default Biweekly;

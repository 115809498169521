import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Avatar5 from '../../../assets/img/avatar_5.svg';
import crossIcon from '../../../assets/img/icons/cross_black.svg';
import Bean from '../../../assets/img/icons/delete.svg';
import PlusBlack from '../../../assets/img/icons/plus_black_2.svg';
import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import DropdownLoader from '../../../components/DropdownLoader/DropdownLoader';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import {
  ButtonIcon,
  PrimaryButton,
  WhiteButton,
  WhiteButtonWithIcon,
} from '../../../styledComponents/buttons';
import {
  GeneralInfoItem,
  GeneralInfoSection,
  HoveredSection,
  InnerDivTooltip,
  RoundHover,
  ToolTipContainer,
  TooltipText,
} from '../../../styledComponents/clients';
import {
  CardTitle,
  CommonFlex,
  CommonGrid,
  CommonText,
  Container,
  ContentHeader,
  FormAlert,
  InputField,
  InputLabel,
  InputSection,
  InputWithLabelSection,
  ManagerBadge,
  PageTitle,
} from '../../../styledComponents/common';
import {
  ButtonFlexContainer,
  PaddingBox,
  UpperTitleButtonSection,
} from '../../../styledComponents/members';
import { RedStar } from '../../../styledComponents/members';
import {
  AddOfficeContainer,
  AssigneeSection,
  CrossIcon,
  NameRoleSection,
  OfficeMemberDivContainer,
  officeMemberAvatar,
  officeMemberDiv,
  officeMemberRole,
} from '../../../styledComponents/offices';
import { checkHtml, checkUrl, validateEmail } from '../../../utils/helper';
import StaticTaskLocationModal from '../../ClientsNew/ClientDetails/StaticTaskLocationModal';
import { DataAndMapContainer } from '../../FieldServices/JobSites/jobSitesStyles';
import { MapAndAlertContainer } from '../../FieldServices/RouteMap/routeMapStyles';
import { AddAssigneeDropdown } from '../AddAssigneeDropdown';
import AddLocationModal from '../AddLocationModal';
import OfficeMembers from '../AddOffice/OfficeMembers';
import DeleteOfficeModal from '../DeleteOfficeModal';

const OfficeDetails = props => {
  const { t } = useTranslation();
  const [officeName, setOfficeName] = useState('');
  const [officeID, setOfficeId] = useState(null);
  const [assignees, setAssignees] = useState([]);
  const [visibility, setVisibility] = useState(0);
  const [saveErrors, setSaveErrors] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [chnagedOfficeName, setChnagedOfficeName] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [action, setAction] = useState('');
  const [locationInfo, setLocationInfo] = useState(null);
  const [staticLocationModalOpen, setStaticLocationModalOpen] = useState(false);
  const [selectedStaticTaskLocation, setSelectedStaticTaskLocation] =
    useState(null);

  useEffect(() => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.officeDetails != null
    ) {
      setOfficeName(props.officeDetails.name);
    }
  }, [props.officeDetails]);

  useEffect(() => {
    if (props.officeLocation && props.officeLocation[0]) {
      setLocationInfo(props.officeLocation[0]);
    } else if (props.officeLocation !== null) {
      setLocationInfo(props.officeLocation);
    }
  }, [props.officeLocation, props.officeLocationCreateUpdateSuccess]);

  const onOfficeNameChange = e => {
    const { value } = e.target;
    setChnagedOfficeName(true);
    setOfficeName(value);
    if (saveErrors && saveErrors['officeName']) {
      delete saveErrors['officetName'];
    }
  };

  const cancelFunction = () => {
    props.history.push({
      pathname: '/user/offices',
      // state: { from: 'create' },
    });
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!officeName.trim()) {
      formIsValid = false;
      saveErrors['officeName'] = t('please_enter_office_name');
    } else if (checkUrl(officeName)) {
      formIsValid = false;
      saveErrors['officeName'] = t('office_name_can_not_contain_url');
    } else if (checkHtml(officeName)) {
      formIsValid = false;
      saveErrors['officeName'] = t('invalid_office_name');
    } else if (officeName.length > 256) {
      formIsValid = false;
      saveErrors['officeName'] = t(
        'please_enter_office_name_within_256_characters',
      );
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      let payload = {
        organization_id: props.selectedOrganization.id,
      };
      props.getOrganizationMembersShortList(payload);
    }
  }, [props.selectedOrganization]);

  useEffect(() => {
    if (
      props.officeDetails &&
      props.officeDetails.employees &&
      props.officeDetails.employees.length > 0
    ) {
      let membersList = [];
      props.officeDetails.employees.map(member => {
        membersList.push({
          id: member.id,
          userId: member.user.id,
          name: `${member.user.first_name}${
            member.user.last_name ? ` ${member.user.last_name}` : ``
          }`,
          email: member.user.email,
          manageTeam: member.can_manage
            ? { value: 'manager', label: 'Team Manager' }
            : { value: 'member', label: 'Team Member' },
          avatar: member.avatar ? member.avatar : Avatar5,
          can_manage: member.can_manage,
          color: member.user.color,
        });
      });
      setAssignees(membersList);
    } else if (
      props.officeMembersList &&
      props.officeMembersList.length === 0
    ) {
      setAssignees([]);
    }
  }, [props.officeDetails]);

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      if (props.location.search) {
        const queryParsed = queryString.parse(props.location.search);
        let payload = {
          organization_id: props.selectedOrganization.id,
          office_id: queryParsed.id,
        };

        setOfficeId(payload.office_id);
        props.getOfficeDetails(payload);
      }
    }
  }, [props.selectedOrganization, officeID]);

  const updateAssingeeList = person => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      officeID
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        office_id: officeID,
        user_id: person.id,
      };
      props.addOfficeMember(payload);
    }
  };

  const handleDeleteMember = assigneeId => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      officeID &&
      assigneeId
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        office_id: officeID,
        user_id: assigneeId,
      };
      props.removeOfficeMember(payload);
    }
  };

  const showAssignee = state => {
    setVisibility(state);
  };

  const handleUpdateOfficeMember = (canManage, memberId) => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      officeID &&
      memberId
    ) {
      const payload = {
        organization_id: props.selectedOrganization.id,
        office_id: officeID,
        member_id: memberId,
        can_manage: canManage,
      };
      props.updateOfficeMember(payload);
    }
  };

  const handleRemoveOfficeMember = memberId => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      officeID &&
      memberId
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        office_id: officeID,
        user_id: memberId,
        // getDetails: true,
      };
      props.removeOfficeMember(payload);
    }
  };

  const popupToggle = () => {
    setAction('add');
    setPopupOpen(!popupOpen);
  };

  const popupToggleUpdate = () => {
    setAction('update');
    setPopupOpen(!popupOpen);
  };

  const toggle = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleOfficeDelete = () => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        office_id: officeID,
      };

      props.deleteOffice(payload);

      setTimeout(() => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
        props.history.push({
          pathname: '/user/offices',
          state: { from: 'details' },
        });
      }, 500);
    }
  };

  const handleSave = () => {
    if (chnagedOfficeName) {
      if (checkError()) {
        if (props.selectedOrganization && props.selectedOrganization.id) {
          let payload = {
            organizationID: props.selectedOrganization.id,
            office_id: officeID,
            name: officeName,
          };
          props.updateOfficeInfo(payload);

          setTimeout(() => {
            props.history.push({
              pathname: '/user/offices',
              // state: { from: "create" },
            });
          }, 500);
        }
      }
    } else {
      props.history.push('/user/offices');
    }
  };

  const handleShowLocation = () => {
    setTimeout(() => {
      setStaticLocationModalOpen(true);
    }, 300);
  };

  return (
    <div className="content">
      <StaticTaskLocationModal
        isOpen={staticLocationModalOpen}
        toggle={() => {
          setStaticLocationModalOpen(false);
          setSelectedStaticTaskLocation(null);
        }}
        taskLocation={locationInfo !== null ? locationInfo : ''}
      />

      <PageTitle>{t('offices')}</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => props.history.push('/user/offices')}
          subTitle="Back to all offces"
        />
      </ContentHeader>

      <Container style={{ padding: `30px 0` }}>
        {props.isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            {props.officeDetails &&
              props.officeDetails !== null &&
              officeID && (
                <>
                  <PaddingBox>
                    <UpperTitleButtonSection padding="0 0">
                      <CardTitle>{t('edit_office_details')}</CardTitle>
                      <UpperTitleButtonSection padding="0 0" gap="0">
                        <WhiteButtonWithIcon
                          type="delete"
                          fontSize="14px"
                          onClick={toggle}
                        >
                          <ButtonIcon src={Bean} alt="" />
                          {t('delete_office')}
                        </WhiteButtonWithIcon>
                        <DeleteOfficeModal
                          id={officeID ? officeID : ''}
                          isOpen={isDeleteModalOpen}
                          toggle={toggle}
                          handleDelete={handleOfficeDelete}
                        />
                      </UpperTitleButtonSection>
                    </UpperTitleButtonSection>

                    <InputSection>
                      <InputLabel>
                        {t('office_name')} <RedStar>*</RedStar>
                      </InputLabel>
                      <InputField
                        marginTop="10px"
                        width="25%"
                        type="text"
                        placeholder={t('type_office_name')}
                        value={officeName}
                        onChange={e => onOfficeNameChange(e)}
                        error={
                          saveErrors &&
                          saveErrors['officeName'] &&
                          saveErrors['officeName'] !== undefined
                        }
                      />
                      {saveErrors &&
                        saveErrors['officeName'] &&
                        saveErrors['officeName'] !== undefined && (
                          <FormAlert margin="0 0 0 5px">
                            {saveErrors['officeName']}
                          </FormAlert>
                        )}
                    </InputSection>

                    <AssigneeSection>
                      <CommonText $label>{t('assignee')}</CommonText>

                      <AddAssigneeDropdown
                        isLoading={props.memberShortListIsLoading}
                        assignees={assignees}
                        visibilityChange={showAssignee}
                        updateState={updateAssingeeList}
                        membersList={props.orgMembersShortList}
                        getMembersList={props.getOrganizationMembersShortList}
                        selectedOrganization={props.selectedOrganization}
                        assigneeRemove={handleDeleteMember}
                        officeMemberIsLoading={props.officeMemberIsLoading}
                        loadingMemberId={props.loadingMemberId}
                        office_id={officeID}
                      />
                    </AssigneeSection>

                    {props.officeDetails.employees &&
                      props.officeDetails.employees.length > 0 && (
                        <div
                          style={{ marginTop: '25px', marginBottom: '25px' }}
                        >
                          <CommonText $label margin="0 0 15px 0">
                            Office Members
                          </CommonText>

                          <OfficeMembers
                            selectedOrganization={props.selectedOrganization}
                            officeId={officeID}
                            officeMemberListLoading={
                              props.officeMemberListLoading
                            }
                            getOfficeMembersList={props.getOfficeMembersList}
                            membersList={props.officeMembersList}
                            updateMember={handleUpdateOfficeMember}
                            deleteMember={handleRemoveOfficeMember}
                            officeMemberIsLoading={props.officeMemberIsLoading}
                            loadingMemberId={props.loadingMemberId}
                            history={props.history}
                          />
                        </div>
                      )}

                    <CommonText margin="10px 0" $label>
                      {t('location')}
                    </CommonText>

                    {props.officeLocation &&
                    props.officeLocation.length !== 0 ? (
                      <>
                        <GeneralInfoSection
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleShowLocation()}
                        >
                          <GeneralInfoItem>
                            <CommonText title>
                              {locationInfo && locationInfo.address
                                ? locationInfo.address
                                : ''}
                            </CommonText>
                            <CommonText $label fontWeight="500">
                              {locationInfo && locationInfo.name
                                ? locationInfo.name
                                : ''}
                            </CommonText>
                          </GeneralInfoItem>
                        </GeneralInfoSection>
                        <ButtonFlexContainer>
                          <WhiteButtonWithIcon
                            onClick={() => popupToggleUpdate()}
                          >
                            <ButtonIcon src={PlusBlack} alt="" />
                            {t('change_location')}
                          </WhiteButtonWithIcon>
                        </ButtonFlexContainer>
                      </>
                    ) : (
                      <>
                        <GeneralInfoSection>
                          <GeneralInfoItem>
                            <CommonText title>
                              {t('no_address_found')}
                            </CommonText>
                            <CommonText $label fontWeight="500">
                              {t('no_name_set')}
                            </CommonText>
                          </GeneralInfoItem>
                        </GeneralInfoSection>
                        <ButtonFlexContainer>
                          <WhiteButtonWithIcon onClick={() => popupToggle()}>
                            <ButtonIcon src={PlusBlack} alt="" />
                            {t('add_location')}
                          </WhiteButtonWithIcon>
                        </ButtonFlexContainer>
                      </>
                    )}
                    <div style={{ margin: '10px  0 0 0' }}>
                      <PrimaryButton
                        onClick={() => handleSave()}
                        padding="0 30px"
                      >
                        Save & Close
                      </PrimaryButton>
                    </div>
                  </PaddingBox>
                </>
              )}
          </>
        )}

        {locationInfo && locationInfo.name ? (
          props.selectedOrganization && props.selectedOrganization.id ? (
            <AddLocationModal
              isOpen={popupOpen}
              toggle={popupToggleUpdate}
              addOfficeLocation={props.addOrUpdateOfficeLocation}
              addOrUpdateOfficeLocalMapLoading={
                props.addOrUpdateOfficeLocalMapLoading
              }
              createdOfficeLocationInfo={props.officeLocation}
              officeLocationCreateUpdateSuccess={
                props.officeLocationCreateUpdateSuccess
              }
              organization_id={props.selectedOrganization.id}
              id={locationInfo.id}
              office_id={officeID}
              action={action}
              name={locationInfo.name}
              address={locationInfo.address}
              radius={locationInfo.radius}
              latitude={locationInfo.latitude}
              longitude={locationInfo.longitude}
            />
          ) : null
        ) : props.selectedOrganization && props.selectedOrganization.id ? (
          <AddLocationModal
            isOpen={popupOpen}
            toggle={popupToggle}
            addOfficeLocation={props.addOfficeLocation}
            addOrUpdateOfficeLocalMapLoading={
              props.addOrUpdateOfficeLocalMapLoading
            }
            createdOfficeLocationInfo={props.officeLocation}
            organization_id={props.selectedOrganization.id}
            office_id={officeID}
            action={action}
          />
        ) : null}
      </Container>
    </div>
  );
};

export default OfficeDetails;

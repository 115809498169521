import { useState, useEffect } from "react";

import styled from "styled-components";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import {
  InputField,
  InputWithLabelSection,
  InputLabel,
  FormAlert,
} from "../../styledComponents/common";
import { PrimaryButton } from "../../styledComponents/buttons";
import { RedStar } from "../../styledComponents/members";

import { timezones } from "../../utils/timezone";
import { currenciesWithSymbols } from "../../utils/currencies";
import { checkUrl, checkHtml } from "../../utils/helper";

import Select from "../../components/ReactSelectDropdown";
import DropdownStyles from "../../components/DropdownStyle/AddOrganizationDropdownStyle";
import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";

import alertLightIcon from "../../assets/img/icons/alert-light.svg";

const AddOrgForm = styled.div`
  display: grid;
  grid-row-gap: 12px;
`;

const SubmitButton = styled(PrimaryButton)`
  width: 100%;
  @media (min-width: 1400px) {
    width: 80%;
  }
`;
const CurrencyAlertSection = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 15px;
`;
const CurrencyAlertIcon = styled.img``;
const CurrencyAlertText = styled.div`
  font-size: 13px;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.main.labelText};
`;

const filterOptions = (options, filterString, values) => {
  return options.filter(
    (x) => x.label.includes(filterString) || x.metadata.includes(filterString)
  );
};

export const AddOrganizationForm = (props) => {
  const [organizationName, setOrganizationName] = useState("");
  const [address, setAddress] = useState("");
  const [type, setType] = useState({ name: "", id: "" });
  const [timezone, setTimezone] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currency, setCurrency] = useState({
    value: "USD",
    label: "USD ($) - United States Dollar",
  });
  const [saveErrors, setSaveErrors] = useState({});

  useEffect(() => {
    // setTimezone({
    //   label: `(UTC${convertOffset(new Date().getTimezoneOffset())}) ${
    //     Intl.DateTimeFormat().resolvedOptions().timeZone
    //   }`,
    //   value: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    // });

    setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);

  const convertOffset = (offset) => {
    const hour = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;

    return `${offset <= 0 ? "+" : "-"}${hour >= 10 ? hour : `0${hour}`}:${
      minutes >= 10 ? minutes : `0${minutes}`
    }`;
  };

  const handleNameChange = (e) => {
    setOrganizationName(e.target.value);
    if (saveErrors["name"]) {
      delete saveErrors["name"];
    }
  };
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };
  const handleTypeChange = (e) => {
    setType({
      name: e.label,
      id: e.value,
    });
  };
  const handleTimezoneChange = (e) => {
    setTimezone(e.value);
    if (saveErrors["timezone"]) {
      delete saveErrors["timezone"];
    }
  };
  const handleCurrencyChange = (e) => {
    setCurrency(e);
  };
  const handleNext = () => {
    if (checkError()) {
      let data = {
        name: organizationName,
        timezone: timezone,
        phone: phoneNumber ? `+${phoneNumber}` : null,
      };
      if (address) {
        data.address = address;
      }
      if (type.id) {
        data.organization_type_id = type.id;
      }
      if (currency && currency.value) {
        data.currency = currency.value;
      }
      props.handleSubmitGeneralInfo(data);
    }
  };
  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!organizationName.trim()) {
      formIsValid = false;
      saveErrors["name"] = "Organization name is required.";
    } else if (checkUrl(organizationName)) {
      formIsValid = false;
      saveErrors["name"] = "Organization name can not contain url.";
    } else if (checkHtml(organizationName)) {
      formIsValid = false;
      saveErrors["name"] = "Invalid input in organization name.";
    }
    if (timezone === "") {
      formIsValid = false;
      saveErrors["timezone"] = "Please select a timezone.";
    }
    if (phoneNumber && phoneNumber.length > 20) {
      formIsValid = false;
      saveErrors["phone"] = "Phone number can't be more than 20 characters.";
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };
  return (
    <AddOrgForm>
      <InputWithLabelSection>
        <InputLabel for="organizationName">
          Name <RedStar>*</RedStar>
        </InputLabel>
        <InputField
          type="text"
          id="organizationName"
          placeholder="SpaceX"
          defaultValue={organizationName}
          onChange={(e) => handleNameChange(e)}
          error={
            saveErrors && saveErrors["name"] && saveErrors["name"] !== undefined
          }
        />
        {saveErrors &&
          saveErrors["name"] &&
          saveErrors["name"] !== undefined && (
            <FormAlert margin="0">{saveErrors["name"]}</FormAlert>
          )}
      </InputWithLabelSection>

      <InputWithLabelSection>
        <InputLabel for="address">Address</InputLabel>
        <InputField
          type="text"
          id="address"
          placeholder="New York"
          defaultValue={address}
          onChange={(e) => handleAddressChange(e)}
        />
        {/* <FormAlert isOpen={saveErrors && saveErrors["address"] !== undefined}>
          {saveErrors["address"]}
        </FormAlert> */}
      </InputWithLabelSection>

      <InputWithLabelSection>
        <InputLabel>Phone</InputLabel>
        <PhoneInput
          enableSearch
          country="us"
          value={phoneNumber}
          onChange={(phone) => setPhoneNumber(phone)}
          inputClass="phone-number-input"
          buttonClass="phone-number-flag-dropdown"
        />
        {saveErrors &&
          saveErrors["phone"] &&
          saveErrors["phone"] !== undefined && (
            <FormAlert margin="0">{saveErrors["phone"]}</FormAlert>
          )}
      </InputWithLabelSection>

      <InputWithLabelSection>
        <InputLabel for="organizationName">Organization Type</InputLabel>
        <Select
          isSearchable
          defaultValue={
            type && type.id !== ""
              ? {
                  label: type.name,
                  value: type.id,
                }
              : ""
          }
          name="organization_type_id"
          id="organizationType"
          placeholder="Select type"
          options={
            props.orgTypes &&
            props.orgTypes.map((item) => {
              return {
                label: `${item.name}`,
                value: `${item.id}`,
              };
            })
          }
          onChange={(e) => handleTypeChange(e)}
          styles={DropdownStyles()}
        />
      </InputWithLabelSection>

      <InputWithLabelSection>
        <InputLabel for="organizationTimezone">
          Timezone <RedStar style={{ fontSize: "14px" }}>*</RedStar>
        </InputLabel>
        <Select
          isSearchable
          value={
            timezone
              ? {
                  label: timezone,
                  value: timezone,
                }
              : ""
          }
          name="timezone"
          id="timezone"
          placeholder="Select Timezone"
          options={timezones}
          filterOptions={filterOptions}
          onChange={(e) => handleTimezoneChange(e)}
          styles={DropdownStyles()}
          error={
            saveErrors &&
            saveErrors["timezone"] &&
            saveErrors["timezone"] !== undefined
          }
        />
        {saveErrors &&
          saveErrors["timezone"] &&
          saveErrors["timezone"] !== undefined && (
            <FormAlert margin="0">{saveErrors["timezone"]}</FormAlert>
          )}
      </InputWithLabelSection>
      <InputWithLabelSection>
        <InputLabel for="currency">Currency</InputLabel>
        <Select
          isSearchable
          value={currency}
          name="currency"
          id="currency"
          placeholder="Select Currency"
          options={currenciesWithSymbols}
          filterOptions={filterOptions}
          onChange={(e) => handleCurrencyChange(e)}
          styles={DropdownStyles()}
        />
        <CurrencyAlert />
      </InputWithLabelSection>

      <SubmitButton onClick={() => handleNext()} height="50px" fontSize="16px">
        {props.isLoading ? (
          <ButtonTextLoader loadingText="Creating Organization" />
        ) : (
          "Next"
        )}
      </SubmitButton>
    </AddOrgForm>
  );
};

export const CurrencyAlert = (props) => {
  return (
    <CurrencyAlertSection>
      <CurrencyAlertIcon src={alertLightIcon} alt="" />
      <CurrencyAlertText>
        Reminder: Please don't forget to change member's pay rate & bill rate
        after currency changes.
      </CurrencyAlertText>
    </CurrencyAlertSection>
  );
};

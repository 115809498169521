import { Doughnut } from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import { CommonGrid, CommonText } from '../../styledComponents/common';
import {
  EmployeeActivity,
  SmallCardContent,
  SmallCardLabel,
  Value,
} from '../../styledComponents/dashboard';
import {
  DataDiv,
  DataDivHeaderWrapper,
  SmallCardContainer,
} from './cardStyles';

const EmployeesStatusCard = ({ employee_status, isLoading }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (employee_status) {
      const newData = {
        labels: ['Online Employees', 'Offline Employees'],
        datasets: [
          {
            data: [employee_status.online, employee_status.total - employee_status.online],
            backgroundColor: ['#6ab04c', '#a29bfe'],
            circumference: 180,
            rotation: 270,
          },
        ],
      };
      setData(newData);
    }
  }, [employee_status]);

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: false },
      tooltip: {
        displayColors: false,
        caretPadding: 8,
      },
    },
    radius: '100%',
  };

  return (
    <SmallCardContainer laptopGridColumn="span 1" tabletGridColumn="span 1">
      {isLoading ? (
        <ComponentCircleLoader padding="7vh" />
      ) : (
        <>
          {employee_status ? (
            <DataDiv>
              <DataDivHeaderWrapper>
                <SmallCardLabel fontSize="16px" style={{ fontWeight: '600' }}>
                  Who's In & Out
                </SmallCardLabel>
              </DataDivHeaderWrapper>
              <CommonText style={{ textAlign: 'center', marginTop: '10px' }}>
                Total Employees
              </CommonText>
              <CommonText
                fontSize="16px"
                fontWeight="600"
                style={{ textAlign: 'center', marginTop: '10px' }}
              >
                {employee_status.total}
              </CommonText>
              <div
                style={{
                  position: 'relative',
                  width: '260px',
                  height: '150px',
                  margin: 'auto',
                  paddingBottom: '0px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  
                }}
              >
                {data && <Doughnut style={{zIndex:"1"}} data={data} options={options} />} {/* Render Doughnut only when data is available */}
                <div
                  style={{
                    position: 'absolute',
                    top: '79%',
                    left: '53%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    color: '#555',
                  }}
                >
                  <Value>
                    <div style={{fontSize:"11px"}}>{employee_status.online}</div>
                    <div style={{fontSize:"10px"}}>Online</div>
                  </Value>
                </div>
              </div>
            </DataDiv>
          ) : null}
        </>
      )}
    </SmallCardContainer>
  );
};

export default EmployeesStatusCard;

import { all, put, takeLatest } from 'redux-saga/effects';

import {
  EXPORT_CLOCK_IN_OUT_LIST,
  EXPORT_CLOCK_IN_OUT_LIST_FAILURE,
  EXPORT_CLOCK_IN_OUT_LIST_SUCCESS,
  GET_CLOCK_IN_OUT_LIST,
  GET_CLOCK_IN_OUT_LIST_FAILURE,
  GET_CLOCK_IN_OUT_LIST_SUCCESS,
  GET_GROUPED_CLOCK_IN_OUT_LIST,
  GET_GROUPED_CLOCK_IN_OUT_LIST_FAILURE,
  GET_GROUPED_CLOCK_IN_OUT_LIST_SUCCESS,
} from '../../modules/constants';
import { defaultApi } from '../../utils/axiosApi';

export function* getClockInOutList({ payload }) {
  const {
    organization_id,
    start_timestamp,
    end_timestamp,
    name,
    page,
    office,
    tz,
    employee_id,
  } = payload;
  try {
    const url = `${organization_id}/field-service/attendances/?from=${start_timestamp}&to=${end_timestamp}${
      name ? `&name=${name}` : ``
    }${page ? `&page=${page}` : ``}${office ? `&office=${office}` : ``}${
      tz ? `&tz=${tz}` : ``
    }${employee_id ? `&employee_id=${employee_id}` : ``}`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_CLOCK_IN_OUT_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_CLOCK_IN_OUT_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getGroupedClockInOutList({ payload }) {
  const {
    organization_id,
    start_timestamp,
    end_timestamp,
    name,
    page,
    office,
    tz,
    employee_id,
  } = payload;
  try {
    const url = `${organization_id}/field-service/attendances/grouped/?from=${start_timestamp}&to=${end_timestamp}${
      name ? `&name=${name}` : ``
    }${page ? `&page=${page}` : ``}${office ? `&office=${office}` : ``}${
      tz ? `&tz=${tz}` : ``
    }${employee_id ? `&employee_id=${employee_id}` : ``}`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_GROUPED_CLOCK_IN_OUT_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_GROUPED_CLOCK_IN_OUT_LIST_FAILURE,
      payload: err,
    });
  }
}
export function* exportClockInOutList({ payload }) {
  const {
    organization_id,
    start_timestamp,
    end_timestamp,
    name,
    page,
    office,
    tz,
    employee_id,
    time_format,
  } = payload;
  try {
    const url = `${organization_id}/field-service/attendances/export/?from=${start_timestamp}&to=${end_timestamp}${
      name ? `&name=${name}` : ``
    }${page ? `&page=${page}` : ``}${office ? `&office=${office}` : ``}${
      tz ? `&tz=${tz}` : ``
    }${employee_id ? `&employee_id=${employee_id}` : ``}${
      time_format ? `&time_format=${time_format}` : ``
    }`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: EXPORT_CLOCK_IN_OUT_LIST_SUCCESS,
      payload: { data: response, start_timestamp, end_timestamp },
    });
  } catch (err) {
    yield put({
      type: EXPORT_CLOCK_IN_OUT_LIST_FAILURE,
      payload: err,
    });
  }
}

export default function* clockInOutSagas() {
  yield all([takeLatest(GET_CLOCK_IN_OUT_LIST, getClockInOutList)]);
  yield all([
    takeLatest(GET_GROUPED_CLOCK_IN_OUT_LIST, getGroupedClockInOutList),
  ]);
  yield all([takeLatest(EXPORT_CLOCK_IN_OUT_LIST, exportClockInOutList)]);
}

import { useState, useEffect } from "react";
import { planFeatures } from "./pricingPlanData";
import { PricingAccordion } from "./PricingAccordion";
import {
  PageTitle,
  CardTitle,
  CommonGrid,
  CommonText,
} from "../../../styledComponents/common";
import {
  PricingMainContainer,
  ToggleSection,
  ToggleText,
  TickImage,
  PricingCardContainer,
  SeeFullComparisonDiv,
  SeeFullComparisonButton,
} from "../../../styledComponents/billing";
import {
  PricingPlanContainer,
  PricingPlanHeader,
  HeaderTitle,
  SinglePricingCard,
  TopSectionContainer,
  PlanNameAndTagContainer,
  PlanName,
  PlanNameTag,
  PlanPriceContainer,
  PlanPrice,
  PlanPriceText,
  PlanDescription,
  PlanFeaturesContainer,
  SinglePlanFeature,
  FeatureTickIcon,
  FeatureText,
  TypeTogglerContainer,
  TypeText,
  PlanSelectButton,
  PlusAddonDiv,
  EverythingInPrev,
  ArrowLeft,
  NotificationText,
  MoneyBackTag,
  ComparePlansLink,
} from "../../../components/PricingPlan/pricingPlanStyles";

import {
  ToggleButton,
  ToggleButtonRound,
} from "../../../styledComponents/invoice";
import {
  YearlySaveBadge,
  SubscriptionCurrentPlanBadge,
  CurrentPlanDot,
  CurrentPlanBadgeText,
} from "../subscriptionStyles";
import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import DowngradeModal from "./DowngradeModal";
import SoloErrorModal from "./SoloErrorModal";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";

import tickSolo from "../../../assets/img/common/tick-standard.svg";
import tickStandard from "../../../assets/img/common/tick-elite.svg";
import arrow from "../../../assets/img/common/arrow.svg";

const getIsCurrent = (
  planId,
  planStatus,
  planInterval,
  yearlyId,
  monthlyId
) => {
  if (!planId || !planStatus) return false;
  if (planStatus === "active" || planStatus === "trialing") {
    if (planInterval === "yearly") {
      return planId === yearlyId;
    } else {
      return planId === monthlyId;
    }
  }
  return false;
};

const CurrentPlanBadge = (props) => {
  return (
    <SubscriptionCurrentPlanBadge isCurrent={props.isCurrent} plan={props.plan}>
      <CurrentPlanDot plan={props.plan} />
      <CurrentPlanBadgeText>Current Plan</CurrentPlanBadgeText>
    </SubscriptionCurrentPlanBadge>
  );
};

const ChangeSubscription = (props) => {
  const [planInterval, setPlanInterval] = useState("yearly");
  const [currentPlanId, setCurrentPlanId] = useState(null);
  const [currentPlanName, setCurrentPlanName] = useState("");
  const [currentPlanInterval, setCurrentPlanInterval] = useState(null);
  const [currentPlanStatus, setCurrentPlanStatus] = useState(null);
  const [isPaddleSubscriptionActive, setIsPaddleSubscriptionActive] = useState(
    false
  );
  const [isDowngrading, setIsDowngrading] = useState(false);
  const [soloError, setSoloError] = useState(false);

  const {
    getOrganizationPlans,
    subscriptionPlans,
    currentSubscription,
    getOrganizationSubscription,
    selectedOrganization,
    clearCurrentSubscription,
  } = props;

  const planPriceMonthly = {
    standard: 15,
    elite: 20,
  };
  const planPriceYearly = {
    standard: 9,
    elite: 12,
  };

  useEffect(() => {
    getOrganizationPlans();
    return () => {
      clearCurrentSubscription();
      setCurrentPlanId(null);
      setCurrentPlanName("");
      setCurrentPlanInterval(null);
      setCurrentPlanStatus(null);
      setIsPaddleSubscriptionActive(false);
      setPlanInterval("yearly");
    };
  }, []);

  const [soloYearly, setSoloYearly] = useState({
    id: "",
    paddleId: null,
    amount: null,
  });
  const [soloMonthly, setSoloMonthly] = useState({
    id: "",
    paddle_id: null,
    amount: null,
  });
  const [standardYearly, setStandardYearly] = useState({
    id: "",
    paddleId: null,
    amount: null,
  });
  const [eliteYearly, setEliteYearly] = useState({
    id: "",
    paddleId: null,
    amount: null,
  });
  const [standardMonthly, setStandardMonthly] = useState({
    id: "",
    paddleId: null,
    amount: null,
  });
  const [eliteMonthly, setEliteMonthly] = useState({
    id: "",
    paddleId: null,
    amount: null,
  });

  useEffect(() => {
    if (subscriptionPlans && subscriptionPlans.length > 0) {
      subscriptionPlans.map((item) => {
        if (item.name === "Free") {
          setSoloYearly({
            id: item.id,
            paddleId: item.paddle_id,
            amount: item.amount,
          });
        } else if (item.name === "Standard" && item.interval === "yearly") {
          setStandardYearly({
            id: item.id,
            paddleId: "pri_01jbe9j6m41qqf4grmj6spv18m",
            amount: item.amount,
          });
        } else if (item.name === "Elite" && item.interval === "yearly") {
          setEliteYearly({
            id: item.id,
            paddleId: "pri_01jbe9r4kyh2s3kke5cr53rg38",
            amount: item.amount,
          });
        } else if (item.name === "Standard" && item.interval === "monthly") {
          setStandardMonthly({
            id: item.id,
            paddleId: "pri_01jbe9gnsjgy5m8est44jkxv84",
            amount: item.amount,
          });
        } else if (item.name === "Elite" && item.interval === "monthly") {
          setEliteMonthly({
            id: item.id,
            paddleId: "pri_01jbe9pryh28jdgaks50hm6mpg",
            amount: item.amount,
          });
        }
      });
    }
  }, [subscriptionPlans]);

  useEffect(() => {
    console.log(standardYearly);
    console.log(eliteYearly);
    console.log(standardMonthly);
    console.log(eliteMonthly);
  }, [standardYearly, eliteYearly, standardMonthly, eliteMonthly]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      if (
        !currentSubscription ||
        (currentSubscription.organization &&
          currentSubscription.organization !== selectedOrganization.id)
      ) {
        const payload = {
          organization_id: selectedOrganization.id,
        };
        getOrganizationSubscription(payload);
      }
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (currentSubscription && currentSubscription.plan) {
      setCurrentPlanId(currentSubscription.plan.id);
      setCurrentPlanName(currentSubscription.plan.name);
      setCurrentPlanInterval(currentSubscription.plan.interval);
      setPlanInterval(currentSubscription.plan.interval);
      setCurrentPlanStatus(currentSubscription.status);
      setIsPaddleSubscriptionActive(
        currentSubscription.paddlesubscription_active
      );

      // This part is for testing purpose
      // setIsPaddleSubscriptionActive(true);
      // setCurrentPlanStatus("active");
      // setCurrentPlanInterval("monthly");
    }
  }, [currentSubscription]);

  const onTogglePlan = () => {
    if (planInterval === "monthly") {
      setPlanInterval("yearly");
    } else {
      setPlanInterval("monthly");
    }
  };

  const confirmSelectPlan = (plan) => {
    if (
      currentPlanId &&
      currentPlanStatus &&
      (currentPlanStatus === "active" || currentPlanStatus === "trialing") &&
      // isPaddleSubscriptionActive
      currentSubscription?.paddlesubscription_status &&
      (currentSubscription.paddlesubscription_status === "active" ||
        currentSubscription.paddlesubscription_status === "trialing")
    ) {
      if (currentPlanId !== plan.selectedPlanId) {
        if (currentPlanName) {
          if (currentPlanName === "Standard" && plan.name === "Free") {
            setIsDowngrading(true);
          } else if (
            currentPlanName === "Elite" &&
            (plan.name === "Standard" || plan.name === "Free")
          ) {
            setIsDowngrading(true);
          } else {
            props.history.push({
              pathname: "/user/upgrade-plan",
              state: {
                plan: {
                  selectedPlanId: plan.selectedPlanId,
                  ids: plan.ids,
                  name: plan.name,
                  paddleIds: plan.paddleIds,
                  amounts: plan.amounts,
                  interval: planInterval,
                  currentUsers:
                    currentSubscription && currentSubscription.member_count,
                  prepurchasedCount:
                    currentSubscription &&
                    currentSubscription.prepurchased_count,
                },
                currentPlan: {
                  id: currentPlanId,
                  name: currentPlanName,
                  interval: currentPlanInterval,
                },
              },
            });
          }
        }
      }
    } else if (
      plan.name === "Solo" &&
      currentSubscription &&
      currentSubscription.member_count &&
      currentSubscription.member_count > 100
    ) {
      setSoloError(true);
    } else {
      props.history.push({
        pathname: "/user/checkout",
        state: {
          plan: {
            selectedPlanId: plan.selectedPlanId,
            ids: plan.ids,
            name: plan.name,
            paddleIds: plan.paddleIds,
            amounts: plan.amounts,
            interval: planInterval,
            currentUsers:
              currentSubscription && currentSubscription.member_count,
          },
        },
      });
    }
  };

  return (
    <div className="content">
      <DowngradeModal
        isOpen={isDowngrading}
        toggle={() => setIsDowngrading(false)}
      />
      <SoloErrorModal isOpen={soloError} toggle={() => setSoloError(false)} />
      <PageTitle marginBottom="30px">Billing - Subscription Plan</PageTitle>
      <BackButtonComponent
        onClick={() => props.history.push("/user/settings")}
        subTitle="Back to settings"
      />
      <PricingMainContainer>
        {props.isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            <div>
              <CommonGrid alignItem="flex-start">
                <CardTitle margin="0 0 0 10px">Choose Plan</CardTitle>
                {!(
                  currentPlanInterval &&
                  currentPlanInterval === "yearly" &&
                  currentPlanStatus &&
                  currentPlanStatus === "active"
                ) && (
                  <ToggleSection
                    style={{
                      padding: `20px 0 40px 0`,
                    }}
                  >
                    <ToggleText
                      active={planInterval === "yearly"}
                      onClick={() => setPlanInterval("yearly")}
                    >
                      <YearlySaveBadge isActive={planInterval === "yearly"}>
                        Save 50%
                      </YearlySaveBadge>
                      Yearly
                    </ToggleText>
                    <ToggleButton
                      onClick={() => onTogglePlan()}
                      style={{ backgroundColor: `#20BEAD` }}
                    >
                      <ToggleButtonRound active={planInterval === "monthly"} />
                    </ToggleButton>

                    <ToggleText
                      active={planInterval === "monthly"}
                      onClick={() => setPlanInterval("monthly")}
                    >
                      Monthly
                    </ToggleText>
                  </ToggleSection>
                )}
              </CommonGrid>
              <PricingCardContainer
                isYearly={
                  currentPlanInterval &&
                  currentPlanInterval === "yearly" &&
                  currentPlanStatus &&
                  currentPlanStatus === "active"
                }
              >
                <SinglePricingCard plan="lite">
                  <CurrentPlanBadge
                    isCurrent={
                      currentSubscription &&
                      currentSubscription.plan &&
                      currentSubscription.plan.nick_name === "free"
                    }
                    plan="Solo"
                  />
                  <div>
                    <PlanName plan="lite">LITE</PlanName>
                    <PlanPriceContainer>
                      <PlanPrice>Free</PlanPrice>
                    </PlanPriceContainer>
                    <PlanDescription plan="lite">
                      Ideal for small teams (up to 100 members).
                    </PlanDescription>
                  </div>
                  <PlanFeaturesContainer margin="15px 0">
                    {planFeatures.lite.map((item, index) => (
                      <SinglePlanFeature key={index}>
                        <TickImage
                          src={tickSolo}
                          alt=""
                          style={{ margin: `2px 0 0 0` }}
                        />
                        <FeatureText>{item}</FeatureText>
                      </SinglePlanFeature>
                    ))}
                  </PlanFeaturesContainer>
                  {/* <PlanSelectButton
                    plan="lite"
                    isCurrent={getIsCurrent(
                      currentPlanId,
                      currentPlanStatus,
                      planInterval,
                      soloYearly.id,
                      soloMonthly.id
                    )}
                    onClick={() =>
                      confirmSelectPlan({
                        selectedPlanId:
                          planInterval === "yearly"
                            ? soloYearly.id
                            : soloMonthly.id,
                        ids: {
                          yearlyId: soloYearly.id,
                          monthlyId: soloMonthly.id,
                        },
                        name: "Solo",
                        paddleIds: {
                          yearlyId: soloYearly.paddleId,
                          monthlyId: soloMonthly.paddleId,
                        },
                        amounts: {
                          yearlyAmount: soloYearly.amount,
                          monthlyAmount: soloMonthly.amount,
                        },
                      })
                    }
                  >
                    {currentPlanId
                      ? planInterval === "yearly"
                        ? currentPlanId === soloYearly.id
                          ? `Continue Current Plan`
                          : `Select LITE`
                        : currentPlanId === soloMonthly.id
                        ? `Continue Current Plan`
                        : `Select LITE`
                      : `Select LITE`}
                  </PlanSelectButton> */}
                </SinglePricingCard>
                <SinglePricingCard plan="standard">
                  <CurrentPlanBadge
                    isCurrent={
                      currentPlanInterval === planInterval &&
                      currentPlanName === "Standard"
                    }
                    plan="Standard"
                  />

                  <div>
                    <PlanNameAndTagContainer>
                      <PlanName plan="standard">STANDARD</PlanName>
                      <PlanNameTag plan="standard">
                        Geofenced Attendance
                      </PlanNameTag>
                    </PlanNameAndTagContainer>
                    <PlanPriceContainer>
                      <PlanPrice>
                        $
                        {planInterval === "yearly"
                          ? planPriceYearly.standard
                          : planPriceMonthly.standard}
                      </PlanPrice>
                      <PlanPriceText>per user/month</PlanPriceText>
                    </PlanPriceContainer>
                    <PlanDescription plan="standard">
                      Ideal for managing multiple offices & job-sites
                    </PlanDescription>
                  </div>
                  <PlanFeaturesContainer>
                    {planFeatures.standard.map((item, index) => (
                      <SinglePlanFeature key={index}>
                        <TickImage
                          src={tickStandard}
                          alt=""
                          style={{ margin: `2px 0 0 0` }}
                        />
                        <FeatureText>{item}</FeatureText>
                      </SinglePlanFeature>
                    ))}
                  </PlanFeaturesContainer>
                  <PlanSelectButton
                    plan="standard"
                    style={{ marginTop: `auto` }}
                    isCurrent={getIsCurrent(
                      currentPlanId,
                      currentPlanStatus,
                      planInterval,
                      standardYearly.id,
                      standardMonthly.id
                    )}
                    onClick={() =>
                      confirmSelectPlan({
                        selectedPlanId:
                          planInterval === "yearly"
                            ? standardYearly.id
                            : standardMonthly.id,
                        ids: {
                          yearlyId: standardYearly.id,
                          monthlyId: standardMonthly.id,
                        },
                        name: "Standard",
                        paddleIds: {
                          yearlyId: standardYearly.paddleId,
                          monthlyId: standardMonthly.paddleId,
                        },
                        amounts: {
                          yearlyAmount: standardYearly.amount,
                          monthlyAmount: standardMonthly.amount,
                        },
                      })
                    }
                  >
                    {currentPlanId
                      ? planInterval === "yearly"
                        ? currentPlanId === standardYearly.id
                          ? `Continue Current Plan`
                          : `Select STANDARD`
                        : currentPlanId === standardMonthly.id
                        ? `Continue Current Plan`
                        : `Select STANDARD`
                      : `Select STANDARD`}
                  </PlanSelectButton>
                </SinglePricingCard>
                <SinglePricingCard plan="elite">
                  <CurrentPlanBadge
                    isCurrent={
                      currentPlanInterval == planInterval &&
                      currentPlanName === "Elite"
                    }
                    plan="Elite"
                  />
                  <div>
                    <PlanNameAndTagContainer>
                      <PlanName plan="elite">ELITE</PlanName>
                      <PlanNameTag plan="elite">
                        Field, Remote & Hybrid
                      </PlanNameTag>
                    </PlanNameAndTagContainer>
                    <PlanPriceContainer>
                      <PlanPrice>
                        $
                        {planInterval === "yearly"
                          ? planPriceYearly.elite
                          : planPriceMonthly.elite}
                      </PlanPrice>
                      <PlanPriceText>per user/month</PlanPriceText>
                    </PlanPriceContainer>
                    <PlanDescription plan="elite">
                      Ideal for managing jobs and work orders
                    </PlanDescription>
                  </div>
                  <EverythingInPrev plan="elite">
                    <TickImage src={arrow} alt="" style={{ margin: `0` }} />
                    <FeatureText style={{ marginLeft: "8px" }} fontSize="12px">
                      Everything in Standard
                    </FeatureText>
                  </EverythingInPrev>
                  <PlusAddonDiv>Plus Add On:</PlusAddonDiv>
                  <PlanFeaturesContainer>
                    {planFeatures.elite.map((item, index) => (
                      <SinglePlanFeature key={index}>
                        <FeatureTickIcon
                          src={tickStandard}
                          plan="elite"
                          alt=""
                          style={{ margin: `2px 0 0 0` }}
                        />
                        <FeatureText>{item}</FeatureText>
                      </SinglePlanFeature>
                    ))}
                  </PlanFeaturesContainer>
                  <PlanSelectButton
                    plan="elite"
                    style={{ marginTop: `auto` }}
                    isCurrent={getIsCurrent(
                      currentPlanId,
                      currentPlanStatus,
                      planInterval,
                      eliteYearly.id,
                      eliteMonthly.id
                    )}
                    onClick={() =>
                      confirmSelectPlan({
                        selectedPlanId:
                          planInterval === "yearly"
                            ? eliteYearly.id
                            : eliteMonthly.id,
                        ids: {
                          yearlyId: eliteYearly.id,
                          monthlyId: eliteMonthly.id,
                        },
                        name: "Elite",
                        paddleIds: {
                          yearlyId: eliteYearly.paddleId,
                          monthlyId: eliteMonthly.paddleId,
                        },
                        amounts: {
                          yearlyAmount: eliteYearly.amount,
                          monthlyAmount: eliteMonthly.amount,
                        },
                      })
                    }
                  >
                    {currentPlanId
                      ? planInterval === "yearly"
                        ? currentPlanId === eliteYearly.id
                          ? `Continue Current Plan`
                          : `Select ELITE`
                        : currentPlanId === eliteMonthly.id
                        ? `Continue Current Plan`
                        : `Select ELITE`
                      : `Select ELITE`}
                  </PlanSelectButton>
                </SinglePricingCard>
              </PricingCardContainer>
            </div>
            <SeeFullComparisonDiv>
              <SeeFullComparisonButton
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.fieldservicely.com/pricing"
              >
                See Full Pricing Comparison
              </SeeFullComparisonButton>
            </SeeFullComparisonDiv>

            <PricingAccordion />
          </>
        )}
      </PricingMainContainer>
    </div>
  );
};

export default ChangeSubscription;

import { useState, useEffect } from "react";
import moment from "moment";
import PaymentModal from "./PaymentModal";
import {
  PageTitle,
  CommonText,
  CommonGrid,
  InputLabel,
  InputField,
  FirstWordRound,
} from "../../../styledComponents/common";
import { PrimaryButton } from "../../../styledComponents/buttons";
import {
  UpgradeUpperSection,
  BillingContainer,
  ToggleSection,
  ToggleText,
  SaveTag,
  UpgradeLowerSection,
  PaymentCard,
  UserNumberBox,
  AdditionalUserInputSection,
  AdditionalUserInput,
  InputButton,
  OrderSummarySection,
  ColoredDot,
  LinkSpan,
} from "../../../styledComponents/billing";
import {
  ToggleButton,
  ToggleButtonRound,
} from "../../../styledComponents/invoice";
import {
  CouponAlert,
  YearlySaveBadge,
  SecurePaymentContainer,
  SecurePaymentIcon,
  SecurePaymentText,
} from "../subscriptionStyles";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import ButtonLoader from "../../../components/DropdownLoader/DropdownLoader";

import minus from "../../../assets/img/icons/minus.svg";
import plus from "../../../assets/img/icons/plus_black_2.svg";

import secureIcon from "../../../assets/img/icons/secure-payment.svg";
import visaIcon from "../../../assets/img/common/visa.svg";
import masterCardIcon from "../../../assets/img/common/master-card.svg";
import paypalIcon from "../../../assets/img/common/paypal.svg";
import applePayIcon from "../../../assets/img/common/apple-pay.svg";
import americanExpressIcon from "../../../assets/img/common/american-express.svg";

const RoundLetter = ({ text, color, isActive }) => {
  return (
    <FirstWordRound
      backColor={isActive ? color : "#84919e"}
      size="50px"
      fontSize="26px"
    >
      {" "}
      {text.slice(0, 1).toUpperCase()}{" "}
    </FirstWordRound>
  );
};

const Checkout = (props) => {
  const [planInterval, setPlanInterval] = useState("yearly");
  const [planData, setPlanData] = useState(null);
  const [usersNumber, setUsersNumber] = useState(0);
  const [prepurchasedCount, setPrepurchasedCount] = useState(0);
  const [totalSeats, setTotalSeats] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discountPlan, setDiscountPlan] = useState(null);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0.0);
  const [discountedTotal, setDiscountedTotal] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [couponError, setCouponError] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);

  const {
    subscriptionUpdated,
    selectedOrganization,
    clearCurrentSubscription,
    clearSubscriptionUpdated,
    discountInfo,
    clearDiscountInfo,
    clearDiscountErrorMessage,
    discountErrorMessage,
  } = props;

  useEffect(() => {
    if (props.location.state && props.location.state.plan) {
      const planInfo = { ...props.location.state.plan };
      setPlanInterval(planInfo.interval);
      setPlanData(planInfo);
      setUsersNumber(planInfo.currentUsers);
    } else {
      props.history.goBack();
    }

    return () => {
      clearCurrentSubscription();
      clearSubscriptionUpdated();
      // clearDiscountInfo();
      clearDiscountErrorMessage();
    };
  }, []);

  useEffect(() => {
    if (planData && planData.interval) {
      let planInfo = { ...planData };
      if (planInterval === "yearly") {
        planInfo.interval = "yearly";
      } else {
        planInfo.interval = "monthly";
      }
      setPlanData({ ...planInfo });
    }
    setCoupon("");
    setCouponError("");
  }, [planInterval]);

  useEffect(() => {
    if (planData && planData.amounts) {
      let seats = usersNumber;
      let total = 0.0;
      if (planInterval === "yearly") {
        if (seats > 1) {
          seats += prepurchasedCount - 1;
        } else {
          if (prepurchasedCount > 0) {
            seats += prepurchasedCount - 1;
          }
        }
        total = (seats * planData.amounts.yearlyAmount).toFixed(2);
      } else if (planInterval === "monthly") {
        if (seats > 1) {
          seats -= 1;
        }
        total = (seats * planData.amounts.monthlyAmount).toFixed(2);
      }
      setTotalAmount(total);
      if (
        discountPercentage &&
        discountPercentage > 0 &&
        discountPlan &&
        discountPlan.interval === planInterval
      ) {
        const discount = (total * (discountPercentage / 100)).toFixed(2);
        total = (total - discount).toFixed(2);
        setDiscountAmount(discount);
      }
      setTotalSeats(seats);
      setDiscountedTotal(total);
    }
  }, [
    planInterval,
    planData,
    usersNumber,
    prepurchasedCount,
    discountPercentage,
  ]);

  useEffect(() => {
    if (
      subscriptionUpdated &&
      selectedOrganization &&
      selectedOrganization.id &&
      planData &&
      planData.paddleIds
    ) {
      const plan = {
        organizationName: selectedOrganization.name,
        organizationId: selectedOrganization.id,
        organizationColor: selectedOrganization.color,
        isOrgActive: selectedOrganization.is_active,
        currentUsers: usersNumber,
        paddleId:
          planInterval === "yearly"
            ? planData.paddleIds.yearlyId
            : planData.paddleIds.monthlyId,
      };
      props.history.push({
        pathname: "/user/paddle-payment-checkout",
        state: { plan },
      });
      setTimeout(() => {
        props.clearSubscriptionUpdated();
      }, 200);
    }
  }, [subscriptionUpdated]);

  useEffect(() => {
    if (
      discountInfo &&
      discountInfo.id &&
      discountInfo.organization_id &&
      discountInfo.plan &&
      planData &&
      planData.ids &&
      selectedOrganization &&
      selectedOrganization.id &&
      selectedOrganization.id === discountInfo.organization_id
    ) {
      const selectedPlanId =
        planInterval === "yearly"
          ? planData.ids.yearlyId
          : planData.ids.monthlyId;
      if (discountInfo.plan.id === selectedPlanId) {
        if (discountInfo.type === "percentage" && discountInfo.percentage_off) {
          setDiscountPercentage(discountInfo.percentage_off);
          setDiscountPlan(discountInfo.plan);
          setCoupon("");
        }
      }
    } else if (
      discountInfo &&
      discountInfo.organization_id &&
      selectedOrganization &&
      selectedOrganization.id &&
      selectedOrganization.id !== discountInfo.organization_id
    ) {
      clearDiscountInfo();
    }
  }, [discountInfo, planData, planInterval]);

  useEffect(() => {
    if (discountErrorMessage) {
      setCouponError(discountErrorMessage);
    }
  }, [discountErrorMessage]);

  const onToggleInterval = () => {
    if (planInterval === "monthly") {
      setPlanInterval("yearly");
    } else {
      setPlanInterval("monthly");
    }
  };

  const onUsersNumberChange = (e) => {
    setPrepurchasedCount(e.target.value);
  };

  const decreaseUsers = () => {
    if (parseInt(prepurchasedCount) > 0 && prepurchasedCount !== "") {
      setPrepurchasedCount(parseInt(prepurchasedCount) - 1);
    }
  };

  const increaseUsers = () => {
    if (prepurchasedCount !== "") {
      setPrepurchasedCount(parseInt(prepurchasedCount) + 1);
    } else {
      setPrepurchasedCount(0);
    }
  };

  const onCouponChange = (e) => {
    setCouponError("");
    setCoupon(e.target.value);
  };

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const handleApplyCoupon = () => {
    if (!coupon) {
      setCouponError("Coupon can not be empty");
    } else if (
      coupon &&
      props.selectedOrganization &&
      props.selectedOrganization.id
    ) {
      const payload = {
        email: localStorage.getItem("email"),
        codes: [coupon],
        organization_id: props.selectedOrganization.id,
        selected_plan_id: planData
          ? planData.interval === "yearly"
            ? planData.ids.yearlyId
            : planData.ids.monthlyId
          : null,
        selected_plan_interval: planData && planData.interval,
      };

      props.applyCoupon(payload);
    }
  };

  const confirmPayment = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      planData &&
      planData.paddleIds
    ) {
      const plan = {
        organizationName: selectedOrganization.name,
        organizationId: selectedOrganization.id,
        organizationColor: selectedOrganization.color,
        isOrgActive: selectedOrganization.is_active,
        currentUsers: usersNumber,
        paddleId:
          planInterval === "yearly"
            ? planData.paddleIds.yearlyId
            : planData.paddleIds.monthlyId,
        id:
          planInterval === "yearly"
            ? planData.ids.yearlyId
            : planData.ids.monthlyId,
      };
      props.history.push({
        pathname: "/user/paddle-payment-checkout",
        state: { plan },
      });
    }
  };

  return (
    <div className="content">
      <PageTitle>Billing - Checkout</PageTitle>
      {/* <PaymentModal
        isOpen={popupOpen}
        toggle={popupToggle}
        toggleInterval={onToggleInterval}
        planInterval={planInterval}
        planData={planData}
        totalSeats={totalSeats}
        discountPercentage={discountPercentage}
        discountPlan={discountPlan}
        discountedTotal={discountedTotal}
        confirmPayment={confirmPayment}
        isLoading={props.updateSubscriptionIsLoading}
      /> */}
      <BillingContainer>
        <div>
          <UpgradeUpperSection>
            <BackButtonComponent
              onClick={() => props.history.goBack()}
              subTitle="Go back to plans"
            />
            <CommonGrid
              columns="auto"
              gap="15px"
              justifyContent="center"
              style={{ justifyItems: `center` }}
            >
              {/* {selectedOrganization && selectedOrganization.name && (
                <RoundLetter
                  text={selectedOrganization.name}
                  color={
                    selectedOrganization.color
                      ? selectedOrganization.color
                      : "#9A96F9"
                  }
                  isActive={selectedOrganization.is_active}
                />
              )} */}
              <CommonText name title fontSize="20px">
                {planData ? `Upgrade to ${planData.name}` : `Change Plan`}
              </CommonText>
              <ToggleSection isCheckout>
                <ToggleText
                  active={planInterval === "yearly"}
                  onClick={() => setPlanInterval("yearly")}
                >
                  <YearlySaveBadge isActive={planInterval === "yearly"}>
                    Save 50%
                  </YearlySaveBadge>
                  Yearly
                </ToggleText>
                <ToggleButton
                  onClick={() => onToggleInterval()}
                  style={{ backgroundColor: `#20BEAD` }}
                >
                  <ToggleButtonRound active={planInterval === "monthly"} />
                </ToggleButton>

                <ToggleText
                  active={planInterval === "monthly"}
                  onClick={() => setPlanInterval("monthly")}
                >
                  Monthly
                </ToggleText>
              </ToggleSection>
            </CommonGrid>
            <div />
          </UpgradeUpperSection>
          <UpgradeLowerSection>
            <div>
              <InputLabel fontSize="14px">Current Users</InputLabel>
              <CommonText fontSize="13px" style={{ marginTop: `10px` }}>
                Current number of active members in your organization
              </CommonText>
              <UserNumberBox>
                {usersNumber > 1 ? usersNumber - 1 : usersNumber}
              </UserNumberBox>

              {couponError && <CouponAlert>{couponError}</CouponAlert>}
              <InputLabel fontSize="14px" margin="25px 0 0 0">
                Order Summary
              </InputLabel>
              <OrderSummarySection>
                <ColoredDot color="#9A96F9" />
                <CommonText fontSize="13px">
                  Your total amount due may change based on the VAT/Sales Tax
                </CommonText>
                <ColoredDot />
                <CommonText fontSize="13px">
                  Your payment method will be charged{" "}
                  <span style={{ fontWeight: `600` }}>{planInterval}</span>{" "}
                  starting{" "}
                  <span style={{ fontWeight: `600` }}>
                    {moment(new Date()).format("MMMM D, YYYY")}
                  </span>
                </CommonText>
                {planInterval === "yearly" &&
                  planData &&
                  planData.name !== "Solo" && (
                    <>
                      <ColoredDot color="#4D94FB" />
                      <CommonText fontSize="13px">
                        Any extra users added after your yearly plan starts will
                        be charged at a monthly rate of{" "}
                        <span style={{ fontWeight: `600` }}>
                          $
                          {planData &&
                            planData.amounts &&
                            planData.amounts.monthlyAmount}{" "}
                          per month
                        </span>{" "}
                        until your plan renews next year & likewise for billing
                        cycle afterwards.{" "}
                        <LinkSpan
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://support.apploye.com/en/article/why-am-i-billed-both-monthly-and-yearly-on-a-yearly-plan-1j6qeyq"
                        >
                          Learn More
                        </LinkSpan>
                      </CommonText>
                      <ColoredDot color="#F2C741" />
                      <CommonText fontSize="13px">
                        For example : If your yearly billing cycle is from the
                        start of Month 1 to the end of Month 12 but you add an
                        extra user on Month 7, you will be charged on monthly
                        basis only for that extra user for 5 months until month
                        12 arrives. Once new billing cycle starts after Month
                        12, this extra users will be included in yearly plan
                        with rest of users and billed accordingly. This will
                        continue for the future iterations.
                      </CommonText>
                    </>
                  )}
              </OrderSummarySection>
            </div>
            <PaymentCard>
              <CommonGrid
                padding={
                  discountAmount &&
                  discountAmount > 0 &&
                  discountPlan &&
                  discountPlan.interval === planInterval
                    ? "30px 30px 20px 30px"
                    : "30px 30px 40px 30px"
                }
                style={{
                  borderBottom:
                    discountAmount &&
                    discountAmount > 0 &&
                    discountPlan &&
                    discountPlan.interval === planInterval
                      ? `none`
                      : `1px solid #EAEEF7`,
                }}
              >
                <CommonText name>
                  {totalSeats} Users × $
                  {planData && planData.amounts && planInterval === "yearly"
                    ? (planData.amounts.yearlyAmount / 12).toFixed(1)
                    : planData &&
                      planData.amounts &&
                      planData.amounts.monthlyAmount}{" "}
                  per month{planInterval === "yearly" && ` X 12`}
                </CommonText>
                <CommonText name title>
                  $ {totalAmount}
                </CommonText>
              </CommonGrid>
              {discountAmount &&
              discountAmount > 0 &&
              discountPlan &&
              discountPlan.interval === planInterval ? (
                <CommonGrid
                  padding="0px 30px 20px 30px"
                  style={{ borderBottom: `1px solid #EAEEF7` }}
                >
                  <CommonText name>
                    Discount ({discountPercentage}% off)
                  </CommonText>
                  <CommonText name>$ -{discountAmount}</CommonText>
                </CommonGrid>
              ) : null}
              <CommonGrid columns="1fr" padding="30px" gap="30px">
                <CommonGrid>
                  <CommonText name title>
                    Total{" "}
                    <span style={{ color: `#fe5969` }}>(Excluding VAT)</span>
                  </CommonText>
                  <CommonText name title>
                    $ {discountedTotal}/{" "}
                    {planInterval === "yearly" ? "Year" : "Month"}
                  </CommonText>
                </CommonGrid>
                <CommonText name>
                  * VAT/ Sales taxes will be added in checkout based on
                  location.
                </CommonText>
                <PrimaryButton
                  fontSize="16px"
                  height="46px"
                  onClick={() => confirmPayment()}
                >
                  Continue Payment
                </PrimaryButton>
                <SecurePaymentContainer>
                  <SecurePaymentIcon src={secureIcon} alt="" />
                  <CommonText margin="0 0 0 8px">Secure Payments</CommonText>
                </SecurePaymentContainer>
                <CommonText $label style={{ marginTop: `0` }}>
                  We accept following cards
                </CommonText>
                <CommonGrid
                  columns="repeat(5, auto)"
                  justifyContent="start"
                  style={{ marginBottom: `15px` }}
                >
                  <img src={visaIcon} alt="visa" />
                  <img src={masterCardIcon} alt="Mastercard" />
                  <img src={paypalIcon} alt="paypal" />
                  <img src={applePayIcon} alt="apple pay" />
                  <img src={americanExpressIcon} alt="american express" />
                </CommonGrid>
              </CommonGrid>
            </PaymentCard>
          </UpgradeLowerSection>
        </div>
      </BillingContainer>
    </div>
  );
};

export default Checkout;

import { useEffect, useState } from 'react';

import searchIcon from '../../../assets/img/icons/search.svg';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../../components/NoDataComponent/NoDataComponent';
import BottomPagination from '../../../components/Pagination/BottomPagination/BottomPagination';
import TopPagination from '../../../components/Pagination/TopPagination/TopPagination';
import InfoTooltip from '../../../components/Tooltip/InfoTooltip';
import {
  CardTitle,
  CommonGrid,
  CommonText,
  TableText,
} from '../../../styledComponents/common';
import { TableItem } from '../../../styledComponents/teams';
import {
  CommFirstRoundLetterCompNew,
  CommImageNameComp,
} from '../../Members/CommonComponents';
import {
  ToggleButton,
  ToggleButtonRound,
} from '../ActivitySettings/activitySettingsStyles';
import {
  Input,
  MemberNameDiv,
  MemberSettingsSection,
  MembersTableContainer,
  MembersTableHeadContainer,
} from '../ActivitySettings/membersTableStyles';

const MembersTable = props => {
  const [memberList, setMemberList] = useState([]);
  const [searchTerm, setSearchText] = useState('');

  const {
    history,
    membersListLoading,
    selectedOrganization,
    membersList,
    memberSettingsChange,

    // pagination
    totalMembersCount,
    currentPageNumber,
    onPageChange,
  } = props;

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      const list = [];
      membersList.map(member => {
        list.push({
          id: member.id,
          userId: member.user.id,
          name: `${member.user.first_name}${
            member.user.last_name ? ` ${member.user.last_name}` : ``
          }`,
          avatar: member.user.avatar ? member.user.avatar : null,
          settings: member.location_track,
        });
      });
      setMemberList(list);
    } else if (membersList && membersList.length === 0) {
      setMemberList([]);
    }
  }, [membersList]);

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      const originalList = JSON.parse(JSON.stringify(membersList));
      if (searchTerm && searchTerm !== '') {
        let filteredList = [];
        originalList.forEach(e => {
          let searchField;
          searchField = `${e.user.first_name}${
            e.user.last_name ? ` ${e.user.last_name}` : ``
          }`;

          if (
            searchTerm &&
            searchField
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase().trim()) === -1
          ) {
            return;
          }
          if (selectedOrganization) {
            filteredList.push({
              id: e.id,
              name: `${e.user.first_name}${
                e.user.last_name ? ` ${e.user.last_name}` : ``
              }`,
              avatar: e.user.avatar ? e.user.avatar : null,
              settings: e.location_track,
            });
          }
        });
        setMemberList([...filteredList]);
      } else if (searchTerm === '') {
        const list = [];
        originalList.map(member => {
          list.push({
            id: member.id,
            name: `${member.user.first_name}${
              member.user.last_name ? ` ${member.user.last_name}` : ``
            }`,
            avatar: member.user.avatar ? member.user.avatar : null,
            settings: member.location_track,
          });
        });
        setMemberList([...list]);
      }
    }
  }, [searchTerm]);

  const onTextChange = event => {
    if (event.target.value != null || event.target.value != undefined) {
      setSearchText(event.target.value);
    }
  };

  return (
    <MemberSettingsSection>
      {membersListLoading ? (
        <ComponentCircleLoader padding="10px 0" />
      ) : memberList && memberList.length > 0 ? (
        <>
          <CommonGrid columns="45% 55%" gap="2em" alignItem="center">
            <CardTitle fontSize="16px" margin="0 0 0 40px">
              Tracking Individual Member
            </CardTitle>
            <Input
              backgroundImage={searchIcon}
              type="text"
              value={searchTerm}
              placeholder="Search by member name..."
              onChange={e => onTextChange(e)}
            />
          </CommonGrid>
          {totalMembersCount && totalMembersCount > 0 ? (
            <TopPagination
              itemName="members"
              totalCount={totalMembersCount}
              currentPage={currentPageNumber}
              onPageChange={page => onPageChange(page)}
              pageSize={50}
              padding="0 40px"
            />
          ) : null}
          <MembersTableHeadContainer gap="2em">
            <TableItem>
              <TableText>Member Name</TableText>
            </TableItem>
            <TableItem>
              <InfoTooltip
                mainText="Location Tracking"
                toolTipText={`Control whether you want to track GPS location of any specific employee or not. If you turn it off, location tracking of that member will be disabled.`}
                marginTop="0px"
                top="-115px"
                label
              />
            </TableItem>
          </MembersTableHeadContainer>
          {memberList.map((member, index) => (
            <MembersTableContainer key={index} gap="2em">
              <TableItem>
                <MemberNameDiv
                  onClick={() =>
                    history.push(`/user/member-details?id=${member.userId}`)
                  }
                >
                  {member.avatar ? (
                    <CommImageNameComp
                      imgSource={member.avatar}
                      name={member.name}
                      size="36px"
                      fontSize="13px"
                      index={index}
                      color={member.color}
                      hoverUnderline
                    />
                  ) : (
                    <CommFirstRoundLetterCompNew
                      backColor={index}
                      text={member.name}
                      size="36px"
                    />
                  )}
                </MemberNameDiv>
              </TableItem>
              <TableItem>
                <CommonGrid
                  columns="36px 30px"
                  gap="0.25em"
                  justifyContent="start"
                >
                  <ToggleButton
                    onClick={() => memberSettingsChange(member)}
                    active={member.settings}
                  >
                    <ToggleButtonRound active={member.settings} />
                  </ToggleButton>
                  <CommonText
                    $label={!member.settings}
                    fontWeight="500"
                    fontSize="13px"
                    margin="0"
                  >
                    {member.settings ? 'ON' : 'OFF'}
                  </CommonText>
                </CommonGrid>
              </TableItem>
            </MembersTableContainer>
          ))}
          {totalMembersCount && totalMembersCount > 0 ? (
            <BottomPagination
              totalCount={totalMembersCount}
              currentPage={currentPageNumber}
              onPageChange={page => onPageChange(page)}
              pageSize={50}
            />
          ) : null}
        </>
      ) : memberList && memberList.length === 0 && searchTerm ? (
        <>
          <CommonGrid columns="45% 55%" gap="2em" alignItem="center">
            <CardTitle fontSize="16px" margin="0 0 0 40px">
              Tracking Individual Member
            </CardTitle>
            <Input
              backgroundImage={searchIcon}
              type="text"
              value={searchTerm}
              placeholder="Search by member name..."
              onChange={e => onTextChange(e)}
            />
          </CommonGrid>
          <NoDataComponent
            title="No member found with this name"
            padding="30px 0"
            imageHeight="130px"
            imageWidth="130px"
            titleFontSize="16px"
          />
        </>
      ) : null}
    </MemberSettingsSection>
  );
};

export default MembersTable;

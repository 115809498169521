import { useState, useMemo, Fragment, useEffect } from "react";

import moment from "moment";

import {
  CardTitle,
  TableText,
  Container,
  RoundMiniImage,
  CenteredTooltip,
  CommonFlex,
  ColoredText,
  CommonImage,
} from "../../../styledComponents/common";
import { WhiteButton } from "../../../styledComponents/buttons";

import { TableItem } from "../../../styledComponents/teams";

import { HistoryTableRow } from "./approvalHistoryStyles";
import { ButtonContainer } from "../AwaitingApprovals/awaitingApprovalStyles";

import { toHHMMSS } from "../../../utils/helper";

import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";

import avatar5 from "../../../assets/img/avatar_5.svg";
import acceptedIcon from "../../../assets/img/icons/verified.svg";
import rejectedIcon from "../../../assets/img/icons/cross_red_bg.svg";
import { CommImageNameComp } from "custom_modules/Members/CommonComponents";

const HistoryTable = (props) => {
  const [approvalHistoryData, setApprovalHistoryData] = useState([]);

  const {
    seeMoreIsLoading,
    historyList,
    organizationMembersList,
    selectedOrganization,

    // pagination
    paginationSize,
    handleSeeMore,
    allLoadedDates,
  } = props;

  useEffect(() => {
    if (
      historyList &&
      historyList.size > 0 &&
      organizationMembersList &&
      organizationMembersList.length > 0
    ) {
      const listData = Array.from(historyList);
      const historyData = [];
      listData.forEach((entry) => {
        const date = entry[0];
        const userMap = entry[1];

        const dateData = {
          date,
        };
        const memberData = [];
        Array.from(userMap).forEach((userEntry) => {
          const userId = userEntry[0];
          const userData = userEntry[1];

          const data = {
            id: userId,
            ...userData,
          };
          organizationMembersList.some((member) => {
            if (member.id === userId) {
              data.user = {
                id: member.id,
                name: member.name,
                avatar: member.avatar || avatar5,
              };
              return true;
            }
            return false;
          });
          memberData.push({ ...data });
        });
        dateData.membersData = [...memberData];

        historyData.push({ ...dateData });
      });
      setApprovalHistoryData([...historyData]);
    } else if (historyList && historyList.size === 0) {
      setApprovalHistoryData([]);
    }
  }, [historyList, organizationMembersList]);

  return (
    <>
      {approvalHistoryData && approvalHistoryData.length > 0 ? (
        <>
          {approvalHistoryData.map(({ date, membersData }, dateIndex) => {
            return (
              <Fragment key={date}>
                <ButtonContainer index={dateIndex}>
                  <WhiteButton
                    type="cancel"
                    bgColor="#f8f9fc"
                    borderColor="#c2cce1"
                    cursor="auto"
                  >
                    {moment(date).format("ddd, MMM DD, YYYY")}
                  </WhiteButton>
                </ButtonContainer>
                <HistoryTableRow header>
                  <TableItem>
                    <TableText>Member Name</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>Approved/Rejected Time</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText>Total Time Worked</TableText>
                  </TableItem>
                </HistoryTableRow>
                {membersData.map((userData, memberIndex) => {
                  return (
                    <HistoryTableRow key={userData.id}>
                      <TableItem>
                        <CommImageNameComp
                          name={userData.user && userData.user.name}
                          imgSource={userData.user && userData.user.avatar}
                          size="36px"
                          fontSize="13px"
                          index={memberIndex}
                          color={userData.user.color}
                        />
                      </TableItem>
                      <TableItem>
                        <CommonFlex>
                          {userData.acceptedDuration &&
                          userData.acceptedDuration > 0 ? (
                            <CommonFlex gap="6px">
                              <TableText name>
                                {toHHMMSS(userData.acceptedDuration)}
                              </TableText>
                              <CommonImage
                                src={acceptedIcon}
                                alt=""
                                size="15px"
                              />
                            </CommonFlex>
                          ) : null}
                          {userData.rejectedDuration &&
                          userData.rejectedDuration > 0 ? (
                            <CommonFlex gap="6px">
                              <TableText name>
                                {toHHMMSS(userData.rejectedDuration)}
                              </TableText>
                              <CommonImage
                                src={rejectedIcon}
                                alt=""
                                size="15px"
                              />
                            </CommonFlex>
                          ) : null}
                        </CommonFlex>
                      </TableItem>
                      <TableItem>
                        <TableText name>
                          {toHHMMSS(userData.duration)}
                        </TableText>
                      </TableItem>
                    </HistoryTableRow>
                  );
                })}
                {seeMoreIsLoading && seeMoreIsLoading === date ? (
                  <ButtonTextLoader
                    fontSize="13px"
                    color="#20bead"
                    justifyContent="flex-start"
                    margin="5px 0 5px 40px"
                  />
                ) : membersData.length >= paginationSize &&
                  !(allLoadedDates && allLoadedDates.hasOwnProperty(date)) ? (
                  <ColoredText
                    primary
                    hover
                    fontSize="13px"
                    fontWeight="600"
                    margin="10px 0 10px 40px"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleSeeMore(date, membersData.length, "history")
                    }
                  >
                    See More
                  </ColoredText>
                ) : null}
              </Fragment>
            );
          })}
        </>
      ) : (
          <NoDataComponent title="No approval history found." />
      )}
    </>
  );
};

export default HistoryTable;

import { connect } from "react-redux";
import Preview from "./Preview";

// Actions
import {
  getInvoiceDetails,
  getInvoiceHistory,
  recordPaymentInvoice,
  getRecordPaymentInvoice,
  deleteRecordPaymentInvoice,
  createInvoiceUpdateSendLog,
  sendInvoiceEmail,
  deleteInvoice,
  isMailSendFromEdit,
} from "../invoiceActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  isLoading: state.invoice.isLoading,
  sendMailLoading: state.invoice.sendMailLoading,
  recordPaymentLoading: state.invoice.recordPaymentLoading,
  paymentDetailsLoading: state.invoice.paymentDetailsLoading,
  deleteInvoiceLoading: state.invoice.deleteInvoiceLoading,
  invoiceDetails: state.invoice.invoiceDetails,
  invoiceHistory: state.invoice.invoiceHistory,
  profileData: state.profile.profileData,
  updateInvoiceSendLog: state.invoice.updateInvoiceSendLog,
  sendInvoiceEmailInfo: state.invoice.sendInvoiceEmailInfo,
  recordPaymentInfo: state.invoice.recordPaymentInfo,
  getRecordPaymentInfo: state.invoice.getRecordPaymentInfo,
  isDeletedRecordPayment: state.invoice.isDeletedRecordPayment,
  deletedInvoiceCallback: state.invoice.deletedInvoiceCallback,
  isMailSend: state.invoice.isMailSend,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getInvoiceDetails: (details) => dispatch(getInvoiceDetails({ ...details })),
  getInvoiceHistory: (details) => dispatch(getInvoiceHistory({ ...details })),
  recordPaymentInvoice: (details) =>
    dispatch(recordPaymentInvoice({ ...details })),
  getRecordPaymentInvoice: (details) =>
    dispatch(getRecordPaymentInvoice({ ...details })),
  deleteRecordPaymentInvoice: (details) =>
    dispatch(deleteRecordPaymentInvoice({ ...details })),
  createInvoiceUpdateSendLog: (details) =>
    dispatch(createInvoiceUpdateSendLog({ ...details })),
  sendInvoiceEmail: (details) => dispatch(sendInvoiceEmail({ ...details })),
  deleteInvoice: (details) => dispatch(deleteInvoice({ ...details })),
  isMailSendFromEdit: (details) => dispatch(isMailSendFromEdit(details)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Preview);

import { Fragment, useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

import CreateProjectDropdown from '../../../components/DropdownStyle/CreateProjectDropdown';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import Select from '../../../components/ReactSelectDropdown';
import InfoTooltip from '../../../components/Tooltip/InfoTooltip';
import { ColoredButton, WhiteButton } from '../../../styledComponents/buttons';
import { CommonText, PageTitle } from '../../../styledComponents/common';
import {
  AdditionalContainer,
  AssigneesManageImgTextGrid,
  ButtonSection,
  CollapseContainer,
  CollapseRotate,
  DropDownGrid,
  HourlyRateDropdownSection,
  IconTitleSection,
  InputField,
  PersonHourlyRateContainer,
  ProjectBillingSection,
  ProjectBillingTabContainer,
  ProjectBillingtab,
  ProjectBudgetUpperColumn,
  ProjectBudgetUpperGrid,
  ProjectHourlyRateInputSection,
  SetRateButton,
  SetRateContainer,
  SliderContainer,
  StyledSlider,
  StyledThumb,
  StyledTrack,
  TitleContainer,
} from '../../../styledComponents/createProject';
import { ModalButtonSection } from '../../../styledComponents/members';
import { currencySymbolMap } from '../../../utils/currencies';

//REACT SLIDER COMPONENT
const Thumb = (props, state) => <StyledThumb {...props} />;
const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const BudgetModal = ({
  isOpen,
  toggle,
  currency,
  isLoading,
  organization_id,
  workorder_id,
  //budgeting

  createWorkOrderBudget,
  getWorkOrderBudget,
  updateWorkOrderBudget,
  //billing and budgeting states

  saveBudgetLoading,
  workOrderBudget,
  getBudgetLoading,
  clearWorkOrderBudget,
}) => {
  const [budgetWorkOrderType, setBudgetWorkOrderType] = useState({
    value: 'work_order_fees',
    label: 'Total WorkOrder Cost',
  }); //WORKORDER BUDGET > WORKORDER TYPE DROPDOWN VALUE
  const budgetWorkOrderTypeOption = [
    { value: 'work_order_fees', label: 'Total WorkOrder Cost' },
    { value: 'work_order_hours', label: 'Total WorkOrder Hours' },
  ]; //WORKORDER BUDGET > WORKORDER TYPE DROPDOWN OPTION
  const [budgetBasedOnInput, setBudgetBasedOnInput] = useState({
    value: 'pay_rate',
    label: 'Employee Pay Rate',
  }); //WORKORDER BUDGET > BASED ON DROPDOWN VALUE
  const budgetBasedOnOption = [
    { value: 'pay_rate', label: 'Employee Pay Rate' },
    { value: 'bill_rate', label: 'Employee Bill Rate' },
  ]; //WORKORDER BUDGET > BASED ON DROPDOWN OPTION
  const [budgetCostInput, setBudgetCostInput] = useState(''); //WORKORDER BUDGET > COST INPUT
  const [budgetHours, setBudgetHours] = useState(''); //WORKORDER BUDGET > BUDGET HOURS
  const [budgetHourlyRates, setBudgetHourlyRates] = useState([]);
  const [sliderValue, setSliderValue] = useState(0); //WORKORDER BUDGET > NOTIFY AT > SLIDER
  const [saveErrors, setSaveErrors] = useState({}); //STATE FOR SAVING THE ERRORS
  const [budgetPayRateInput, setBudgetPayRateInput] = useState([]);
  const [budgetBillRateInput, setBudgetBillRateInput] = useState([]);
  // useEffect(() => {
  //   getWorkOrderBudget({
  //     organization_id: organization_id,
  //     workorder_id: workorder_id,
  //   });
  // }, [workorder_id]);

  useEffect(() => {
    return () => {
      clearWorkOrderBudget();
    };
  }, []);

  useEffect(() => {
    if (workOrderBudget && workOrderBudget !== null) {
      if (workOrderBudget.budget_type) {
        if (workOrderBudget.budget_type === 'work_order_fees') {
          setBudgetWorkOrderType({
            value: 'work_order_fees',
            label: 'Total WorkOrder Cost',
          });
          if (workOrderBudget.budget_amount) {
            setBudgetCostInput(
              (workOrderBudget.budget_amount / 100).toFixed(2),
            );
          }
        } else if (workOrderBudget.budget_type === 'work_order_hours') {
          setBudgetWorkOrderType({
            value: 'work_order_hours',
            label: 'Total WorkOrder Hours',
          });
          if (workOrderBudget.budget_hours) {
            setBudgetHours(workOrderBudget.budget_hours);
          }
        }
      }
      if (workOrderBudget.budget_hourly_based_on) {
        if (workOrderBudget.budget_hourly_based_on === 'pay_rate') {
          setBudgetBasedOnInput({
            value: 'pay_rate',
            label: 'Employee Pay Rate',
          });
        } else if (workOrderBudget.budget_hourly_based_on === 'bill_rate') {
          setBudgetBasedOnInput({
            value: 'bill_rate',
            label: 'Employee Bill Rate',
          });
        }
      }
    } else if (!workOrderBudget) {
      clearBudgetState();
    }
  }, [workOrderBudget]);

  const handleBudgetSave = () => {
    if (checkBudgetError()) {
      if (organization_id && workorder_id) {
        let payload = {
          organization_id: organization_id,
          workorder_id: workorder_id,
          budget_type: budgetWorkOrderType.value,
          budget_amount:
            budgetWorkOrderType.value === 'work_order_fees'
              ? parseInt(budgetCostInput * 100)
              : 0,
          budget_hours:
            budgetWorkOrderType.value === 'work_order_hours'
              ? parseInt(budgetHours)
              : 0,
          budget_hourly_based_on:
            budgetWorkOrderType.value === 'work_order_fees'
              ? budgetBasedOnInput.value
              : null,
          notify_at: sliderValue && sliderValue > 0 ? sliderValue : null,
        };
        if (workOrderBudget && workOrderBudget !== null) {
          updateWorkOrderBudget(payload);
        } else if (!workOrderBudget) {
          createWorkOrderBudget(payload);
        }
      }
    }
  };

  const checkBudgetError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (budgetWorkOrderType === '') {
      formIsValid = false;
      saveErrors['budgetWorkOrderType'] = 'Please select a budget type first.';
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  //PROJECT BUDGET > COST INPUT
  const budgetCostInputFunc = e => {
    const { value } = e.target;
    setSaveErrors({});
    setBudgetCostInput(value);
  };
  const clearBudgetState = () => {
    setBudgetWorkOrderType({
      value: 'work_order_fees',
      label: 'Total WorkOrder Cost',
    });
    setBudgetBasedOnInput({
      value: 'pay_rate',
      label: 'Employee Pay Rate',
    });
    setBudgetCostInput('');
  };

  //PROJECT BUDGET > BUDGET HOURS
  const budgetHoursInputFunc = e => {
    const { value } = e.target;
    setSaveErrors({});
    setBudgetHours(value);
  };

  //PROJECT BUDGET > SLIDER VALUE
  const onSliderValueChange = e => {
    setSliderValue(e);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `25vh`, maxWidth: '700px' }}
    >
      <ModalBody style={{ padding: `20px` }}>
        <PageTitle
          fontSize="16px"
          style={{ paddingLeft: `10px`, paddingBottom: `15px` }}
        >
          {workOrderBudget && workOrderBudget.id
            ? 'Update Work Order Budget'
            : 'Add Work Order Budget'}
        </PageTitle>
        <>
          <ProjectBudgetUpperGrid>
            <ProjectBudgetUpperColumn style={{ paddingLeft: `10px` }}>
              <InfoTooltip
                label
                mainText="Budget Type"
                toolTipText="Budget your WORKORDER based on total WORKORDER cost or total hours"
                marginTop="10px"
              />
              <DropDownGrid columnWidth="220px">
                <Select
                  style={{ width: '100%' }}
                  isSearchable
                  value={budgetWorkOrderType}
                  options={budgetWorkOrderTypeOption}
                  placeholder="Select..."
                  onChange={e => {
                    setBudgetWorkOrderType(e);
                  }}
                  styles={CreateProjectDropdown(null)}
                />
              </DropDownGrid>
              <CommonText $label>
                {budgetWorkOrderType.value === 'work_order_fees'
                  ? 'Cost'
                  : 'Hours'}
              </CommonText>
              <ProjectHourlyRateInputSection>
                {budgetWorkOrderType.value === 'work_order_fees' && (
                  <CommonText>
                    {currency} ({currencySymbolMap[currency]})
                  </CommonText>
                )}
                <InputField
                  type="text"
                  placeholder={
                    budgetWorkOrderType.value === 'work_order_fees'
                      ? `00.00`
                      : `00`
                  }
                  value={
                    budgetWorkOrderType.value === 'work_order_fees'
                      ? budgetCostInput
                      : budgetHours
                  }
                  onChange={e => {
                    budgetWorkOrderType.value === 'work_order_fees'
                      ? budgetCostInputFunc(e)
                      : budgetHoursInputFunc(e);
                  }}
                />
                {budgetWorkOrderType.value === 'work_order_hours' && (
                  <CommonText>Hours</CommonText>
                )}
              </ProjectHourlyRateInputSection>
            </ProjectBudgetUpperColumn>
            <ProjectBudgetUpperColumn
              style={{
                alignContent:
                  budgetWorkOrderType.value === 'work_order_hours'
                    ? 'start'
                    : 'none',
              }}
            >
              {budgetWorkOrderType.value === 'work_order_fees' && (
                <>
                  <InfoTooltip
                    label
                    mainText="Based On"
                    toolTipText={`Pay Rate – Rate you pay your team member/s.`}
                    moreText={`Bill Rate – Rate you, bill clients, for the time tracked by your team member/s.`}
                    marginTop="10px"
                  />
                  <DropDownGrid>
                    <Select
                      isSearchable
                      value={budgetBasedOnInput}
                      options={budgetBasedOnOption}
                      placeholder="Select..."
                      onChange={e => {
                        setBudgetBasedOnInput(e);
                      }}
                      styles={CreateProjectDropdown(null)}
                    />
                  </DropDownGrid>
                </>
              )}
              <CommonText
                $label
                style={{
                  margin:
                    budgetWorkOrderType.value === 'work_order_fees'
                      ? 'auto 0'
                      : '10px 0 0',
                }}
              >
                Notify At
              </CommonText>
              <SliderContainer>
                <StyledSlider
                  renderTrack={Track}
                  renderThumb={Thumb}
                  onChange={e => onSliderValueChange(e)}
                />
                <CommonText
                  name
                  title
                  fontSize="13px"
                  style={{ textAlign: `end` }}
                >
                  {`${sliderValue}%`}
                </CommonText>
                <CommonText>of total budget.</CommonText>
              </SliderContainer>
            </ProjectBudgetUpperColumn>
          </ProjectBudgetUpperGrid>
        </>

        <ModalButtonSection
          style={{ justifyContent: 'start', paddingLeft: `10px` }}
        >
          <WhiteButton type="cancel" onClick={toggle}>
            Cancel
          </WhiteButton>
          <ColoredButton
            padding={saveBudgetLoading && '0 24px'}
            onClick={() => handleBudgetSave()}
            // disabled={
            //   hourlyRatesType.value === 'work_order_hourly' &&
            //   !workOrderHourlyRate
            // }
          >
            {saveBudgetLoading ? (
              <ButtonTextLoader loadingText="Saving" fontSize="13px" />
            ) : (
              'Save Budget'
            )}
          </ColoredButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default BudgetModal;

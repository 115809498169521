import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRandomColor } from 'utils/getRandomColor';

import avatar5 from '../../../assets/img/avatar_5.svg';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import RightSlidingPanel from '../../../components/RightSlidingPanel/RightSlidingPanel';
import { CommonGrid, PageTitle } from '../../../styledComponents/common';
import {
  DailyTime,
  TableBodyContainer,
  TableBodyItem,
  TableContainer,
  TableHeadContainer,
  TableHeadItem,
  TimeRangeText,
  TimesheetContainer,
  TimesheetDate,
  TotalTimeContainer,
  TotalTimeDiv,
  TotalTimeLabel,
  TotalTimeText,
} from '../../../styledComponents/timesheet';
import { getHours, toHHMMSS } from '../../../utils/helper';
import TimesheetDetails from '../TimesheetDetailsPanel';
import TimesheetFilter from '../TimesheetFilter';

const getTimes = (startDate, endDate) => {
  let times = [];
  while (moment(startDate).isSameOrBefore(moment(endDate))) {
    times.push({
      date: startDate,
      hours: '0 h 00 m',
    });
    startDate = moment(startDate).add(1, 'days');
  }
  return times;
};

const CustomRange = ({
  isLoading,

  selectedOrganization,
  profileData,

  clearTimesheetList,
  getOtherTimesheets,
  timesheetsList,

  getDailyTimesheets,
  dailyTimesheetLoading,
  dailyTimesheets,

  getOrganizationMembersList,

  //member short list
  memberShortListIsLoading,
  orgMembersShortList,
  getOrganizationMembersShortList,

  //Office
  getOfficeDetails,
  officeDetails,
  getOfficesShortList,
  officesShortList,
}) => {
  const { t } = useTranslation();
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedTask, setSelectedTask] = useState('');
  const [currentDate, setCurrentDate] = useState(moment());
  const [startDate, setStartDate] = useState(
    moment().endOf('isoWeek').subtract(13, 'days'),
  );
  const [endDate, setEndDate] = useState(moment().endOf('isoWeek'));
  const [dateLabel, setDateLabel] = useState('');
  const [dateChanged, setDateChanged] = useState(false);
  const [selectedMember, setSelectedMember] = useState('');

  const [selectedTimezone, setSelectedTimezone] = useState(null);

  const [timesheetsData, setTimesheetsData] = useState(null);

  // details panel
  const [detailsUserData, setDetailsUserData] = useState(null);
  const [detailsPanelIsOpen, setDetailsPanelIsOpen] = useState(false);
  const [selectedOffice, setSelectedOffice] = useState({
    label: '(All Offices)',
    value: null,
  });

  const [bgColors, setBgColors] = useState({});

  useEffect(() => {
    return () => {
      clearTimesheetList();
    };
  }, []);

  useEffect(() => {
    if (startDate) {
      if (!endDate) {
        setEndDate(startDate);
      }

      const maxEndDate = moment(startDate).add(3, 'months');
      if (
        endDate &&
        moment(endDate).diff(moment(startDate), 'months', true) > 3
      ) {
        setEndDate(maxEndDate);
      }

      if (startDate && endDate) {
        setDateLabel(getDateLabel());
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      if (selectedOrganization.timezone) {
        setSelectedTimezone({
          type: 'Organization',
          timezone: selectedOrganization.timezone,
          offset: selectedOrganization.timezoneoffset,
        });
      }

      setSelectedProject('');
      setSelectedTask('');
      setSelectedMember('');

      const payload = {
        organization_id: selectedOrganization.id,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      };
      getOtherTimesheets(payload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    const durations = [];
    const rows = [];
    const times = getTimes(startDate, endDate);
    for (let i = 0; i < Math.ceil(times.length / 7); i++) {
      rows.push(i);
    }
    if (timesheetsList && timesheetsList.length > 0) {
      timesheetsList.forEach(timesheet => {
        durations.push(timesheet.duration);
        times.some(item => {
          if (moment(item.date).format('YYYY-MM-DD') === timesheet.date) {
            if (timesheet.duration !== 0) {
              item.hours = getHours(toHHMMSS(timesheet.duration));
            }
            return true;
          }
          return false;
        });
        if (
          detailsUserData &&
          detailsUserData.id === timesheet.user_id &&
          detailsUserData.date &&
          moment(detailsUserData.date).format('YYYY-MM-DD') === timesheet.date
        ) {
          setDetailsUserData({
            ...detailsUserData,
            totalTime: getHours(toHHMMSS(timesheet.duration)),
          });
        }
      });
      setTimesheetsData({
        durations,
        rows,
        timesheets: [...times],
      });
    } else if (timesheetsList && timesheetsList.length === 0) {
      setTimesheetsData({
        durations,
        rows,
        timesheets: times,
      });
    }
  }, [timesheetsList]);

  const getDateLabel = () => {
    const diff = Math.ceil(
      moment(endDate).diff(moment(startDate), 'days', true),
    );
    if (
      diff === 0 &&
      moment().format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD')
    ) {
      return 'Today selected';
    }
    return diff + 1 + ' day' + (diff > 0 ? 's' : '') + ' ' + 'selected';
  };

  const selectProject = e => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      };
      if (selectedMember) {
        payload.user_id = selectedMember.id;
      }
      if (e && e.value) {
        payload.project_id = e.value;
      }
      if (selectedTimezone && selectedTimezone.type === 'Member') {
        payload.timezone = selectedTimezone.timezone;
      }
      getOtherTimesheets(payload);
    }

    if (
      e &&
      e.value &&
      selectedProject &&
      selectedProject.value &&
      e.value !== selectedProject.value
    ) {
      setSelectedProject(e);
      setSelectedTask('');
    } else if ((e && !e.value) || e == null) {
      setSelectedMember('');
      setSelectedTask('');
      setSelectedProject(e);
    } else {
      setSelectedProject(e);
    }
  };

  const selectTask = e => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      };
      if (selectedMember) {
        payload.user_id = selectedMember.id;
      }
      if (e && e.value) {
        payload.task_id = e.value;
      }
      if (selectedTimezone && selectedTimezone.type === 'Member') {
        payload.timezone = selectedTimezone.timezone;
      }
      getOtherTimesheets(payload);
    }
    setSelectedTask(e);
  };

  const selectTeamMember = e => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        user_id: e.id,
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (
        selectedTimezone &&
        selectedTimezone.type === 'Member' &&
        e.timezone &&
        e.timezone.tz
      ) {
        payload.timezone = e.timezone.tz;
        setSelectedTimezone({
          type: 'Member',
          timezone: e.timezone.tz,
          offset: e.timezone.offset,
        });
      }
      setSelectedMember(e);
      getOtherTimesheets(payload);
    }
  };

  const clearMember = () => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedOrganization.timezone) {
        setSelectedTimezone({
          type: 'Organization',
          timezone: selectedOrganization.timezone,
          offset: selectedOrganization.timezoneoffset,
        });
      }
      getOtherTimesheets(payload);
    }
    setSelectedMember('');
  };

  const onDateSelect = () => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedMember && selectedMember.id) {
        payload.user_id = selectedMember.id;
      }
      if (selectedTimezone && selectedTimezone.type === 'Member') {
        payload.timezone = selectedTimezone.timezone;
      }
      getOtherTimesheets(payload);
    }
  };

  const singleDateChange = direction => {
    if (direction === 'right') {
      setStartDate(moment(endDate).add(1, 'days'));
      setEndDate(moment(endDate).add(1, 'days'));
    } else {
      setStartDate(moment(startDate).subtract(1, 'days'));
      setEndDate(moment(startDate).subtract(1, 'days'));
    }
    setDateChanged(!dateChanged);
  };
  const selectToday = () => {
    setStartDate(moment());
    setEndDate(moment());
    setDateChanged(!dateChanged);
  };

  const handleDateChange = () => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedMember && selectedMember.id) {
        payload.user_id = selectedMember.id;
      }
      if (selectedTimezone && selectedTimezone.type === 'Member') {
        payload.timezone = selectedTimezone.timezone;
      }
      getOtherTimesheets(payload);
    }
  };

  useEffect(() => {
    handleDateChange();
  }, [dateChanged]);

  const onTimezoneSelect = timezone => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    setSelectedTimezone(timezone);
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedMember && selectedMember.id !== '') {
        payload.user_id = selectedMember.id;
      }
      if (timezone && timezone.type === 'Member') {
        payload.timezone = timezone.timezone;
      }
      getOtherTimesheets(payload);
    }
  };

  const changeDateRange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };
  const selectLastWeek = () => {
    setStartDate(moment(currentDate).subtract(6, 'days'));
    setEndDate(currentDate);
  };
  const selectLastMonth = () => {
    setStartDate(moment(currentDate).subtract(29, 'days'));
    setEndDate(currentDate);
  };

  const toggleDetailsPanel = value => {
    setDetailsPanelIsOpen(value);
    if (!value) {
      setDetailsUserData(null);
    }
  };

  const getTimesheetDetails = (date, userId) => {
    if (selectedOrganization && selectedOrganization.id && date && userId) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: date.format('YYYY-MM-DD'),
        user_id: userId,
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedTimezone && selectedTimezone.timezone) {
        payload.timezone = selectedTimezone.timezone;
      }
      getDailyTimesheets(payload);
      toggleDetailsPanel(true);
    }
  };

  const handleSetUserData = (date, hours) => {
    if (date && hours) {
      setDetailsUserData({
        id: selectedMember
          ? selectedMember.id
          : localStorage.getItem('user_id'),
        name: selectedMember
          ? selectedMember.name
          : profileData && profileData.id
            ? `${profileData.first_name}${profileData.last_name ? ` ${profileData.last_name}` : ''
            }`
            : null,
        avatar: selectedMember
          ? selectedMember.avatar
            ? selectedMember.avatar
            : avatar5
          : profileData && profileData.thumbnail
            ? profileData.thumbnail
            : avatar5,
        color: selectedMember
        ? selectedMember.color
        : profileData && profileData.color
        ? profileData.color
        : null,
        date: date,
        totalTime: hours,
      });
    }
  };

  const selectOffice = e => {
    const payload = {
      organization_id: selectedOrganization ? selectedOrganization.id : '',
    };
    if (e && e.value) {
      payload.office_id = e.value;
      getOfficeDetails(payload);
    }

    setSelectedMember('');
    setSelectedOffice(e);
  };

  return (
    <div className="content">
      <RightSlidingPanel
        isOpen={detailsPanelIsOpen}
        closePanel={() => toggleDetailsPanel(false)}
        width="500px"
      >
        {dailyTimesheetLoading ? (
          <ComponentCircleLoader padding="35vh 0" />
        ) : (
          <>
            <TimesheetDetails
              timesheetsList={dailyTimesheets}
              userData={detailsUserData}
              selectedProject={selectedProject}
              selectedTask={selectedTask}
              timezone={selectedTimezone && selectedTimezone.timezone}
              fromPage="other"
            />
          </>
        )}
      </RightSlidingPanel>
      <CommonGrid alignItem="center">
        <PageTitle>Custom Timesheet</PageTitle>
      </CommonGrid>
      <TimesheetFilter
        page="custom"
        startDate={startDate}
        endDate={endDate}
        dateLabel={dateLabel}
        onDateSelect={onDateSelect}
        singleDateChange={singleDateChange}
        changeDateRange={changeDateRange}
        selectLastWeek={selectLastWeek}
        selectLastMonth={selectLastMonth}
        selectedProject={selectedProject}
        // selectProject={selectProject}
        selectedMember={selectedMember}
        selectMember={selectTeamMember}
        clearMember={clearMember}
        // selectedTask={selectedTask}
        // selectTask={selectTask}
        selectedOrganization={selectedOrganization}
        // selectTimezone={onTimezoneSelect}
        // selectedTimezone={selectedTimezone}
        getOfficesShortList={getOfficesShortList}
        officesShortList={officesShortList}
        selectedOffice={selectedOffice}
        selectOffice={selectOffice}
        getOrganizationMembersList={getOrganizationMembersShortList}
        organizationMembersList={orgMembersShortList}
        officeDetails={officeDetails}
      />
      <TimesheetContainer>
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            <TotalTimeContainer>
              <TimeRangeText>{`${moment(startDate).format(
                'MMMM DD',
              )} - ${moment(endDate).format('MMMM DD')}`}</TimeRangeText>
              <TotalTimeDiv>
                <TotalTimeLabel>{t('total_hour')}</TotalTimeLabel>
                <TotalTimeText>
                  {timesheetsData &&
                    timesheetsData.durations &&
                    timesheetsData.durations.length > 0
                    ? getHours(
                      toHHMMSS(
                        timesheetsData.durations
                          .slice(1)
                          .reduce(
                            (prev, cur) => cur + prev,
                            timesheetsData.durations[0],
                          ),
                      ),
                    )
                    : '0 h 00 m'}
                </TotalTimeText>
              </TotalTimeDiv>
            </TotalTimeContainer>

            <TableContainer>
              <TableHeadContainer custom>
                {timesheetsData &&
                  timesheetsData.timesheets &&
                  timesheetsData.timesheets.length > 0 &&
                  timesheetsData.timesheets
                    .slice(0, 7)
                    .map((time, index) => (
                      <TableHeadItem key={index}>
                        {moment(time.date).format('ddd')}
                      </TableHeadItem>
                    ))}
              </TableHeadContainer>
              {timesheetsData &&
                timesheetsData.rows &&
                timesheetsData.rows.length > 0
                ? timesheetsData.rows.map(item => (
                  <TableBodyContainer key={item} custom>
                    {timesheetsData.timesheets
                      .slice(item * 7, (item + 1) * 7)
                      .map((time, index) => {
                        if (!bgColors[time.date])
                          bgColors[time.date] = getRandomColor();
                        return (
                          <TableBodyItem key={index}>
                            {time.hours === '0 h 00 m' ? (
                              <DailyTime
                                backgroundColor={bgColors[time.date]}
                              >
                                -
                              </DailyTime>
                            ) : (
                              <DailyTime
                                backgroundColor={bgColors[time.date]}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  getTimesheetDetails(
                                    time.date,
                                    selectedMember
                                      ? selectedMember.id
                                      : localStorage.getItem('user_id'),
                                  );
                                  handleSetUserData(time.date, time.hours);
                                }}
                              >
                                {time.hours}
                              </DailyTime>
                            )}
                            <TimesheetDate>
                              {moment(time.date).format('MMM DD')}
                            </TimesheetDate>
                          </TableBodyItem>
                        );
                      })}
                  </TableBodyContainer>
                ))
                : timesheetsData &&
                timesheetsData.timesheets &&
                timesheetsData.timesheets.length > 0 && (
                  <>
                    <TableBodyContainer custom>
                      {timesheetsData.timesheets
                        .slice(0, 7)
                        .map((time, index) => (
                          <TableBodyItem key={index}>
                            <DailyTime>-</DailyTime>
                            <TimesheetDate>
                              {moment(time.date).format('MMM DD')}
                            </TimesheetDate>
                          </TableBodyItem>
                        ))}
                    </TableBodyContainer>
                    <TableBodyContainer custom>
                      {timesheetsData.timesheets
                        .slice(7, 14)
                        .map((time, index) => (
                          <TableBodyItem key={index}>
                            <DailyTime>-</DailyTime>
                            <TimesheetDate>
                              {moment(time.date).format('MMM DD')}
                            </TimesheetDate>
                          </TableBodyItem>
                        ))}
                    </TableBodyContainer>
                  </>
                )}
            </TableContainer>
          </>
        )}
      </TimesheetContainer>
    </div>
  );
};

export default CustomRange;

import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Chat from '../../../assets/img/icons/chat.svg';
import Bean from '../../../assets/img/icons/delete.svg';
import deleteWhiteIcon from '../../../assets/img/icons/delete_white.svg';
import editWhiteIcon from '../../../assets/img/icons/edit-white.svg';
import Pencil from '../../../assets/img/icons/pencil.svg';
import PlusBlack from '../../../assets/img/icons/plus_black_2.svg';
import Plus from '../../../assets/img/icons/plus_white.svg';
import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import AddProjectDropdown from '../../../components/CustomDropdown/AddProjectDropdown';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import {
  ButtonIcon,
  ColoredButtonWithIcon,
  DeleteButton,
  EditButton,
  PrimaryButton,
  WhiteButton,
  WhiteButtonWithIcon,
} from '../../../styledComponents/buttons';
import {
  ClientNameRow,
  GeneralInfoItem,
  GeneralInfoSection,
  GeneralInfoSection2,
  HoveredSection,
  HoveredSection2,
  HoveredSectionClient,
  InnerDivTooltip,
  InvoiceTableSection,
  InvoiceTitle,
  RoundHover,
  ToolTipContainer,
  TooltipText,
} from '../../../styledComponents/clients';
import {
  CardTitle,
  CommonFlex,
  CommonImage,
  CommonText,
  Container,
  ContentHeader,
  FirstWordRound,
  PageTitle,
  TableText,
} from '../../../styledComponents/common';
import { AssigneesManageImgTextGrid } from '../../../styledComponents/createProject';
import { InvoiceStatusBox } from '../../../styledComponents/invoice';
import { SingleSection } from '../../../styledComponents/members';
import {
  ButtonFlexContainer,
  PaddingBox,
  UpperTitleButtonSection,
} from '../../../styledComponents/members';
import { TableItem } from '../../../styledComponents/teams';
import { currencySymbolMap } from '../../../utils/currencies';
import { CommFirstRoundLetterComp } from '../../Teams/CommonComponents';
import DeleteClientModal from '../DeleteClientModal';
import AddLocationModal from './AddLocationModal';
import DeleteClientLocationModal from './DeleteClientLocationModal';
import StaticTaskLocationModal from './StaticTaskLocationModal';
import FirstRoundLetterComp from 'components/FirstRoundLetter/FirstRoundLetter';

const ClientInvoiceSection = ({ invoicesList = [], history }) => {
  return (
    <>
      <InvoiceTableSection heading>
        <TableItem>
          <TableText>Invoice Number</TableText>
        </TableItem>
        <TableItem>
          <TableText>Issued Date</TableText>
        </TableItem>
        <TableItem>
          <TableText>Paid Amount / Total Amount</TableText>
        </TableItem>
        <TableItem>
          <TableText>Status</TableText>
        </TableItem>
      </InvoiceTableSection>
      {invoicesList.map((invoice, index) => (
        <InvoiceTableSection key={invoice.id}>
          <TableItem>
            <TableText name>{invoice.invoice_number}</TableText>
          </TableItem>
          <TableItem>
            <TableText name>{invoice.issued_date}</TableText>
          </TableItem>
          <TableItem>
            <TableText name>
              {`${
                currencySymbolMap[invoice.currency]
              } ${invoice.paid_amount.toFixed(2)} / ${
                currencySymbolMap[invoice.currency]
              } ${invoice.discounted_amount.toFixed(2)}`}
            </TableText>
          </TableItem>
          <TableItem>
            <InvoiceStatusBox status={invoice.status}>
              {invoice.status === 'partial' ? 'Partially Paid' : invoice.status}
            </InvoiceStatusBox>
          </TableItem>
          <TableItem>
            <PrimaryButton
              onClick={() =>
                history.push(`/user/invoice-preview?id=${invoice.id}`)
              }
            >
              Preview Invoice
            </PrimaryButton>
          </TableItem>
        </InvoiceTableSection>
      ))}
    </>
  );
};

const ClientDetails = props => {
  const [clientDetails, setClientDetails] = useState(null);
  const [clientID, setclientId] = useState(null);
  const [clientLocations, setClientLocations] = useState([]);
  const [modalDeleteClient, setModalDeleteClient] = useState(false);
  const [modalLocation, setModalLocation] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [action, setAction] = useState('add');
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [hoveredId, setHoveredId] = useState(null);
  const [staticLocationModalOpen, setStaticLocationModalOpen] = useState(false);
  const [selectedStaticLocation, setSelectedStaticLocation] = useState(null);
  const [locationIndex, setLocationIndex] = useState(null);
  const { t } = useTranslation();

  // useEffect(() => {
  //   if (props.selectedOrganization && props.selectedOrganization.id) {
  //     if (props.location.search) {
  //       const queryParsed = queryString.parse(props.location.search);
  //       let payload = {
  //         organization_id: props.selectedOrganization.id,
  //         client_id: queryParsed.id,
  //       };

  //       setclientId(payload.client_id)
  //       props.getClientDetails(payload);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      if (props.location.search) {
        const queryParsed = queryString.parse(props.location.search);
        let payload = {
          organization_id: props.selectedOrganization.id,
          client_id: queryParsed.id,
        };
        setclientId(payload.client_id);
        props.getClientDetails(payload);
      }
    }
  }, [props.selectedOrganization]);

  useEffect(() => {
    if (props.clientLocationList && props.clientLocationList.length > 0) {
      setClientLocations(props.clientLocationList);
    } else if (
      props.clientLocationList &&
      props.clientLocationList.length === 0
    ) {
      setClientLocations([]);
    }
  }, [props.clientLocationList]);

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      if (props.location.search) {
        const queryParsed = queryString.parse(props.location.search);
        let payload = {
          organization_id: props.selectedOrganization.id,
          client_id: queryParsed.id,
        };

        props.getClientLocation(payload);
      }
    }
  }, [props.selectedOrganization]);

  useEffect(() => {
    if (props.clientDetails !== undefined && props.clientDetails !== null) {
      setClientDetails(props.clientDetails);
    } else if (!props.clientDetails) {
      setClientDetails([]);
    }
  }, [props.clientDetails]);

  const toggle = () => setModalDeleteClient(!modalDeleteClient);
  const toggle2 = () => setModalLocation(!modalLocation);

  // const updateProjectList = (project, index) => {
  //   let details = { ...clientDetails };
  //   details.projects = [...details.projects, project];
  //   setClientDetails(details);
  // };

  // const handleAssignProject = project => {
  //   if (
  //     props.selectedOrganization &&
  //     props.selectedOrganization.id &&
  //     project &&
  //     project.id &&
  //     clientDetails &&
  //     clientDetails.id
  //   ) {
  //     let payload = {
  //       organization_id: props.selectedOrganization.id,
  //       project_id: project.id,
  //       client_id: clientDetails.id,
  //       clientDetails: true,
  //     };
  //     props.updateProject(payload);
  //   }
  // };

  const handleClientDelete = clientId => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        client_id: clientId,
      };
      props.deleteClient(payload);
      setTimeout(() => {
        setModalDeleteClient(!modalDeleteClient);
        props.history.push({
          pathname: '/user/customers',
          state: { from: 'details' },
        });
      }, 500);
    }
  };

  const handleClientLocationDelete = location_id => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      clientDetails &&
      clientDetails.id
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        client_id: clientDetails.id,
        location_id: location_id,
      };
      props.deleteClientLocation(payload);
      setTimeout(() => {
        setModalLocation(!modalLocation);
      }, 500);
    }
  };

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
    setAction('add');
  };

  const popupToggleUpdate = item => {
    setSelectedLocation(item);
    setAction('update');
    setPopupOpen(!popupOpen);
  };

  const toolTipChange = (value, index) => {
    setToolTip(value);
    setSelectIndex(index);
  };
  const onHoverChange = id => {
    setHoveredId(id);
  };

  const handleShowLocation = (location, index) => {
    setSelectedStaticLocation(location);
    setLocationIndex(index);

    setTimeout(() => {
      setStaticLocationModalOpen(true);
    }, 300);
  };

  return (
    <div className="content">
      <PageTitle>{t('customer')}</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => props.history.push('/user/customers')}
          subTitle={t('back_to_all_customers')}
        />
        {/* <ColoredButtonWithIcon
          onClick={() => props.history.push('/user/add-customer')}
        >
          <ButtonIcon src={Plus} alt="" />
          {t('add_customer')}
        </ColoredButtonWithIcon> */}
      </ContentHeader>
      <Container style={{ padding: `30px 0` }}>
        {props.isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            <PaddingBox>
              {clientDetails &&
                clientDetails !== null &&
                clientDetails.name && (
                  <>
                    <UpperTitleButtonSection padding="0 0">
                      <CardTitle>{t('customer_details')}</CardTitle>
                      <UpperTitleButtonSection padding="0 0" gap="0">
                        <WhiteButtonWithIcon
                          type="delete"
                          fontSize="14px"
                          onClick={toggle}
                        >
                          <ButtonIcon src={Bean} alt="" />
                          {t('delete_customer')}
                        </WhiteButtonWithIcon>
                        <DeleteClientModal
                          id={clientDetails.id}
                          isOpen={modalDeleteClient}
                          toggle={toggle}
                          handleDelete={handleClientDelete}
                          isLoading={props.clientDeleteLoading}
                        />
                      </UpperTitleButtonSection>
                    </UpperTitleButtonSection>

                    <ClientNameRow>
                      <AssigneesManageImgTextGrid>
                        <FirstRoundLetterComp
                          text={clientDetails.name}
                          backColor={0} 
                          color={clientDetails.color}
                        />
                        <CommonText>{clientDetails.name}</CommonText>
                      </AssigneesManageImgTextGrid>
                    </ClientNameRow>

                    <CommonText $label>{t('general_information')}</CommonText>

                    <GeneralInfoSection>
                      <GeneralInfoItem backColor={1}>
                        <CommonText title>
                          {clientDetails.phone_number
                            ? clientDetails.phone_number
                            : t('phone_number_not_set')}
                        </CommonText>
                        <CommonText $label fontWeight="500">
                          {t('phone_number')}
                        </CommonText>
                      </GeneralInfoItem>
                      <GeneralInfoItem
                        style={{
                          wordWrap: 'break-word',
                          overfloWrap: 'break-word',
                          wordBreak: 'break-all',
                          whiteSpace: 'normal',
                        }}
                        backColor={2}
                      >
                        <CommonText title>
                          {clientDetails.email
                            ? clientDetails.email
                            : t('email_not_set')}
                        </CommonText>
                        <CommonText $label fontWeight="500">
                          {t('email_address')}
                        </CommonText>
                      </GeneralInfoItem>
                    </GeneralInfoSection>

                    {/* {props.clientLocationList && props.clientLocationList.length > 0 ? 
            
                    props.clientLocationList.map((item, index)=>(
                     <span> 
                      <CommonText $label>Service Location {index+1}</CommonText>
                      </span>
                    ))
                    :
                    <></>
                  } */}

                    {clientLocations && clientLocations.length > 0 ? (
                      <GeneralInfoSection2>
                        {props.clientLocationList.map((item, index) => (
                          <div
                            onMouseEnter={() => onHoverChange(item.id)}
                            onMouseLeave={() => onHoverChange(null)}
                            style={{
                              position: 'relative',
                              zIndex: hoveredId === item.id ? 1 : 'auto',
                            }}
                          >
                            <CommonText $label>
                              {t('service_location')} {index + 1}
                            </CommonText>

                            {hoveredId && hoveredId === item.id ? (
                              <HoveredSectionClient>
                                <EditButton
                                  onClick={() => popupToggleUpdate(item)}
                                >
                                  <CommonImage
                                    src={editWhiteIcon}
                                    alt=""
                                    size="26px"
                                  />
                                </EditButton>
                                <DeleteButton onClick={toggle2}>
                                  <CommonImage
                                    src={deleteWhiteIcon}
                                    alt=""
                                    size="16px"
                                  />
                                </DeleteButton>

                                <DeleteClientLocationModal
                                  id={item.id}
                                  isOpen={modalLocation}
                                  toggle={toggle2}
                                  handleDelete={handleClientLocationDelete}
                                  isLoading={props.clientLocationDeleteLoading}
                                />
                              </HoveredSectionClient>
                            ) : null}
                            <div
                              style={{
                                paddingBottom: '10px',
                                paddingTop: '10px',
                                cursor: 'pointer',
                              }}
                            >
                              <GeneralInfoItem
                                minHeight="130px"
                                backColor={(index + 3) % 8}
                                onClick={() =>
                                  handleShowLocation(item, index + 1)
                                }
                              >
                                <CommonText title>{item.address}</CommonText>
                                <CommonText $label fontWeight="500">
                                  {item.name}
                                </CommonText>
                              </GeneralInfoItem>
                            </div>
                          </div>
                        ))}
                      </GeneralInfoSection2>
                    ) : (
                      <>
                        <CommonText $label>
                          {t('service_location')} 1
                        </CommonText>
                        <GeneralInfoSection>
                          <GeneralInfoItem>
                            <CommonText title>
                              {t('no_address_found')}
                            </CommonText>
                            <CommonText $label fontWeight="500">
                              {t('no_name_set')}
                            </CommonText>
                          </GeneralInfoItem>
                        </GeneralInfoSection>
                      </>
                    )}

                    {selectedLocation &&
                    selectedLocation.name !== null &&
                    selectedLocation.address !== null ? (
                      props.selectedOrganization &&
                      props.selectedOrganization.id ? (
                        <AddLocationModal
                          isOpen={popupOpen}
                          toggle={popupToggleUpdate}
                          addClientLocation={props.addClientLocation}
                          updateClientLocation={props.updateClientLocation}
                          addOrUpdateclientLocalMapLoading={
                            props.addOrUpdateclientLocalMapLoading
                          }
                          createdClientLocationInfo={
                            props.createdClientLocationInfo
                          }
                          clientLocationCreateUpdateSuccess={
                            props.clientLocationCreateUpdateSuccess
                          }
                          organization_id={props.selectedOrganization.id}
                          client_id={clientID}
                          action={action}
                          id={selectedLocation.id}
                          name={selectedLocation.name}
                          address={selectedLocation.address}
                          radius={selectedLocation.radius}
                          latitude={selectedLocation.latitude}
                          longitude={selectedLocation.longitude}
                        />
                      ) : null
                    ) : props.selectedOrganization &&
                      props.selectedOrganization.id ? (
                      <AddLocationModal
                        isOpen={popupOpen}
                        toggle={popupToggle}
                        addClientLocation={props.addClientLocation}
                        addOrUpdateclientLocalMapLoading={
                          props.addOrUpdateclientLocalMapLoading
                        }
                        createdClientLocationInfo={
                          props.createdClientLocationInfo
                        }
                        organization_id={props.selectedOrganization.id}
                        client_id={clientID}
                        action={action}
                      />
                    ) : null}

                    <ButtonFlexContainer>
                      <WhiteButtonWithIcon onClick={() => popupToggle()}>
                        <ButtonIcon src={PlusBlack} alt="" />
                        {clientLocations && clientLocations.length >= 1
                          ? t('add_more_location')
                          : t('add_location')}
                      </WhiteButtonWithIcon>
                    </ButtonFlexContainer>

                    <StaticTaskLocationModal
                      isOpen={staticLocationModalOpen}
                      toggle={() => {
                        setStaticLocationModalOpen(false);
                        setSelectedStaticLocation(null);
                      }}
                      taskLocation={selectedStaticLocation}
                      locationIndex={locationIndex}
                    />
                    {clientDetails &&
                      clientDetails.jobs &&
                      clientDetails.jobs.length > 0 && (
                        <>
                          <CommonText margin="0 0 10px 0" $label>
                            {t('customers_jobs')}
                          </CommonText>

                          <SingleSection padding="0 0 0 0">
                            <CommonFlex
                              style={{ flexWrap: 'wrap', gridRowGap: '10px' }}
                            >
                              {clientDetails.jobs.map((job, index) => (
                                <WhiteButtonWithIcon
                                  key={index}
                                  fontSize="12px"
                                  onClick={() =>
                                    props.history.push(
                                      `/user/job-details?id=${job.id}&workOrder_id=${job.work_order_id}`,
                                    )
                                  }
                                >
                                  {job.name}
                                </WhiteButtonWithIcon>
                              ))}
                            </CommonFlex>
                          </SingleSection>
                        </>
                      )}

                    {/* <ButtonFlexContainer>
                    {clientDetails.projects &&
                      clientDetails.projects.length > 0 &&
                      clientDetails.projects.map((project, index) => (
                        <WhiteButton
                          type="cancel"
                          key={index}
                          onClick={() =>
                            props.history.push(
                              `/user/project-details?id=${project.id}&type=${
                                project.is_active ? "active" : "archived"
                              }`
                            )
                          }
                        >
                          {project.name}
                        </WhiteButton>
                      ))}
                    <AddProjectDropdown
                      isLoading={props.projectListIsLoading}
                      selectedOrganization={props.selectedOrganization}
                      assignedProjects={clientDetails.projects}
                      getProjectList={props.getProjectShortList}
                      updateState={handleAssignProject}
                      projectsList={props.projectList}
                      buttonPadding="9px 15px"
                    />
                  </ButtonFlexContainer> */}
                    {clientDetails &&
                      clientDetails.invoices &&
                      clientDetails.invoices.length > 0 && (
                        <InvoiceTitle>
                          Previous Invoices for this customer
                        </InvoiceTitle>
                      )}
                  </>
                )}
            </PaddingBox>
            {clientDetails &&
              clientDetails.invoices &&
              clientDetails.invoices.length > 0 && (
                <ClientInvoiceSection
                  invoicesList={clientDetails.invoices}
                  history={props.history}
                />
              )}
          </>
        )}
      </Container>
    </div>
  );
};

export default ClientDetails;

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  PageTitle,
  ContentHeader,
  CommonFlex,
  CommonImage,
  CommonText,
  CommonGrid,
} from "../../../styledComponents/common";
import { PrimaryButton } from "../../../styledComponents/buttons";
import {
  IntegrationNameContainer,
  WorkflowsContainer,
  WorkflowCard,
} from "./integrationSetupStyles";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import ToggleButton from "../../../components/ToggleButton/ToggleButton";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";

import { createNotification } from "../../../modules/notificationManager";

import PMSetup from "./ProjectManagement";

export const WorkflowsSection = (props) => {
  const { integrationWorkflowsList, handleEnableWorkflow } = props;

  return (
    <WorkflowsContainer>
      {integrationWorkflowsList.map((workflow) => {
        return (
          <WorkflowCard key={workflow.id} enabled={workflow.is_enabled}>
            <CommonFlex justifyContent="space-between">
              <CommonGrid columns="auto">
                <CommonText fontWeight="600">{workflow.name}</CommonText>
                <CommonText $label fontWeight="500">
                  {workflow.description}
                </CommonText>
              </CommonGrid>
              <CommonFlex gap="12px">
                <ToggleButton
                  active={workflow.is_enabled}
                  handleClick={() => {
                    if (!workflow.is_enabled) {
                      handleEnableWorkflow(workflow.id);
                    }
                  }}
                />
                <CommonText $label fontWeight="500">
                  {workflow.is_enabled ? "ON" : "OFF"}
                </CommonText>
              </CommonFlex>
            </CommonFlex>
          </WorkflowCard>
        );
      })}
    </WorkflowsContainer>
  );
};

const IntegrationSetup = (props) => {
  const [integration, setIntegration] = useState(null);

  const [isActivateLoading, setIsActivateLoading] = useState(false);

  const {
    history,
    isLoading,
    selectedOrganization,
    getEnabledIntegrationsList,
    enabledIntegrationsList,
    getIntegrationWorkflowsList,
    integrationWorkflowsList,
    enableIntegrationWorkflow,
    enabledWorkflowData,
    runIntegrationWorkflow,
  } = props;

  const { id } = useParams();

  useEffect(() => {
    if (enabledIntegrationsList && enabledIntegrationsList.length > 0) {
      enabledIntegrationsList.some((item) => {
        if (item.integration_id === id) {
          setIntegration(item);
          return true;
        }
        return false;
      });
    }
  }, [enabledIntegrationsList]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id && id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      getEnabledIntegrationsList(payload);
      payload.integration_id = id;
      getIntegrationWorkflowsList(payload);
    }
  }, [selectedOrganization]);

  // useEffect(() => {
  // }, [enabledWorkflowData]);

  const handleEnableWorkflow = (workflowId) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      integration &&
      integration.id &&
      workflowId
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        enabled_integration_id: integration.id,
        workflow_id: workflowId,
      };

      enableIntegrationWorkflow(payload);
    }
  };

  const runWorkflows = (workflowsList) => {
    if (selectedOrganization && selectedOrganization.id) {
      const activated = workflowsList.filter((workflow) => workflow.is_enabled);

      if (activated && activated.length > 0) {
        activated.forEach((workflow) => {
          const { tag, name, associated_resource } = workflow;
          const payload = {
            organization_id: selectedOrganization.id,
            tag,
            name,
            associated_resource,
          };
          runIntegrationWorkflow(payload);
        });
      }
    }
  };

  const handleActivate = () => {
    const workflows = JSON.parse(JSON.stringify(integrationWorkflowsList));
    let hasActiveWorkflow = false;
    if (workflows && workflows.length > 0) {
      workflows.some((workflow) => {
        if (workflow.is_enabled) {
          hasActiveWorkflow = true;
          return true;
        }
        return false;
      });
    }
    if (hasActiveWorkflow && integration && integration.id) {
      setIsActivateLoading(true);
      runWorkflows(integrationWorkflowsList);

      setTimeout(() => {
        setIsActivateLoading(false);
        history.push({
          pathname: `/user/integrations/${integration.id}/details`,
          state: {
            enabledIntegration: integration,
          },
        });
      }, 3000);
    } else {
      createNotification("error", "No workflow is activated.", 3000);
    }
  };

  return (
    <div className="content">
      <PageTitle>
        Add {integration && integration.display_name} Details
      </PageTitle>
      <ContentHeader>
        <BackButtonComponent
          subTitle="Back to all integrations"
          onClick={() => history.push("/user/integrations")}
        />
      </ContentHeader>
      <IntegrationNameContainer>
        <CommonFlex gap="12px">
          {integration && integration.id ? (
            <>
              <CommonImage size="40px" src={integration && integration.icon} />
              <CommonText fontWeight="600" fontSize="16px">
                {integration && integration.display_name}
              </CommonText>
            </>
          ) : null}
        </CommonFlex>
      </IntegrationNameContainer>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : integrationWorkflowsList && integrationWorkflowsList.length > 0 ? (
        <>
          <CommonText fontWeight="600" fontSize="16px" margin="25px 0 0">
            Select which workflows you want to activate
          </CommonText>
          <WorkflowsSection
            integrationWorkflowsList={integrationWorkflowsList}
            handleEnableWorkflow={handleEnableWorkflow}
          />
          <PrimaryButton
            padding={isActivateLoading ? "0 20px" : "0 40px"}
            margin="30px 0 0"
            onClick={() => handleActivate()}
          >
            {isActivateLoading ? (
              <ButtonTextLoader loadingText="Activating" fontSize="13px" />
            ) : (
              "Activate"
            )}
          </PrimaryButton>
        </>
      ) : null}

      {/* <PMSetup integration={integration} /> */}
    </div>
  );
};

export default IntegrationSetup;

import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { defaultApi } from '../../utils/axiosApi';
import FilterDropDownStyle from '../DropdownStyle/FilterDropDownStyle';
import PaginatedDropdown from '../ReactSelectPaginatedDropdown';

const getProjectOptions = async (search, prevOptions, page, pageSize, url) => {
  try {
    if (url) {
      const queryParams = { page };
      if (search.trim()) {
        queryParams.name = search;
      }
      const finalUrl = queryString.stringifyUrl({ url, query: queryParams });

      const response = await defaultApi(finalUrl, 'GET');
      const { status, data } = response;

      const options = [];
      let hasMore = false;

      if (status === 200 && data?.length > 0) {
        data.forEach(item => {
          options.push({
            value: item.id,
            label: item.name,
          });
        });
        if (data.next) {
          hasMore = true;
        }
      }
      return { options, hasMore };
    } else {
      return {
        options: [],
        hasMore: false,
      };
    }
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

const ProjectDropdown = props => {
  const { t } = useTranslation();
  const [requestUrl, setRequestUrl] = useState('');
  const {
    selectedOrganization,
    selectedProject,
    selectProject,
    selectedMember,

    placeholder,
    style,
    indicatorIconSize,
    menuPlacement,

    clearable,
    multiple,
  } = props;

  useEffect(() => {
    if (selectedOrganization?.id) {
      let url;
      if (selectedMember?.value) {
        url = `${selectedOrganization.id}/workorder/short/?active=true`;
        setRequestUrl(
          queryString.stringifyUrl({
            url,
            query: { client_id: selectedMember.value },
          }),
        );
      } else {
        setRequestUrl(url);
      }
    }
  }, [selectedOrganization, selectedMember]);

  // useEffect(() => {
  //   if (requestUrl) {
  //     if (selectedMember && selectedMember.value) {
  //       setRequestUrl(
  //         queryString.stringifyUrl({
  //           url: requestUrl,
  //           query: { client_id: selectedMember.value },
  //         }),
  //       );
  //     } else {
  //       const parsedUrl = queryString.parseUrl(requestUrl);
  //       setRequestUrl(`${parsedUrl.url}?active=true`);
  //     }
  //   }
  // }, [selectedMember]);

  return (
    <PaginatedDropdown
      isSearchable
      isClearable={clearable}
      isMulti={multiple}
      menuPlacement={menuPlacement}
      indicatorIconSize={indicatorIconSize}
      placeholder={placeholder || 'Select Work Orders'}
      value={selectedProject}
      url={requestUrl}
      getOptions={getProjectOptions}
      onChange={e => selectProject(e)}
      styles={style || FilterDropDownStyle({ height: '40px' })}
    />
  );
};

export default ProjectDropdown;

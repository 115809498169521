import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Plus from '../../assets/img/icons/plus_white.svg';
import BottomPagination from '../../components/Pagination/BottomPagination/BottomPagination';
import TopPagination from '../../components/Pagination/TopPagination/TopPagination';
import SearchWithButton from '../../components/Search/SearchWithButton';
import { setPathName } from '../../filterReducer';
import {
  ButtonIcon,
  ColoredButtonWithIcon,
  TabButton,
  TabButtonContainer,
} from '../../styledComponents/buttons';
import {
  CommonGrid,
  FilterLabel,
  HeaderContainer,
  InputField,
  InputWithLabelSection,
  PageTitle,
} from '../../styledComponents/common';
import { TabLeftGrid } from '../../styledComponents/members';
import DeleteInvitationModal from './DeleteInvitationModal';
import GHLSyncModal from './GHLSyncModal';
import MemberDeleteModal from './MemberDeleteModal';
import MembersFilter from './MembersFilter';
import MembersTable from './MembersTable';

const Members = ({
  location,
  history,
  selectedOrganization,
  officesShortList,
  getOfficesShortList,
  getMembersList,
  getOrganizationMembersShortList,
  getInvitedMembersList,
  memberShortListIsLoading,
  membersList,
  orgMembersShortList,
  invitedMembersList,
  inviteMemberBulk,
  memberInvited,
  clearMemberInvited,
  inviteMemberLoading,
  isLoading,
  updateMember,
  updatedData,
  updateMemberwiseTimesheetApprovalSettings,
  //ghl employee data
  getGHLCustomerList,
  ghlSyncMemberIsLoading,
  ghlEmployeeList,
  // pagination
  totalMembersCount,
  pageSize,
  filters,
  getinvitedMembersList,
  inviteMember,
  deleteMemberInvitation,
  deleteInvitationLoading,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [memberList, setMemberList] = useState([]);
  const [invitedMemberList, setInvitedMemberList] = useState([]);
  const [activeTab, setActiveTab] = useState(
    filters?.pathname === '/user/members' && filters?.activeTab
      ? filters?.activeTab
      : 'active',
  );

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isDeleteInvitationModalVisible, setIsDeleteInvitationModalVisible] =
    useState(false);
  const [memberDeleteId, setMemberDeleteId] = useState('');
  const [invitationDeleteId, setInvitationDeleteId] = useState('');

  // filter related state
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [searchTerm, setSearchTerm] = useState(
    filters?.pathname === '/user/members' && filters?.currentSearchedTerm
      ? filters?.currentSearchedTerm
      : '',
  );
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState(
    filters?.pathname === '/user/members' && filters?.currentSearchedTerm
      ? filters?.currentSearchedTerm
      : '',
  );

  const [selectedRole, setSelectedRole] = useState({
    label: 'All Roles',
    value: null,
  });

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  //GHL employee modal
  const [isGHLSyncModalOpen, setIsGHLSyncModalOpen] = useState(false);
  const [filteredGHLEmployee, setFilteredGHLEmployee] = useState([]);

  const [filterValue, setFilterValue] = useState(
    filters?.pathname === '/user/members' && filters?.filterValue
      ? filters?.filterValue
      : {
          label: `(All members)`,
          value: 'all',
        },
  );

  const callGetMembersList = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        page: currentPageNumber,
      };
      if (selectedRole && selectedRole.value) {
        payload.role = selectedRole.value;
      }
      if (currentSearchedTerm && currentSearchedTerm.trim()) {
        payload.name = encodeURIComponent(currentSearchedTerm.toLowerCase().trim());
      }
      if (selectedOffice && selectedOffice.value) {
        payload.office_id = selectedOffice.value;
      }
      getMembersList(payload);
    }
  };
  useEffect(() => {
    callGetMembersList();
  }, [
    selectedOrganization,
    currentPageNumber,
    selectedRole,
    // selectedOffice,
    currentSearchedTerm,
    activeTab,
  ]);

  useEffect(() => {
    dispatch(
      setPathName({
        pathname: location.pathname,
        currentSearchedTerm,
        activeTab,
        // selectedOffice,
        filterValue,
      }),
    );
  }, [
    currentSearchedTerm,
    activeTab,
    // selectedOffice
    filterValue,
  ]);

  // ghl first time login

  useEffect(() => {
    if (orgMembersShortList && ghlEmployeeList) {
      const filteredList = ghlEmployeeList.filter(employee => {
        const emailExists = orgMembersShortList.some(
          orgMember => orgMember.email === employee.email,
        );
        return !emailExists;
      });
      setFilteredGHLEmployee(filteredList);
    }
  }, [orgMembersShortList, ghlEmployeeList]);

  useEffect(() => {
    if (location && location.search) {
      let queryParsed = queryString.parse(location.search);
      if (queryParsed.tab && queryParsed.tab === 'invited') {
        setActiveTab('invited');
      }
      if (
        queryParsed.ghl_members &&
        selectedOrganization &&
        selectedOrganization.id
      ) {
        ghlCustomerFetch();
      }
    }
  }, [location, selectedOrganization]);

  useEffect(() => {
    setSelectedOffice({
      label: `(${t('all_offices')})`,
      value: null,
    });
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
      if (activeTab === 'invited') {
        getInvitedMembersList({
          organization_id: selectedOrganization.id,
        });
      }
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      if (activeTab === 'invited') {
        setSearchTerm('');

        setCurrentSearchedTerm('');
      }
    }
  }, [activeTab]);

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      let filteredList = [];
      
      if (filterValue.value === 'all') {
        filteredList = membersList;
      } else if (filterValue.value === 'office assigned') {
        filteredList = membersList.filter(member => member.offices.length > 0);
      } else {
        filteredList = membersList.filter(member => member.offices.length === 0);
      }
  
      setMemberList(filteredList);
    } else if (membersList && membersList.length === 0) {
      setMemberList([]);
    }
  }, [membersList, filterValue]);
  

  useEffect(() => {
    if (invitedMembersList && invitedMembersList.length > 0) {
      setInvitedMemberList(invitedMembersList);
    } else if (invitedMembersList && invitedMembersList.length === 0) {
      setInvitedMemberList([]);
    }
  }, [invitedMembersList]);

  const onTabSwitch = value => {
    if (value === 'invited') {
      if (selectedOrganization && selectedOrganization.id) {
        getInvitedMembersList({
          organization_id: selectedOrganization.id,
        });
      }
    }
    setActiveTab(value);
  };

  const GHLSyncToggle = () => {
    setIsGHLSyncModalOpen(!isGHLSyncModalOpen);
  };

  const ghlCustomerFetch = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      getGHLCustomerList(payload);
      getOrganizationMembersShortList(payload);
      GHLSyncToggle();
    }
  };

  const handleSearchByName = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      activeTab === 'active'
    ) {
      setCurrentPageNumber(1);
      if (searchTerm && searchTerm.trim()) {
        setCurrentSearchedTerm(searchTerm);
      } else {
        setCurrentSearchedTerm('');
      }
    }
  };

  const handleClearSearch = () => {
    if (
      currentSearchedTerm &&
      selectedOrganization &&
      selectedOrganization.id
    ) {
      setCurrentPageNumber(1);
    }
    setSearchTerm('');
    setCurrentSearchedTerm('');
  };

  const handlePageChange = page => {
    setCurrentPageNumber(page);
  };

  const selectOffice = e => {
    setCurrentPageNumber(1);
    setSearchTerm('');
    setCurrentSearchedTerm('');
    setCurrentUserCodeSearchedTerm('');
    setSelectedOffice(e);
  };

  const onSearchTermChange = e => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const onGeoFenceTimeClockChange = (index, user_id, value) => {
    let newArr = [...memberList];
    newArr.map((member, i) => {
      if (index === i) {
        member.geofence_time_clock = value;
      }
    });
    setMemberList(newArr);
    updateMember({
      user_id: user_id,
      geofence_time_clock: value,
    });
  };
  const toggleInvitationDeleteModal = id => {
    if (!isDeleteInvitationModalVisible) {
      setInvitationDeleteId(id);
    }
    setIsDeleteInvitationModalVisible(!isDeleteInvitationModalVisible);
  };

  const handleDeleteInvitation = () => {
    if (selectedOrganization && selectedOrganization.id && invitationDeleteId) {
      const payload = {
        organization_id: selectedOrganization.id,
        invitation_id: invitationDeleteId,
      };
      deleteMemberInvitation(payload);
      setTimeout(() => {
        setIsDeleteInvitationModalVisible(!isDeleteInvitationModalVisible);
      }, 1200);
    }
  };

  const handleFilterChange = e => {
    setFilterValue(e);
  };


  return (
    <div className="content">
      {/* <MemberDeleteModal
        isOpen={isDeleteModalVisible}
        toggle={() => setIsDeleteModalVisible(!isDeleteModalVisible)}
        handleDelete={handleDeleteMember}
        isLoading={deleteMemberLoading}
      /> */}
      <DeleteInvitationModal
        isOpen={isDeleteInvitationModalVisible}
        toggle={() =>
          setIsDeleteInvitationModalVisible(!isDeleteInvitationModalVisible)
        }
        handleDelete={handleDeleteInvitation}
        isLoading={deleteInvitationLoading}
      />
      <GHLSyncModal
        isOpen={isGHLSyncModalOpen}
        toggle={GHLSyncToggle}
        memberShortListIsLoading={memberShortListIsLoading}
        ghlSyncMemberIsLoading={ghlSyncMemberIsLoading}
        filteredGHLEmployee={filteredGHLEmployee}
        inviteMemberBulk={inviteMemberBulk}
        memberInvited={memberInvited}
        inviteMemberLoading={inviteMemberLoading}
        selectedOrganization={selectedOrganization}
        clearMemberInvited={clearMemberInvited}
      />
      <CommonGrid alignItem="center">
        <PageTitle>{t('members')}</PageTitle>
        {/* {localStorage.getItem('user_role') &&
          localStorage.getItem('user_role') === 'admin' &&
          !localStorage.getItem('viewer_role') && (
            <ColoredButtonWithIcon
              onClick={() => history.push('/user/member-add-update')}
            >
              <ButtonIcon src={Plus} />
              {t('add_user')}
            </ColoredButtonWithIcon>
          )} */}
        {/* {selectedOrganization &&
        (selectedOrganization?.role === 'owner' ||
          selectedOrganization?.role === 'admin') ? (
          <ColoredButtonWithIcon
            onClick={() => history.push('/user/member-invitation')}
          >
            <ButtonIcon src={Plus} alt="" />
            Invite Member
          </ColoredButtonWithIcon>
        ) : null} */}
      </CommonGrid>
      <TabLeftGrid
        columns={
          selectedOrganization?.role !== 'owner' &&
          selectedOrganization?.role !== 'admin'
            ? '600px'
            : '150px 1fr'
        }
        margin="30px 0 20px 0"
      >
        {' '}
        {selectedOrganization?.role === 'owner' ||
        selectedOrganization?.role === 'admin' ? (
          <InputWithLabelSection>
            <FilterLabel>Type</FilterLabel>
            <TabButtonContainer>
              <TabButton
                onClick={() => onTabSwitch('active')}
                selected={activeTab}
                index={'active'}
              >
                Active
              </TabButton>
              <TabButton
                onClick={() => onTabSwitch('invited')}
                selected={activeTab}
                index={'invited'}
              >
                Invited
              </TabButton>
            </TabButtonContainer>
          </InputWithLabelSection>
        ) : null}
        <MembersFilter
          selectedOrganization={selectedOrganization}
          officesShortList={officesShortList}
          getOfficesShortList={getOfficesShortList}
          selectedOffice={selectedOffice}
          selectOffice={selectOffice}
          searchTerm={searchTerm}
          activeTab={activeTab}
          onSearchTermChange={onSearchTermChange}
          handleSearchByName={handleSearchByName}
          handleClearSearch={handleClearSearch}
          ghlCustomerFetch={ghlCustomerFetch}
          history={history}
          filterValue={filterValue}
          handleFilterChange={handleFilterChange}
        />
      </TabLeftGrid>
      {totalMembersCount && totalMembersCount > 0 && pageSize ? (
        <TopPagination
          itemName={t('members')}
          totalCount={totalMembersCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}

      <MembersTable
        membersList={memberList}
        invitedMembersList={invitedMembersList}
        history={history}
        searchTerm={searchTerm}
        isLoading={isLoading}
        activeTab={activeTab}
        inviteMember={inviteMember}
        toggleInvitationDeleteModal={toggleInvitationDeleteModal}
        onGeoFenceTimeClockChange={onGeoFenceTimeClockChange}
        selectedOrganization={selectedOrganization}
        updateMemberwiseTimesheetApprovalSettings={
          updateMemberwiseTimesheetApprovalSettings
        }
      />
      {totalMembersCount && totalMembersCount > 0 && pageSize ? (
        <BottomPagination
          totalCount={totalMembersCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}
    </div>
  );
};

export default Members;

import styled from "styled-components";

export const RouteMapFilterContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || "230px 150px 57px 90px"};
  grid-column-gap: ${({ gap }) => gap || "30px"};
  align-items: center;
  margin: 0;

  @media screen and (max-width: 1400px) {
    grid-column-gap: 18px;
  }
`;

import { t } from 'i18next';
import { Fragment, useEffect, useState } from 'react';

import avatar5 from '../../assets/img/avatar_5.svg';
import ProjectDropdown from '../../components/CommonFilter/ProjectDropdown';
import AddSingleMemberDropdown from '../../components/CustomDropdown/AddSingleMemberDropdown';
import CreateProjectDropdown from '../../components/DropdownStyle/CreateProjectDropdown';
import MembersDropdownStyle from '../../components/DropdownStyle/MembersDropdownStyle';
import ButtonTextLoader from '../../components/Loaders/ButtonTextLoader';
import Select from '../../components/ReactSelectDropdown';
import { filterOption } from '../../components/ReactSelectDropdown/ReactSelectDropdown';
import ClearFilterButton from '../../components/Search/clearFilterButton';
import { PrimaryButton } from '../../styledComponents/buttons';
import {
  CardTitle,
  Container,
  FormAlert,
  InputField,
  InputLabel,
  InputTextArea,
  InputWithLabelSection,
  RedStar,
} from '../../styledComponents/common';
import { FixedPaymentContainer } from '../../styledComponents/payroll';
import { currenciesWithSymbols } from '../../utils/currencies';
import { checkHtml, checkUrl } from '../../utils/helper';

const FixedAmount = props => {
  const [selectedMember, setSelectedMember] = useState(null);
  const [membersList, setMembersList] = useState([]);

  const [selectedOffice, setSelectedOffice] = useState({
    label: `(${t('all_offices')})`,
    value: null,
  });
  const [amountValue, setAmountValue] = useState('');
  const [currency, setCurrency] = useState('');
  const [description, setDescription] = useState('');
  const [saveErrors, setSaveErrors] = useState({});

  useEffect(() => {
    return () => {
      props.clearOneTimePaymentData();
    };
  }, []);

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      setSelectedOffice({
        label: `(${t('all_offices')})`,
        value: null,
      });
      setSelectedMember(null);

      let payload = {
        organization_id: props.selectedOrganization.id,
      };
      props.getOrganizationMembersList(payload);
    }
  }, [props.selectedOrganization]);

  useEffect(() => {
    if (props.membersList && props.membersList.length > 0) {
      setMembersList(props.membersList);
    } else if (props.membersList && props.membersList.length === 0) {
      setMembersList([]);
    }
  }, [props.membersList]);

  useEffect(() => {
    if (props.oneTimePaymentData && props.oneTimePaymentData.paid_at) {
      props.setTabSelect('history');
      props.setHistoryType('fixed_amount');
    }
  }, [props.oneTimePaymentData]);

  useEffect(() => {
    if (props.officeMembersList && props.officeMembersList.length > 0) {
      let assigneeList = [];
      props.officeMembersList.map(assignee => {
        assigneeList.push({
          id: assignee.user.id,
          name: `${assignee.user.first_name} ${assignee.user.last_name}`,
          avatar: assignee.user.avatar ? assignee.user.avatar : avatar5,
          color: assignee.user.color,
        });
      });
      setMembersList(assigneeList);
    } else if (
      props.officeMembersList &&
      props.officeMembersList.length === 0
    ) {
      setMembersList([]);
    }
  }, [props.officeMembersList]);

  const onOfficeChange = e => {
    setSelectedOffice(e);
    let errors = saveErrors;
    errors['project'] = '';
    setSaveErrors(errors);
    let payload = {
      organization_id: props.selectedOrganization.id,
    };
    if (e && e.value) {
      payload.office_id = e.value;
      props.getOfficeDetails(payload);
    } else {
      props.getOrganizationMembersList({
        organization_id: props.selectedOrganization.id,
      });
    }
    setSelectedMember(null);
  };
  const onAmountChange = e => {
    const { value } = e.target;
    setAmountValue(value);
    let errors = saveErrors;
    errors['amount'] = '';
    setSaveErrors(errors);
  };

  const handleSelectCurrency = e => {
    setCurrency(e);
    let errors = saveErrors;
    errors['currency'] = '';
    setSaveErrors(errors);
  };

  const onProjectDescriptionChange = e => {
    const { value } = e.target;
    setDescription(value);
    let errors = saveErrors;
    errors['note'] = '';
    setSaveErrors(errors);
  };

  const updatePerson = person => {
    setSelectedMember(person);
    let errors = saveErrors;
    errors['member'] = '';
    setSaveErrors(errors);
  };

  const handleSubmit = () => {
    if (checkError()) {
      if (props.selectedOrganization && props.selectedOrganization.id) {
        let payload = {
          organization_id: props.selectedOrganization.id,
          user_id: selectedMember.id,
          paid_amt: parseFloat(amountValue).toFixed(2) * 100,
          note: description,
        };
        if (currency && currency.value) {
          payload.currency = currency.value;
        }
        if (selectedOffice && selectedOffice.value) {
          payload.office_id = selectedOffice.value;
        }
        props.sendOneTimePayment(payload);
      }
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!selectedMember) {
      formIsValid = false;
      saveErrors['member'] = 'Please select a member.';
    }
    if (!amountValue) {
      formIsValid = false;
      saveErrors['amount'] = 'Please enter an amount.';
    } else if (amountValue && amountValue <= 0) {
      formIsValid = false;
      saveErrors['amount'] = 'Amount must be greater than 0.';
    } else if (amountValue && amountValue >= 20000000) {
      formIsValid = false;
      saveErrors['amount'] = 'Amount must be less than 20000000.';
    }
    if (!description.trim()) {
      formIsValid = false;
      saveErrors['note'] = 'Note is required for one time payment.';
    } else if (checkUrl(description)) {
      formIsValid = false;
      saveErrors['note'] = 'Note can not contain url.';
    } else if (checkHtml(description)) {
      formIsValid = false;
      saveErrors['note'] = 'Invalid input in note.';
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  return (
    <Container padding="30px">
      <CardTitle margin=" 0 0 20px 0">One Time Payment</CardTitle>
      <FixedPaymentContainer>
        <InputWithLabelSection>
          <InputLabel>Office</InputLabel>
          <Select
            isSearchable
            isClearable={selectedOffice?.value}
            value={selectedOffice}
            options={props.officeOptions}
            placeholder="Select Office..."
            onChange={e =>
              e
                ? onOfficeChange(e)
                : onOfficeChange({
                    label: `(${t('all_offices')})`,
                    value: null,
                  })
            }
            styles={CreateProjectDropdown()}
            // clearable
            filterOption={filterOption}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: props => {
                return <ClearFilterButton {...props} />;
              },
            }}
          />
        </InputWithLabelSection>
        {saveErrors && saveErrors.project && (
          <FormAlert margin="-10px 0 0 0">{saveErrors.project}</FormAlert>
        )}
        <InputWithLabelSection>
          <InputLabel>
            Member <RedStar>*</RedStar>
          </InputLabel>
          <div style={{ marginRight: `auto` }}>
            <AddSingleMemberDropdown
              assigneeManage={selectedMember}
              updateState={updatePerson}
              membersList={membersList}
              clearAssignee={() => setSelectedMember(null)}
            />
          </div>
        </InputWithLabelSection>
        {saveErrors && saveErrors.member && (
          <FormAlert margin="-10px 0 0 0">{saveErrors.member}</FormAlert>
        )}
        <InputWithLabelSection>
          <InputLabel>
            Amount <RedStar>*</RedStar>
          </InputLabel>
          <InputField
            type="number"
            placeholder="0.00"
            min={0}
            step="0.01"
            value={amountValue}
            onChange={e => onAmountChange(e)}
            error={
              saveErrors &&
              saveErrors['amount'] &&
              saveErrors['amount'] !== undefined
            }
          />
        </InputWithLabelSection>
        {saveErrors && saveErrors.amount && (
          <FormAlert margin="-10px 0 0 0">{saveErrors.amount}</FormAlert>
        )}
        <InputWithLabelSection>
          <InputLabel>Currency</InputLabel>
          <Select
            isSearchable
            value={currency}
            options={currenciesWithSymbols}
            onChange={e => {
              handleSelectCurrency(e);
            }}
            styles={MembersDropdownStyle({ minHeight: '40px' })}
          />
        </InputWithLabelSection>
        {saveErrors && saveErrors.currency && (
          <FormAlert margin="-10px 0 0 0">{saveErrors.currency}</FormAlert>
        )}
        <InputWithLabelSection>
          <InputLabel>
            Note <RedStar>*</RedStar>
          </InputLabel>
          <InputTextArea
            text="Description"
            cols="40"
            rows="5"
            height="100px"
            value={description}
            placeholder="Keep record about payment information and other stuffs..."
            onChange={e => onProjectDescriptionChange(e)}
            error={
              saveErrors &&
              saveErrors['note'] &&
              saveErrors['note'] !== undefined
            }
          ></InputTextArea>
        </InputWithLabelSection>
        {saveErrors && saveErrors.note && (
          <FormAlert margin="-10px 0 0 0">{saveErrors.note}</FormAlert>
        )}
        <PrimaryButton
          onClick={() => handleSubmit()}
          padding={props.paymentIsLoading ? '0 40px' : '0 35px'}
          width="fit-content"
        >
          {props.paymentIsLoading ? (
            <ButtonTextLoader loadingText="Sending" fontSize="13px" />
          ) : (
            'Send Payment'
          )}
        </PrimaryButton>
      </FixedPaymentContainer>
    </Container>
  );
};

export default FixedAmount;

import moment from 'moment-timezone';

import {
  CLEAR_DAILY_TIMESHEETS_LIST,
  CLEAR_MANUAL_TIME_ADDED,
  CLEAR_TIMESHEET_LIST,
  CREATE_DAILY_TIMESHEETS_LIST,
  CREATE_DAILY_TIMESHEETS_LIST_FAILURE,
  CREATE_DAILY_TIMESHEETS_LIST_SUCCESS,
  DELETE_DAILY_TIMESHEETS_LIST,
  DELETE_DAILY_TIMESHEETS_LIST_FAILURE,
  DELETE_DAILY_TIMESHEETS_LIST_SUCCESS,
  EXPORT_MONTHLY_TIMESHEET,
  EXPORT_MONTHLY_TIMESHEET_FAILURE,
  EXPORT_MONTHLY_TIMESHEET_SUCCESS,
  EXPORT_WEEKLY_TIMESHEET,
  EXPORT_WEEKLY_TIMESHEET_FAILURE,
  EXPORT_WEEKLY_TIMESHEET_SUCCESS,
  GET_DAILY_TIMESHEETS_LIST,
  GET_DAILY_TIMESHEETS_LIST_FAILURE,
  GET_DAILY_TIMESHEETS_LIST_SUCCESS,
  GET_OTHER_TIMESHEETS_LIST,
  GET_OTHER_TIMESHEETS_LIST_FAILURE,
  GET_OTHER_TIMESHEETS_LIST_SUCCESS,
  GET_WEEKLY_TIMESHEET_DETAILS,
  GET_WEEKLY_TIMESHEET_DETAILS_FAILURE,
  GET_WEEKLY_TIMESHEET_DETAILS_SUCCESS,
  LOGIN_PROFILE_CREATED_RESET,
  UPDATE_DAILY_TIMESHEETS_LIST,
  UPDATE_DAILY_TIMESHEETS_LIST_FAILURE,
  UPDATE_DAILY_TIMESHEETS_LIST_SUCCESS,
} from '../../modules/constants';
import { createNotification } from '../../modules/notificationManager';

const noInternetMsg =
  'No Internet Connection! Please connect to the internet and try again';

// ------------------------------------
// Action handler methods
// ------------------------------------
export const handleDailyTimesheetRequest = (state, action) => {
  return {
    ...state,
    dailyTimesheetLoading: true,
  };
};
export const handleDailyTimesheetRequestSuccess = (state, action) => {
  const {
    payload: { data, timezone },
  } = action;
  if (data.status === 200) {
    const timesheetList = [...data.data];
    if (timesheetList && timesheetList.length > 0) {
      timesheetList.sort((a, b) => a.start_timestamp - b.start_timestamp);
      timesheetList.forEach(timesheet => {
        timesheet.duration =
          timesheet.end_timestamp - timesheet.start_timestamp;
        if (timezone) {
          timesheet.start_timestamp = moment
            .unix(timesheet.start_timestamp)
            .tz(timezone);
          timesheet.end_timestamp = moment
            .unix(timesheet.end_timestamp)
            .tz(timezone);
        } else {
          timesheet.start_timestamp = moment.unix(timesheet.start_timestamp);
          timesheet.end_timestamp = moment.unix(timesheet.end_timestamp);
        }
        if (timesheet.idle_times && timesheet.idle_times.length > 0) {
          timesheet.idle_times.forEach(idleTime => {
            idleTime.duration =
              idleTime.end_timestamp - idleTime.start_timestamp;
            if (timezone) {
              idleTime.start_timestamp = moment
                .unix(idleTime.start_timestamp)
                .tz(timezone);
              idleTime.end_timestamp = moment
                .unix(idleTime.end_timestamp)
                .tz(timezone);
            } else {
              idleTime.start_timestamp = moment.unix(idleTime.start_timestamp);
              idleTime.end_timestamp = moment.unix(idleTime.end_timestamp);
            }
          });
          timesheet.idle_times.sort(
            (a, b) => a.start_timestamp - b.start_timestamp,
          );
        }
      });
    }
    return {
      ...state,
      dailyTimesheetLoading: false,
      dailyList: [...timesheetList],
    };
  }
  return {
    ...state,
    dailyTimesheetLoading: false,
  };
};
export const handleDailyTimesheetRequestFailure = (state, action) => {
  return {
    ...state,
    dailyTimesheetLoading: false,
  };
};

export const handleOtherTimesheetRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const handleOtherTimesheetRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      list: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const handleOtherTimesheetRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};

export const handleWeeklyTimesheetDetailsRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const handleWeeklyTimesheetDetailsRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      weeklyTimesheetDetails: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const handleWeeklyTimesheetDetailsRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};

export const handleExportWeeklyTimesheetRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const handleExportWeeklyTimesheetRequestSuccess = (state, action) => {
  const {
    payload: { data, start_date, end_date },
  } = action;
  if (data.status === 200) {
    try {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `weekly_timesheet_report_${start_date}_${end_date}.csv`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
    }

    return {
      ...state,
      isLoading: false,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const handleExportWeeklyTimesheetRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};
export const handleExportMonthlyTimesheetRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const handleExportMonthlyTimesheetRequestSuccess = (state, action) => {
  const {
    payload: { data, start_date, end_date },
  } = action;
  if (data.status === 200) {
    try {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `monthly_timesheet_report_${start_date}_${end_date}.csv`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
    }

    return {
      ...state,
      isLoading: false,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const handleExportMonthlyTimesheetRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};

export const dailyTimesheetAddRequestHandler = (state, action) => {
  return {
    ...state,
    manualTimeLoading: true,
    manualTimeAdded: false,
  };
};
export const dailyTimesheetAddRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 201 && data.data && data.data.id) {
    createNotification('success', 'Manual Time Added Successfully', 2000);
    return {
      ...state,
      manualTimeLoading: false,
      manualTimeAdded: true,
      callback: true,
    };
  }
  return {
    ...state,
    manualTimeLoading: false,
    manualTimeAdded: false,
  };
};
export const dailyTimesheetAddRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification('error', noInternetMsg, 3000);
  } else {
    let errorMsg;
    switch (response.status) {
      case 400:
        if (response.data && response.data.non_field_errors) {
          switch (response.data.non_field_errors[0]) {
            case 'you already have an active timesheet in that range':
              errorMsg =
                'You already have an active timesheet in this time range. Please exclude that time range and try again.';
              break;
            default:
              errorMsg = response.data.non_field_errors[0];
              break;
          }
        } else if (response.data && response.data.comment) {
          switch (response.data.comment[0]) {
            case 'This field may not be blank.':
              errorMsg = 'You must write a reason for adding manual time.';
              break;
            case 'Ensure this field has at least 5 characters.':
              errorMsg = 'Reason should contain at least 5 characters.';
              break;
            default:
              errorMsg = response.data.comment[0];
              break;
          }
        } else if (response.data && response.data.detail) {
          errorMsg = response.data.detail;
        } else {
          errorMsg = 'Failed to add manual time. Please try again.';
        }
        break;
      default:
        errorMsg = 'Failed to add manual time. Please try again.';
    }
    if (errorMsg) {
      createNotification('error', errorMsg, 4000);
    }
  }
  return {
    ...state,
    manualTimeLoading: false,
    manualTimeAdded: false,
  };
};

export const dailyTimesheetUpdateRequestHandler = (state, action) => {
  return {
    ...state,
    manualTimeLoading: true,
  };
};
export const dailyTimesheetUpdateRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    createNotification('success', 'Daily timesheet updated successfully', 2000);
    return {
      ...state,
      manualTimeLoading: false,
      callback: true,
    };
  }
  return {
    ...state,
    manualTimeLoading: false,
  };
};
export const dailyTimesheetUpdateRequestFailureHandler = (state, action) => {
  createNotification('error', 'Failed!! please try again', 2000);
  return {
    ...state,
    manualTimeLoading: false,
  };
};

export const dailyTimesheetDeleteRequestHandler = (state, action) => {
  return {
    ...state,
    deleteTimesheetLoading: true,
  };
};
export const dailyTimesheetDeleteRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, duration, fromPage, userId, date },
  } = action;
  if (data.status === 200) {
    createNotification('success', 'Timesheet deleted successfully', 2000);

    if (duration && userId && date && fromPage) {
      if (fromPage === 'weeklyAdmin') {
        const weeklyDetails = JSON.parse(
          JSON.stringify(state.weeklyTimesheetDetails),
        );

        weeklyDetails[userId].some(timesheetDate => {
          if (timesheetDate.date === date) {
            timesheetDate.duration -= duration;
            return true;
          }
          return false;
        });

        return {
          ...state,
          deleteTimesheetLoading: false,
          weeklyTimesheetDetails: { ...weeklyDetails },
        };
      } else if (fromPage === 'other') {
        const otherList = JSON.parse(JSON.stringify(state.list));

        otherList.some(timesheet => {
          if (timesheet.date === date && timesheet.user_id === userId) {
            timesheet.duration -= duration;
            return true;
          }
          return false;
        });

        return {
          ...state,
          deleteTimesheetLoading: false,
          list: [...otherList],
        };
      }
      return {
        ...state,
        deleteTimesheetLoading: false,
      };
    }
    return {
      ...state,
      deleteTimesheetLoading: false,
    };
  }
  return {
    ...state,
    deleteTimesheetLoading: false,
  };
};
export const dailyTimesheetDeleteRequestFailureHandler = (state, action) => {
  createNotification(
    'error',
    'Could not delete timesheet! please try again',
    2000,
  );
  return {
    ...state,
    deleteTimesheetLoading: false,
  };
};

export const handleClearDailyTimesheetsRequest = (state, action) => {
  return {
    ...state,
    dailyList: [],
  };
};
export const handleClearTimesheetRequest = (state, action) => {
  return {
    ...state,
    list: [],
  };
};
export const handleClearManualTimeAdded = (state, action) => {
  return {
    ...state,
    manualTimeAdded: false,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_DAILY_TIMESHEETS_LIST]: handleDailyTimesheetRequest,
  [GET_DAILY_TIMESHEETS_LIST_SUCCESS]: handleDailyTimesheetRequestSuccess,
  [GET_DAILY_TIMESHEETS_LIST_FAILURE]: handleDailyTimesheetRequestFailure,

  [GET_OTHER_TIMESHEETS_LIST]: handleOtherTimesheetRequest,
  [GET_OTHER_TIMESHEETS_LIST_SUCCESS]: handleOtherTimesheetRequestSuccess,
  [GET_OTHER_TIMESHEETS_LIST_FAILURE]: handleOtherTimesheetRequestFailure,

  [GET_WEEKLY_TIMESHEET_DETAILS]: handleWeeklyTimesheetDetailsRequest,
  [GET_WEEKLY_TIMESHEET_DETAILS_SUCCESS]:
    handleWeeklyTimesheetDetailsRequestSuccess,
  [GET_WEEKLY_TIMESHEET_DETAILS_FAILURE]:
    handleWeeklyTimesheetDetailsRequestFailure,

  [EXPORT_WEEKLY_TIMESHEET]: handleExportWeeklyTimesheetRequest,
  [EXPORT_WEEKLY_TIMESHEET_SUCCESS]: handleExportWeeklyTimesheetRequestSuccess,
  [EXPORT_WEEKLY_TIMESHEET_FAILURE]: handleExportWeeklyTimesheetRequestFailure,

  [EXPORT_MONTHLY_TIMESHEET]: handleExportMonthlyTimesheetRequest,
  [EXPORT_MONTHLY_TIMESHEET_SUCCESS]:
    handleExportMonthlyTimesheetRequestSuccess,
  [EXPORT_MONTHLY_TIMESHEET_FAILURE]:
    handleExportMonthlyTimesheetRequestFailure,

  [CREATE_DAILY_TIMESHEETS_LIST]: dailyTimesheetAddRequestHandler,
  [CREATE_DAILY_TIMESHEETS_LIST_SUCCESS]:
    dailyTimesheetAddRequestSuccessHandler,
  [CREATE_DAILY_TIMESHEETS_LIST_FAILURE]:
    dailyTimesheetAddRequestFailureHandler,

  [UPDATE_DAILY_TIMESHEETS_LIST]: dailyTimesheetUpdateRequestHandler,
  [UPDATE_DAILY_TIMESHEETS_LIST_SUCCESS]:
    dailyTimesheetUpdateRequestSuccessHandler,
  [UPDATE_DAILY_TIMESHEETS_LIST_FAILURE]:
    dailyTimesheetUpdateRequestFailureHandler,

  [DELETE_DAILY_TIMESHEETS_LIST]: dailyTimesheetDeleteRequestHandler,
  [DELETE_DAILY_TIMESHEETS_LIST_SUCCESS]:
    dailyTimesheetDeleteRequestSuccessHandler,
  [DELETE_DAILY_TIMESHEETS_LIST_FAILURE]:
    dailyTimesheetDeleteRequestFailureHandler,

  [CLEAR_DAILY_TIMESHEETS_LIST]: handleClearDailyTimesheetsRequest,

  [CLEAR_TIMESHEET_LIST]: handleClearTimesheetRequest,

  [CLEAR_MANUAL_TIME_ADDED]: handleClearManualTimeAdded,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  redirect: false,
  isLoading: false,
  dailyTimesheetLoading: false,
  manualTimeLoading: false,
  deleteTimesheetLoading: false,
  list: [],
  dailyList: [],
  weeklyTimesheetDetails: null,
  manualTimeAdded: false,
};

export default function timesheetReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

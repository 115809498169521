import { connect } from 'react-redux';

// Actions
import { createClient } from '../clientsActions';
// Component
import AddClient from './AddClient';

// Store props to map with current state
const mapStateToProps = state => ({
  isLoading: state.client.isLoading,
  selectedOrganization: state.organization.selectedOrganization,
  createdClientId: state.client.createdClientId,
  duplicateEmail: state.client.duplicateEmail,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  createClient: details => dispatch(createClient({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(AddClient);

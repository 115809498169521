export const planFeatures = {
  solo: [
    "Time Tracking",
    "Task Management",
    "Projects & Budgeting",
    "Client & Invoice",
    "Reporting & Dashboard",
    "Schedules (Coming Soon)",
    "Desktop App",
    "Mobile App",
    "Apps & Devices",
  ],
  standard: [
    "Office",
    "Scheduling",
    "Route Map Tracking",
    "Live Location Tracking",
    "Timesheets",
    "Timesheets Approval",
    "Payroll",
    "Members",
    "Integrations",
    "Reports",
  ],
  premium: [],
  elite: [
    "Customers",
    "Work Orders",
    "Jobs",
    "Job Approval",
    "Photo Evidence Upload",
    "Audio Evidence Recording",
    "Job Notes - Unlimited",
  ],
};

export const planFeaturesShort = {
  lite: [
    "Time Tracking",
    "Task Management",
    "Projects & Budgeting",
    "Client & Invoice",
    "Reporting & Dashboard",
    "Schedules (Coming Soon)",
    "Desktop App",
    "Mobile App",
    "Apps & Devices",
  ],
  standard: [
    "Office",
    "Scheduling",
    "Route Map Tracking",
    "Live Location Tracking",
    "Timesheets, Timesheets Approval",
    "Payroll",
    "Integrations",
    "Reports",
  ],
  elite: [
    "Customers",
    "Work Orders",
    "Jobs with job approval",
    "Photo, audio Evidence Upload",
    "Job Notes - Unlimited",
  ],
};

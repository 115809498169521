import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WhiteButton } from 'styledComponents/buttons';

import avatar5 from '../../../assets/img/avatar_5.svg';
import LeftArrow_3 from '../../../assets/img/icons/LeftArrow_3.svg';
import RightArrow_3 from '../../../assets/img/icons/RightArrow_3.svg';
import AddSingleMemberDropdown from '../../../components/CustomDropdown/AddSingleMemberDropdown';
import MembersDropdownStyle from '../../../components/DropdownStyle/MembersDropdownStyle';
import ReactDateRangePicker from '../../../components/ReactDateRangePicker/ReactDateRangePicker';
import Select from '../../../components/ReactSelectDropdown';
import {
  CommonFlex,
  FilterLabel,
  IconArrow,
  InputWithLabelSection,
} from '../../../styledComponents/common';
import { FilterContainer } from '../timesheetApprovalStyles';

const ApprovalFilter = props => {
  const { t } = useTranslation();
  const [memberOptions, setMemberOptions] = useState([]);
  const sortOptions = [
    { value: 'oldToNew', label: 'Date (Old to New)' },
    { value: 'newToOld', label: 'Date (New to Old)' },
  ];

  const {
    selectedTab,
    selectedMember,
    setSelectedMember,
    startDate,
    endDate,
    dateLabel,
    singleDateChange,
    selectToday,
    onDateSelect,
    changeDateRange,
    selectLastWeek,
    selectLastMonth,
    selectedOrganization,
    getOrganizationMembersList,
    getOrganizationMembersShortList,
    organizationMembersList,
    sortType,
    changeSortType,
    getTimesheetApprovalAwaitingList,
    getTimesheetApprovalHistory,
    disableCurrentAndFutureDates,

    // pagination
    paginationSize,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      const memberListPayload = { organization_id: selectedOrganization.id };
      getOrganizationMembersShortList(memberListPayload);

      const payload = {
        organization_id: selectedOrganization.id,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        offset: 0,
        limit: paginationSize,
      };
      if (selectedTab === 'awaiting') {
        getTimesheetApprovalAwaitingList(payload);
      } else {
        getTimesheetApprovalHistory(payload);
      }
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (organizationMembersList && organizationMembersList.length > 0) {
      setMemberOptions(organizationMembersList);
    } else if (
      organizationMembersList &&
      organizationMembersList.length === 0
    ) {
      setMemberOptions([]);
    }
  }, [organizationMembersList]);

  const onMemberSelect = member => {
    if (member && member.id) {
      if (selectedOrganization && selectedOrganization.id) {
        const payload = {
          organization_id: selectedOrganization.id,
          start_date: moment(startDate).format('YYYY-MM-DD'),
          end_date: moment(endDate).format('YYYY-MM-DD'),
          user_id: member.id,
        };

        if (selectedTab === 'awaiting') {
          getTimesheetApprovalAwaitingList(payload);
        } else {
          getTimesheetApprovalHistory(payload);
        }
        setSelectedMember(member);
      }
    }
  };

  const clearSelectedMember = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        offset: 0,
        limit: paginationSize,
      };
      if (selectedTab === 'awaiting') {
        getTimesheetApprovalAwaitingList(payload);
      } else {
        getTimesheetApprovalHistory(payload);
      }
      setSelectedMember(null);
    }
  };

  return (
    <FilterContainer
      columns={
        selectedOrganization &&
        selectedOrganization.role === 'member' &&
        '280px 230px'
      }
    >
      {selectedOrganization && selectedOrganization.role !== 'member' && (
        <InputWithLabelSection>
          <FilterLabel>Member</FilterLabel>
          <AddSingleMemberDropdown
            assigneeManage={selectedMember}
            updateState={onMemberSelect}
            membersList={memberOptions}
            clearAssignee={() => clearSelectedMember()}
          />
        </InputWithLabelSection>
      )}

      <CommonFlex gap="4px" alignItems="end">
        <InputWithLabelSection>
          <FilterLabel>
            {t('date_range')}
            {` (${dateLabel})`}
          </FilterLabel>
          <ReactDateRangePicker
            startDate={startDate ? moment(startDate) : null}
            startDateId="custom-date-range-start-date"
            endDate={endDate ? moment(endDate) : null}
            endDateId="custom-date-range-end-date"
            dateDisplayFormat="DD/MM/YYYY"
            onDateSelect={onDateSelect}
            onDatesChange={changeDateRange}
            selectLastWeek={selectLastWeek}
            selectLastMonth={selectLastMonth}
            // disableCurrentAndFutureDates={disableCurrentAndFutureDates}
            disableFutureDates={true}
          />
        </InputWithLabelSection>
        <IconArrow
          onClick={() => {
            singleDateChange('left');
          }}
          padding="5px"
          size="39px"
          src={LeftArrow_3}
        />
        <IconArrow
          onClick={() => {
            moment(endDate).isBefore(moment().subtract(1, 'day')) &&
              singleDateChange('right');
          }}
          disabled={!moment(endDate).isBefore(moment().subtract(1, 'day'))}
          padding="5px"
          size="39px"
          src={RightArrow_3}
        />
        <WhiteButton
          type="nav"
          style={{
            height: '38px',
            padding: '0px 15px',
          }}
          onClick={selectToday}
        >
          Today
        </WhiteButton>
      </CommonFlex>
      {/* <InputWithLabelSection>
        <FilterLabel>Sort By</FilterLabel>
        <Select
          isSearchable={false}
          value={sortType}
          options={sortOptions}
          onChange={(e) => {
            changeSortType(e);
          }}
          styles={MembersDropdownStyle({ minHeight: "40px" })}
        />
      </InputWithLabelSection> */}
    </FilterContainer>
  );
};

export default ApprovalFilter;

import styled, { css } from 'styled-components';

const projectColors = ['#9A96F9', '#82C4BB', '#FECF34', '#F9828B'];

export const FilterContainer = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: ${({ columns, admin }) =>
    columns
      ? columns
      : admin
      ? `180px auto 220px auto 190px`
      : `180px 220px auto 190px`};
  justify-content: start;
  grid-column-gap: ${({ gap }) => gap || '22px'};
  /* @media (max-width: 800px) {
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    grid-column-gap: 10px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    justify-content: start;
    grid-row-gap: 15px;
  }
   @media (max-width: 500px) {
    justify-content: center;
  } */
`;
export const FilterLeftGrid = styled.div`
  display: grid;
  grid-template-columns: 20% auto 55%;
  justify-content: space-between;
  grid-column-gap: 30px;
  @media (max-width: 800px) {
    grid-column-gap: 10px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    justify-content: start;
    grid-row-gap: 15px;
  }
`;
export const SearchTaskSection = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-column-gap: 30px;
  @media (max-width: 800px) {
    grid-column-gap: 10px;
  }
  @media (max-width: 374px) {
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
  }
`;

export const TaskTable = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr 1.2fr 0.5fr;
  grid-gap: 30px;
  justify-content: space-between;
  padding: ${({ title }) => (title ? `15px 60px` : `15px 30px`)};
  border-bottom: ${({ title, theme }) =>
    title && `1px solid ${theme.colors.border.secondary}`};
  background: ${({ title }) => !title && `#f8f9fc`};
  margin-top: ${({ title }) => title && `15px`};
  @media (max-width: 1366px) {
    grid-template-columns: 2.5fr 1.3fr 1fr 1.2fr 50px;
  }
`;
export const ActionTooltip = styled.div`
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  top: -38px;
  left: -50px;
  padding: 0px 4px;
  border-radius: ${({ theme }) => theme.borderRadii.tooltip};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.main.textMain};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  background-color: ${({ theme }) => theme.colors.main.white};
  min-width: 120px;
  height: 30px;
  z-index: 5;
  &::before,
  ::after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &::after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: ${({ theme }) => theme.colors.main.white};
    border-width: 5px;
    margin-left: -5px;
  }
  &::before {
    border-color: rgba(194, 204, 225, 0);
    border-top-color: ${({ theme }) => theme.colors.border.secondary};
    border-width: 6px;
    margin-left: -6px;
  }
`;
export const RoundComplete = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  border: ${({ complete, theme }) =>
    `1px solid ${
      complete ? theme.colors.border.primary : theme.colors.border.secondary
    }`};
  cursor: pointer;
  display: grid;
  justify-content: center;
  align-content: center;
  grid-template-columns: auto;
  background-color: ${({ complete, theme }) =>
    complete && theme.colors.main.primary};
  &:hover {
    background-color: rgba(32, 190, 173, 0.5);
    border: none;
  }
  &:hover ${ActionTooltip} {
    display: flex;
  }
`;
export const CompletedDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0 0 25px;
`;
export const CompletedButton = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 20px;
  grid-column-gap: 3px;
  padding: 5px 15px;
  border-radius: ${({ theme }) => theme.button.borderRadius};
  background-color: ${({ theme }) => theme.button.blueButton.bgColor};
  color: ${({ theme }) => theme.button.blueButton.textColor};
  font-size: 13px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.button.blueButton.hoverBgColor};
  }
`;

export const CollapseTable = styled.div`
  display: grid;
  max-height: ${props => (props.active ? `${props.length * 100}px` : `0`)};
  overflow: ${props => (props.active ? `visible` : `hidden`)};
  transition: max-height 0.8s ease;
  background-color: white;
  @media (max-width: 450px) {
    padding: 0 10px;
  }
`;

export const CreateTaskContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ loading }) => (loading ? `100%` : `50% 50%`)};
  justify-content: space-between;
  grid-column-gap: 25px;
`;
export const CreateTaskInput = styled.input`
  padding-left: 15px;
  color: ${({ textColor, theme }) => textColor || theme.input.textColor};
  font-size: ${({ fontSize, theme }) => fontSize || theme.input.fontSize};
  border: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: ${({ fontSize, theme }) =>
      fontSize || theme.input.placeholder.size};
    color: ${({ theme }) => theme.input.placeholder.color};
  }
`;

export const CreateTaskButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  grid-column-gap: 10px;
  padding-right: 25px;
`;

export const TaskParentProjectName = styled.div`
  font-size: ${({ fontSize, theme }) => fontSize || theme.font.pageText.size};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.white};
  background: ${({ index }) => projectColors[index % 4]};
  margin-left: 25px;
  padding: 5px 10px;
  border-radius: ${({ theme }) => theme.borderRadii.button};
`;
export const NumberOfTasks = styled.div`
  margin-left: 25px;
  font-size: 13px;
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
`;

export const NoTaskDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.main.whiteBg};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: ${({ theme }) => theme.borderRadii.card};
  padding: 30px 0px;
  margin: 20px 25px 0px;
`;
export const NoTaskArtwork = styled.img`
  height: 84px;
  width: 84px;
`;
export const NoTaskText = styled.div`
  color: ${({ theme }) => theme.colors.main.labelText};
  margin-top: 10px;
`;

export const IntegrationIconDiv = styled.div`
  position: relative;
`;
export const IntegrationIcon = styled.img`
  height: ${({ size }) => size || '18px'};
  width: ${({ size }) => size || '18px'};
  margin: ${({ margin }) => margin || '0'};
`;

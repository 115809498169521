import styled, { css } from 'styled-components';

export const PricingPlanContainer = styled.div`
  display: grid;
`;

export const PricingPlanHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
  padding-right: 4%;
`;
export const NotificationText = styled.p`
  font-size: 14px;
  color: #5d687e;
  margin-bottom: 0;
`;
export const MoneyBackTag = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 3px 6px;
  background: ${({ theme }) => theme.colors.main.successLight};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.main.successMedium};
  color: ${({ theme }) => theme.colors.main.successText};
  font-size: 12px;
  font-weight: 600;
`;

export const HeaderTitle = styled.div`
  font-size: 21px;
  font-weight: 600;
`;

export const PricingCardContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ onlyElite }) =>
    onlyElite ? `350px` : `repeat(2, 1fr)`};
  grid-column-gap: 20px;
  justify-content: center;
`;

export const SinglePricingCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${({ plan, theme }) =>
    plan === 'elite'
      ? theme.colors.main.greenBackground
      : plan === 'standard'
      ? theme.colors.main.blueBackground
      : theme.colors.main.playGrayLight};
  border-radius: 20px;
  padding: 30px 0px;
`;

export const TopSectionContainer = styled.div``;

export const PlanNameAndTagContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const PlanName = styled.div`
  font-size: 20px;
  font-weight: 600;
  padding: 0px 10px 0px 30px;
  color: ${({ theme }) => theme.colors.main.textSecondary};
  @media (max-width: 1450px) {
    padding: 0 7px 0 20px;
    font-size: 16px;
  }
`;
export const PlanNameTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  padding: 0px 15px;
  border-radius: 13px;
  font-size: 12px;
  font-weight: 600;
  background: ${({ plan, theme }) =>
    plan === 'standard' ? '#b1d0fd' : theme.colors.main.successLight};
  color: ${({ plan, theme }) =>
    plan === 'elite'
      ? theme.colors.main.successText
      : theme.colors.main.primary};

  @media (max-width: 1450px) {
    height: 22px;
    border-radius: 11px;
    font-size: 11px;
    padding: 0px 10px;
  }
`;

export const PlanPriceContainer = styled.div`
  display: flex;
  align-items: baseline;
  padding: 0px 25px;
`;
export const PlanPrice = styled.span`
  font-size: 25px;
  font-weight: 700;
  color: #1f2939;
`;
export const PlanPriceText = styled.span`
  font-size: 14px;
  color: #5d687e;
  margin-left: 7px;
`;
export const PlanDescription = styled.div`
  border-left: ${({ plan, theme }) =>
    `4px solid ${
      plan === 'elite'
        ? theme.colors.main.successMedium
        : plan === 'standard'
        ? '#4D94FF'
        : '#5D687E'
    }`};
  font-size: 16px;
  font-weight: 600;
  color: #1f2939;
  padding: 0px 15px 0px 30px;
  margin-top: 25px;
`;
export const PlanFeaturesContainer = styled.div`
  margin-top: 6px;
  padding: 0 25px;
`;
export const SinglePlanFeature = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
`;
export const FeatureTickIcon = styled.img`
  margin-top: 3px;
  filter: ${({ plan }) =>
    plan === 'elite'
      ? `hue-rotate(-120deg) saturate(100%) brightness(105%);`
      : 'none'};
`;
export const FeatureText = styled.span`
  color: #5d687e;
  font-size: 14px;
  margin-left: 12px;
`;

export const PlanSelectButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 60px);
  height: 46px;
  margin: 12px 30px 0 30px;
  background: ${({ plan, theme }) =>
    plan === 'elite'
      ? theme.colors.main.successMedium
      : plan === 'standard'
      ? '#4D94FF'
      : '#5D687E'};
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  align-self: center;
  border-radius: 10px;
  ${({ isCurrent }) =>
    isCurrent &&
    css`
      display: none;
    `}
  cursor: pointer;
  &:hover {
    background-color: ${({ plan }) =>
      plan === 'elite'
        ? '#63aa67'
        : plan === 'standard'
        ? '#3f86f0'
        : '#4f596d'};
  }
`;

export const EverythingInPrev = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 60px);
  height: 46px;
  padding: 0px 10px;
  border-radius: 12px;
  background: #5fbb723b;
  margin-left: 30px;
  margin-top: 20px;
`;
export const ArrowLeft = styled.img``;

export const PlusAddonDiv = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #5d687e;
  margin-top: 20px;
  padding-left: 30px;
`;

// toggle Switch
export const TypeTogglerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
`;
export const TypeText = styled.span`
  font-size: 16px;
  font-weight: ${({ active }) => (active ? 600 : 400)};
  color: ${({ theme, active }) =>
    active ? theme.colors.main.primary : theme.colors.main.textMain};
`;

export const ToggleSwitchContainer = styled.div`
  position: relative;
  width: 38px;
  height: 22px;
  display: inline-block;
  margin: 0px 10px;
`;
export const ToggleSwitchWrapper = styled.div`
  position: relative;
  width: 38px;
  height: 22px;
`;
export const Slider = styled.div`
  border-radius: 11px;
  background: ${({ theme }) => theme.colors.main.primary};
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.3s ease;
  &::before {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    position: absolute;
    background: #fff;
    content: '';
    margin: 0;
    padding: 0;
    top: 50%;
    left: ${({ active }) => (active ? 'calc(100% - 4px - 12px)' : '4px')};
    transform: translateY(-50%);
    transition: 0.3s;
    cursor: pointer;
    box-shadow: ${({ active }) =>
      active ? 'none' : '0 4px 4px rgba( 25, 40, 61, 0.1)'};
  }
`;
export const ComparePlansLink = styled.a`
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  margin: 0;
  cursor: pointer;
`;

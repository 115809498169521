import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import excelIcon from '../../assets/img/icons/excel-green.svg';
import plusIcon from '../../assets/img/icons/plus_white.svg';
import ProjectDropdown from '../../components/CommonFilter/ProjectDropdown';
import FilterDropDownStyle from '../../components/DropdownStyle/FilterDropDownStyle';
import ButtonTextLoader from '../../components/Loaders/ButtonTextLoader';
import BottomPagination from '../../components/Pagination/BottomPagination/BottomPagination';
import TopPagination from '../../components/Pagination/TopPagination/TopPagination';
import PricingPlan from '../../components/PricingPlan/PricingPlan';
import Select from '../../components/ReactSelectDropdown';
import { setPathName } from '../../filterReducer';
import {
  ButtonIcon,
  ColoredButton,
  ColoredButtonWithIcon,
  PrimaryButton,
  WhiteButton,
  WhiteButtonWithIcon,
} from '../../styledComponents/buttons';
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonText,
  FormAlert,
  HeaderContainer,
  PageTitle,
} from '../../styledComponents/common';
import { exportToExcel } from '../../utils/exportToExcel';
import { checkHtml, checkUrl } from '../../utils/helper';
import DeleteJobModal from './DeleteJobModal';
import DeleteTaskModal from './DeleteTaskModal';
import JobFilter from './JobFilter';
import JobList from './JobList';

const Jobs = ({
  selectedOrganization,
  getOrganizationMembersShortList,
  orgMembersShortList,
  loadingTaskAssigneeId,
  history,
  //jobs
  getjobsList,
  jobList,
  isLoadingJobs,
  totaljobsCount,
  pageSizeJob,
  //workorder short
  getWorkOrderShortList,
  workOrderShortList,
  //delete job
  deleteJob,
  jobDeleteLoading,
  deletingJobId,
  filters,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedWorkOrder, setSelectedWorkOrder] = useState(
    filters?.pathname === '/user/jobs' && filters?.selectedWorkOrder
      ? filters?.selectedWorkOrder
      : {
          label: `(${t('all_workorders')})`,
          value: null,
        },
  );
  const [selectedMember, setSelectedMember] = useState(
    filters?.pathname === '/user/jobs' && filters?.selectedMember
      ? filters?.selectedMember
      : '',
  );
  const [taskStatus, setTaskStatus] = useState(
    filters?.pathname === '/user/jobs' && filters?.taskStatus
      ? filters?.taskStatus
      : {
          label: `(${t('all_status without unscheduled')})`,
          value: 'all',
        },
  );
  const [searchTerm, setSearchTerm] = useState(
    filters?.pathname === '/user/jobs' && filters?.currentSearchedTerm
      ? filters?.currentSearchedTerm
      : '',
  );
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState(
    filters?.pathname === '/user/jobs' && filters?.currentSearchedTerm
      ? filters?.currentSearchedTerm
      : '',
  );
  const [expandedTaskId, setExpandedTaskId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [jobIdDelete, setJobIdDelete] = useState(null);
  const [selectedWorkOrderId, setSelectedWorkOrderId] = useState(null);
  const [pricingPopup, setPricingPopup] = useState(false);

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const plan = 'free';

  // SingleDate -> DateRange
  const [startDate, setStartDate] = useState(
    filters?.pathname === '/user/jobs' && filters?.startDate
      ? filters?.startDate
      : moment().startOf('isoWeek').format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(
    filters?.pathname === '/user/jobs' && filters?.endDate
      ? filters?.endDate
      : moment().endOf('isoWeek').format('YYYY-MM-DD'),
  );
  const [dateLabel, setDateLabel] = useState('');
  const [dateChanged, setDateChanged] = useState(false);

  // SingleDate -> DateRange
  const selectLastWeek = () => {
    setStartDate(moment().subtract(6, 'days').format('YYYY-MM-DD'));
    setEndDate(moment().format('YYYY-MM-DD'));
  };
  const selectLastMonth = () => {
    setStartDate(moment().subtract(29, 'days').format('YYYY-MM-DD'));
    setEndDate(moment().format('YYYY-MM-DD'));
  };
  const changeDateRange = dates => {
    setStartDate(dates.startDate);
    setEndDate(dates.endDate);
  };
  const getDateLabel = () => {
    const diff = Math.ceil(
      moment(endDate).diff(moment(startDate), 'days', true),
    );
    if (
      diff === 0 &&
      moment().format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD')
    ) {
      return 'Today selected';
    }
    return diff + 1 + ' day' + (diff > 0 ? 's' : '') + ' ' + 'selected';
  };
  const singleDateChange = direction => {
    if (direction === 'right') {
      setStartDate(moment(endDate).add(1, 'days'));
      setEndDate(moment(endDate).add(1, 'days'));
    } else {
      setStartDate(moment(startDate).subtract(1, 'days'));
      setEndDate(moment(startDate).subtract(1, 'days'));
    }
    setDateChanged(!dateChanged);
  };
  const selectToday = () => {
    setStartDate(moment());
    setEndDate(moment());
    setDateChanged(!dateChanged);
  };

  useEffect(() => {
    if (startDate) {
      if (!endDate) {
        setEndDate(startDate);
      }

      const maxEndDate = moment(startDate).add(3, 'months');
      if (
        endDate &&
        moment(endDate).diff(moment(startDate), 'months', true) > 3
      ) {
        setEndDate(maxEndDate);
      }

      if (startDate && endDate) {
        setDateLabel(getDateLabel());
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.plan &&
      selectedOrganization.plan.name === 'Standard'
    ) {
      pricingPopupToggle();
    }
    if (
      selectedOrganization &&
      selectedOrganization.plan &&
      selectedOrganization.plan.name === "Standard"
    ) {
      pricingPopupToggle();
    }
  }, [selectedOrganization]);

  const handleSearchByName = () => {
    if (searchTerm && searchTerm.trim()) {
      setCurrentSearchedTerm(searchTerm);
    } else {
      setCurrentSearchedTerm('');
    }
    setCurrentPageNumber(1);
  };
  const handleClearSearch = () => {
    setCurrentPageNumber(1);
    setSearchTerm('');
    setCurrentSearchedTerm('');
  };

  const handlePageChange = page => {
    setCurrentPageNumber(page);
  };

  const selectWorkOrder = e => {
    setCurrentPageNumber(1);
    setSearchTerm('');
    setCurrentSearchedTerm('');
    setSelectedMember('');
    setSelectedWorkOrder(e);
  };

  const selectMember = person => {
    if (person.id) {
      setSelectedMember(person);
      setSearchTerm('');
      setCurrentSearchedTerm('');
      setCurrentPageNumber(1);
    }
  };

  const clearMember = () => {
    setCurrentPageNumber(1);
    setSelectedMember('');
  };

  const onSearchTermChange = e => {
    const { value } = e.target;
    if (value !== '') {
      setSelectedMember('');
    }
    setSearchTerm(value);
  };

  const callGetJobsList = () => {
    const payload = {
      paginate: true,
      page: currentPageNumber,
    };

    if (selectedWorkOrder && selectedWorkOrder.value) {
      payload.work_order_id = selectedWorkOrder.value;
    }

    if (selectedMember && selectedMember.id) {
      payload.assignee_id = selectedMember.id;
    }

    if (taskStatus && taskStatus.value !== 'all') {
      payload.status = taskStatus.value;
    }

    if (taskStatus && taskStatus.value !== 'unscheduled') {
      payload.start_date = moment(startDate).format('YYYY-MM-DD');
      payload.end_date = moment(endDate).format('YYYY-MM-DD');
    }
    if (currentSearchedTerm && currentSearchedTerm.length > 0) {
      payload.job_name = currentSearchedTerm.toLowerCase().trim();
    }
    if (selectedOrganization && selectedOrganization.id) {
      payload.organization_id = selectedOrganization.id;
      getjobsList(payload);
    }
  };

  useEffect(() => {
    if (selectedOrganization?.id) {
      callGetJobsList();
    }
  }, [
    currentPageNumber,
    selectedOrganization,
    selectedWorkOrder,
    selectedMember,
    taskStatus,
    dateChanged,
    deletingJobId,
    currentSearchedTerm,
  ]);
  useEffect(() => {
    dispatch(
      setPathName({
        pathname: location.pathname,
        selectedWorkOrder,
        selectedMember,
        taskStatus,
        startDate,
        endDate,
        currentSearchedTerm,
      }),
    );
  }, [
    selectedWorkOrder,
    selectedMember,
    taskStatus,
    dateChanged,
    currentSearchedTerm,
  ]);
  const handleStatusChange = e => {
    setCurrentPageNumber(1);
    setSearchTerm('');
    setCurrentSearchedTerm('');
    setTaskStatus(e);
  };

  const handleDateChange = () => {
    setCurrentPageNumber(1);
  };

  useEffect(() => {
    handleDateChange();
  }, [dateChanged]);

  const toggleDeleteModal = (jobId, workOrderId) => {
    setIsOpen(!isOpen);
    if (jobId && workOrderId) {
      setJobIdDelete(jobId);
      setSelectedWorkOrderId(workOrderId);
    }
  };

  const pricingPopupToggle = () => {
    setPricingPopup(!pricingPopup);
  };

  const handleJobDelete = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      jobIdDelete &&
      selectedWorkOrderId
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        workOrder_id: selectedWorkOrderId,
        job_id: jobIdDelete,
      };
      deleteJob(payload);
      toggleDeleteModal();
    }
  };

  return (
    <div className="content">
      <DeleteJobModal
        isOpen={isOpen}
        toggle={() => toggleDeleteModal()}
        handleDelete={handleJobDelete}
        isLoading={jobDeleteLoading}
      />
      <PricingPlan
        isOpen={pricingPopup}
        toggle={pricingPopupToggle}
        popupText="You don't have permission to this feature."
        popupTitle="Upgrade to Elite to access this feature"
        history={history}
        backRoute="/user/dashboard"
        onlyElite
      />
      <CommonGrid alignItem="center">
        <PageTitle>{t('all_jobs')}</PageTitle>
        {selectedOrganization &&
        selectedOrganization.role &&
        (selectedOrganization.role === 'admin' ||
          selectedOrganization.role === 'owner') ? (
          <CommonFlex gap="12px">
            <ColoredButtonWithIcon
              onClick={() => history.push(`/user/create-job`)}
            >
              <ButtonIcon src={plusIcon} alt="" />
              {t('create_job')}
            </ColoredButtonWithIcon>
          </CommonFlex>
        ) : (
          <> </>
        )}
      </CommonGrid>
      <HeaderContainer columns="1fr">
        <JobFilter
          selectedOrganization={
            selectedOrganization && selectedOrganization.id
              ? selectedOrganization
              : ''
          }
          getOrganizationMembersList={getOrganizationMembersShortList}
          organizationMembersList={orgMembersShortList}
          //WorkOrder short list
          workOrderShortList={workOrderShortList}
          getWorkOrderShortList={getWorkOrderShortList}
          selectedWorkOrder={selectedWorkOrder}
          setSelectedWorkOrder={setSelectedWorkOrder}
          selectWorkOrder={selectWorkOrder}
          selectedMember={selectedMember}
          selectMember={selectMember}
          clearMember={clearMember}
          searchTerm={searchTerm}
          onSearchTermChange={onSearchTermChange}
          taskStatus={taskStatus}
          handleStatusChange={handleStatusChange}
          handleSearchByName={handleSearchByName}
          handleClearSearch={handleClearSearch}
          startDate={startDate}
          endDate={endDate}
          dateLabel={dateLabel}
          singleDateChange={singleDateChange}
          selectToday={selectToday}
          changeDateRange={changeDateRange}
          onDateSelect={() => {
            setDateChanged(!dateChanged);
          }}
          selectLastWeek={selectLastWeek}
          selectLastMonth={selectLastMonth}
        />
      </HeaderContainer>

      {totaljobsCount && totaljobsCount > 0 && pageSizeJob ? (
        <TopPagination
          itemName={t('jobs')}
          totalCount={totaljobsCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSizeJob}
        />
      ) : null}

      <JobList
        selectedOrganization={selectedOrganization}
        loadingTaskAssigneeId={loadingTaskAssigneeId}
        expandedTaskId={expandedTaskId}
        setExpandedTaskId={setExpandedTaskId}
        toggleDeleteModal={toggleDeleteModal}
        history={history}
        jobList={jobList}
        isLoading={isLoadingJobs}
        taskStatus={taskStatus}
      />

      {totaljobsCount && totaljobsCount > 0 && pageSizeJob ? (
        <BottomPagination
          totalCount={totaljobsCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={pageSizeJob}
        />
      ) : null}
    </div>
  );
};

export default Jobs;

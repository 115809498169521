import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import { WhiteButton } from '../../../styledComponents/buttons';
import {
  CenteredTooltip,
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonText,
  DividerLine,
  LabelWithTextSection,
} from '../../../styledComponents/common';
import { toHHMMSS } from '../../../utils/helper';
import {
  IdleTimeDurationBox,
  IdleTimeDurationDiv,
  RejectModalBody,
  RejectModalContainer,
  TimeInputContainer,
  TimeInputDiv,
} from './awaitingApprovalStyles';

const doubleChar = value => (value.length >= 2 ? value : '0' + value).slice(-2);

const TimeInput = props => {
  const { value, setValue, min, max, inputRef, moveNext, movePrev } = props;

  const [changedValue, setChangedValue] = useState(value);
  const [keyPressed, setKeyPressed] = useState('');
  const [firstFocus, setFirstFocus] = useState(true);
  const [changeCount, setChangeCount] = useState(0);

  const cleanNumber = number =>
    (!isNaN(number) ? number : '').toString().replace('0', '');
  const setSafeValue = value => {
    if (parseInt(value) >= min && parseInt(value) <= max) {
      setValue(value);
    }
  };

  useEffect(() => {
    if (changedValue !== value) {
      setChangedValue(value);
    }
  }, [value]);

  const canNotWriteMoreTo = value => {
    const hasTwoValidChars = cleanNumber(value).length === 2;
    const lastCharachterOfEndRange = parseInt(max.toString().charAt(0));
    const cannotHaveSecondChar =
      parseInt(cleanNumber(value)) > parseInt(lastCharachterOfEndRange);
    const exceedRangeLimit = parseInt(cleanNumber(value) + keyPressed) > max;
    const isMultipleOf10 =
      value.length === 2 && value.charAt(1) === '0' && value.charAt(0) !== '0';
    return (
      isMultipleOf10 ||
      hasTwoValidChars ||
      cannotHaveSecondChar ||
      exceedRangeLimit
    );
  };

  useEffect(() => {
    if (changedValue !== value && changedValue !== '' && !isNaN(keyPressed)) {
      let newHour = doubleChar(cleanNumber(value) + keyPressed);
      if (firstFocus) {
        newHour = doubleChar(keyPressed);
        setFirstFocus(false);
        setChangeCount(changeCount + 1);
        setChangedValue('');
      }
      if (parseInt(newHour.toString()) >= min) {
        parseInt(newHour.toString()) <= max && setSafeValue(newHour);
        if (canNotWriteMoreTo(newHour) || changeCount >= 1) {
          moveNext && moveNext();
        }
      } else {
        setFirstFocus(true);
        setChangeCount(0);
      }
    }
  }, [changedValue]);

  const onBackSpaceTap = e => e.key === 'Backspace' && setValue('00');
  const onSideArrowTap = e => {
    e.key === 'ArrowRight' && moveNext && moveNext();
    e.key === 'ArrowLeft' && movePrev && movePrev();
  };
  const onArrowDownTap = e => {
    if (e.key === 'ArrowDown') {
      if (parseInt(value.toString()) === min) {
        setSafeValue(doubleChar(max));
      } else {
        setSafeValue(doubleChar(parseInt(value) - 1));
      }
    }
  };
  const onArrowUpTap = e => {
    if (e.key === 'ArrowUp') {
      if (parseInt(value.toString()) === max) {
        setSafeValue(doubleChar(min));
      } else {
        setSafeValue(doubleChar(parseInt(value) + 1));
      }
    }
  };

  return (
    <TimeInputDiv
      onFocus={() => {
        setFirstFocus(true);
        setChangeCount(0);
      }}
      ref={inputRef}
      type="number"
      min={min}
      max={max}
      value={value}
      onChange={e => setChangedValue(e.target.value)}
      onKeyDown={e => {
        setKeyPressed(e.key);
        onBackSpaceTap(e);
        onSideArrowTap(e);
        onArrowDownTap(e);
        onArrowUpTap(e);
      }}
    />
  );
};

const TimePicker = props => {
  const [rangeData, setRangeData] = useState(null);
  const [hour, setHour] = useState('00');
  const [minute, setMinute] = useState('00');
  const [second, setSecond] = useState('00');

  const hourRef = useRef(null);
  const minuteRef = useRef(null);
  const secondRef = useRef(null);

  const { duration, setRejectDuration, error, setSaveErrors } = props;

  useEffect(() => {
    if (duration) {
      const hours = parseInt(duration.split(':')[0]);
      const minutes = parseInt(duration.split(':')[1]);
      const seconds = parseInt(duration.split(':')[2]);

      setRangeData({
        hoursRange: hours,
        minutesRange: hours > 0 ? 59 : minutes,
        secondsRange: minutes > 0 ? 59 : seconds,
      });
    }
  }, [duration]);

  useEffect(() => {
    if (hour !== '' && minute !== '' && second !== '') {
      setSaveErrors({});
      const reject =
        parseInt(hour) * 3600 + parseInt(minute) * 60 + parseInt(second);
      setRejectDuration(reject);
    }
  }, [hour, minute, second]);

  const focusElementByRef = ref => {
    ref.current && ref.current.focus();
  };
  const blurElementByRef = ref => {
    ref.current && ref.current.blur();
  };
  const focusHour = () => focusElementByRef(hourRef);
  const focusMinute = () => focusElementByRef(minuteRef);
  const focusSecond = () => focusElementByRef(secondRef);

  return (
    <TimeInputContainer error={error}>
      <TimeInput
        inputRef={hourRef}
        value={hour}
        setValue={setHour}
        moveNext={focusMinute}
        min={0}
        max={rangeData && rangeData.hoursRange}
      />
      <CommonText>:</CommonText>
      <TimeInput
        inputRef={minuteRef}
        value={minute}
        setValue={setMinute}
        moveNext={focusSecond}
        movePrev={focusHour}
        min={0}
        max={rangeData && rangeData.minutesRange}
      />
      <CommonText>:</CommonText>
      <TimeInput
        inputRef={secondRef}
        value={second}
        setValue={setSecond}
        moveNext={() => blurElementByRef(secondRef)}
        movePrev={focusMinute}
        min={0}
        max={rangeData && rangeData.secondsRange}
      />
    </TimeInputContainer>
  );
};

const RejectModal = props => {
  const [rejectDuration, setRejectDuration] = useState(0);
  const [saveErrors, setSaveErrors] = useState({});

  const {
    isOpen,
    toggle,
    timesheetData,
    handleRejectPartialTimesheet,
    rejectIsLoading,
  } = props;

  const checkError = () => {
    const saveErrors = {};
    let isValid = true;
    if (rejectDuration <= 0) {
      isValid = false;
      saveErrors['rejectDuration'] = 'Please enter a value more than 0';
    } else if (
      timesheetData &&
      timesheetData.rejectedDuration &&
      rejectDuration >
        timesheetData.endTimestamp -
          timesheetData.startTimestamp -
          timesheetData.rejectedDuration
    ) {
      isValid = false;
      saveErrors['rejectDuration'] =
        'Please enter a value less than or equal to the awaiting duration';
    } else if (
      timesheetData &&
      rejectDuration > timesheetData.endTimestamp - timesheetData.startTimestamp
    ) {
      isValid = false;
      saveErrors['rejectDuration'] =
        'Please enter a value less than or equal to the duration';
    }
    setSaveErrors(saveErrors);
    return isValid;
  };

  const handleReject = () => {
    if (checkError()) {
      // handle click reject
      handleRejectPartialTimesheet(timesheetData, rejectDuration);
    }
  };

  return (
    <RejectModalContainer isOpen={isOpen} toggle={toggle}>
      <RejectModalBody>
        {timesheetData && timesheetData.project && (
          <CommonGrid
            padding="0 20px"
            columns="50px auto"
            alignItem="center"
            justifyContent="flex-start"
          >
            <CommonText $label fontWeight="500">
              Project
            </CommonText>
            <CommonText>{timesheetData.project.name}</CommonText>
          </CommonGrid>
        )}

        {timesheetData && timesheetData.task && (
          <CommonGrid
            padding="0 20px"
            margin="10px 0 0"
            columns="50px auto"
            alignItem="center"
            justifyContent="flex-start"
          >
            <CommonText $label fontWeight="500">
              Task
            </CommonText>
            <CommonText>{timesheetData.task.name}</CommonText>
          </CommonGrid>
        )}

        <CommonFlex padding="20px 20px 0" justifyContent="space-between">
          <LabelWithTextSection gap="8px">
            <CommonText $label>Total Time Worked</CommonText>
            <CommonFlex gap="10px">
              <CommonText $title>
                {timesheetData &&
                  toHHMMSS(
                    timesheetData.endTimestamp - timesheetData.adjustedStart,
                  )}
              </CommonText>
              {timesheetData &&
              timesheetData.idleTimeDuration &&
              timesheetData.idleTimeDuration > 0 ? (
                <IdleTimeDurationDiv>
                  <IdleTimeDurationBox>
                    {toHHMMSS(timesheetData.idleTimeDuration)}
                  </IdleTimeDurationBox>
                  <CenteredTooltip
                    right="calc(100% + 4px)"
                    top="0"
                    left="unset"
                  >
                    Idle Time
                  </CenteredTooltip>
                </IdleTimeDurationDiv>
              ) : null}
            </CommonFlex>
          </LabelWithTextSection>
          <CommonFlex gap="45px">
            <LabelWithTextSection gap="8px">
              <CommonText $label>Start Time</CommonText>
              <CommonText>
                {timesheetData &&
                  moment.unix(timesheetData.adjustedStart).format('LT')}
              </CommonText>
            </LabelWithTextSection>
            <LabelWithTextSection gap="8px">
              <CommonText $label>End Time</CommonText>
              <CommonText>
                {timesheetData &&
                  moment.unix(timesheetData.endTimestamp).format('LT')}
              </CommonText>
            </LabelWithTextSection>
          </CommonFlex>
        </CommonFlex>
        <DividerLine margin="20px 0 0" />
        {timesheetData && timesheetData.rejectedDuration ? (
          <>
            <CommonFlex
              padding="15px 20px"
              bgColor="#f8f9fc"
              justifyContent="space-between"
            >
              <CommonFlex>
                <CommonText $label fontWeight="500">
                  Awaiting Approval
                </CommonText>
                <CommonText $title>
                  {toHHMMSS(
                    timesheetData.endTimestamp -
                      timesheetData.startTimestamp -
                      (timesheetData.acceptedDuration +
                        timesheetData.rejectedDuration),
                  )}
                </CommonText>
              </CommonFlex>
              <CommonFlex>
                <CommonText $label fontWeight="500">
                  Previously Rejected
                </CommonText>
                <ColoredText type="error" fontSize="14px">
                  {toHHMMSS(timesheetData.rejectedDuration)}
                </ColoredText>
              </CommonFlex>
            </CommonFlex>
            <DividerLine margin="0" />
          </>
        ) : null}
        <CommonFlex
          padding="0 20px"
          margin="20px 0 0"
          justifyContent="space-between"
        >
          <CommonFlex gap="12px">
            <CommonText>Reject</CommonText>
            <TimePicker
              duration={
                timesheetData &&
                toHHMMSS(
                  timesheetData.endTimestamp - timesheetData.adjustedStart,
                )
              }
              rejectDuration={rejectDuration}
              setRejectDuration={setRejectDuration}
              setSaveErrors={setSaveErrors}
              error={
                saveErrors &&
                saveErrors['rejectDuration'] &&
                saveErrors['rejectDuration'] !== undefined
              }
            />
            <CommonText>from this timesheet</CommonText>
          </CommonFlex>
          <WhiteButton
            type="delete"
            onClick={() => handleReject()}
            padding={rejectIsLoading && '0 10px'}
          >
            {rejectIsLoading ? (
              <ButtonTextLoader
                fontSize="13px"
                loadingText="Rejecting"
                color="#fe5969"
                fontWeight="500"
              />
            ) : (
              'Reject'
            )}
          </WhiteButton>
        </CommonFlex>
        {saveErrors &&
          saveErrors['rejectDuration'] &&
          saveErrors['rejectDuration'] !== undefined && (
            <ColoredText type="error" fontSize="13px" margin="10px 20px 0">
              {saveErrors['rejectDuration']}
            </ColoredText>
          )}
      </RejectModalBody>
    </RejectModalContainer>
  );
};

export default RejectModal;

import ReactDateRangePicker from 'components/ReactDateRangePicker/ReactDateRangePicker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WhiteButton } from 'styledComponents/buttons';

import avatar5 from '../../assets/img/avatar_5.svg';
import LeftArrow_3 from '../../assets/img/icons/LeftArrow_3.svg';
import RightArrow_3 from '../../assets/img/icons/RightArrow_3.svg';
import ProjectDropdown from '../../components/CommonFilter/ProjectDropdown';
import AddSingleMemberDropdown from '../../components/CustomDropdown/AddSingleMemberDropdown';
import CreateProjectDropdown from '../../components/DropdownStyle/CreateProjectDropdown';
import FilterDropDownStyle from '../../components/DropdownStyle/FilterDropDownStyle';
import Select from '../../components/ReactSelectDropdown';
import { filterOption } from '../../components/ReactSelectDropdown/ReactSelectDropdown';
import SearchWithButton from '../../components/Search/SearchWithButton';
import ClearFilterButton from '../../components/Search/clearFilterButton';
import DatePicker from '../../components/SingleDatePicker/SingleDatePicker';
import {
  CommonFlex,
  CommonImage,
  Container,
  FilterLabel,
  IconArrow,
  InputField,
  InputWithLabelSection,
} from '../../styledComponents/common';
import { FilterContainer } from '../../styledComponents/tasks';
const JobFilter = props => {
  const { t } = useTranslation();
  const [officeOptions, setOfficeOptions] = useState([
    {
      label: `(${t('all_offices')})`,
      value: null,
    },
  ]);
  const [workOrderOptions, setWorkOrderOptions] = useState([
    {
      label: `(${t('all_workorders')})`,
      value: null,
    },
  ]);

  const [memberOptions, setMemberOptions] = useState([]);
  const statusOptions = [
    {
      label: `(${t('all_status without unscheduled')})`,
      value: 'all',
    },
    {
      value: 'unscheduled',
      label: t('unscheduled'),
    },
    {
      value: 'ready to dispatch',
      label: t('ready_to_dispatch'),
    },
    {
      value: 'completed',
      label: t('completed'),
    },
    {
      value: 'missed',
      label: t('missed'),
    },
  ];

  const {
    selectedOrganization,
    getOrganizationMembersList,
    organizationMembersList,
    selectedMember,
    selectProject,
    selectMember,
    clearMember,
    taskStatus,
    handleStatusChange,
    handleSearchByName,
    handleClearSearch,
    searchTerm,
    onSearchTermChange,
    workOrderShortList,
    getWorkOrderShortList,
    selectedWorkOrder,
    setSelectedWorkOrder,
    selectWorkOrder,
    selectToday,
    singleDateChange,
    startDate,
    endDate,
    onDateSelect,
    selectLastWeek,
    selectLastMonth,
    changeDateRange,
    dateLabel,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      getWorkOrderShortList({ organization_id: selectedOrganization.id });
      getOrganizationMembersList({ organization_id: selectedOrganization.id });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (workOrderShortList && workOrderShortList.length > 0) {
      let newArr = [
        {
          label: `(${t('all_workorders')})`,
          value: null,
        },
      ];
      workOrderShortList.forEach((workorder, i) => {
        newArr.push({
          label: workorder.name,
          value: workorder.id,
        });
      });
      setWorkOrderOptions(newArr);
    }
  }, [workOrderShortList]);

  const onMemberSelect = person => {
    selectMember(person);
  };

  const clearSelectedMember = () => {
    clearMember();
  };

  // console.log('taskStatus?.value:', taskStatus?.value);
  return (
    <FilterContainer
      admin={
        selectedOrganization &&
        selectedOrganization.role &&
        (selectedOrganization.role === 'admin' ||
          selectedOrganization.role === 'owner')
      }
    >
      <InputWithLabelSection>
        <FilterLabel>{t('work_order')}</FilterLabel>
        <Select
          isSearchable
          isClearable={selectedWorkOrder?.value}
          value={selectedWorkOrder}
          options={workOrderOptions}
          placeholder="Select WorkOrder..."
          onChange={e => {
            e
              ? selectWorkOrder(e)
              : selectWorkOrder({
                  label: `(${t('all_workorders')})`,
                  value: null,
                });
          }}
          styles={CreateProjectDropdown()}
          filterOption={filterOption}
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator: props => {
              return <ClearFilterButton {...props} />;
            },
          }}
        />
      </InputWithLabelSection>

      {selectedOrganization &&
        selectedOrganization.role &&
        (selectedOrganization.role === 'admin' ||
          selectedOrganization.role === 'owner') && (
          <InputWithLabelSection>
          <FilterLabel>{t('assignee')}</FilterLabel>
            <div style={{ width: `40px` }}>
              <AddSingleMemberDropdown
                assigneeManage={selectedMember}
                updateState={onMemberSelect}
                membersList={organizationMembersList}
                clearAssignee={() => clearMember()}
              />
            </div>
          </InputWithLabelSection>
        )}

      <SearchWithButton
        itemName={t('job')}
        searchTermName={t('job_name')}
        searchInput={searchTerm}
        onInputChange={onSearchTermChange}
        handleSearch={handleSearchByName}
        handleClearSearch={handleClearSearch}
      />

      <CommonFlex gap="4px" alignItems="end">
        <InputWithLabelSection>
          <FilterLabel>
            {t('date_range')}
            {` (${taskStatus?.value === 'unscheduled' ? 'NA for Unscheduled' : dateLabel})`}
          </FilterLabel>
          <ReactDateRangePicker
            startDate={startDate ? moment(startDate) : null}
            startDateId="report-date-range-start-date"
            endDate={endDate ? moment(endDate) : null}
            endDateId="report-date-range-end-date"
            onDatesChange={changeDateRange}
            dateDisplayFormat="DD/MM/YYYY"
            onDateSelect={onDateSelect}
            selectLastWeek={selectLastWeek}
            selectLastMonth={selectLastMonth}
            rangeRestricted={true}
            disableFutureDates={true}
            disabled={taskStatus?.value === 'unscheduled'}
          />
        </InputWithLabelSection>

        <IconArrow
          onClick={() => {
            taskStatus?.value !== 'unscheduled' &&
              singleDateChange('left');
          }}
          disabled={taskStatus?.value === 'unscheduled'}
          padding="5px"
          size="39px"
          src={LeftArrow_3}
        />
        <IconArrow
          onClick={() => {
            taskStatus?.value !== 'unscheduled' &&
              moment(endDate).isBefore(moment().subtract(1, 'day')) &&
              singleDateChange('right');
          }}
          disabled={!moment(endDate).isBefore(moment().subtract(1, 'day')) || taskStatus?.value === 'unscheduled'}
          padding="5px"
          size="39px"
          src={RightArrow_3}
        />
        <WhiteButton
          type="nav"
          style={{
            height: '38px',
            padding: '0px 15px',
          }}
          onClick={taskStatus?.value !== 'unscheduled' && selectToday}
          disabled={taskStatus?.value === 'unscheduled'}
        >
          Today
        </WhiteButton>
      </CommonFlex>

      <InputWithLabelSection style={{ width: '100%' }}>
        <FilterLabel>{t('status')}</FilterLabel>
        <Select
          isSearchable={false}
          isClearable={taskStatus?.value !== 'all'}
          value={taskStatus}
          options={statusOptions}
          placeholder="Select Status..."
          onChange={e => {
            e
              ? handleStatusChange(e)
              : handleStatusChange({
                  label: `(${t('all_status without unscheduled')})`,
                  value: 'all',
                });
          }}
          styles={FilterDropDownStyle({
            height: '40px',
            width: '150px',
            menuWidth: '130px',
            height: '40px',
            width: '150px',
            menuWidth: '130px',
          })}
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator: props => {
              return <ClearFilterButton {...props} />;
            },
          }}
        />
      </InputWithLabelSection>
    </FilterContainer>
  );
};

export default JobFilter;

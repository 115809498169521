import { useState, useEffect } from "react";
import moment from "moment";
import {
  PageTitle,
  Container,
  CardTitle,
  CommonText,
  ColoredText,
  CommonGrid,
  CommonFlex,
  CommonImage,
  DividerLine,
} from "../../../styledComponents/common";
import {
  PrimaryButton,
  WhiteButton,
  ColoredButtonWithIcon,
  ButtonIcon,
  SecondaryButton,
} from "../../../styledComponents/buttons";
import {
  PlanSection,
  CapsuleAndPriceContainer,
  PlanCapsule,
  MemeberInfoSection,
  MemeberBlock,
  SaveTag,
} from "../../../styledComponents/billing";
import {
  BillingTextTooltip,
  BillingQues,
  BillingTooltip,
  BillingTooltipText,
} from "../../../styledComponents/createProject";
import { ActivityTextSection } from "../../../styledComponents/members";
import { ColorCircle } from "../../../styledComponents/dashboard";
import {
  NoPlanContainer,
  NoPlanArtwork,
  NoPlanMainText,
  NoPlanSecondaryText,
  SeatedDiscountDiv,
  PausedReasonsDiv,
  PaymentInfoContainer,
} from "../subscriptionStyles";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";

import CancelSubscriptionModal from "./CancelSubscriptionModal";
import ChangePaymentMethodModal from "./ChangePaymentMethodModal";

import crown from "../../../assets/img/common/crown.svg";
import noPlanArtwork from "../../../assets/img/common/no-plan-artwork.svg";
import subscriptionActiveIcon from "../../../assets/img/icons/subscription-active.svg";
import subscriptionPausedIcon from "../../../assets/img/icons/subscription-paused.svg";
import subscriptionCancelledIcon from "../../../assets/img/icons/subscription-cancelled.svg";

import masterIcon from "../../../assets/img/payment-methods/card-master.svg";
import visaIcon from "../../../assets/img/payment-methods/card-visa.svg";
import aeIcon from "../../../assets/img/payment-methods/card-american-express.svg";
import discoverIcon from "../../../assets/img/payment-methods/card-discover.svg";
import jcbIcon from "../../../assets/img/payment-methods/card-jcb.svg";
import maestroIcon from "../../../assets/img/payment-methods/card-maestro.svg";
import dcIcon from "../../../assets/img/payment-methods/card-dinersclub.svg";
import unionpayIcon from "../../../assets/img/payment-methods/card-unionpay.svg";
import paypalIcon from "../../../assets/img/payment-methods/card-paypal.svg";

const getCardIcon = (cardType) => {
  switch (cardType) {
    case "paypal":
      return paypalIcon;
    case "master":
      return masterIcon;
    case "visa":
      return visaIcon;
    case "american_express":
      return aeIcon;
    case "discover":
      return discoverIcon;
    case "jcb":
      return jcbIcon;
    case "maestro":
      return maestroIcon;
    case "diners_club":
      return dcIcon;
    case "unionpay":
      return unionpayIcon;
    default:
      return null;
  }
};

const planDetails = {
  Solo: "Ideal for freelancers & contractors to track time, keep focus & bill clients.",
  Standard: "Ideal for the in-house teams or teams of any size & shape.",
  Premium:
    "Ideal for Mobile teams or a combination of in-house & Mobile teams.",
  Elite:
    "Ideal for Remote Teams or a combination of in-house, mobile & remote teams.",
};

const colors = ["#73A8FC", "#B6B4FA", "#F9DD8F"];

const NoPlanSection = (props) => {
  return (
    <NoPlanContainer>
      <NoPlanArtwork src={noPlanArtwork} alt="" />
      <NoPlanMainText>
        Organization{" "}
        {props.organization && props.organization.name
          ? `"${props.organization.name}"`
          : ""}{" "}
        does not have any plan and is archived now
      </NoPlanMainText>
      <NoPlanSecondaryText>
        Please Upgrade your plan to unlock the archived organization.
      </NoPlanSecondaryText>
      <ColoredButtonWithIcon
        margin="30px 0 0 0"
        onClick={() => props.history.push("/user/subscription")}
      >
        <ButtonIcon src={crown} alt="" />
        UPGRADE
      </ColoredButtonWithIcon>
    </NoPlanContainer>
  );
};

const PausedReasonsSection = (props) => {
  return (
    <CommonGrid columns="100%" gap="10px" margin="30px 0" padding="0 30px">
      <CommonText fontSize="13px">
        Possible reasons on subscription paused
      </CommonText>
      <PausedReasonsDiv>
        <CommonFlex gap="8px">
          <ColorCircle background={colors[0]} size="10px" />
          <CommonText fontSize="12px">Your card is expired.</CommonText>
        </CommonFlex>
        <CommonFlex gap="8px">
          <ColorCircle background={colors[1]} size="10px" />
          <CommonText fontSize="12px">
            You have insufficient balance on your card.
          </CommonText>
        </CommonFlex>
        <CommonFlex gap="8px">
          <ColorCircle background={colors[2]} size="10px" />
          <CommonText fontSize="12px">
            Your bank declined the payment.
          </CommonText>
        </CommonFlex>
      </PausedReasonsDiv>

      <CommonText margin="20px 0 0">
        We request you to try again to activate your subscription or change your
        payment method
      </CommonText>
    </CommonGrid>
  );
};

const CurrentPlan = (props) => {
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [status, setStatus] = useState(null);
  const [planName, setPlanName] = useState("");
  const [planDescription, setPlanDescription] = useState("");
  const [planInterval, setPlanInterval] = useState("");
  const [planPrice, setPlanPrice] = useState(null);
  const [membersCount, setMembersCount] = useState(null);
  const [prepurchasedCount, setPrepurchasedCount] = useState(null);
  const [extraMembersCount, setExtraMembersCount] = useState(null);
  const [trialEndDate, setTrialEndDate] = useState("");
  const [renewalDate, setRenewalDate] = useState("");
  const [isGrandfatheredPlan, setIsGrandfatheredPlan] = useState(false);

  const [discountInfo, setDiscountInfo] = useState(null);
  const [discountedPrice, setDiscountedPrice] = useState(null);

  const [paymentInfo, setPaymentInfo] = useState(null);

  const [cancelUrl, setCancelUrl] = useState(null);
  const [updateUrl, setUpdateUrl] = useState(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isChangePaymentMethodModalOpen, setIsChangePaymentMethodModalOpen] =
    useState(false);

  const [toolTipActive, setToolTipActive] = useState(false);

  const {
    history,
    isLoading,
    selectedOrganization,
    currentSubscription,
    getOrganizationSubscription,
    activateOrganizationSubscription,
    activateSubscriptionLoading,
    updateSubscriptionPaymentMethod,
    updatePaymentMethodLoading,
    updatePaymentMethodSuccess,

    clearPaymentMethodUpdated,
  } = props;

  useEffect(() => {
    return () => {
      clearPaymentMethodUpdated();
    };
  }, []);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      getOrganizationSubscription(payload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (!currentSubscription) {
      setSubscriptionId(null);
      setPlanId(null);
      setStatus(null);
      setPlanName("");
      setPlanDescription("");
      setPlanInterval("");
      setPlanPrice(null);
      setMembersCount(null);
      setPrepurchasedCount(null);
      setExtraMembersCount(null);
      setTrialEndDate("");
      setRenewalDate("");
      setDiscountInfo(null);
      setDiscountedPrice(null);
      setCancelUrl(null);
      setIsGrandfatheredPlan(false);
      return;
    } else {
      if (currentSubscription.id) {
        setSubscriptionId(currentSubscription.id);
        if (currentSubscription.plan && currentSubscription.plan.id) {
          setPlanId(currentSubscription.plan.id);
          setPlanName(currentSubscription.plan.name);
          setPlanDescription(planDetails[currentSubscription.plan.name]);
          setPlanInterval(currentSubscription.plan.interval);
          setIsGrandfatheredPlan(currentSubscription.plan.grandfathered);
          if (currentSubscription.plan.interval === "yearly") {
            setPlanPrice(currentSubscription.plan.amount / 12);
          } else {
            setPlanPrice(currentSubscription.plan.amount);
          }

          if (
            currentSubscription.status === "active" &&
            currentSubscription.discount &&
            currentSubscription.discount.plan &&
            currentSubscription.discount.plan.id === currentSubscription.plan.id
          ) {
            setDiscountInfo(currentSubscription.discount);
            if (
              currentSubscription.discount.type === "percentage" &&
              currentSubscription.discount.percentage_off &&
              currentSubscription.discount.percentage_off > 0
            ) {
              if (currentSubscription.plan.interval === "yearly") {
                const discount =
                  (currentSubscription.plan.amount / 12) *
                  (currentSubscription.discount.percentage_off / 100);
                setDiscountedPrice(
                  (currentSubscription.plan.amount / 12 - discount).toFixed(2)
                );
              } else {
                const discount =
                  currentSubscription.plan.amount *
                  (currentSubscription.discount.percentage_off / 100);
                setDiscountedPrice(
                  (currentSubscription.plan.amount - discount).toFixed(2)
                );
              }
            }
          }
        }
        setStatus(currentSubscription.status);
        setMembersCount(
          currentSubscription.member_count > 1
            ? currentSubscription.member_count - 1
            : currentSubscription.member_count
        );
        if (
          currentSubscription.plan &&
          currentSubscription.plan.interval === "yearly"
        ) {
          if (currentSubscription.prepurchased_count > 0) {
            setPrepurchasedCount(currentSubscription.prepurchased_count);
          }
          if (
            currentSubscription.member_count > 1 &&
            currentSubscription.member_count >
              currentSubscription.prepurchased_count
          ) {
            setExtraMembersCount(
              currentSubscription.member_count -
                currentSubscription.prepurchased_count -
                1
            );
          }
        }
        setTrialEndDate(currentSubscription.trial_till);
        setRenewalDate(currentSubscription.next_billing);
        if (currentSubscription.payment_information) {
          setPaymentInfo(currentSubscription.payment_information);
        }
        if (currentSubscription.update_url) {
          setUpdateUrl(currentSubscription.update_url);
        }
        if (currentSubscription.cancel_url) {
          setCancelUrl(currentSubscription.cancel_url);
        }
      }
    }
  }, [currentSubscription]);

  useEffect(() => {
    if (updatePaymentMethodSuccess) {
      handleChangePaymentMethod();

      setTimeout(() => {
        clearPaymentMethodUpdated();
      }, 500);
    }
  }, [updatePaymentMethodSuccess]);

  useEffect(() => {
    if (status && status === "active" && discountInfo) {
    }
  }, [discountInfo]);

  const handleUpgrade = (status) => {
    if (status === "paused") {
      if (selectedOrganization && selectedOrganization.id) {
        activateOrganizationSubscription({
          organization_id: selectedOrganization.id,
        });
      }
    } else {
      history.push("/user/subscription");
    }
  };

  const changePaymentMethodRequest = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      updateSubscriptionPaymentMethod(payload);
    }
  };

  const toggleCancelModal = () => {
    setIsCancelModalOpen(!isCancelModalOpen);
  };

  const toggleChangePaymentMethodModal = () => {
    setIsChangePaymentMethodModalOpen(!isChangePaymentMethodModalOpen);
  };

  const handleCancelSubscription = () => {
    if (cancelUrl) {
      window.open(cancelUrl, "_blank");
    }
  };

  const handleChangePaymentMethod = () => {
    if (updateUrl) {
      window.open(updateUrl, "_blank");
    }
  };

  return (
    <div className="content">
      <CancelSubscriptionModal
        isOpen={isCancelModalOpen}
        toggle={toggleCancelModal}
        cancelSubscription={handleCancelSubscription}
      />
      <ChangePaymentMethodModal
        isOpen={isChangePaymentMethodModalOpen}
        toggle={toggleChangePaymentMethodModal}
        updatePaymentMethodLoading={updatePaymentMethodLoading}
        changePaymentMethod={changePaymentMethodRequest}
      />
      <PageTitle style={{ marginBottom: "30px" }}>Billing - My Plan</PageTitle>
      <BackButtonComponent
        onClick={() => history.push("/user/settings")}
        subTitle="Back to settings"
      />
      <Container padding="30px 0" style={{ maxWidth: `1050px` }}>
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            {subscriptionId && planId && (
              <>
                <CardTitle margin="0 30px">Current Plan</CardTitle>
                <PlanSection>
                  <div>
                    <CommonGrid
                      columns={
                        isGrandfatheredPlan
                          ? "auto"
                          : discountInfo
                          ? "auto auto auto auto"
                          : "auto auto auto"
                      }
                      justifyContent="start"
                      alignItem="center"
                      gap="20px"
                    >
                      <CommonFlex gap="12px">
                        <ColoredText
                          color="#4D94FF"
                          fontWeight="700"
                          fontSize="24px"
                          style={{ textTransform: "uppercase" }}
                        >
                          {planName}
                          {isGrandfatheredPlan && ` (GRANDFATHERED)`}
                        </ColoredText>
                        <PlanCapsule>{planInterval}</PlanCapsule>
                      </CommonFlex>

                      <CapsuleAndPriceContainer>
                        {status !== "active" && (
                          <PlanCapsule status={status} fontWeight="500">
                            {status === "trialing" && trialEndDate ? (
                              <>
                                Your Trial is Ending
                                <span style={{ color: `#1f2939` }}>
                                  {" "}
                                  on{" "}
                                  {moment(trialEndDate).format("MMM D, YYYY")}.
                                </span>
                              </>
                            ) : status === "suspended" &&
                              trialEndDate &&
                              moment(trialEndDate).isBefore(moment(), "day") ? (
                              <>
                                Your Trial Ended on{" "}
                                {moment(trialEndDate).format("MMM D, YYYY")}.{" "}
                                {selectedOrganization &&
                                  selectedOrganization.name &&
                                  `Organization "${selectedOrganization.name}" is archived.`}
                              </>
                            ) : (
                              <>
                                <span
                                  style={{
                                    color: `#fe5969`,
                                    textTransform: "none",
                                  }}
                                >
                                  {selectedOrganization &&
                                    selectedOrganization.name &&
                                    `Organization "${selectedOrganization.name}" is archived.`}
                                </span>
                              </>
                            )}
                          </PlanCapsule>
                        )}

                        {status === "active" && (
                          <CommonText name>
                            ${discountedPrice ? discountedPrice : planPrice} /
                            user / month
                          </CommonText>
                        )}
                        {status === "active" &&
                          discountInfo &&
                          discountInfo.type === "percentage" &&
                          discountInfo.percentage_off > 0 && (
                            <SaveTag style={{ marginLeft: "-10px" }}>
                              After {discountInfo.percentage_off}% discount
                            </SaveTag>
                          )}
                      </CapsuleAndPriceContainer>
                    </CommonGrid>
                    {planDescription && (
                      <CommonText margin="20px 0 0 0">
                        {planDescription}
                      </CommonText>
                    )}
                  </div>
                  <div>
                    {status === "active" ? (
                      <PrimaryButton
                        onClick={() => handleUpgrade(status)}
                        width="100%"
                      >
                        Change Plan
                      </PrimaryButton>
                    ) : (
                      <ColoredButtonWithIcon
                        width="100%"
                        padding={activateSubscriptionLoading && "0 45px"}
                        onClick={() => handleUpgrade(status)}
                      >
                        {status === "paused" ? (
                          activateSubscriptionLoading ? (
                            <ButtonTextLoader
                              loadingText="Activating"
                              fontSize="13px"
                            />
                          ) : (
                            "Activate Subscription"
                          )
                        ) : (
                          <>
                            <ButtonIcon src={crown} alt="" />
                            UPGRADE
                          </>
                        )}
                      </ColoredButtonWithIcon>
                    )}
                    {cancelUrl && (
                      <WhiteButton
                        type="cancel"
                        margin="20px 0 0 0"
                        onClick={() => toggleCancelModal()}
                      >
                        Cancel Subscription
                      </WhiteButton>
                    )}
                  </div>
                </PlanSection>
                <CommonFlex gap="20px" padding="0 30px">
                  <CommonText>Status:</CommonText>
                  {status === "trialing" ? (
                    <ColoredText
                      style={{ textTransform: "capitalize" }}
                      semibold
                      color="#fe5969"
                      fontSize="14px"
                    >
                      On Trial
                    </ColoredText>
                  ) : (
                    <CommonFlex gap="8px">
                      <CommonImage
                        src={
                          status === "active"
                            ? subscriptionActiveIcon
                            : status === "paused"
                            ? subscriptionPausedIcon
                            : subscriptionCancelledIcon
                        }
                        alt=""
                      />
                      <CommonText
                        style={{ textTransform: "capitalize" }}
                        fontWeight="600"
                      >
                        {status === "paused" ? "Subscription Paused" : status}
                      </CommonText>
                    </CommonFlex>
                  )}
                </CommonFlex>
                {status === "paused" && <PausedReasonsSection />}

                {status === "active" && (
                  <>
                    {discountInfo &&
                      discountInfo.type === "seated" &&
                      discountInfo.max_seat_quantity > 0 && (
                        <SeatedDiscountDiv>
                          Your plan has a discount of{" "}
                          {discountInfo.max_seat_quantity} users
                          {discountInfo.valid_till
                            ? ` valid till ${moment(
                                discountInfo.valid_till
                              ).format("MMMM D, YYYY")}`
                            : ""}
                          .
                        </SeatedDiscountDiv>
                      )}
                    {renewalDate && (
                      <CommonText name margin="20px 30px">
                        Renew subscription by{" "}
                        <span style={{ fontWeight: `600` }}>
                          {moment(renewalDate).format("MMMM D, YYYY")}
                        </span>
                      </CommonText>
                    )}
                    <MemeberInfoSection
                      margin={
                        paymentInfo &&
                        paymentInfo.payment_method &&
                        "10px 30px 30px"
                      }
                    >
                      <MemeberBlock>
                        <CommonText name fontSize="18px" fontWeight="600">
                          {membersCount}
                        </CommonText>
                        <CommonText name>Active Members</CommonText>
                      </MemeberBlock>
                      {planInterval === "yearly" &&
                        prepurchasedCount &&
                        prepurchasedCount > 0 && (
                          <>
                            <MemeberBlock>
                              <CommonText name fontSize="18px" fontWeight="600">
                                {prepurchasedCount}
                              </CommonText>
                              <CommonText name>
                                Yearly Seats Purchased
                              </CommonText>
                            </MemeberBlock>
                            {extraMembersCount && extraMembersCount > 0 ? (
                              <MemeberBlock>
                                <CommonText
                                  name
                                  fontSize="18px"
                                  fontWeight="600"
                                >
                                  {extraMembersCount}
                                </CommonText>
                                <BillingTextTooltip>
                                  <CommonText name>
                                    Monthly Subscription Users
                                  </CommonText>
                                  <BillingQues
                                    onMouseEnter={() => setToolTipActive(true)}
                                    onMouseLeave={() => setToolTipActive(false)}
                                    style={{
                                      backgroundColor: `#20BEAD`,
                                      color: `white`,
                                    }}
                                  >
                                    ?
                                    {toolTipActive && (
                                      <BillingTooltip
                                        style={{ width: `270px` }}
                                        bgColor="#1F2939"
                                        top="-75px"
                                        left="-120px"
                                      >
                                        <BillingTooltipText>
                                          The users added after yearly plan is
                                          purchased becomes monthly users until
                                          the yearly plan renews.
                                          <span
                                            style={{
                                              color: "#4D94FF",
                                              cursor: `pointer`,
                                            }}
                                          >
                                            {" "}
                                            Learn More
                                          </span>
                                        </BillingTooltipText>
                                      </BillingTooltip>
                                    )}
                                  </BillingQues>
                                </BillingTextTooltip>
                              </MemeberBlock>
                            ) : null}
                          </>
                        )}
                      <ColoredText
                        color="#4D94FF"
                        fontWeight="600"
                        style={{ cursor: `pointer`, paddingLeft: `20px` }}
                        onClick={() => history.push("/user/member-invitation")}
                      >
                        Invite Members
                      </ColoredText>
                    </MemeberInfoSection>
                  </>
                )}
                {paymentInfo && paymentInfo.payment_method && (
                  <PaymentInfoContainer>
                    <DividerLine />
                    <CommonFlex
                      justifyContent="space-between"
                      padding="30px 30px 0px"
                    >
                      <CommonFlex gap="35px">
                        <ActivityTextSection>
                          <CommonText fontSize="13px">
                            Payment Method
                          </CommonText>
                          <CommonFlex gap="12px">
                            <CommonImage
                              src={getCardIcon(
                                paymentInfo.payment_method === "paypal"
                                  ? "paypal"
                                  : paymentInfo.card_type
                              )}
                              alt=""
                            />
                            {paymentInfo.payment_method === "card" && (
                              <CommonText margin="0" fontWeight="600">
                                **** {paymentInfo.last_four_digits}
                              </CommonText>
                            )}
                          </CommonFlex>
                        </ActivityTextSection>
                        {paymentInfo.payment_method === "card" && (
                          <ActivityTextSection>
                            <CommonText fontSize="13px">Expiry Date</CommonText>
                            <CommonText
                              style={{ lineHeight: "29px" }}
                              fontWeight="600"
                            >
                              {paymentInfo.expiry_date}
                            </CommonText>
                          </ActivityTextSection>
                        )}
                      </CommonFlex>
                      {updateUrl && (
                        <SecondaryButton
                          onClick={() => {
                            if (status === "paused") {
                              toggleChangePaymentMethodModal();
                            } else {
                              // handleChangePaymentMethod();
                              changePaymentMethodRequest();
                            }
                          }}
                        >
                          Change Payment Method
                        </SecondaryButton>
                      )}
                    </CommonFlex>
                  </PaymentInfoContainer>
                )}
              </>
            )}
            {subscriptionId && !planId && (
              <NoPlanSection
                organization={selectedOrganization}
                history={history}
              />
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default CurrentPlan;

import { useLoadScript } from '@react-google-maps/api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';

import crossWhite from '../../assets/img/icons/remove_assignee.svg';
import CreateProjectDropdown from '../../components/DropdownStyle/CreateProjectDropdown';
import ButtonTextLoader from '../../components/Loaders/ButtonTextLoader';
import MapsAutocompleteDropdown from '../../components/MapsAutocompleteDropdown/MapsAutocompleteDropdown';
import useAutocompleteService from '../../customHooks/usePlacesAutocompleteService';
import {
  ColoredButton,
  PrimaryButton,
  RadiusButton,
  WhiteButton,
} from '../../styledComponents/buttons';
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
  FormAlert,
  InputField,
  InputLabel,
  InputWithLabelSection,
  RedStar,
} from '../../styledComponents/common';
import { checkHtml, checkUrl } from '../../utils/helper';
import {
  AddTaskLocationInfoContainer,
  AddTaskLocationSection,
} from '../TasksNew/CreateTask/createTaskStyles';
import TaskLocationMap from '../TasksNew/TaskLocationMap';

const mapLibraries = ['places'];
const mapIds = [process.env.REACT_APP_JOB_SITES_MAP_ID];

const AddLocationModal = ({
  isOpen,
  toggle,
  organization_id,
  office_id,
  addOfficeLocation,
  updateOfficeLocation,
  addOrUpdateOfficeLocalMapLoading,
  createdOfficeLocationInfo,
  officeLocationCreateUpdateSuccess,
  action,
  //updating the map
  id,
  name,
  address,
  radius,
  latitude,
  longitude,

  // autocomplete and geocoding service
  getGeocode,
  // addTaskLocationLoading,
  // createdTaskLocationInfo,
  // addTaskLocation,
  getTaskLocation,
  // getOfficesShortList,
  // officesShortList,
}) => {
  const { t } = useTranslation();

  const [locationName, setLocationName] = useState('');
  const [locationAddress, setLocationAddress] = useState('');
  const [radiusInput, setRadiusInput] = useState(100);
  const [saveErrors, setSaveErrors] = useState({});
  const radiusOptions = [100, 150, 200, 300];
  const [isCreated, setIsCreated] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [addressMarker, setAddressMarker] = useState(null);

  // const [selectedOffice, setSelectedOffice] = useState(null);
  const [officeOptions, setOfficeOptions] = useState([]);

  // map states
  const [map, setMap] = useState(null);

  const [userCurrentLocation, setUserCurrentLocation] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    id: 'job-site-details-map-script-loader',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY,
    libraries: mapLibraries,
    mapIds,
  });

  const {
    geocoderService,
    getPlacePredictions,
    placePredictions,
    setPlacePredictions,
    isPredictionsLoading,
    refreshSessionToken,
  } = useAutocompleteService({ map, debounceTimeout: 500 });

  // useEffect(() => {
  //   fetch('https://hutils.loxal.net/whois')
  //     .then(resp => resp.json())
  //     .then(res => {
  //       if (res && res.ip) {
  //         setUserCurrentLocation({
  //           lat: res.latitude,
  //           lng: res.longitude,
  //         });
  //       }
  //     })
  //     .catch(err => {
  //     });
  // }, []);

  useEffect(() => {
    clearState();
    if (action === 'update') {
      setLocationName(name);
      setLocationAddress(address);
      setRadiusInput(radius);

      if (!isNaN(latitude) && !isNaN(longitude)) {
        setAddressMarker({
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        });
      }
    }
  }, [action, name, address, radius, latitude, longitude]);

  useEffect(() => {
    if (
      createdOfficeLocationInfo &&
      createdOfficeLocationInfo.id &&
      isOpen &&
      isCreated
    ) {
      handleToggle();
    }
  }, [createdOfficeLocationInfo]);

  useEffect(() => {
    if (officeLocationCreateUpdateSuccess && isOpen && isUpdated) {
      handleToggle();
    }
  }, [officeLocationCreateUpdateSuccess]);

  useEffect(() => {
    if (map && addressMarker) {
      handleMapPanAndZoomToSingleLocation(addressMarker);
    }
  }, [map, addressMarker]);

  const handleGetGeocode = place => {
    if (geocoderService && place && place.place_id && map) {
      setLocationAddress(place.description);
      geocoderService
        .geocode({ placeId: place.place_id })
        .then(({ results }) => {
          handleSetAddressMarker(results[0].geometry.location);
        })
        .catch(e => {
        });
      setPlacePredictions([]);
    }
    refreshSessionToken();
  };

  const handleReverseGeocode = coord => {
    if (geocoderService && coord) {
      setPlacePredictions([]);
      geocoderService
        .geocode({ location: coord })
        .then(results => {
          if (results.results && results.results.length) {
            setLocationAddress(results.results[0].formatted_address);
          } else {
            setLocationAddress(`${coord.lat()}, ${coord.lng()}`);
          }
        })
        .catch(e => {
        });
    }
  };

  const handleSetAddressFromMap = location => {
    if (location && location.latLng) {
      handleSetAddressMarker(location.latLng);
      handleReverseGeocode(location.latLng);
    }
  };

  const handleSetAddressMarker = location => {
    if (location) {
      setAddressMarker(location);
      // handleMapPanAndZoomToSingleLocation(location);
    }
  };

  const handleMapPanAndZoomToSingleLocation = location => {
    if (map) {
      map.panTo(location);
      map.setZoom(17);
    }
  };

  const onLocationNameChange = e => {
    const { value } = e.target;
    setLocationName(value);
    if (saveErrors && saveErrors['locationName']) {
      delete saveErrors['locationName'];
    }
  };

  const onLocationAddressChange = e => {
    const { value } = e.target;
    getPlacePredictions({ input: value });
    setLocationAddress(value);
    if (saveErrors && saveErrors['locationAddress']) {
      delete saveErrors['locationAddress'];
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;

    if (checkUrl(locationName)) {
      formIsValid = false;
      saveErrors['locationName'] = 'task location name can not contain url';
    } else if (checkHtml(locationName)) {
      formIsValid = false;
      saveErrors['locationName'] = 'Invalid job site name';
    }
    if (checkUrl(locationAddress)) {
      formIsValid = false;
      saveErrors['locationAddress'] = 'Address can not contain url';
    } else if (checkHtml(locationAddress)) {
      formIsValid = false;
      saveErrors['locationAddress'] = 'Invalid address input';
    }
    if (!radiusInput) {
      formIsValid = false;
      saveErrors['radius'] = 'Radius is required';
    } else if (radiusInput % 1 !== 0) {
      formIsValid = false;
      saveErrors['radius'] = 'Fraction is not allowed for radius';
    } else if (radiusInput < 50) {
      formIsValid = false;
      saveErrors['radius'] = 'Minimum value for radius is 50';
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleAddTaskLocation = () => {
    if (checkError()) {
      let latitude, longitude;

      if (addressMarker && addressMarker.lat) {
        latitude =
          typeof addressMarker.lat === 'function'
            ? addressMarker.lat()
            : addressMarker.lat;
        longitude =
          typeof addressMarker.lng === 'function'
            ? addressMarker.lng()
            : addressMarker.lng;
      }

      if (action === 'add') {
        const payload = {
          organizationID: organization_id,
          name: locationName,
          address: locationAddress,
          office_id: office_id,
          radius: radiusInput,
          latitude: latitude,
          longitude: longitude,
        };
        addOfficeLocation(payload);
        setIsCreated(true);
      } else if (action === 'update') {
        const payload = {
          organizationID: organization_id,
          office_id: office_id,
          location: [
            {
              id: id,
              name: locationName,
              address: locationAddress,
              radius: radiusInput,
              latitude: latitude,
              longitude: longitude,
            },
          ],
        };
        addOfficeLocation(payload);
        setIsUpdated(true);
      }
    }
  };

  const clearState = () => {
    setLocationName('');
    setLocationAddress('');
    setRadiusInput(100);
    setSaveErrors({});
    setAddressMarker(null);
    setMap(null);
    // setUserCurrentLocation(null);
    setIsCreated(false);
    setIsUpdated(false);
  };

  const handleToggle = () => {
    clearState();
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleToggle}
      style={{ marginTop: `2vh`, maxWidth: `950px` }}
    >
      <ModalBody style={{ padding: `0` }}>
        <AddTaskLocationSection>
          <AddTaskLocationInfoContainer>
            <CommonText name title fontSize="16px" margin="auto 0 10px 0">
              {t('add_office_location')}
            </CommonText>

            <InputWithLabelSection margin="20px 0 0">
              <InputLabel>
                {t('location_name')} <RedStar>*</RedStar>
              </InputLabel>
              <InputField
                type="text"
                placeholder={t('type_location_name')}
                value={locationName}
                onChange={e => onLocationNameChange(e)}
                style={
                  saveErrors &&
                  saveErrors[`locationName`] && {
                    border: `1px solid #fe5969`,
                    backgroundColor: `rgba(252, 87, 104, 0.05)`,
                  }
                }
              />
              {saveErrors &&
                saveErrors['locationName'] &&
                saveErrors['locationName'] !== undefined && (
                  <FormAlert>{saveErrors['locationName']}</FormAlert>
                )}
            </InputWithLabelSection>

            <InputWithLabelSection
              style={{ position: 'relative' }}
              margin="20px 0 0"
            >
              <InputLabel>
                {t('location_address')} <RedStar>*</RedStar>
              </InputLabel>
              <InputField
                type="text"
                placeholder={t('type_location_address')}
                value={locationAddress}
                onChange={e => onLocationAddressChange(e)}
                style={
                  saveErrors &&
                  saveErrors[`locationAddress`] && {
                    border: `1px solid #fe5969`,
                    backgroundColor: `rgba(252, 87, 104, 0.05)`,
                  }
                }
              />
              {saveErrors &&
                saveErrors['locationAddress'] &&
                saveErrors['locationAddress'] !== undefined && (
                  <FormAlert>{saveErrors['locationAddress']}</FormAlert>
                )}
              {!isPredictionsLoading &&
              placePredictions &&
              placePredictions.length &&
              locationAddress ? (
                <MapsAutocompleteDropdown
                  dropdownOptions={placePredictions}
                  handleSelect={handleGetGeocode}
                />
              ) : null}
            </InputWithLabelSection>

            <InputWithLabelSection margin="20px 0 0">
              <CommonGrid>
                <InputLabel>
                  {t('location_radius')} <RedStar>*</RedStar>
                </InputLabel>
              </CommonGrid>
              <CommonFlex justifyContent="space-between" gap="12px">
                {radiusOptions.map((option, i) => {
                  return (
                    <RadiusButton
                      key={option}
                      active={radiusInput === option}
                      onClick={() => {
                        if (radiusInput !== option) {
                          setRadiusInput(option);
                          if (saveErrors && saveErrors['radius']) {
                            delete saveErrors['radius'];
                          }
                        }
                      }}
                    >
                      {option} m
                    </RadiusButton>
                  );
                })}
              </CommonFlex>
            </InputWithLabelSection>
            <CommonGrid
              justifyContent="unset"
              padding="10px 0 0 0"
              margin="30px 0 0"
            >
              <WhiteButton onClick={() => handleToggle()}>
                {t('cancel')}
              </WhiteButton>
              <PrimaryButton
                disabled={!locationName || !locationAddress}
                onClick={() => handleAddTaskLocation()}
              >
                {addOrUpdateOfficeLocalMapLoading ? (
                  <ButtonTextLoader
                    loadingText={`${
                      action === 'update' ? 'Updating' : 'Adding'
                    } `}
                    fontSize="13px"
                  />
                ) : (
                  `${action === 'update' ? 'Update' : 'Add'} Location`
                )}
              </PrimaryButton>
            </CommonGrid>
          </AddTaskLocationInfoContainer>
          <TaskLocationMap
            setMap={setMap}
            singleAddressMarker={addressMarker}
            setAddressFromMap={handleSetAddressFromMap}
            radius={radiusInput}
            action={action}
          />
        </AddTaskLocationSection>
      </ModalBody>
    </Modal>
  );
};

export default AddLocationModal;

import AddMultipleAssigneeDropdown from 'components/CustomDropdown/AddMultipleAssigneeDropdown';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';

import CreateProjectDropdown from '../../../components/DropdownStyle/CreateProjectDropdown';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import Select from '../../../components/ReactSelectDropdown';
import DatePicker from '../../../components/SingleDatePicker/SingleDatePicker';
import InfoTooltip from '../../../components/Tooltip/InfoTooltip';
import { ColoredButton, WhiteButton } from '../../../styledComponents/buttons';
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
  Container,
  ContentHeader,
  FormAlert,
  InputField,
  InputLabel,
  InputTextArea,
  InputWithLabelSection,
  PageTitle,
  RedStar,
} from '../../../styledComponents/common';
import {
  AdditionalContainer,
  AssigneesManageImgTextGrid,
  ButtonSection,
  CollapseContainer,
  CollapseRotate,
  DropDownGrid,
  HourlyRateDropdownSection,
  IconTitleSection, //   InputField,
  PersonHourlyRateContainer,
  ProjectBillingSection,
  ProjectBillingTabContainer,
  ProjectBillingtab,
  ProjectBudgetUpperColumn,
  ProjectBudgetUpperGrid,
  ProjectHourlyRateInputSection,
  SetRateButton,
  SetRateContainer,
  SliderContainer,
  StyledSlider,
  StyledThumb,
  StyledTrack,
  TitleContainer,
} from '../../../styledComponents/createProject';
import { ModalButtonSection } from '../../../styledComponents/members';
import { currencySymbolMap } from '../../../utils/currencies';
import { AddAssigneeDropdown } from '../../Jobs/AddAssigneeDropdown';
import {
  AssigneeSection,
  InputSection,
  TimeRangeSection,
} from '../../Jobs/CreateJob/createTaskStyles';
import TimeRangeComponent from '../../Timesheet/Daily/TimeRangeComponent';

const ShiftModal = ({
  isOpen,
  toggle,

  orgMembersShortList,
  getOrganizationMembersShortList,
  memberShortListIsLoading,
  addJobAssignee,
  removeJobAssignee,

  workorder_id,
  selectedOrganization,
  job_id,

  shiftAction,
  jobAssignee,

  selectedDate,
  setSelectedDate,

  requiredStartDate,
  setRequiredStartDate,

  startTime,
  setStartTime,

  endTime,
  setEndTime,

  requiredEndDate,
  setRequiredEndDate,

  createOrUpdateJobIsLoading,
  updateJobRequest,

  getJobDetails,
  jobDetails,

  loadingjobId,
  JobMemberIsLoading,

  getJobIsLoading,
}) => {
  const { t, i18n } = useTranslation();
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [saveErrors, setSaveErrors] = useState({});
  const [visibility, setVisibility] = useState(0);

  // useEffect(() => {
  //   if (
  //     selectedOrganization &&
  //     selectedOrganization.id !== null &&
  //     workorder_id !== null &&
  //     job_id !== null
  //   ) {
  //     getJobDetails({
  //       organization_id: selectedOrganization.id,
  //       workOrder_id: workorder_id,
  //       job_id: job_id,
  //     });
  //   }
  // }, [job_id]);

  // useEffect(() => {
  //   if (jobDetails) {
  //     if (jobDetails.assignees && jobDetails.assignees.length > 0) {
  //       let newArr = [];
  //       jobDetails.assignees.forEach((assignee, i) => {
  //         newArr.push({
  //           ...assignee,
  //           name:
  //             `${assignee.first_name} ${assignee.last_name}`.trim() ||
  //             assignee.first_name,
  //           //user_id: assignee.id,
  //         });
  //       });

  //       setSelectedAssignees(newArr);
  //     }

  //     if (jobDetails.assignees && jobDetails.assignees.length === 0) {
  //       setSelectedAssignees([]);
  //     }
  //   }
  // }, [jobDetails]);

  const onDateChange = date => {
    setSelectedDate(date);
  };

  const showAssignee = state => {
    setVisibility(state);
  };

  const handleStartTime = time => {
    if (time) {
      let errors = { ...saveErrors };
      delete errors['timeDifference'];
      setSaveErrors(errors);
      const start_time = time.replace(/ /g, '');
      setStartTime(moment(start_time, 'h:mmA').format('HH:mm:ss'));
    } else {
      setStartTime('');
    }
  };
  const handleEndTime = time => {
    if (time) {
      let errors = { ...saveErrors };
      delete errors['timeDifference'];
      setSaveErrors(errors);
      const end_time = time.replace(/ /g, '');
      setEndTime(moment(end_time, 'h:mmA').format('HH:mm:ss'));
    } else {
      setEndTime('');
    }
  };

  const handleSave = () => {
    if (checkError()) {
      const start_date = moment(
        selectedDate.format('YYYY-MM-DD') + ' ' + startTime,
      );
      const end_date = moment(
        selectedDate.format('YYYY-MM-DD') + ' ' + endTime,
      );

      setRequiredStartDate(moment(start_date).format('YYYY-MM-DDTHH:mm:ss'));
      setRequiredEndDate(moment(end_date).format('YYYY-MM-DDTHH:mm:ss'));

      toggle();

      // if (
      //   selectedOrganization &&
      //   selectedOrganization.id &&
      //   workorder_id &&
      //   job_id
      // ) {
      //   let payload = {
      //     organization_id: selectedOrganization.id,
      //     workOrder_id: workorder_id,
      //     job_id: job_id,
      //   };

      //   const start_date = moment(
      //     selectedDate.format('YYYY-MM-DD') + ' ' + startTime,
      //   );
      //   const end_date = moment(
      //     selectedDate.format('YYYY-MM-DD') + ' ' + endTime,
      //   );

      //   payload.required_start_date = moment(start_date).format(
      //     'YYYY-MM-DDTHH:mm:ss',
      //   );
      //   payload.required_end_date = moment(end_date).format(
      //     'YYYY-MM-DDTHH:mm:ss',
      //   );

      //   // updateJobRequest(payload);
      //   //setIsSubmitted(true);
      // }
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;

    if (selectedAssignees.length === 0) {
      formIsValid = false;
      saveErrors['selectedAssignees'] = 'Assignee is required';
    }

    if (!selectedDate) {
      formIsValid = false;
    }
    if (!startTime || !endTime) {
      formIsValid = false;
    }
    if (
      moment(selectedDate.format('YYYY-MM-DD') + ' ' + endTime).diff(
        moment(selectedDate.format('YYYY-MM-DD') + ' ' + startTime),
      ) < 0.5
    ) {
      formIsValid = false;
      saveErrors['timeDifference'] = 'End time must be after start time';
    } else if (
      moment(selectedDate.format('YYYY-MM-DD') + ' ' + endTime).diff(
        moment(selectedDate.format('YYYY-MM-DD') + ' ' + startTime),
      ) < 1800000
    ) {
      formIsValid = false;
      saveErrors['timeDifference'] =
        'Minmium 30 minutes time difference required';
    }
    if (
      moment(selectedDate.format('YYYY-MM-DD') + ' ' + startTime).diff(
        moment(),
      ) < 1
    ) {
      formIsValid = false;
      saveErrors['timeDifference'] = "Past time can't be set";
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const updateAssingeeList = person => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      workorder_id &&
      job_id
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        workOrder_id: workorder_id,
        job_id: job_id,
        user_id: person.id,
      };

      addJobAssignee(payload);
    }
  };

  const handleDeleteMember = assigneeId => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      workorder_id &&
      job_id
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        workOrder_id: workorder_id,
        job_id: job_id,
        user_id: assigneeId,
      };
      removeJobAssignee(payload);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `25vh`, maxWidth: '700px' }}
    >
      <ModalBody style={{ padding: `20px` }}>
        <PageTitle
          fontSize="16px"
          style={{ paddingLeft: `10px`, paddingBottom: `15px` }}
        >
          {shiftAction === 'Update' ? 'Update Shift' : 'Add Shift'}
        </PageTitle>
        <CommonText style={{ paddingLeft: `10px`, paddingBottom: `15px` }}>
          Assignee
        </CommonText>
        <AssigneeSection style={{ paddingLeft: `10px`, paddingBottom: `25px` }}>
          <AddAssigneeDropdown
            isLoading={memberShortListIsLoading}
            assignees={selectedAssignees}
            visibilityChange={showAssignee}
            updateState={updateAssingeeList}
            membersList={orgMembersShortList}
            getMembersList={getOrganizationMembersShortList}
            selectedOrganization={selectedOrganization}
            assigneeRemove={handleDeleteMember}
            officeMemberIsLoading={JobMemberIsLoading}
            loadingMemberId={loadingjobId}
          />

          {saveErrors && saveErrors['selectedAssignees'] && (
            <FormAlert>{saveErrors['selectedAssignees']}</FormAlert>
          )}
        </AssigneeSection>

        <InputSection>
          <InputWithLabelSection
            style={{ paddingLeft: `10px`, paddingBottom: `15px` }}
          >
            <InputLabel>{t('select_date')}</InputLabel>
            <DatePicker
              id="createIssuedDate"
              date={selectedDate}
              onDateChange={onDateChange}
              dateDisplayFormat={'DD/MM/YYYY'}
              placeholder={'Select date'}
            />
          </InputWithLabelSection>
          <InputWithLabelSection>
            <TimeRangeSection>
              <InputLabel>
                {t('start_time')}
                <RedStar>*</RedStar>
              </InputLabel>
              <InputLabel>
                {t('end_time')}
                <RedStar>*</RedStar>
              </InputLabel>
            </TimeRangeSection>
            <TimeRangeComponent
              setStartTime={handleStartTime}
              setEndTime={handleEndTime}
              initialStartTime={
                startTime && moment(startTime).format('HH:mm')
              }
              initialEndTime={
                endTime && moment(endTime).format('HH:mm')
              }
              startTimeError={
                saveErrors &&
                saveErrors['timeDifference'] !== undefined &&
                saveErrors['timeDifference'] &&
                saveErrors['timeDifference']
              }
              endTimeError={
                saveErrors &&
                saveErrors['timeDifference'] !== undefined &&
                saveErrors['timeDifference'] &&
                saveErrors['timeDifference']
              }
            />
            {saveErrors &&
              saveErrors['timeDifference'] &&
              saveErrors['timeDifference'] !== undefined && (
                <FormAlert>{saveErrors['timeDifference']}</FormAlert>
              )}
          </InputWithLabelSection>
        </InputSection>

        <ModalButtonSection
          style={{ justifyContent: 'start', paddingLeft: `10px` }}
        >
          <WhiteButton type="cancel" onClick={toggle}>
            Cancel
          </WhiteButton>
          <ColoredButton
            // padding={createOrUpdateJobIsLoading && '0 24px'}
            //disabled={!jobName || !selectedWorkOrder}
            onClick={() => handleSave()}
          >
            {createOrUpdateJobIsLoading ? (
              <ButtonTextLoader
                loadingText={shiftAction === 'Update' ? 'Updating' : 'Adding'}
                fontSize="13px"
              />
            ) : shiftAction === 'Update' ? (
              'Update Shift'
            ) : (
              'Add Shift'
            )}
          </ColoredButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default ShiftModal;

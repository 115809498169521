import 'assets/scss/paper-dashboard.scss';
//Deafult CSS files
import 'bootstrap/dist/css/bootstrap.css';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import 'lightgallery.js/dist/css/lightgallery.css';
import moment from 'moment';
import { Suspense } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
//react-dates
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next';
//Provider
import { Provider, ReactReduxContext } from 'react-redux';
import { Slide, ToastContainer } from 'react-toastify';
// import "assets/scss/paper-dashboard.scss";
import 'react-toastify/dist/ReactToastify.css';
import 'react-virtualized/styles.css';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';
//Persist
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import Cookies from 'universal-cookie';

import App from './App';
//Store
import { persistor, store } from './configureStore';
//import "react-table/react-table.css";
import { checkToken } from './custom_modules/SignUp/signUpActions';
import { GlobalStyles, lightTheme } from './styles';
import { initClarity } from './utils/clarity';

// import 'moment/locale/ar';

momentLocalizer(moment);
//cookies test
const cookies = new Cookies();
const language =
  cookies.get('language') != undefined ? cookies.get('language') : 'en';
//import i18next configuration
i18next
  .use(HttpApi)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['ar', 'en'],
    fallbackLng: 'en',
    lng: language,
    debug: false,
    // Options for language detector
    // detection: {
    //   order: ['path', 'cookie', 'htmlTag'],
    //   caches: ['cookie'],
    // },
    react: { useSuspense: false },
    backend: {
      loadPath: '/language/{{lng}}/translation.json',
    },
  });

// Initialize Clarity
initClarity();

const hist = createBrowserHistory();
ReactDOM.render(
  <Provider store={store} context={ReactReduxContext}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles language={language} />
        <ConnectedRouter history={hist} context={ReactReduxContext}>
          <App />
        </ConnectedRouter>
        <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          transition={Slide}
          pauseOnHover
        />
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

if (localStorage.getItem('access_token')) {
  store.dispatch(checkToken());
}

import crossWhite from '../../assets/img/icons/remove_assignee.svg';
import { CommonImage } from '../../styledComponents/common';
import { ClearSearchButton } from './searchStyles';

const ClearFilterButton = ({ innerProps, onClick }) => {
  return (
    <ClearSearchButton
      {...innerProps}
      onClick={() => onClick && onClick()}
    >
      <CommonImage src={crossWhite} alt="clear" />
    </ClearSearchButton>
  );
};

export default ClearFilterButton;

import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";

import crossIcon from "../../assets/img/icons/cross_black.svg";
import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";
import {
  ColoredButton,
  PrimaryButton,
  SecondaryButton,
  WhiteButton,
} from "../../styledComponents/buttons";
import {
  CardTitle,
  CommonGrid,
  CommonText,
  InputLabel,
  StyledLink,
} from "../../styledComponents/common";

const ColoredCircle = styled.div`
  position: absolute;
  top: -12px;
  right: ${(props) => (props.edit ? `52px` : `20px`)};
  display: none;
  z-index: 5;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${(props) => (props.edit ? `#7F9DBF` : `#FE5969`)};
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const ClosePopup = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  cursor: pointer;
`;
const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  margin-top: 30px;
`;

const CreateOptionPopup = ({
  isOpen,
  toggle,
  createToggle,
  controlOfficeCreate,
  controlJobCreate,
  plan,
}) => {
  const handleCreateOfficeShift = () => {
    toggle();
    setTimeout(controlOfficeCreate, 300);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `15vh`, maxWidth: `355px` }}
    >
      <ModalBody style={{ padding: `20px` }}>
        <ClosePopup onClick={() => toggle()}>
          <img src={crossIcon} alt="" width="30px" />
        </ClosePopup>
        <ButtonContainer>
          <WhiteButton primary onClick={() => handleCreateOfficeShift()}>
            Create Office Schedule
          </WhiteButton>
          {plan !== "Standard" ? (
            <ColoredButton onClick={() => controlJobCreate()}>
              Create Job Schedule
            </ColoredButton>
          ) : (
            <> </>
          )}
        </ButtonContainer>
      </ModalBody>
    </Modal>
  );
};

export default CreateOptionPopup;

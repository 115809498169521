import { useState, useEffect } from "react";
import { planFeatures } from "../../Subscription/ChangeSubscription/pricingPlanData";
import {
  CommonText,
  CommonGrid,
  CardTitle,
} from "../../../styledComponents/common";
import {
  PricingMainContainer,
  ToggleSection,
  ToggleText,
  PricingCardContainer,
  SinglePricingCard,
  PlanNameAndTagContainer,
  PlanName,
  PlanNameTag,
  PlanPriceContainer,
  PlanPrice,
  PlanPriceText,
  PlanDescription,
  PlanFeaturesContainer,
  SinglePlanFeature,
  FeatureText,
  PlanSelectButton,
  EverythingInPrev,
  PlusAddonDiv,
  TickImage,
  SeeFullComparisonDiv,
  SeeFullComparisonButton,
} from "../../../styledComponents/billing";
import {
  ToggleButton,
  ToggleButtonRound,
} from "../../../styledComponents/invoice";
import { YearlySaveBadge } from "../../Subscription/subscriptionStyles";
import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import tickSolo from "../../../assets/img/common/tick-solo.svg";
import tickStandard from "../../../assets/img/common/tick-standard.svg";
import tickPremium from "../../../assets/img/common/tick-premium.svg";
import tickElite from "../../../assets/img/common/tick-elite.svg";
import arrow from "../../../assets/img/common/arrow.svg";

const SelectPlan = (props) => {
  const [planInterval, setPlanInterval] = useState("yearly");

  const {
    getOrganizationPlans,
    subscriptionPlans,
    getOrganizationSubscription,
    selectedOrganization,
  } = props;

  const planPriceMonthly = {
    solo: 4,
    standard: 5,
    premium: 6,
    elite: 7,
  };
  const planPriceYearly = {
    solo: 2,
    standard: 2.5,
    premium: 3,
    elite: 3.5,
  };

  useEffect(() => {
    getOrganizationPlans();
  }, []);

  const [soloYearly, setSoloYearly] = useState({
    id: "",
    paddleId: null,
    amount: null,
  });
  const [soloMonthly, setSoloMonthly] = useState({
    id: "",
    paddle_id: null,
    amount: null,
  });
  const [standardYearly, setStandardYearly] = useState({
    id: "",
    paddleId: null,
    amount: null,
  });
  const [premiumYearly, setPremiumYearly] = useState({
    id: "",
    paddleId: null,
    amount: null,
  });
  const [eliteYearly, setEliteYearly] = useState({
    id: "",
    paddleId: null,
    amount: null,
  });
  const [standardMonthly, setStandardMonthly] = useState({
    id: "",
    paddleId: null,
    amount: null,
  });
  const [premiumMonthly, setPremiumMonthly] = useState({
    id: "",
    paddleId: null,
    amount: null,
  });
  const [eliteMonthly, setEliteMonthly] = useState({
    id: "",
    paddleId: null,
    amount: null,
  });

  useEffect(() => {
    if (subscriptionPlans && subscriptionPlans.length > 0) {
      subscriptionPlans.map((item) => {
        if (item.name === "Solo" && item.interval === "yearly") {
          setSoloYearly({
            id: item.id,
            paddleId: item.paddle_id,
            amount: item.amount,
          });
        } else if (item.name === "Solo" && item.interval === "monthly") {
          setSoloMonthly({
            id: item.id,
            paddleId: item.paddle_id,
            amount: item.amount,
          });
        } else if (item.name === "Standard" && item.interval === "yearly") {
          setStandardYearly({
            id: item.id,
            paddleId: item.paddle_id,
            amount: item.amount,
          });
        } else if (item.name === "Premium" && item.interval === "yearly") {
          setPremiumYearly({
            id: item.id,
            paddleId: item.paddle_id,
            amount: item.amount,
          });
        } else if (item.name === "Elite" && item.interval === "yearly") {
          setEliteYearly({
            id: item.id,
            paddleId: item.paddle_id,
            amount: item.amount,
          });
        } else if (item.name === "Standard" && item.interval === "monthly") {
          setStandardMonthly({
            id: item.id,
            paddleId: item.paddle_id,
            amount: item.amount,
          });
        } else if (item.name === "Premium" && item.interval === "monthly") {
          setPremiumMonthly({
            id: item.id,
            paddleId: item.paddle_id,
            amount: item.amount,
          });
        } else if (item.name === "Elite" && item.interval === "monthly") {
          setEliteMonthly({
            id: item.id,
            paddleId: item.paddle_id,
            amount: item.amount,
          });
        }
      });
    }
  }, [subscriptionPlans]);

  // useEffect(() => {
  //   if (selectedOrganization && selectedOrganization.id) {
  //     if (
  //       !currentPlan ||
  //       (currentPlan.organization &&
  //         currentPlan.organization !== selectedOrganization.id)
  //     ) {
  //       const payload = {
  //         organization_id: selectedOrganization.id,
  //       };
  //       getOrganizationSubscription(payload);
  //     }
  //   }
  // }, [selectedOrganization]);

  const onTogglePlan = () => {
    if (planInterval === "monthly") {
      setPlanInterval("yearly");
    } else {
      setPlanInterval("monthly");
    }
  };

  const confirmSelectPlan = (plan) => {
    props.history.push({
      pathname: "/user/checkout",
      state: {
        plan: {
          selectedPlanId: plan.selectedPlanId,
          ids: plan.ids,
          name: plan.name,
          paddleIds: plan.paddleIds,
          amounts: plan.amounts,
          interval: planInterval,
          currentUsers: 1,
        },
      },
    });
  };

  return (
    <div
      style={{
        display: "grid",
        justifyContent: "center",
        gridTemplateColumns: "auto",
        padding: "25px 15px",
      }}
    >
      <div>
        <CommonGrid alignItem="flex-start">
          <CardTitle margin="0 0 0 10px">
            Choose the plan that fits for your team
          </CardTitle>
          <ToggleSection style={{ padding: `0 0 20px 0` }}>
            <ToggleText
              active={planInterval === "yearly"}
              onClick={() => setPlanInterval("yearly")}
            >
              <YearlySaveBadge isActive={planInterval === "yearly"}>
                Save 50%
              </YearlySaveBadge>
              Yearly
            </ToggleText>
            <ToggleButton
              onClick={() => onTogglePlan()}
              style={{ backgroundColor: `#20BEAD` }}
            >
              <ToggleButtonRound active={planInterval === "monthly"} />
            </ToggleButton>
            <ToggleText
              active={planInterval === "monthly"}
              onClick={() => setPlanInterval("monthly")}
            >
              Monthly
            </ToggleText>
          </ToggleSection>
        </CommonGrid>

        <PricingCardContainer>
          <SinglePricingCard plan="solo">
            <div>
              <PlanName plan="solo">SOLO</PlanName>
              <PlanPriceContainer>
                <PlanPrice>
                  $
                  {planInterval === "yearly"
                    ? planPriceYearly.solo
                    : planPriceMonthly.solo}
                </PlanPrice>
                <PlanPriceText>one user/month</PlanPriceText>
              </PlanPriceContainer>
              <PlanDescription plan="solo">
                Ideal for freelancers & contractors to track time, keep focus &
                bill clients.
              </PlanDescription>
            </div>
            <PlanFeaturesContainer margin="15px 0">
              {planFeatures.solo.map((item, index) => (
                <SinglePlanFeature key={index}>
                  <TickImage
                    src={tickSolo}
                    alt=""
                    style={{ margin: `2px 0 0 0` }}
                  />
                  <FeatureText>{item}</FeatureText>
                </SinglePlanFeature>
              ))}
            </PlanFeaturesContainer>
            <PlanSelectButton
              plan="solo"
              onClick={() =>
                confirmSelectPlan({
                  selectedPlanId:
                    planInterval === "yearly" ? soloYearly.id : soloMonthly.id,
                  ids: { yearlyId: soloYearly.id, monthlyId: soloMonthly.id },
                  name: "Solo",
                  paddleIds: {
                    yearlyId: soloYearly.paddleId,
                    monthlyId: soloMonthly.paddleId,
                  },
                  amounts: {
                    yearlyAmount: soloYearly.amount,
                    monthlyAmount: soloMonthly.amount,
                  },
                })
              }
            >
              Select SOLO
            </PlanSelectButton>
          </SinglePricingCard>
          <SinglePricingCard plan="standard" isRecommended>
            <div>
              <PlanNameAndTagContainer>
                <PlanName plan="standard">STANDARD</PlanName>
                <PlanNameTag plan="standard">Time</PlanNameTag>
              </PlanNameAndTagContainer>
              <PlanPriceContainer>
                <PlanPrice>
                  $
                  {planInterval === "yearly"
                    ? planPriceYearly.standard
                    : planPriceMonthly.standard}
                </PlanPrice>
                <PlanPriceText>per user/month</PlanPriceText>
              </PlanPriceContainer>
              <PlanDescription plan="standard">
                Ideal for the in-house teams or general teams of any size &
                shape.
              </PlanDescription>
            </div>
            <EverythingInPrev>
              <TickImage src={arrow} alt="" style={{ margin: `0` }} />
              <FeatureText style={{ marginLeft: "8px" }} fontSize="12px">
                Everything in Solo
              </FeatureText>
            </EverythingInPrev>
            <PlusAddonDiv>Plus:</PlusAddonDiv>
            <PlanFeaturesContainer>
              {planFeatures.standard.map((item, index) => (
                <SinglePlanFeature key={index}>
                  <TickImage
                    src={tickStandard}
                    alt=""
                    style={{ margin: `2px 0 0 0` }}
                  />
                  <FeatureText>{item}</FeatureText>
                </SinglePlanFeature>
              ))}
            </PlanFeaturesContainer>
            <PlanSelectButton
              plan="standard"
              onClick={() =>
                confirmSelectPlan({
                  selectedPlanId:
                    planInterval === "yearly"
                      ? standardYearly.id
                      : standardMonthly.id,
                  ids: {
                    yearlyId: standardYearly.id,
                    monthlyId: standardMonthly.id,
                  },
                  name: "Standard",
                  paddleIds: {
                    yearlyId: standardYearly.paddleId,
                    monthlyId: standardMonthly.paddleId,
                  },
                  amounts: {
                    yearlyAmount: standardYearly.amount,
                    monthlyAmount: standardMonthly.amount,
                  },
                })
              }
            >
              Select STANDARD
            </PlanSelectButton>
          </SinglePricingCard>
          <SinglePricingCard plan="premium">
            <div>
              <PlanNameAndTagContainer>
                <PlanName plan="premium">PREMIUM</PlanName>
                <PlanNameTag plan="premium">Field</PlanNameTag>
              </PlanNameAndTagContainer>
              <PlanPriceContainer>
                <PlanPrice>
                  $
                  {planInterval === "yearly"
                    ? planPriceYearly.premium
                    : planPriceMonthly.premium}
                </PlanPrice>
                <PlanPriceText>per user/month</PlanPriceText>
              </PlanPriceContainer>
              <PlanDescription plan="premium">
                Ideal for Mobile teams or a combination of in-house & Mobile
                teams.
              </PlanDescription>
            </div>
            <EverythingInPrev plan="premium">
              <TickImage src={arrow} alt="" style={{ margin: `0` }} />
              <FeatureText style={{ marginLeft: "8px" }} fontSize="12px">
                Everything in Standard
              </FeatureText>
            </EverythingInPrev>
            <PlusAddonDiv>Plus Field Services Add On:</PlusAddonDiv>
            <PlanFeaturesContainer>
              {planFeatures.premium.map((item, index) => (
                <SinglePlanFeature key={index}>
                  <TickImage
                    src={tickPremium}
                    alt=""
                    style={{ margin: `2px 0 0 0` }}
                  />
                  <FeatureText>{item}</FeatureText>
                </SinglePlanFeature>
              ))}
            </PlanFeaturesContainer>
            <PlanSelectButton
              plan="premium"
              onClick={() =>
                confirmSelectPlan({
                  selectedPlanId:
                    planInterval === "yearly"
                      ? premiumYearly.id
                      : premiumMonthly.id,
                  ids: {
                    yearlyId: premiumYearly.id,
                    monthlyId: premiumMonthly.id,
                  },
                  name: "Premium",
                  paddleIds: {
                    yearlyId: premiumYearly.paddleId,
                    monthlyId: premiumMonthly.paddleId,
                  },
                  amounts: {
                    yearlyAmount: premiumYearly.amount,
                    monthlyAmount: premiumMonthly.amount,
                  },
                })
              }
            >
              Select PREMIUM
            </PlanSelectButton>
          </SinglePricingCard>
          <SinglePricingCard plan="elite">
            <div>
              <PlanNameAndTagContainer>
                <PlanName plan="elite">ELITE</PlanName>
                <PlanNameTag plan="elite">Field, Remote & Hybrid</PlanNameTag>
              </PlanNameAndTagContainer>
              <PlanPriceContainer>
                <PlanPrice>
                  $
                  {planInterval === "yearly"
                    ? planPriceYearly.elite
                    : planPriceMonthly.elite}
                </PlanPrice>
                <PlanPriceText>per user/month</PlanPriceText>
              </PlanPriceContainer>
              <PlanDescription plan="elite">
                Ideal for Remote Teams or a combination of in-house, mobile &
                remote teams.
              </PlanDescription>
            </div>
            <EverythingInPrev plan="elite">
              <TickImage src={arrow} alt="" style={{ margin: `0` }} />
              <FeatureText style={{ marginLeft: "8px" }} fontSize="12px">
                Everything in Premium
              </FeatureText>
            </EverythingInPrev>
            <PlusAddonDiv>Plus RemoteTrack Add On:</PlusAddonDiv>
            <PlanFeaturesContainer>
              {planFeatures.elite.map((item, index) => (
                <SinglePlanFeature key={index}>
                  <TickImage
                    src={tickElite}
                    alt=""
                    style={{ margin: `2px 0 0 0` }}
                  />
                  <FeatureText>{item}</FeatureText>
                </SinglePlanFeature>
              ))}
            </PlanFeaturesContainer>
            <PlanSelectButton
              plan="elite"
              onClick={() =>
                confirmSelectPlan({
                  selectedPlanId:
                    planInterval === "yearly"
                      ? eliteYearly.id
                      : eliteMonthly.id,
                  ids: {
                    yearlyId: eliteYearly.id,
                    monthlyId: eliteMonthly.id,
                  },
                  name: "Elite",
                  paddleIds: {
                    yearlyId: eliteYearly.paddleId,
                    monthlyId: eliteMonthly.paddleId,
                  },
                  amounts: {
                    yearlyAmount: eliteYearly.amount,
                    monthlyAmount: eliteMonthly.amount,
                  },
                })
              }
            >
              Select ELITE
            </PlanSelectButton>
          </SinglePricingCard>
        </PricingCardContainer>
      </div>

      <SeeFullComparisonDiv>
        <SeeFullComparisonButton
          target="_blank"
          rel="noopener noreferrer"
          href="https://fieldservicely.com/expandplans"
        >
          See Full Pricing Comparison
        </SeeFullComparisonButton>
      </SeeFullComparisonDiv>
    </div>
  );
};

export default SelectPlan;

import { useEffect } from "react";

import { Row, Col } from "reactstrap";

import myPlanIcon from "../../assets/img/icons/my_plan.svg";
import changePlanIcon from "../../assets/img/icons/change_plan.svg";
import changeCardIcon from "../../assets/img/icons/credit-card-change.svg";
import organizationIcon from "../../assets/img/icons/workspace.svg";
import emailIcon from "../../assets/img/icons/email-icon.svg";
import tickWhite from "../../assets/img/icons/tick_2.svg";
import activity from "../../assets/img/icons/activity.svg";
import timesheetSettingsIcon from "../../assets/img/icons/timesheet-settings.svg";
import locationSettingsIcon from "../../assets/img/icons/location-settings.svg";
import geofenceSettingsIcon from "../../assets/img/icons/geofence-settings.svg";

import {
  SettingsGridContainer,
  SettingsCard,
  WeeklyEmailCard,
  CheckboxDiv,
  CheckboxTickIcon,
} from "../../styledComponents/settings";
import {
  CommonFlex,
  CommonText,
  PageTitle,
} from "../../styledComponents/common";

const CommonCard = ({
  Title,
  Subtitle,
  Icon,
  history,
  onClickUrl,
  handleClick,
  isLink,
  isFunction,
}) => {
  return (
    <SettingsCard
      onClick={() => {
        if (isLink) {
          window.open(onClickUrl, "_blank");
        } else if (isFunction) {
          handleClick();
        } else {
          history.push(onClickUrl);
        }
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={Icon} alt="" style={{ height: "25px", width: "25px" }} />
        <span
          style={{
            fontSize: "14px",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          {Title}
        </span>
      </div>
      <div style={{ marginTop: "8px" }}>
        <span style={{ color: "rgba(61, 77, 105, 0.7)", fontSize: "13px" }}>
          {Subtitle}
        </span>
      </div>
    </SettingsCard>
  );
};
const ActivityCard = ({
  Title,
  Subtitle,
  Icon,
  history,
  onClickUrl,
  isLink,
}) => {
  return (
    <SettingsCard
      onClick={() => {
        if (isLink) {
          window.open(onClickUrl, "_blank");
        } else {
          history.push(onClickUrl);
        }
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={Icon}
          alt=""
          style={{ height: "24px", width: "24px", backgroundColor: "#4E85F3" }}
        />
        <span
          style={{ fontSize: "14px", fontWeight: "600", marginLeft: "10px" }}
        >
          {Title}
        </span>
      </div>
      <div style={{ marginTop: "8px" }}>
        <span style={{ color: "rgba(61, 77, 105, 0.7)", fontSize: "13px" }}>
          {Subtitle}
        </span>
      </div>
    </SettingsCard>
  );
};

const WeeklyEmailSettingsCard = (props) => {
  const updateEmailSettings = (value) => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.selectedOrganization.configuration &&
      props.selectedOrganization.configuration.id
    ) {
      const payload = {
        organization_id: props.selectedOrganization.id,
        configuration_id: props.selectedOrganization.configuration.id,
        receive_weekly_email: value,
      };
      props.updateWeeklyEmailSettings(payload);
    }
  };
  return (
    <WeeklyEmailCard>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={emailIcon} alt="" style={{ height: "25px", width: "25px" }} />
        <span
          style={{ fontSize: "14px", fontWeight: "600", marginLeft: "10px" }}
        >
          Email Preferences
        </span>
      </div>
      <CommonFlex margin="12px 0 0 0" gap="10px">
        <CheckboxDiv
          active={props.weeklyEmail}
          onClick={() => updateEmailSettings(!props.weeklyEmail)}
        >
          <CheckboxTickIcon src={tickWhite} alt="" />
        </CheckboxDiv>
        <CommonText $label fontWeight="500">
          Send weekly reports in email
        </CommonText>
      </CommonFlex>
    </WeeklyEmailCard>
  );
};

const Settings = ({
  history,
  selectedOrganization,
  settingsIsLoading,
  weeklyEmail,
  getWeeklyEmailSettings,
  updateWeeklyEmailSettings,

  updateSubscriptionPaymentMethod,
  updatePaymentMethodSuccess,
  clearPaymentMethodUpdated,
}) => {
  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      selectedOrganization.configuration &&
      selectedOrganization.configuration.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        configuration_id: selectedOrganization.configuration.id,
      };

      getWeeklyEmailSettings(payload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    return () => {
      clearPaymentMethodUpdated();
    };
  }, []);

  useEffect(() => {
    if (updatePaymentMethodSuccess) {
      handleChangePaymentMethod();

      setTimeout(() => {
        clearPaymentMethodUpdated();
      }, 500);
    }
  }, [updatePaymentMethodSuccess]);

  const changePaymentMethodRequest = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      updateSubscriptionPaymentMethod(payload);
    }
  };

  const handleChangePaymentMethod = () => {
    if (
      selectedOrganization &&
      selectedOrganization.subscription &&
      selectedOrganization.subscription.update_url
    ) {
      window.open(selectedOrganization.subscription.update_url, "_blank");
    }
  };

  return (
    <div className="content">
      <PageTitle>Settings</PageTitle>
      {/* <Row>
        <Col md={12}>
          <div>
            <p
              style={{
                fontSize: "20px",
                color: "rgba(59, 63, 71, 0.6)",
              }}
            >
              General
            </p>
          </div>
        </Col>
      </Row>
      <SettingsGridContainer>
        <CommonCard
          Title="Organization"
          Subtitle="Create or customize your organizations"
          Icon={organizationIcon}
          history={history}
          onClickUrl="/user/organization"
        />
      </SettingsGridContainer> */}
      {selectedOrganization && selectedOrganization.role === "owner" && (
        <>
          <Row>
            <Col md={12}>
              <div>
                <p
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "rgba(61,77,105,0.7)",
                    margin: "20px 0 10px 5px",
                  }}
                >
                  Billing
                </p>
              </div>
            </Col>
          </Row>
          <SettingsGridContainer>
            <CommonCard
              Title="My Plan"
              Subtitle="View all information"
              Icon={myPlanIcon}
              history={history}
              onClickUrl="/user/current-plan"
            />
            {selectedOrganization &&
              selectedOrganization.id &&
              (!selectedOrganization.subscription ||
                !(
                  selectedOrganization.subscription &&
                  selectedOrganization.subscription.status === "paused"
                )) && (
                <CommonCard
                  Title="Change Subscription Plan"
                  Subtitle="Upgrade to your favorite plan"
                  Icon={changePlanIcon}
                  history={history}
                  onClickUrl="/user/subscription"
                />
              )}

            {selectedOrganization &&
              selectedOrganization.id &&
              selectedOrganization.subscription &&
              // selectedOrganization.subscription.status === "active" &&
              // selectedOrganization.subscription.paddlesubscription_active &&
              selectedOrganization.subscription.update_url && (
                <CommonCard
                  Title="Change Card"
                  Subtitle="Change payment method"
                  Icon={changeCardIcon}
                  isFunction
                  // onClickUrl={selectedOrganization.subscription.update_url}
                  handleClick={changePaymentMethodRequest}
                />
              )}
          </SettingsGridContainer>
        </>
      )}
      <Row
        style={{
          marginTop: "20px",
        }}
      >
        <Col md={12}>
          <div>
            <p
              style={{
                fontSize: "15px",
                fontWeight: "600",
                color: "rgba(61,77,105,0.7)",
                margin: "0 0 10px 5px",
              }}
            >
              Feature
            </p>
          </div>
        </Col>
      </Row>

      <SettingsGridContainer>
        {selectedOrganization &&
          selectedOrganization.plan &&
          selectedOrganization.plan.screenshot_track && (
            <ActivityCard
              Title="Activity"
              Subtitle="Customize screenshots, activity, apps & URLs tracking settings."
              Icon={activity}
              history={history}
              onClickUrl="/user/activity-settings"
            />
            // <CommonCard
            //   Title="Activity"
            //   Subtitle="Customize screenshots, activity, apps & URLs tracking settings."
            //   Icon={activity}
            //   history={history}
            //   onClickUrl="/user/activity-settings"
            // />
          )}
        <WeeklyEmailSettingsCard
          weeklyEmail={weeklyEmail}
          updateWeeklyEmailSettings={updateWeeklyEmailSettings}
          selectedOrganization={selectedOrganization}
        />
        {/* <CommonCard
          Title="Timesheet Settings"
          Subtitle="Control access of manual time permission"
          Icon={timesheetSettingsIcon}
          history={history}
          onClickUrl="/user/timesheet-settings"
        />
        {selectedOrganization &&
          selectedOrganization.plan &&
          selectedOrganization.plan.location_track && (
            <CommonCard
              Title="GPS Location Tracking"
              Subtitle="Manage GPS location tracking of your organization"
              Icon={locationSettingsIcon}
              history={history}
              onClickUrl="/user/location-settings"
            />
          )}
        { (
            <CommonCard
              Title="Geofence Time Clock"
              Subtitle="Manage geofence time clock settings of your organization"
              Icon={geofenceSettingsIcon}
              history={history}
              onClickUrl="/user/geofence-settings"
            />
          )} */}
      </SettingsGridContainer>
    </div>
  );
};

export default Settings;

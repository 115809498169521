import ComponentCircleLoader from "components/Loaders/ComponentCircleLoader";
import { useMemo } from "react";
import { Redirect, Route } from "react-router-dom";

export const PrivateRoute = ({
  component: Component,
  selectedOrganization,
  restricted,
  ...rest
}) => {
  const role = useMemo(
    () => selectedOrganization?.role?.toLowerCase(),
    [selectedOrganization]
  );

  if (!role && restricted?.length) {
    return <ComponentCircleLoader />;
  }

  if (restricted?.includes(role)) {
    return <Redirect to="/user/dashboard" />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("access_token") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

import React, { useEffect, useState } from 'react';

import GoHighLevel from '../../assets/img/GoHighLevel.png';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import SearchWithButton from '../../components/Search/SearchWithButton';
import { TabButton, TabButtonContainer } from '../../styledComponents/buttons';
import {
  CommonText,
  FilterLabel,
  HeaderContainer,
  InputWithLabelSection,
  PageTitle,
} from '../../styledComponents/common';
import {
  ActionDropDown,
  AllIntegrationsContainer,
  AllIntegrationsDiv,
  BoldText,
  CardIcon,
  ConnectButton,
  ConnectText,
  HeaderText,
  Integration,
  IntegrationCard,
  IntegrationCardContainer,
  IntegrationsTableHeader,
  IntegrationsTableRow,
  IntegrationsTableView,
  NormalText,
  PointerText,
  SemiText,
} from '../../styledComponents/integrations';
import { TabLeftGrid } from '../../styledComponents/members';
import { AuthHandler } from './integrationHandler/authHandler/authHandlerService';

const handleAuthFlow = (stepsData, otherData) => {
  const authHandler = new AuthHandler(stepsData, otherData);

  authHandler.init();
  // authHandler.run();
};

const SingleIntegrationCard = props => {
  const {
    integration,
    history,
    selectedOrganization,
    connectIntegration,
    handleViewDetails,
  } = props;

  return (
    <IntegrationCard>
      <CardIcon src={integration.icon} alt="asanaIcon" />
      <CommonText fontWeight="600" margin="10px 0" fontSize="15px">
        {integration.display_name}
      </CommonText>
      <ConnectButton
        onClick={() => {
          if (integration.is_installed) {
            handleViewDetails(integration.id);
          } else {
            connectIntegration(integration);
          }
        }}
      >
        {integration.is_installed ? 'View Details' : 'Connect'}
      </ConnectButton>
    </IntegrationCard>
  );
};

const AllIntegrations = props => {
  const { history, integrations, enabledIntegrations, selectedOrganization } =
    props;

  const connectIntegration = integration => {
    // const { route } = integration;
    // if (route) {
    //   if (route.isLink) {
    //     window.open(route.pathname, "_blank");
    //   }
    // } else {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      integration.id &&
      integration.frontend_steps &&
      integration.frontend_steps.length > 0
    ) {
      handleAuthFlow(integration.frontend_steps, {
        organization_id: selectedOrganization.id,
        integration_id: integration.id,
      });
    }
    // }
  };

  const handleViewDetails = integrationId => {
    if (enabledIntegrations && enabledIntegrations.length > 0) {
      enabledIntegrations.some(item => {
        if (item.integration_id === integrationId) {
          history.push({
            pathname: `/user/integrations/${item.id}/details`,
            state: {
              enabledIntegration: {
                ...item,
              },
            },
          });
          return true;
        }
        return false;
      });
    }
  };

  return (
    <AllIntegrationsContainer>
      <AllIntegrationsDiv>
        {integrations &&
          integrations.map((integration, index) => (
            <React.Fragment key={index}>
              <SingleIntegrationCard
                integration={integration}
                history={history}
                selectedOrganization={selectedOrganization}
                handleViewDetails={handleViewDetails}
                connectIntegration={connectIntegration}
              />
            </React.Fragment>
          ))}
      </AllIntegrationsDiv>
      {/* <CommonColorButton padding="13px 25px">
          All Integrations
        </CommonColorButton> */}
    </AllIntegrationsContainer>
  );
};
const ActivatedIntegrations = props => {
  return null;
};

const IntegrationsNew = props => {
  const [selectedTab, setSelectedTab] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');

  const {
    history,
    getAllIntegrationsList,
    allIntegrationsList,
    getEnabledIntegrationsList,
    enabledIntegrationsList,
    isLoading,
    selectedOrganization,
  } = props;

  // useEffect(() => {
  //   if (selectedOrganization && selectedOrganization.id) {
  //     const payload = {
  //       organization_id: selectedOrganization.id,
  //     };
  //     getAllIntegrationsList(payload);
  //     getEnabledIntegrationsList(payload);
  //   }
  // }, [selectedOrganization]);

  const onTabSelect = value => {
    setSearchTerm('');
    setSelectedTab(value);
  };

  return (
    <div className="content">
      <PageTitle>Integrations</PageTitle>
      {/* <HeaderContainer>
        <TabLeftGrid columns="auto 280px">
          <InputWithLabelSection>
            <FilterLabel>Type</FilterLabel>
            <TabButtonContainer>
              <TabButton
                index="all"
                selected={selectedTab}
                onClick={() => onTabSelect("all")}
              >
                All Integrations
              </TabButton>
              <TabButton
                index="activated"
                selected={selectedTab}
                onClick={() => onTabSelect("activated")}
              >
                Activated Integrations
              </TabButton>
            </TabButtonContainer>
          </InputWithLabelSection>
          <SearchWithButton
            itemName="Integration"
            searchTermName="integration name"
          />
        </TabLeftGrid>
      </HeaderContainer> */}
      {/* {selectedTab && selectedTab === "all" ? (
        <AllIntegrations history={history} />
      ) : (
        <ActivatedIntegrations />
      )} */}
      {/* {isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <AllIntegrations
          history={history}
          integrations={allIntegrationsList}
          enabledIntegrations={enabledIntegrationsList}
          selectedOrganization={selectedOrganization}
        />
      )} */}

      <IntegrationCardContainer>
        <IntegrationCard style={{ margin: '30px 0 0 0 ' }}>
          <CardIcon src={GoHighLevel} alt="GoHighLevelIcon" />
          <CommonText fontWeight="600" margin="10px 0" fontSize="15px">
            HighLevel
          </CommonText>
          <ConnectButton
            onClick={() => {
              history.push(`/user/ghl-settings/`);
            }}
          >
            View Details
          </ConnectButton>
        </IntegrationCard>
      </IntegrationCardContainer>
    </div>
  );
};

export default IntegrationsNew;

import EvidenceModal from 'custom_modules/TasksNew/TaskDetails/EvidenceModal';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../assets/img/avatar_5.svg';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent';
import InfoTooltip from '../../components/Tooltip/InfoTooltip';
import {
  CommonFlex,
  CommonImage,
  CommonText,
  Container,
  RoundMiniImage,
  TableText,
} from '../../styledComponents/common';
import { MemberNameDiv } from '../../styledComponents/members';
import { TableItem } from '../../styledComponents/teams';
import { getHours, toHHMM, toHHMMSS } from '../../utils/helper';
import { CommImageNameComp } from '../Members/CommonComponents';
import { ClockInOutTableHeader, ClockInOutTableRow } from './clockInOutStyles';

const ClockInOutTable = ({ isLoading, history, clockInOutList }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const { t } = useTranslation();

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };
  return (
    <Container style={{ paddingBottom: '20px', overflowX: 'auto' }}>
      {selectedImage && (
        <EvidenceModal
          isOpen={popupOpen}
          toggle={popupToggle}
          evidence={selectedImage}
        />
      )}
      {isLoading ? (
        <ComponentCircleLoader />
      ) : clockInOutList?.length > 0 ? (
        <>
          <ClockInOutTableHeader>
            <TableItem>
              <TableText>{t('member_name')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('date')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>
                {t('clock_in')}
                {' - '}
                {t('out')}
              </TableText>
            </TableItem>
            {/* <TableItem>
              <InfoTooltip
                mainText={t('clock_out')}
                toolTipText="Clock out occurs when the user stops the timer at the end of the day"
                title
                label
                top="-55px"
                tooltipPadding="5px 8px"
                infoSize="17px"
              />
              <TableText>{t('clock_out')}</TableText>
            </TableItem> */}
            <TableItem>
              <TableText>{t('duration')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('office')}</TableText>
            </TableItem>
            <TableItem>
              <TableText>{t('image')}</TableText>
            </TableItem>
          </ClockInOutTableHeader>

          {clockInOutList?.map((instance, index) => {
            const { attendances } = instance;
            return (
              <ClockInOutTableRow key={index}>
                <CommonFlex direction="column" alignItems="start">
                  <div
                    style={{
                      position: 'sticky',
                      top: '0',
                    }}
                  >
                    <TableItem
                      onClick={() =>
                        instance?.employee?.id &&
                        history.push(
                          `/user/member-details?id=${instance.employee.id}`,
                        )
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      <CommImageNameComp
                        imgSource={instance.employee.avatar || avatar5}
                        name={`${instance.employee.first_name}${
                          instance.employee.last_name
                            ? ` ${instance.employee.last_name}`
                            : ``
                        }`}
                        size="36px"
                        fontSize="13px"
                        index={index}
                        color={instance.employee.color}
                        hoverUnderline
                      />
                    </TableItem>
                  </div>
                </CommonFlex>
                {/* <TableItem>
                    <TableText name>
                      {instance.employee && instance.employee.user_code
                        ? instance.employee.user_code
                        : '-'}
                    </TableText>
                  </TableItem> */}
                <TableItem>
                  <CommonFlex
                    direction="column"
                    style={{
                      width: 'max-content',
                    }}
                  >
                    {attendances?.map((attendance, index) => (
                      <TableText
                        name
                        key={index}
                        height="46px"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {attendance.clockin_timestamp
                          ? moment
                              .unix(attendance.clockin_timestamp)
                              .format('DD-MM-yyyy')
                          : '-'}
                      </TableText>
                    ))}
                    {attendances?.length === 0 && <TableText>-</TableText>}
                  </CommonFlex>
                </TableItem>
                <TableItem>
                  {attendances?.length === 0 && <TableText>-</TableText>}
                  <CommonFlex
                    direction="column"
                    style={{
                      width: 'max-content',
                      alignItems: 'start',
                    }}
                  >
                    {attendances.map((attendance, index) => (
                      <TableText
                        name
                        key={index}
                        height="46px"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {attendance.clockin_timestamp
                          ? moment
                              .unix(attendance.clockin_timestamp)
                              .format('LT')
                          : ' '}
                        {' - '}
                        {attendance.clockout_timestamp
                          ? moment
                              .unix(attendance.clockout_timestamp)
                              .format('LT')
                          : ' '}
                      </TableText>
                    ))}
                  </CommonFlex>
                </TableItem>

                <TableItem>
                  {attendances?.length === 0 && <TableText>-</TableText>}
                  <CommonFlex
                    direction="column"
                    style={{
                      width: '100%',
                      alignContent: 'start',
                      alignItems: 'start',
                    }}
                  >
                    {attendances.map((attendance, index) => (
                      <TableText
                        name
                        key={index}
                        height="46px"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {attendance.clockout_timestamp
                          ? getHours(
                              toHHMM(
                                attendance.clockout_timestamp -
                                  attendance.clockin_timestamp,
                              ),
                            )
                          : '-'}
                      </TableText>
                    ))}
                  </CommonFlex>
                </TableItem>
                <TableItem>
                  {attendances?.length === 0 && <TableText>-</TableText>}
                  <CommonFlex
                    direction="column"
                    style={{
                      width: '100%',
                      alignContent: 'start',
                      alignItems: 'start',
                    }}
                  >
                    {attendances.map((attendance, index) => (
                      <TableText
                        name
                        key={index}
                        height="46px"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          overflow: 'wrap',
                        }}
                      >
                        {attendance.office_name ? attendance.office_name : '-'}
                      </TableText>
                    ))}
                  </CommonFlex>
                </TableItem>
                <TableItem>
                  <CommonFlex
                    direction="column"
                    style={{
                      width: 'max-content',
                    }}
                  >
                    {attendances.map((attendance, index) => {
                      if (!attendance?.selfie) {
                        return (
                          <div
                            style={{
                              height: '46px',
                            }}
                          ></div>
                        );
                      }
                      return (
                        <CommonImage
                          height="46px"
                          width="36px"
                          style={{ cursor: `pointer` }}
                          onClick={() => {
                            setSelectedImage(attendance.selfie);
                            popupToggle();
                          }}
                          src={attendance.selfie}
                        />
                      );
                    })}
                  </CommonFlex>
                </TableItem>
              </ClockInOutTableRow>
            );
          })}
        </>
      ) : (
        <NoDataComponent
          title="No Attendance In This Day!"
          padding="5vh 0"
          imageHeight="140px"
        />
      )}
    </Container>
  );
};

export default ClockInOutTable;

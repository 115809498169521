import { Fragment, useEffect, useRef, useState } from 'react';

import avatar5 from '../../assets/img/avatar_5.svg';
import assign from '../../assets/img/icons/assign.svg';
import removeAssignee from '../../assets/img/icons/remove_assignee.svg';
import searchIcon from '../../assets/img/icons/search.svg';
import {
  AssignListContainer,
  AssignListItem,
  AssignListText,
  AssigneeSearchBarContainer,
  AssigneeSearchBarWrapper,
  AssigneeSearchIcon,
  AssigneeSearchInput,
  SearchBarWithAssignListContainer,
  ShowMoreAssigneeList,
} from '../../styledComponents/common';
import {
  AssigneeRoundImage,
  AssigneeRoundImageSection,
  AssigneesManageImgTextGrid,
  AssigneesManageRoundImage,
  RoundImageCross,
  RoundPersonImage,
} from '../../styledComponents/createProject';
import { getFullName } from '../../utils/helper';
import DropdownLoader from '../DropdownLoader/DropdownLoader';
//dropdown
import AssigneeTooltip from '../Tooltip/AssigneeTooltip';
import FirstRoundLetterComp from 'components/FirstRoundLetter/FirstRoundLetter';
import { CommImageNameComp } from 'custom_modules/Members/CommonComponents';

const AddMultipleAssigneeDropdown = ({
  numberOfVisibleMembers = 4,
  isLoading,
  selectedOrganization,
  assigneesList = [],
  index,
  handleAssign,
  visibilityChange = () => true,
  membersList,
  handleRemove,
  assigneeIsLoading,
  loadingAssigneeId,
  autoPlacement = true,
  ...otherProps
}) => {
  const [addAssignee, setAddAssignee] = useState(false);
  const [moreAssignee, setMoreAssignee] = useState(false);

  const wrapperRef1 = useRef(null);
  const wrapperRef2 = useRef(null);

  const moreRef1 = useRef(null);
  const moreRef2 = useRef(null);

  const [addList, setAddList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      let newArr = [];
      if (membersList && membersList.length > 0) {
        membersList.map(member => {
          newArr.push({
            id: member.id,
            name: member.name,
            avatar: member.avatar,
            phone: member.phone,
            color: member.color,
          });
        });
      }
      if (assigneesList && assigneesList.length > 0) {
        assigneesList.map(assignee => {
          newArr = newArr.filter(item => item.id !== assignee.id);
        });
      }
      setAddList(newArr);
    } else if (membersList && membersList.length === 0) {
      setAddList([]);
    }
    if (assigneesList && assigneesList.length < numberOfVisibleMembers) {
      setMoreAssignee(false);
    }
  }, [membersList, assigneesList]);

  useEffect(() => {
    if (
      searchTerm &&
      searchTerm !== '' &&
      membersList &&
      membersList.length > 0
    ) {
      let originalList = membersList;
      let filteredList = [];

      originalList.forEach(e => {
        const name = e.name;
        if (
          searchTerm &&
          name.toLowerCase().indexOf(searchTerm.toLowerCase().trim()) === -1
        ) {
          return;
        }
        filteredList.push({
          id: e.id,
          name: e.name,
          avatar: e.avatar,
          phone: e.phone,
        });
      });
      if (assigneesList && assigneesList.length > 0) {
        assigneesList.map(assignee => {
          filteredList = filteredList.filter(item => item.id !== assignee.id);
        });
      }
      setAddList(filteredList);
    } else if (searchTerm === '') {
      let newArr = [];
      if (membersList && membersList.length > 0) {
        membersList.map(member => {
          newArr.push({
            id: member.id,
            name: member.name,
            avatar: member.avatar,
            phone: member.phone,
          });
        });
      }
      if (assigneesList && assigneesList.length > 0) {
        assigneesList.map(assignee => {
          newArr = newArr.filter(item => item.id !== assignee.id);
        });
      }
      setAddList(newArr);
    }
  }, [searchTerm]);

  const onSearchTermChange = e => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  //function
  useOutsideClickHandler(wrapperRef1, wrapperRef2, moreRef1, moreRef2);

  const showAssignee = () => {
    setAddAssignee(true);
  };

  const showMoreAssignee = () => {
    setMoreAssignee(true);
  };

  function useOutsideClickHandler(ref1, ref2, moreRef1, moreRef2) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref1.current &&
          !ref1.current.contains(event.target) &&
          ref2.current &&
          !ref2.current.contains(event.target)
        ) {
          setAddAssignee(false);
          visibilityChange(false);
        }
        if (
          moreRef1.current &&
          !moreRef1.current.contains(event.target) &&
          moreRef2.current &&
          !moreRef2.current.contains(event.target)
        ) {
          setMoreAssignee(false);
          visibilityChange(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref1, ref2, addAssignee, moreRef1, moreRef2, moreAssignee]);
  }
  return (
    <AssigneeRoundImageSection
      position={assigneesList.length}
      visibleMembers={numberOfVisibleMembers}
      style={{ marginRight: `auto` }}
    >
      <AssigneeRoundImage
        index={0}
        onClick={() => {
          showAssignee();
          visibilityChange(true);
        }}
        ref={wrapperRef2}
      >
        <img src={assign} alt="assign" />
      </AssigneeRoundImage>
      {assigneesList.length > numberOfVisibleMembers - 1 && (
        <AssigneeRoundImage
          index="more"
          onClick={() => {
            showMoreAssignee();
            visibilityChange(true);
          }}
          ref={moreRef2}
          style={{
            border: `1px solid #fff`,
            paddingLeft: '3px',
          }}
        >
          {`+${assigneesList.length - (numberOfVisibleMembers - 1)}`}
        </AssigneeRoundImage>
      )}
      {assigneesList
        .filter((person, fi) => fi < numberOfVisibleMembers - 1)
        .map((filteredPerson, i) => (
          <AssigneeRoundImage
            key={i}
            index={
              assigneesList.length < numberOfVisibleMembers ? i + 1 : i + 2
            }
          >
            <AssigneeTooltip
              toolTipText={filteredPerson.name ? filteredPerson.name : ''}
              phone={filteredPerson.phone}
              top="-60px"
              left="-55px"
            />
            {filteredPerson.avatar ? (
              <RoundPersonImage
                src={filteredPerson.avatar ? filteredPerson.avatar : avatar5}
                alt="addPeople"
              />
            ) : (
              <FirstRoundLetterComp
                text={filteredPerson.name}
                backColor={i}
                color={filteredPerson.color}
              />
            )}
            <RoundImageCross onClick={() => handleRemove(filteredPerson)}>
              <img
                src={removeAssignee}
                style={{ width: '100%', height: '100%' }}
              />
            </RoundImageCross>
          </AssigneeRoundImage>
        ))}
      {moreAssignee && (
        <ShowMoreAssigneeList
          ref={moreRef1}
          autoPlacement
          left={`${27 * numberOfVisibleMembers}px`}
        >
          {assigneesList.map((member, index) => (
            <Fragment key={index}>
              <AssigneeRoundImage index={0} size="30px">
                {member.avatar ? (
                  <RoundPersonImage
                    src={member.avatar ? member.avatar : avatar5}
                    alt=""
                    size="30px"
                    borderColor="#c2cce1"
                  />
                ) : (
                  <FirstRoundLetterComp
                    text={member.name}
                    backColor={index}
                    color={member.color}
                    size='30px'
                  />
                )}
                
                <RoundImageCross onClick={() => handleRemove(member)}>
                  <img
                    src={removeAssignee}
                    style={{ width: '100%', height: '100%' }}
                  />
                </RoundImageCross>
              </AssigneeRoundImage>
              <AssignListText>{member.name}</AssignListText>
            </Fragment>
          ))}
        </ShowMoreAssigneeList>
      )}
      {addAssignee && (
        <AssignListContainer
          ref={wrapperRef1}
          autoPlacement
          left={`${
            27 *
            (assigneesList.length > numberOfVisibleMembers - 1
              ? numberOfVisibleMembers
              : assigneesList.length - 1)
          }px`}
        >
          <SearchBarWithAssignListContainer>
            <AssigneeSearchBarWrapper>
              <AssigneeSearchBarContainer>
                <AssigneeSearchIcon src={searchIcon} alt="" />
                <AssigneeSearchInput
                  value={searchTerm}
                  onChange={e => onSearchTermChange(e)}
                  placeholder="Search..."
                  autoFocus
                />
              </AssigneeSearchBarContainer>
            </AssigneeSearchBarWrapper>

            <DropdownLoader loading={isLoading} />
            {addList &&
              addList.length > 0 &&
              addList.map((person, i) => {
                return assigneeIsLoading &&
                  loadingAssigneeId &&
                  loadingAssigneeId === person.id ? (
                  <AssignListItem columns="100%" key={i}>
                    <DropdownLoader loading />
                  </AssignListItem>
                ) : (
                  <AssignListItem
                    key={i}
                    onClick={() => {
                      handleAssign(person);
                    }}
                    columns="30px auto"
                    padding="7px 8px"
                  >
                    <AssigneeRoundImage index={0} size="30px">
                      {person.avatar ? (
                        <RoundPersonImage
                          src={person.avatar ? person.avatar : avatar5}
                          alt="addPeople"
                          size="30px"
                          borderColor="#c2cce1"
                        />
                      ) : (
                        <FirstRoundLetterComp
                          text={person.name}
                          backColor={i}
                          color={person.color}
                          size='30px'
                        />
                      )}
                    </AssigneeRoundImage>
                    <AssignListText>{person.name}</AssignListText>
                  </AssignListItem>
                );
              })}
          </SearchBarWithAssignListContainer>
        </AssignListContainer>
      )}
    </AssigneeRoundImageSection>
  );
};

export default AddMultipleAssigneeDropdown;

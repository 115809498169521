import moment from 'moment';
import { useState } from 'react';
import { SingleDatePicker, isInclusivelyBeforeDay } from 'react-dates';

import datePickerIcon from '../../assets/img/icons/date_picker_icon.svg';

const CustomInputIcon = () => {
  return <img style={{ margin: '0 5px 0 auto' }} src={datePickerIcon} />;
};

const DatePicker = ({
  width,
  date,
  onDateChange,
  dateDisplayFormat,
  placeholder,
  allowPreviousDates,
  disableFutureDates,
  disableCurrentAndFutureDates,
  disablePreviousDatesFrom,
  disabled,
  error,
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <div className={error ? 'error' : null} style={{ width: width ? width : '150px' }}>
      <SingleDatePicker
        numberOfMonths={1}
        onDateChange={date => onDateChange(date)}
        onFocusChange={({ focused }) => setFocused(focused)}
        focused={focused}
        date={date}
        displayFormat={dateDisplayFormat}
        showDefaultInputIcon={false}
        customInputIcon={<CustomInputIcon />}
        inputIconPosition="after"
        hideKeyboardShortcutsPanel
        placeholder={placeholder}
        disabled={disabled ? true : false}
        readOnly
        isOutsideRange={
          disableFutureDates
            ? day => !isInclusivelyBeforeDay(day, moment())
            : allowPreviousDates
              ? () => false
              : disableCurrentAndFutureDates
                ? day => !isInclusivelyBeforeDay(day, moment().subtract(1, 'day'))
                : disablePreviousDatesFrom
                  ? day => day.isBefore(moment(disablePreviousDatesFrom))
                  : day => {
                    if (day.isSameOrBefore(moment().subtract(1, 'day'))) {
                      return true;
                    }
                    return false;
                  }
        }
      />
    </div>
  );
};

export default DatePicker;

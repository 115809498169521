import { useState, useEffect } from "react";
import classnames from "classnames";
import styled, { keyframes } from "styled-components";
import {
  PageTitle,
  CommonText,
  CommonGrid,
  FirstWordRound,
} from "../../../styledComponents/common";
import {
  UpgradeUpperSection,
  BillingContainer,
  UpgradeLowerSection,
} from "../../../styledComponents/billing";
import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";

const BillingUpperSection = styled(UpgradeUpperSection)`
  grid-template-columns: 200px auto 200px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`;
const BillingLowerSection = styled(UpgradeLowerSection)`
  grid-template-columns: 400px;
  grid-gap: 0;
  padding-top: 15px;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
const PaddlePaymentContainer = styled.div`
  width: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const spin = keyframes`
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;
const LoaderSpinner = styled.div`
  width: 40px;
  height: 40px;
  margin: 0;
  background: transparent;
  border-top: 3px solid #20bead;
  border-right: 3px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s ${spin} linear infinite;
`;
const LoaderText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.main.textSecondary};
  margin-top: 30px;
`;

const RoundLetter = ({ text, color, isActive }) => {
  return (
    <FirstWordRound
      backColor={isActive ? color : "#84919e"}
      size="65px"
      fontSize="30px"
    >
      {" "}
      {text.slice(0, 1).toUpperCase()}{" "}
    </FirstWordRound>
  );
};

const PaymentLoader = () => (
  <LoaderContainer>
    {/* <LoaderSpinner /> */}
    <ComponentCircleLoader padding="0" />
    <LoaderText>Complete your order by entering the details here...</LoaderText>
  </LoaderContainer>
);

const PaddleCheckout = (props) => {
  const [isPaddleLoading, setIsPaddleLoading] = useState(false);

  const Paddle = window.Paddle;

  const loadCallback = () => {
    setIsPaddleLoading(false);
  };

  const successCallback = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      props.location.state.plan &&
      props.location.state.plan.id &&
      props.location.state.plan.paddleId
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        plan_id: props.location.state.plan.id,
      };
      props.updateOrganizationSubscription(payload);
    }
    setTimeout(() => {
      props.history.push("/user/jobs");
    }, 2000);
  };

  useEffect(() => {
    if (
      props.location?.state?.plan?.organizationId
    ) {
      const { plan } = props.location.state;
      if (plan.paddleId) {
        const email = localStorage.getItem("email");

        Paddle.Checkout.open({
          settings: {
            displayMode: "inline",
            frameTarget: "paddle-checkout",
            frameInitialHeight: "416",
            frameStyle:
              "width: 100%; min-width: 312px; background-color: transparent; border: none;",
          },
          items: [
            {
              priceId: plan.paddleId,
              // priceId: "pri_01jd70te6109ww88a042k9d138",
              quantity: plan.currentUsers > 1 ? plan.currentUsers - 1 : 1,
            },
          ],
          customer: {
            email: email || "",
          },
          customData: {
            organizationId: plan.organizationId,
            planId: plan.id,
          },

          allowLogout: false, // Disable the option for the user to log out/change email
          successUrl: `https://app.fieldservicely.com/user/dashboard`,
          // eventCallback: (data) => {
          //   if (data.event === "Checkout.Loaded") {
          //     loadCallback();
          //   } else if (data.event === "Checkout.Complete") {
          //     successCallback(data);
          //   }
          // },
        });
      }
    } else {
      props.history.goBack();
    }
  }, []);

  return (
    <div className="content">
      <PageTitle>Billing - Checkout</PageTitle>

      <BillingContainer style={{ gridTemplateColumns: "90%" }}>
        <div>
          <BillingUpperSection alignItems="center">
            <BackButtonComponent
              onClick={() => props.history.goBack()}
              subTitle="Go back to payment"
            />
            <CommonGrid
              columns="auto"
              gap="15px"
              justifyContent="center"
              style={{ justifyItems: `center` }}
            >
              {/* {props.location.state &&
                props.location.state.plan &&
                props.location.state.plan.organizationName && (
                  <RoundLetter
                    text={props.location.state.plan.organizationName}
                    color={props.location.state.plan.organizationColor}
                    isActive={props.location.state.plan.isOrgActive}
                  />
                )} */}
              <CommonText name title fontSize="20px">
                Payment Details
              </CommonText>
            </CommonGrid>
            <div />
          </BillingUpperSection>
          <BillingLowerSection>
            <PaddlePaymentContainer>
              {isPaddleLoading && <PaymentLoader />}
              <div
                className={classnames(
                  "paddle-checkout",
                  !isPaddleLoading && "loaded"
                )}
              ></div>
            </PaddlePaymentContainer>
          </BillingLowerSection>
        </div>
      </BillingContainer>
    </div>
  );
};

export default PaddleCheckout;

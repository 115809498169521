import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';

import ButtonTextLoader from '../../components/Loaders/ButtonTextLoader';
import { ColoredButton, WhiteButton } from '../../styledComponents/buttons';
import { CommonText } from '../../styledComponents/common';
import { ModalButtonSection } from '../../styledComponents/members';

const DeleteClientModal = ({ isOpen, toggle, handleDelete, id, isLoading }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: `25vh` }}>
      <ModalBody style={{ padding: `20px` }}>
        <CommonText style={{ paddingLeft: `15px` }}>
          {t('are_you_sure_you_want_to_delete_this_customer')}
        </CommonText>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={toggle}>
            {t('cancel')}
          </WhiteButton>
          <ColoredButton
            type="delete"
            padding={isLoading ? '0 15px' : '0 30px'}
            onClick={() => handleDelete(id)}
          >
            {isLoading ? (
              <ButtonTextLoader loadingText="Deleting" fontSize="13px" />
            ) : (
              t('delete')
            )}
          </ColoredButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default DeleteClientModal;

import BackendExportModal from 'custom_modules/ReportsNew/BackendExportModal';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  ColoredButton,
  ExportButtonIcon,
  ExportButtonWithIcon,
} from 'styledComponents/buttons';

import ExportIcon from '../../../assets/img/icons/export_icon.svg';
import Plus from '../../../assets/img/icons/plus_white.svg';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import RightSlidingPanel from '../../../components/RightSlidingPanel/RightSlidingPanel';
import {
  CommonFlex,
  CommonGrid,
  PageTitle,
} from '../../../styledComponents/common';
import { TimesheetContainer } from '../../../styledComponents/timesheet';
import TimesheetDetails from '../TimesheetDetailsPanel';
import TimesheetFilter from '../TimesheetFilter';
import WeeklyAdminView from './WeeklyAdminView';
import WeeklyMemberView from './WeeklyMemberView';

const Weekly = props => {
  const [selectedProject, setSelectedProject] = useState('');
  const [startDate, setStartDate] = useState(
    moment().startOf('isoWeek').format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(
    moment().endOf('isoWeek').format('YYYY-MM-DD'),
  );
  const [selectedMember, setSelectedMember] = useState('');
  const [selectedTask, setSelectedTask] = useState('');
  const [projectAssigneeList, setProjectAssigneeList] = useState([]);
  const [taskAssigneeList, setTaskAssigneeList] = useState([]);

  // details panel
  const [detailsUserData, setDetailsUserData] = useState(null);
  const [detailsPanelIsOpen, setDetailsPanelIsOpen] = useState(false);

  const [selectedOffice, setSelectedOffice] = useState({
    label: '(All Offices)',
    value: null,
  });

  const {
    selectedOrganization,
    profileData,
    clearTimesheetList,
    getWeeklyTimesheetDetails,
    getOtherTimesheets,
    getDailyTimesheets,
    isLoading,
    timesheetDetails,
    organizationMembersList,
    dailyTimesheetLoading,
    dailyTimesheets,
    timesheet,
    //Office
    getOfficeDetails,
    officeDetails,
    getOfficesShortList,
    officesShortList,
    officeDetailsLoading,
    //member short list
    memberShortListIsLoading,
    orgMembersShortList,
    getOrganizationMembersShortList,
    exportWeeklyTimeSheet,
  } = props;

  const [officeMembers, setOfficeMembers] = useState([]);

  const [timeFormat, setTimeFormat] = useState('1h5m');
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const toggle = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };
  const handleExportTimesheetDetails = () => {
    const payload = {
      organization_id: selectedOrganization ? selectedOrganization.id : '',
      start_date: startDate,
      end_date: endDate,
      time_format: timeFormat,
    };
    if (selectedProject && selectedProject.value) {
      payload.project_id = selectedProject.value;
    }
    if (selectedTask && selectedTask.value) {
      payload.task_id = selectedTask.value;
    }
    if (selectedMember && selectedMember.id !== '') {
      payload.user_id = selectedMember.id;
    }
    if (
      (selectedOrganization?.role === 'owner' ||
        selectedOrganization?.role === 'admin' ||
        selectedOrganization?.role === 'team_leader') &&
      !selectedMember
    ) {
      exportWeeklyTimeSheet(payload);
    }
    toggle();
  };

  const selectedOfficeMembers = v => {
    setOfficeMembers(v);
  };

  useEffect(() => {
    return () => {
      clearTimesheetList();
    };
  }, []);
  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      // setSelectedProject('');
      // setSelectedTask('');
      setSelectedMember('');

      const payload = {
        organization_id: selectedOrganization.id,
        start_date: startDate,
        end_date: endDate,
      };
      if (
        selectedOrganization.role === 'owner' ||
        selectedOrganization.role === 'admin'
      ) {
        getWeeklyTimesheetDetails(payload);
      } else {
        getOtherTimesheets(payload);
      }
      // setSelectedProject('');
      // setSelectedProject('');
      setSelectedMember('');
    }
  }, [selectedOrganization]);

  // useEffect(() => {
  //   if (props.projectAssigneeList && props.projectAssigneeList.length > 0) {
  //     let assigneeList = [];
  //     props.projectAssigneeList.map(assignee => {
  //       assigneeList.push({
  //         id: assignee.user.id,
  //         name: `${assignee.user.first_name}${
  //           assignee.user.last_name ? ` ${assignee.user.last_name}` : ``
  //         }`,
  //         avatar: assignee.user.avatar ? assignee.user.avatar : null,
  //       });
  //     });
  //     setProjectAssigneeList(assigneeList);
  //   } else if (
  //     props.projectAssigneeList &&
  //     props.projectAssigneeList.length === 0
  //   ) {
  //     setProjectAssigneeList([]);
  //   }
  // }, [props.projectAssigneeList]);

  // useEffect(() => {
  //   if (props.taskAssigneeList && props.taskAssigneeList.length > 0) {
  //     let assigneeList = [];
  //     props.taskAssigneeList.map(assignee => {
  //       assigneeList.push({
  //         id: assignee.id,
  //         name: `${assignee.first_name}${
  //           assignee.last_name ? ` ${assignee.last_name}` : ``
  //         }`,
  //         avatar: assignee.avatar ? assignee.avatar : null,
  //       });
  //     });
  //     setTaskAssigneeList(assigneeList);
  //   } else if (props.taskAssigneeList && props.taskAssigneeList.length === 0) {
  //     setTaskAssigneeList([]);
  //   }
  // }, [props.taskAssigneeList]);

  // const selectProject = e => {
  //   let organizationId = selectedOrganization ? selectedOrganization.id : '';
  //   if (organizationId) {
  //     const payload = {
  //       organization_id: organizationId,
  //       start_date: startDateOfWeek,
  //       end_date: endDateOfWeek,
  //     };
  //     if (selectedOrganization.role === 'member' || selectedMember) {
  //       payload.user_id = selectedMember.id;
  //     }
  //     if (e && e.value) {
  //       payload.project_id = e.value;
  //     }
  //     if (
  //       (selectedOrganization.role === 'owner' ||
  //         selectedOrganization.role === 'admin') &&
  //       !selectedMember
  //     ) {
  //       getWeeklyTimesheetDetails(payload);
  //     } else {
  //       getOtherTimesheets(payload);
  //     }
  //   }

  //   if (
  //     e &&
  //     e.value &&
  //     selectedProject &&
  //     selectedProject.value &&
  //     e.value !== selectedProject.value
  //   ) {
  //     setSelectedProject(e);
  //     setSelectedTask('');
  //   } else if ((e && !e.value) || e == null) {
  //     setSelectedProject(e);
  //     setSelectedTask('');
  //     setSelectedMember('');
  //   } else {
  //     setSelectedProject(e);
  //   }
  // };

  // const selectTask = e => {
  //   let organizationId = selectedOrganization ? selectedOrganization.id : '';
  //   if (organizationId) {
  //     const payload = {
  //       organization_id: organizationId,
  //       start_date: startDateOfWeek,
  //       end_date: endDateOfWeek,
  //     };
  //     if (selectedOrganization.role === 'member' || selectedMember) {
  //       payload.user_id = selectedMember.id;
  //     }
  //     if (e && e.value) {
  //       payload.task_id = e.value;
  //     }
  //     if (
  //       (selectedOrganization.role === 'owner' ||
  //         selectedOrganization.role === 'admin') &&
  //       !selectedMember
  //     ) {
  //       getWeeklyTimesheetDetails(payload);
  //     } else {
  //       getOtherTimesheets(payload);
  //     }
  //   }

  //   setSelectedTask(e);
  // };

  const selectTeamMember = e => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
        user_id: e.id,
      };
      // if (selectedProject && selectedProject.value) {
      //   payload.project_id = selectedProject.value;
      // }
      // if (selectedTask && selectedTask.value) {
      //   payload.task_id = selectedTask.value;
      // }
      setSelectedMember(e);
      getOtherTimesheets(payload);
    }
  };

  const clearMember = () => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
      };
      // if (selectedProject && selectedProject.value) {
      //   payload.project_id = selectedProject.value;
      // }
      // if (selectedTask && selectedTask.value) {
      //   payload.task_id = selectedTask.value;
      // }
      if (
        selectedOrganization.role === 'owner' ||
        selectedOrganization.role === 'admin'
      ) {
        getWeeklyTimesheetDetails(payload);
      } else {
        getOtherTimesheets(payload);
      }
    }
    setSelectedMember('');
  };

  const selectDate = date => {
    let organizationId = selectedOrganization ? selectedOrganization.id : '';
    setStartDate(moment(date).startOf('isoWeek').format('YYYY-MM-DD'));
    setEndDate(moment(date).endOf('isoWeek').format('YYYY-MM-DD'));
    const payload = {
      organization_id: organizationId,
      start_date: moment(date).startOf('isoWeek').format('YYYY-MM-DD'),
      end_date: moment(date).endOf('isoWeek').format('YYYY-MM-DD'),
    };
    // if (selectedProject && selectedProject.value) {
    //   payload.project_id = selectedProject.value;
    // }
    // if (selectedTask && selectedTask.value) {
    //   payload.task_id = selectedTask.value;
    // }
    if (selectedMember && selectedMember.id !== '') {
      payload.user_id = selectedMember.id;
    }
    if (
      (selectedOrganization.role === 'owner' ||
        selectedOrganization.role === 'admin') &&
      !selectedMember
    ) {
      getWeeklyTimesheetDetails(payload);
    } else {
      getOtherTimesheets(payload);
    }
  };

  const toggleDetailsPanel = value => {
    setDetailsPanelIsOpen(value);
    if (!value) {
      setDetailsUserData(null);
    }
  };

  const handleGetTimesheetDetails = (date, userId) => {
    if (selectedOrganization && selectedOrganization.id && date && userId) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: date.format('YYYY-MM-DD'),
        user_id: userId,
      };
      // if (selectedProject && selectedProject.value) {
      //   payload.project_id = selectedProject.value;
      // }
      // if (selectedTask && selectedTask.value) {
      //   payload.task_id = selectedTask.value;
      // }
      if (selectedOrganization.timezone) {
        payload.timezone = selectedOrganization.timezone;
      }
      getDailyTimesheets(payload);
      toggleDetailsPanel(true);
    }
  };

  const selectOffice = e => {
    const payload = {
      organization_id: selectedOrganization ? selectedOrganization.id : '',
    };
    if (e && e.value) {
      payload.office_id = e.value;
      getOfficeDetails(payload);
    }

    setSelectedMember('');
    // getDailyTimesheets(payload);
    setSelectedOffice(e);
  };
  const changeDateRange = e => {
    setStartDate(moment(e.startDate).format('YYYY-MM-DD'));
    setEndDate(moment(e.endDate).format('YYYY-MM-DD'));
  };
  const selectLastWeek = () => {
    setStartDate(
      moment().subtract(6, 'days').startOf('isoWeek').format('YYYY-MM-DD'),
    );
    setEndDate(
      moment().subtract(6, 'days').endOf('isoWeek').format('YYYY-MM-DD'),
    );
  };
  const selectCurrentWeek = () => {
    setStartDate(moment().startOf('isoWeek').format('YYYY-MM-DD'));
    setEndDate(moment().endOf('isoWeek').format('YYYY-MM-DD'));
  };

  useEffect(() => {
    const payload = {
      organization_id: selectedOrganization ? selectedOrganization.id : '',
      start_date: startDate,
      end_date: endDate,
    };
    if (selectedProject && selectedProject.value) {
      payload.project_id = selectedProject.value;
    }
    if (selectedTask && selectedTask.value) {
      payload.task_id = selectedTask.value;
    }
    if (selectedMember && selectedMember.id !== '') {
      payload.user_id = selectedMember.id;
    }
    if (
      (selectedOrganization?.role === 'owner' ||
        selectedOrganization?.role === 'admin' ||
        selectedOrganization?.role === 'team_leader') &&
      !selectedMember
    ) {
      getWeeklyTimesheetDetails(payload);
    } else {
      getOtherTimesheets(payload);
    }
  }, [startDate, endDate]);
  return (
    <div className="content">
      <RightSlidingPanel
        isOpen={detailsPanelIsOpen}
        closePanel={() => toggleDetailsPanel(false)}
        width="500px"
      >
        {dailyTimesheetLoading ? (
          <ComponentCircleLoader padding="35vh 0 " />
        ) : (
          <>
            <BackendExportModal
              isOpen={isExportModalOpen}
              toggle={toggle}
              excelExport={handleExportTimesheetDetails}
              title="Weekly Timesheet"
              timeFormat={timeFormat}
              setTimeFormat={setTimeFormat}
            />
            <TimesheetDetails
              timesheetsList={dailyTimesheets}
              userData={detailsUserData}
              selectedProject={selectedProject}
              selectedTask={selectedTask}
              fromPage={
                selectedOrganization &&
                (selectedOrganization.role === 'admin' ||
                  selectedOrganization.role === 'owner') &&
                !selectedMember
                  ? 'weeklyAdmin'
                  : 'other'
              }
              timezone={selectedOrganization && selectedOrganization.timezone}
            />
          </>
        )}
      </RightSlidingPanel>
      <CommonGrid alignItem="center">
        <PageTitle>Weekly Timesheet</PageTitle>
      </CommonGrid>
      <CommonFlex alignItems="end" justifyContent="space-between">
        <TimesheetFilter
          page="weekly"
          selectedDate={moment(startDate)}
          startDate={startDate}
          endDate={endDate}
          onDateChange={selectDate}
          // selectedProject={selectedProject}
          // selectProject={selectProject}
          // selectedTask={selectedTask}
          // selectTask={selectTask}
          selectedMember={selectedMember}
          selectMember={selectTeamMember}
          clearMember={clearMember}
          //office
          officeDetails={officeDetails}
          getOfficesShortList={getOfficesShortList}
          officesShortList={officesShortList}
          selectedOffice={selectedOffice}
          selectOffice={selectOffice}
          //Member Short List
          getOrganizationMembersList={getOrganizationMembersShortList}
          organizationMembersList={orgMembersShortList}
          selectedOrganization={selectedOrganization}
          //
          selectedOfficeMembers={selectedOfficeMembers}
          changeDateRange={changeDateRange}
          selectCurrentWeek={selectCurrentWeek}
          selectLastWeek={selectLastWeek}
        />
        {(selectedOrganization?.role === 'admin' ||
          selectedOrganization?.role === 'owner' ||
          selectedOrganization?.role === 'team_leader') &&
          !selectedMember && (
            <ExportButtonWithIcon onClick={() => toggle()}>
              <ExportButtonIcon src={ExportIcon} alt="" size="15px" />
              Export
            </ExportButtonWithIcon>
          )}
      </CommonFlex>
      <TimesheetContainer>
        {isLoading || officeDetailsLoading ? (
          <ComponentCircleLoader />
        ) : selectedOrganization &&
          (selectedOrganization.role === 'admin' ||
            selectedOrganization.role === 'owner') &&
          !selectedMember ? (
          <WeeklyAdminView
            selectedDate={moment(startDate)}
            timesheetsList={timesheetDetails}
            membersList={
              selectedOffice && selectedOffice.label !== '(All Offices)'
                ? officeMembers
                : orgMembersShortList
                ? orgMembersShortList
                : ''
            }
            getTimesheetDetails={handleGetTimesheetDetails}
            detailsUserData={detailsUserData}
            setDetailsUserData={setDetailsUserData}
          />
        ) : selectedOrganization ? (
          <WeeklyMemberView
            profileData={profileData}
            selectedDate={moment(startDate)}
            selectedMember={selectedMember}
            timesheetsList={timesheet}
            getTimesheetDetails={handleGetTimesheetDetails}
            detailsUserData={detailsUserData}
            setDetailsUserData={setDetailsUserData}
          />
        ) : null}
      </TimesheetContainer>
    </div>
  );
};

export default Weekly;

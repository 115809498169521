import Footer from 'components/Footer/Footer.jsx';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, useHistory, Route, Redirect } from 'react-router-dom';
import routes from 'routes.js';

import AdminNavbar from '../../custom_modules/AdminNavbar';
// import Sidebar from "components/Sidebar/Sidebar.jsx";
import Sidebar from '../../custom_modules/Sidebar';
import { addPath } from '../../filterReducer';
import { PrivateRoute } from '../../modules/privateRoute';

const Admin = props => {
  const dispatch = useDispatch();

  const [sidebarMini, setSidebarMini] = useState(false);
  const [navOpen, setNavOpen] = useState(false);

  const adminHistory = useHistory();
  const wrapperRef = useRef(null);

  const { selectedOrganization } = props;

  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.role !== 'admin' &&
      selectedOrganization.role !== 'owner' &&
      adminHistory?.location?.pathname === '/user/dashboard'
    ) {
      adminHistory.push('/user/jobs');
    }
  }, [selectedOrganization, adminHistory?.location?.pathname]);

  useEffect(() => {
    if (localStorage.getItem('selected_organization_role') === 'owner') {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = '99c3a618-53c4-44b5-8e95-dc28db5a70a7';
      (function () {
        var d = document;
        var s = d.createElement('script');
        s.src = 'https://client.crisp.chat/l.js';
        s.async = 1;
        d.getElementsByTagName('head')[0].appendChild(s);
      })();
      if (localStorage.getItem('user_fullName')) {
        window.$crisp.push([
          'set',
          'user:nickname',
          localStorage.getItem('user_fullName'),
        ]);
      }
      if (localStorage.getItem('email')) {
        window.$crisp.push([
          'set',
          'user:email',
          localStorage.getItem('email'),
        ]);
      }
    }
  }, []);

  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.plan &&
      selectedOrganization.plan.id
    ) {
      localStorage.setItem(
        'selected_organization_plan',
        selectedOrganization.plan.name,
      );
    }
  }, [selectedOrganization]);

  useEffect(() => {
    return adminHistory.listen((location, action) => {
      if (action && action === 'PUSH') {
        if (wrapperRef && wrapperRef.current) {
          document.documentElement.scrollTop = 0;
          document.scrollingElement.scrollTop = 0;
          wrapperRef.current.scrollTop = 0;
        }
      }
    });
  }, [adminHistory]);

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/user') {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            restricted={prop.restricted}
            selectedOrganization={selectedOrganization}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const handleMiniClick = () => {
    if (document.body.classList.contains('sidebar-mini')) {
      setSidebarMini(false);
    } else {
      setSidebarMini(true);
    }
    document.body.classList.toggle('sidebar-mini');
  };
  const handleNavOpen = () => {
    setNavOpen(!navOpen);
  };
  const clearNavOpen = () => {
    setNavOpen(false);
  };

  useEffect(() => {
    dispatch(addPath(location.pathname));
  }, [location.pathname, dispatch]);

  return (
    <div className="wrapper" ref={wrapperRef}>
      <Sidebar
        {...props}
        routes={routes}
        handleMiniClick={handleMiniClick}
        sidebarMini={sidebarMini}
        navOpen={navOpen}
        clearNavOpen={clearNavOpen}
      />
      <div className="main-panel">
        <AdminNavbar
          sidebarState={sidebarMini}
          {...props}
          handleMiniClick={handleMiniClick}
          handleNavOpen={handleNavOpen}
        />
        <Switch>
          {getRoutes(routes)}
          <Route path="/user/*">
            <Redirect to="/user/dashboard" />
          </Route>
        </Switch>
        {/* {// we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf("full-screen-map") !==
            -1 ? null : (
              <Footer fluid />
            )} */}
      </div>
    </div>
  );
};

export default Admin;

import ReactDateRangePicker from 'components/ReactDateRangePicker/ReactDateRangePicker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LeftArrow_3 from '../../../assets/img/icons/LeftArrow_3.svg';
import RightArrow_3 from '../../../assets/img/icons/RightArrow_3.svg';
import AddSingleMemberDropdown from '../../../components/CustomDropdown/AddSingleMemberDropdown';
import CreateProjectDropdown from '../../../components/DropdownStyle/CreateProjectDropdown';
import Select from '../../../components/ReactSelectDropdown';
import { filterOption } from '../../../components/ReactSelectDropdown/ReactSelectDropdown';
import SearchWithButton from '../../../components/Search/SearchWithButton';
import ClearFilterButton from '../../../components/Search/clearFilterButton';
import {
  FilterButton,
  TabButton,
  WhiteButton,
} from '../../../styledComponents/buttons';
import {
  CommonFlex,
  FilterLabel,
  IconArrow,
  InputWithLabelSection,
} from '../../../styledComponents/common';
import { TaskFilterContainer } from '../../../styledComponents/taskApproval';
import {
  TabButtonContainer,
  TabSearchGrid,
} from '../../../styledComponents/taskApproval';

const TaskFilter = props => {
  const { t } = useTranslation();
  const [officeOptions, setOfficeOptions] = useState([]);
  const [workOrderOptions, setWorkOrderOptions] = useState([]);

  const {
    selectedOrganization,
    getOrganizationMembersList,
    organizationMembersList,
    getOfficesShortList,
    officesShortList,
    selectedOffice,
    selectOffice,
    // workorder
    getWorkOrderShortList,
    workOrderShortList,
    selectedWorkOrder,
    selectWorkOrder,
    //
    selectedMember,
    clearMember,
    handleSearchByName,
    handleClearSearch,
    searchTerm,
    onSearchTermChange,
    handleSelectedTab,
    selectedTab,
    selectToday,
    singleDateChange,
    startDate,
    endDate,
    onDateSelect,
    selectLastWeek,
    selectLastMonth,
    changeDateRange,
    dateLabel,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      getWorkOrderShortList({ organization_id: selectedOrganization.id });
    }
  }, [selectedOrganization]);

  // useEffect(() => {
  //   // getOfficesShortList();
  //   getOrganizationMembersList({ office_id: null });
  // }, []);

  // useEffect(() => {
  //   if (officesShortList && officesShortList.length === 1) {
  //     getOrganizationMembersList({ office_id: officesShortList[0].id });
  //   }
  //   if (officesShortList && officesShortList.length > 1) {
  //     let newArr = [
  //       {
  //         label: `(${t('all_offices')})`,
  //         value: null,
  //       },
  //     ];
  //     officesShortList.forEach((office, i) => {
  //       newArr.push({
  //         label: office.name,
  //         value: office.id,
  //       });
  //     });
  //     setOfficeOptions(newArr);
  //   }
  // }, [officesShortList]);

  useEffect(() => {
    if (workOrderShortList && workOrderShortList.length > 0) {
      let newArr = [
        {
          label: `(${t('all_workorders')})`,
          value: null,
        },
      ];
      workOrderShortList.forEach((workorder, i) => {
        newArr.push({
          label: workorder.name,
          value: workorder.id,
        });
      });
      setWorkOrderOptions(newArr);
    }
  }, [workOrderShortList]);

  // useEffect(() => {
  //   if (
  //     selectedOffice &&
  //     selectedOffice.value &&
  //     officesShortList &&
  //     officesShortList.length > 1
  //   ) {
  //     getOrganizationMembersList({ office_id: selectedOffice.value });
  //   }
  // }, [selectedOffice]);

  const onMemberSelect = person => {};

  return (
    <TaskFilterContainer columns="auto minmax(210px, 240px) minmax(220px, 240px) minmax(344px, 354px)">
      <TabSearchGrid>
        <InputWithLabelSection>
          <FilterLabel>{t('type')}</FilterLabel>
          <TabButtonContainer columns="auto auto auto">
            <TabButton
              selected={selectedTab}
              index={'submitted'}
              onClick={() => handleSelectedTab('submitted')}
            >
              {t('submitted')}
            </TabButton>
            <TabButton
              selected={selectedTab}
              index={'approved'}
              onClick={() => handleSelectedTab('approved')}
            >
              {t('approved')}
            </TabButton>
            <TabButton
              selected={selectedTab}
              index={'rejected'}
              onClick={() => handleSelectedTab('rejected')}
            >
              {t('rejected')}
            </TabButton>
          </TabButtonContainer>
        </InputWithLabelSection>
      </TabSearchGrid>
      <InputWithLabelSection>
        <FilterLabel>{t('work_order')}</FilterLabel>
        <Select
          isSearchable
          isClearable={selectedWorkOrder?.value}
          value={selectedWorkOrder}
          options={workOrderOptions}
          placeholder="Select WorkOrder"
          onChange={e => {
            e
              ? selectWorkOrder(e)
              : selectWorkOrder({
                  label: `(${t('all_workorders')})`,
                  value: null,
                });
          }}
          styles={CreateProjectDropdown()}
          filterOption={filterOption}
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator: props => {
              return <ClearFilterButton {...props} />;
            },
          }}
        />
      </InputWithLabelSection>
      {/* <InputWithLabelSection>
        <FilterLabel>{t("assignee")}</FilterLabel>
        <div style={{ width: `40px` }}>
          <AddSingleMemberDropdown
            assigneeManage={selectedMember}
            updateState={onMemberSelect}
            membersList={organizationMembersList}
            clearAssignee={() => clearMember()}
          />
        </div>
      </InputWithLabelSection> */}
      <SearchWithButton
        searchTermName={t('job_name')}
        searchInput={searchTerm}
        onInputChange={onSearchTermChange}
        handleSearch={handleSearchByName}
        handleClearSearch={handleClearSearch}
      />

      <CommonFlex gap="4px" alignItems="end">
        <InputWithLabelSection>
          <FilterLabel>
            {t('date_range')}
            {` (${dateLabel})`}
          </FilterLabel>
          <ReactDateRangePicker
            startDate={startDate ? moment(startDate) : null}
            startDateId="report-date-range-start-date"
            endDate={endDate ? moment(endDate) : null}
            endDateId="report-date-range-end-date"
            onDatesChange={changeDateRange}
            dateDisplayFormat="DD/MM/YYYY"
            onDateSelect={onDateSelect}
            selectLastWeek={selectLastWeek}
            selectLastMonth={selectLastMonth}
            rangeRestricted={true}
            disableFutureDates={true}
          />
        </InputWithLabelSection>

        <IconArrow
          onClick={() => {
            singleDateChange('left');
          }}
          padding="5px"
          size="39px"
          src={LeftArrow_3}
        />
        <IconArrow
          onClick={() => {
            moment(endDate).isBefore(moment().subtract(1, 'day')) &&
              singleDateChange('right');
          }}
          disabled={!moment(endDate).isBefore(moment().subtract(1, 'day'))}
          padding="5px"
          size="39px"
          src={RightArrow_3}
        />
        <WhiteButton
          type="nav"
          style={{
            height: '38px',
            padding: '0px 15px',
          }}
          onClick={selectToday}
        >
          Today
        </WhiteButton>
      </CommonFlex>
    </TaskFilterContainer>
  );
};

export default TaskFilter;

import { useState, useEffect } from "react";
import {
  ToggleSwitchContainer,
  ToggleSwitchWrapper,
  Slider,
} from "./pricingPlanStyles";

export default function ToggleSwitch(props) {
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (props.checked !== null) {
      setActive(props.checked);
    }
  }, [props.checked]);
  const handleToggle = () => {
    if (props.toggleAction) {
      props.toggleAction();
    }
    setActive(!active);
  };
  return (
    <ToggleSwitchContainer>
      <ToggleSwitchWrapper>
        <Slider active={active} onClick={() => handleToggle()} />
      </ToggleSwitchWrapper>
    </ToggleSwitchContainer>
  );
}

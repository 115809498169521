import moment from 'moment';
import { useEffect, useState } from 'react';

import { TabButton, TabButtonContainer } from '../../styledComponents/buttons';
import {
  ApprovalFilterContainer,
  Container,
  HorizontalContainer,
  PageTitle,
} from '../../styledComponents/common';
import ApprovalFilter from './ApprovalFilter/filterContainer';
import ApprovalHistory from './ApprovalHistory/historyContainer';
import AwaitingApprovals from './AwaitingApprovals/awaitingApprovalsContainer';

const TimesheetApproval = props => {
  const [selectedTab, setSelectedTab] = useState('awaiting');

  const [startDate, setStartDate] = useState(moment().startOf('isoWeek').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('isoWeek').format('YYYY-MM-DD'));
  const [dateLabel, setDateLabel] = useState('');
  const [dateChanged, setDateChanged] = useState(false);

  const [sortType, setSortType] = useState({
    value: 'oldToNew',
    label: 'Date (Old to New)',
  });

  // const [selectedProject, setSelectedProject] = useState("");
  const [selectedMember, setSelectedMember] = useState(null);

  const {
    selectedOrganization,
    getTimesheetApprovalAwaitingList,
    getTimesheetApprovalHistory,

    // pagination
    paginationSize,
    loadMoreTimesheetApprovalList,
    clearLoadMoreTimesheetApprovalList,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      clearFilters();
    }

    return () => {
      clearLoadMoreTimesheetApprovalList();
    };
  }, [selectedOrganization]);

  useEffect(() => {
    clearFilters();
  }, [selectedTab]);

  const clearFilters = () => {
    setSelectedMember('');
    setSortType({
      value: 'oldToNew',
      label: 'Date (Old to New)',
    });
  };

  const changeDateRange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };
  const getDateLabel = () => {
    const diff = Math.ceil(
      moment(endDate).diff(moment(startDate), 'days', true),
    );
    if (
      diff === 0 &&
      moment().format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD')
    ) {
      return 'Today selected';
    }
    return diff + 1 + ' day' + (diff > 0 ? 's' : '') + ' ' + 'selected';
  };
  const selectLastWeek = () => {
    console.log("in select last week");
    setStartDate(moment().subtract(6, 'days'));
    setEndDate(moment());
  };
  const selectLastMonth = () => {
    setStartDate(moment().subtract(29, 'days'));
    setEndDate(moment());
  };
  const singleDateChange = direction => {
    if (direction === 'right') {
      setStartDate(moment(endDate).add(1, 'days'));
      setEndDate(moment(endDate).add(1, 'days'));
    } else {
      setStartDate(moment(startDate).subtract(1, 'days'));
      setEndDate(moment(startDate).subtract(1, 'days'));
    }
    setDateChanged(!dateChanged);
  };
  const selectToday = () => {
    setStartDate(moment());
    setEndDate(moment());
    setDateChanged(!dateChanged);
  };

  // const onDateSelect = () => {
  //   if (selectedOrganization && selectedOrganization.id) {
  //     const payload = {
  //       organization_id: selectedOrganization.id,
  //       start_date: moment(startDate).format('YYYY-MM-DD'),
  //       end_date: moment(endDate).format('YYYY-MM-DD'),
  //       offset: 0,
  //       limit: paginationSize,
  //     };
  //     if (selectedMember && selectedMember.id) {
  //       payload.user_id = selectedMember.id;
  //     }
  //     if (selectedTab === 'awaiting') {
  //       getTimesheetApprovalAwaitingList(payload);
  //     } else {
  //       getTimesheetApprovalHistory(payload);
  //     }
  //     setDateChanged(!dateChanged);
  //   }
  // };

  useEffect(() => {
    if (startDate) {
      if (!endDate) {
        setEndDate(startDate);
      }

      const maxEndDate = moment(startDate).add(3, 'months');
      if (
        endDate &&
        moment(endDate).diff(moment(startDate), 'months', true) > 3
      ) {
        setEndDate(maxEndDate);
      }

      if (startDate && endDate) {
        setDateLabel(getDateLabel());
      }
    }
  }, [startDate, endDate]);

  const handleDateChange = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        offset: 0,
        limit: paginationSize,
      };
      if (selectedMember && selectedMember.id) {
        payload.user_id = selectedMember.id;
      }
      if (selectedTab === 'awaiting') {
        getTimesheetApprovalAwaitingList(payload);
      } else {
        getTimesheetApprovalHistory(payload);
      }
      setSelectedMember(null);
    }
  };

  useEffect(() => {
    handleDateChange();
  }, [dateChanged]);

  const handleSeeMore = (date, currentOffset, type) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        start_date: moment(date).format('YYYY-MM-DD'),
        end_date: moment(date).format('YYYY-MM-DD'),
        offset: currentOffset,
        limit: paginationSize,
        type,
      };
      loadMoreTimesheetApprovalList(payload);
    }
  };

  const handleTabSelect = tabName => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        offset: 0,
        limit: paginationSize,
      };
      if (tabName === 'awaiting') {
        getTimesheetApprovalAwaitingList(payload);
      } else {
        getTimesheetApprovalHistory(payload);
      }
    }
    setSelectedTab(tabName);
  };

  const changeSortType = type => {
    setSortType(type);
  };

  return (
    <div className="content">
      <PageTitle>Timesheet Approval</PageTitle>

      <HorizontalContainer>
        <TabButtonContainer width="max-content" margin="30px 0 0 0">
          <TabButton
            selected={selectedTab}
            index={'awaiting'}
            onClick={() => handleTabSelect('awaiting')}
          >
            Awaiting
          </TabButton>
          <TabButton
            selected={selectedTab}
            index={'history'}
            onClick={() => handleTabSelect('history')}
          >
            History
          </TabButton>
        </TabButtonContainer>

        <ApprovalFilterContainer>
          <ApprovalFilter
            selectedTab={selectedTab}
            startDate={startDate}
            endDate={endDate}
            dateLabel={dateLabel}
            singleDateChange={singleDateChange}
            selectToday={selectToday}
            onDateSelect={() => {
              setDateChanged(!dateChanged);
            }}
            changeDateRange={changeDateRange}
            selectLastWeek={selectLastWeek}
            selectLastMonth={selectLastMonth}
            sortType={sortType}
            changeSortType={changeSortType}
            selectedMember={selectedMember}
            setSelectedMember={setSelectedMember}
            disableCurrentAndFutureDates
          />
        </ApprovalFilterContainer>
      </HorizontalContainer>

      {selectedTab && selectedTab === 'history' ? (
        <ApprovalHistory
          startDate={startDate}
          endDate={endDate}
          handleSeeMore={handleSeeMore}
        />
      ) : (
        <AwaitingApprovals
          startDate={startDate}
          endDate={endDate}
          handleSeeMore={handleSeeMore}
        />
      )}
    </div>
  );
};

export default TimesheetApproval;

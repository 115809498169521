import { connect } from 'react-redux';

import {
  getOrganizationMembersList,
  getOrganizationMembersShortList,
} from '../../Projects/projectsActions';
// Actions
import {
  addOfficeLocation,
  addOfficeMember,
  addOrUpdateOfficeLocation,
  clearOfficeCreatedUpdated,
  clearOfficeDetails,
  createOffice,
  deleteOffice,
  getOfficeDetails,
  getOfficeMembersList,
  removeOfficeMember,
  setOfficeDetails,
  updateOffice,
  updateOfficeInfo,
  updateOfficeMember,
} from '../officesActions';
// Component
import OfficeDetails from './OfficeDetails';

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  orgMembersList: state.project.orgMembersList,
  orgMembersShortList: state.project.orgMembersShortList,
  officeMembersList: state.office.officeMembersList,
  officeMemberListLoading: state.office.officeMemberListLoading,
  memberShortListIsLoading: state.project.memberShortListIsLoading,
  officeMemberIsLoading: state.office.officeMemberIsLoading,
  isLoading: state.office.isLoading,
  createdOfficeId: state.office.createdOfficeId,
  officeDetails: state.office.officeDetails,
  officeCreateUpdateSuccess: state.office.officeCreateUpdateSuccess,
  addOrUpdateOfficeLoading: state.office.addOrUpdateOfficeLoading,
  loadingMemberId: state.office.loadingMemberId,
  officeLocation: state.office.officeLocation,
  officeLocationCreateUpdateSuccess:
    state.office.officeLocationCreateUpdateSuccess,
  addOrUpdateOfficeLocalMapLoading:
    state.office.addOrUpdateOfficeLocalMapLoading,
  createdOfficeLocationInfo: state.office.createdOfficeLocationInfo,
  officeLocationCreateUpdateSuccess:
    state.office.officeLocationCreateUpdateSuccess,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getOrganizationMembersList: payload =>
    dispatch(getOrganizationMembersList(payload)),
  getOrganizationMembersShortList: params =>
    dispatch(getOrganizationMembersShortList(params)),
  getOfficeMembersList: payload => dispatch(getOfficeMembersList(payload)),
  addOfficeMember: payload => dispatch(addOfficeMember(payload)),
  removeOfficeMember: payload => dispatch(removeOfficeMember(payload)),
  setOfficeDetails: params => dispatch(setOfficeDetails(params)),
  clearOfficeCreatedUpdated: () => dispatch(clearOfficeCreatedUpdated()),
  createOffice: payload => dispatch(createOffice(payload)),
  updateOffice: payload => dispatch(updateOffice(payload)),
  clearOfficeDetails: () => dispatch(clearOfficeDetails()),
  updateOfficeMember: payload => dispatch(updateOfficeMember(payload)),
  getOfficeDetails: payload => dispatch(getOfficeDetails(payload)),
  updateOfficeInfo: payload => dispatch(updateOfficeInfo(payload)),
  addOrUpdateOfficeLocation: payload =>
    dispatch(addOrUpdateOfficeLocation(payload)),
  deleteOffice: params => dispatch(deleteOffice(params)),
  addOfficeLocation: payload => dispatch(addOfficeLocation(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfficeDetails);

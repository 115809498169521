import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import evidence from "../../../assets/img/screenshot_1.svg";
import BottomPagination from "../../../components/Pagination/BottomPagination/BottomPagination";
import TopPagination from "../../../components/Pagination/TopPagination/TopPagination";
import PricingPlan from "../../../components/PricingPlan/PricingPlan";
import { HeaderContainer, PageTitle } from "../../../styledComponents/common";
import ApprovalTaskList from "./ApprovalTaskList";
import EvidenceModal from "./EvidenceModal";
import TaskFilter from "./TaskFilter";

const JobApproval = (props) => {
  const { t, i18n } = useTranslation();

  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState("");
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [selectedTab, setSelectedTab] = useState("submitted");
  const [pricingPopup, setPricingPopup] = useState(false);

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState("");
  const [expandedTaskId, setExpandedTaskId] = useState(null);
  const [evidenceImage, setEvidenceImage] = useState(null);

  const [selectedWorkOrder, setSelectedWorkOrder] = useState({
    label: `(${t("all_workorders")})`,
    value: null,
  });

  // SingleDate -> DateRange
  const [startDate, setStartDate] = useState(
    moment().startOf("isoWeek").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("isoWeek").format("YYYY-MM-DD")
  );
  const [dateLabel, setDateLabel] = useState("");
  const [dateChanged, setDateChanged] = useState(false);

  const {
    selectedOrganization,
    getOrganizationMembersList,
    orgMembersList,
    loadingTaskAssigneeId,
    isLoading,
    officesShortList,
    getOfficesShortList,
    getTasksList,
    updateTaskApproval,
    createTask,
    totalTasksCount,
    pageSize,
    history,
    //jobs
    getjobsList,
    jobList,
    isLoadingJobs,
    totaljobsCount,
    pageSizeJob,
    //workorder short list
    getWorkOrderShortList,
    workOrderShortList,
    //
    updateJobApproval,
  } = props;

  // SingleDate -> DateRange
  const selectLastWeek = () => {
    setStartDate(moment().subtract(6, "days").format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
  };
  const selectLastMonth = () => {
    setStartDate(moment().subtract(29, "days").format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
  };
  const changeDateRange = (dates) => {
    setStartDate(dates.startDate);
    setEndDate(dates.endDate);
  };
  const getDateLabel = () => {
    const diff = Math.ceil(
      moment(endDate).diff(moment(startDate), "days", true)
    );
    if (
      diff === 0 &&
      moment().format("YYYY-MM-DD") === moment(startDate).format("YYYY-MM-DD")
    ) {
      return "Today selected";
    }
    return diff + 1 + " day" + (diff > 0 ? "s" : "") + " " + "selected";
  };
  const singleDateChange = (direction) => {
    if (direction === "right") {
      setStartDate(moment(endDate).add(1, "days"));
      setEndDate(moment(endDate).add(1, "days"));
    } else {
      setStartDate(moment(startDate).subtract(1, "days"));
      setEndDate(moment(startDate).subtract(1, "days"));
    }
    setDateChanged(!dateChanged);
  };
  const selectToday = () => {
    setStartDate(moment().format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
    setDateChanged(!dateChanged);
  };

  useEffect(() => {
    if (startDate) {
      if (!endDate) {
        setEndDate(startDate);
      }

      const maxEndDate = moment(startDate).add(3, "months");
      if (
        endDate &&
        moment(endDate).diff(moment(startDate), "months", true) > 3
      ) {
        setEndDate(maxEndDate);
      }

      if (startDate && endDate) {
        setDateLabel(getDateLabel());
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      getjobsList({
        organization_id: selectedOrganization.id,
        paginate: true,
        start_date: moment().startOf("isoWeek").format("YYYY-MM-DD"),
        end_date: moment().endOf("isoWeek").format("YYYY-MM-DD"),
        approval_status: "pending",
      });
      setSelectedWorkOrder({
        label: `(${t("all_workorders")})`,
        value: null,
      });
    }
    if (
      selectedOrganization &&
      selectedOrganization.plan &&
      selectedOrganization.plan.name === "Standard"
    ) {
      pricingPopupToggle();
    }
  }, [selectedOrganization]);

  useEffect(() => {
    setSelectedOffice({
      label: t("All WorkOrders"),
      value: null,
    });
    // getTasksList({
    //   date: selectedDate.format("YYYY-MM-DD"),
    //   approval_status: "pending",
    // });
  }, []);

  const handleSearchByName = () => {
    const payload = {
      paginate: true,
      page: 1,
      approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
    };
    if (searchTerm && searchTerm.trim()) {
      payload.job_name = searchTerm.toLowerCase().trim();
      setCurrentSearchedTerm(searchTerm);
    } else {
      setCurrentSearchedTerm("");
    }
    if (selectedWorkOrder && selectedWorkOrder.value) {
      payload.work_order_id = selectedWorkOrder.value;
    }

    payload.start_date = moment(startDate).format("YYYY-MM-DD");
    payload.end_date = moment(endDate).format("YYYY-MM-DD");

    if (selectedOrganization && selectedOrganization.id) {
      payload.organization_id = selectedOrganization.id;
      getjobsList(payload);
    }
    setCurrentPageNumber(1);
  };

  const handleClearSearch = () => {
    if (currentSearchedTerm) {
      const payload = {
        paginate: true,
        page: 1,
        approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
      };
      if (selectedWorkOrder && selectedWorkOrder.value) {
        payload.work_order_id = selectedWorkOrder.value;
      }

      payload.start_date = moment(startDate).format("YYYY-MM-DD");
      payload.end_date = moment(endDate).format("YYYY-MM-DD");

      if (selectedOrganization && selectedOrganization.id) {
        payload.organization_id = selectedOrganization.id;
        getjobsList(payload);
      }
      setCurrentPageNumber(1);
    }
    setTimeout(() => {
      setSearchTerm("");
      setCurrentSearchedTerm("");
    }, 100);
  };

  const handlePageChange = (page) => {
    const payload = {
      organization_id:
        selectedOrganization && selectedOrganization.id
          ? selectedOrganization.id
          : "",
      paginate: true,
      page,
      approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
    };
    if (selectedWorkOrder && selectedWorkOrder.value) {
      payload.work_order_id = selectedWorkOrder.value;
    }
    if (searchTerm && searchTerm.trim()) {
      payload.job_name = searchTerm.toLowerCase().trim();
    }

    payload.start_date = moment(startDate).format("YYYY-MM-DD");
    payload.end_date = moment(endDate).format("YYYY-MM-DD");

    getjobsList(payload);
    setCurrentPageNumber(page);
  };

  const selectOffice = (e) => {
    const payload = {
      paginate: true,
      page: 1,
      approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
    };
    if (e && e.value) {
      payload.office_id = e.value;
    }
    if (selectedMember && selectedMember.id) {
      payload.assignee_id = selectedMember.id;
    }

    payload.start_date = moment(startDate).format("YYYY-MM-DD");
    payload.end_date = moment(endDate).format("YYYY-MM-DD");

    getTasksList(payload);
    setCurrentPageNumber(1);
    setSearchTerm("");
    setCurrentSearchedTerm("");
    setSelectedOffice(e);
  };

  const selectWorkOrder = (e) => {
    const payload = {
      paginate: true,
      page: 1,
      approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
    };
    if (e && e.value) {
      payload.work_order_id = e.value;
    }
    // if (searchTerm && searchTerm.trim()) {
    //   payload.job_name = searchTerm.toLowerCase().trim();
    // }

    payload.start_date = moment(startDate).format("YYYY-MM-DD");
    payload.end_date = moment(endDate).format("YYYY-MM-DD");

    if (selectedOrganization && selectedOrganization.id) {
      payload.organization_id = selectedOrganization.id;
      getjobsList(payload);
    }

    setCurrentPageNumber(1);
    setSearchTerm("");
    setCurrentSearchedTerm("");
    setSelectedWorkOrder(e);
  };

  const selectMember = (person) => {
    if (person.id) {
      const payload = {
        assignee_id: person.id,
        paginate: true,
        page: 1,
        approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
      };
      if (selectedOffice && selectedOffice.value) {
        payload.office_id = selectedOffice.value;
      }

      payload.start_date = moment(startDate).format("YYYY-MM-DD");
      payload.end_date = moment(endDate).format("YYYY-MM-DD");

      if (selectedOrganization && selectedOrganization.id) {
        payload.organization_id = selectedOrganization.id;
        getjobsList(payload);
      }

      // getTasksList(payload);
      setSelectedMember(person);
      setSearchTerm("");
      setCurrentSearchedTerm("");
      setCurrentPageNumber(1);
    }
  };

  const clearMember = () => {
    const payload = {
      paginate: true,
      page: 1,
      approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
    };
    if (selectedOffice && selectedOffice.value) {
      payload.office_id = selectedOffice.value;
    }

    payload.start_date = moment(startDate).format("YYYY-MM-DD");
    payload.end_date = moment(endDate).format("YYYY-MM-DD");

    if (selectedOrganization && selectedOrganization.id) {
      payload.organization_id = selectedOrganization.id;
      getjobsList(payload);
    }

    // getTasksList(payload);
    setSearchTerm("");
    setCurrentSearchedTerm("");
    setCurrentPageNumber(1);
    setSelectedMember("");
  };

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    if (value !== "") {
      setSelectedMember("");
    }
    setSearchTerm(value);
  };

  const handleDateChange = () => {
    const payload = {
      paginate: true,
      page: 1,
      approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
    };

    if (selectedWorkOrder && selectedWorkOrder.value) {
      payload.work_order_id = selectedWorkOrder.value;
    }
    if (searchTerm && searchTerm.trim()) {
      payload.job_name = searchTerm.toLowerCase().trim();
      setCurrentSearchedTerm(searchTerm);
    } else {
      setCurrentSearchedTerm("");
    }

    if (selectedMember && selectedMember.id) {
      payload.assignee_id = selectedMember.id;
    }

    // Ensure both startDate and endDate are set
    if (startDate) {
      payload.start_date = moment(startDate).format("YYYY-MM-DD");
    }
    if (endDate) {
      payload.end_date = moment(endDate).format("YYYY-MM-DD");
    }

    if (selectedOrganization && selectedOrganization.id) {
      payload.organization_id = selectedOrganization.id;
      getjobsList(payload);
    }
    setCurrentPageNumber(1);
  };

  useEffect(() => {
    handleDateChange();
  }, [dateChanged]);

  const popupToggle = () => {
    setPopupOpen(!popupOpen);
  };

  const pricingPopupToggle = () => {
    setPricingPopup(!pricingPopup);
  };

  const handleSelectedTab = (selectedTab) => {
    const payload = {
      paginate: true,
      page: 1,
      approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
    };
    if (searchTerm && searchTerm.trim()) {
      payload.job_name = searchTerm.toLowerCase().trim();
      setCurrentSearchedTerm(searchTerm);
    } else {
      setCurrentSearchedTerm("");
    }
    if (selectedWorkOrder && selectedWorkOrder.value) {
      payload.work_order_id = selectedWorkOrder.value;
    }
    payload.start_date = moment(startDate).format("YYYY-MM-DD");
    payload.end_date = moment(endDate).format("YYYY-MM-DD");
    if (selectedOrganization && selectedOrganization.id) {
      payload.organization_id = selectedOrganization.id;
      getjobsList(payload);
    }
    setCurrentPageNumber(1);
    setSelectedTab(selectedTab);
  };

  const updateTaskStatusRequest = (
    task_id,
    approval,
    status,
    supervisorNote
  ) => {
    const details = {
      status,
    };
    if (supervisorNote.trim()) {
      details.supervisor_note = supervisorNote;
    }
    const payload = {
      job_id: task_id,
      approval_id: approval.id,
      details,
    };
    if (selectedOrganization && selectedOrganization.id) {
      payload.organization_id = selectedOrganization.id;
      updateJobApproval(payload);
    }
    setSearchTerm("");
    // setTimeout(() => {
    //   refreshTaskComponent();
    // }, 350);
  };

  // const refreshTaskComponent = () => {
  //   const payload = {
  //     date: selectedDate.format("YYYY-MM-DD"),
  //     paginate: true,
  //     page: 1,
  //     approval_status: selectedTab === "submitted" ? "pending" : selectedTab,
  //   };
  //   if (searchTerm && searchTerm.trim()) {
  //     payload.task_name = searchTerm.toLowerCase().trim();
  //     setCurrentSearchedTerm(searchTerm);
  //   } else {
  //     setCurrentSearchedTerm("");
  //   }
  //   if (selectedOffice && selectedOffice.value) {
  //     payload.office_id = selectedOffice.value;
  //   }
  //   if (selectedMember && selectedMember.id) {
  //     payload.assignee_id = selectedMember.id;
  //   }

  //   getTasksList(payload);
  //   setCurrentPageNumber(1);
  // };

  return (
    <div className="content">
      <PricingPlan
        isOpen={pricingPopup}
        toggle={pricingPopupToggle}
        popupText="You don't have permission to this feature."
        popupTitle="Upgrade to Elite to access this feature"
        history={history}
        backRoute="/user/dashboard"
        onlyElite
      />
      <PageTitle>{t("submitted_jobs_for_apprroval")}</PageTitle>
      <HeaderContainer>
        <TaskFilter
          selectedOrganization={selectedOrganization}
          getOrganizationMembersList={getOrganizationMembersList}
          organizationMembersList={orgMembersList}
          //
          officesShortList={officesShortList}
          getOfficesShortList={getOfficesShortList}
          selectedOffice={selectedOffice}
          selectOffice={selectOffice}
          //workorder
          getWorkOrderShortList={getWorkOrderShortList}
          workOrderShortList={workOrderShortList}
          selectedWorkOrder={selectedWorkOrder}
          selectWorkOrder={selectWorkOrder}
          //
          selectedMember={selectedMember}
          selectMember={selectMember}
          clearMember={clearMember}
          searchTerm={searchTerm}
          onSearchTermChange={onSearchTermChange}
          handleSearchByName={handleSearchByName}
          handleClearSearch={handleClearSearch}
          handleSelectedTab={handleSelectedTab}
          selectedTab={selectedTab}
          startDate={startDate}
          endDate={endDate}
          dateLabel={dateLabel}
          singleDateChange={singleDateChange}
          selectToday={selectToday}
          changeDateRange={changeDateRange}
          onDateSelect={() => {
            setDateChanged(!dateChanged);
          }}
          selectLastWeek={selectLastWeek}
          selectLastMonth={selectLastMonth}
        />
      </HeaderContainer>
      {/* handle page change need to be changed */}
      {totaljobsCount && totaljobsCount > 0 && pageSizeJob ? (
        <TopPagination
          itemName="jobs"
          totalCount={totaljobsCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={pageSizeJob}
        />
      ) : null}
      <ApprovalTaskList
        selectedOrganization={selectedOrganization}
        loadingTaskAssigneeId={loadingTaskAssigneeId}
        expandedTaskId={expandedTaskId}
        setExpandedTaskId={setExpandedTaskId}
        history={history}
        jobList={jobList}
        isLoading={isLoadingJobs}
        createTask={createTask}
        popupOpen={popupOpen}
        setPopupOpen={setPopupOpen}
        handlePageChange={handlePageChange}
        updateTaskStatusRequest={updateTaskStatusRequest}
        selectedTab={selectedTab}
      />
      {totaljobsCount && totaljobsCount > 0 && pageSizeJob ? (
        <BottomPagination
          totalCount={totaljobsCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={pageSizeJob}
        />
      ) : null}
    </div>
  );
};

export default JobApproval;

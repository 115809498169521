import { AuthLinkOpener } from "./authLinkOpener";

import { executeClickupSteps } from "./clickupWorkflow";
import { executeAsanaSteps } from "./asanaWorkflow";

export class AuthHandler {
  constructor(authData, otherData) {
    this.authData = authData;
    this.otherData = otherData;
  }

  init() {
    if (this.authData && this.authData.length > 0) {
      const linkOpener = new AuthLinkOpener(this.authData[0]);
      linkOpener.open();
    }
  }

  async run() {
    // if (this.authData && this.authData.length > 0) {
    //   const [link, ...others] = this.authData;
    //   if (others && others.length > 0) {
    //     // generate the promise chain
    //   }
    // }

    if (this.otherData && this.otherData.integration_name) {
      switch (this.otherData.integration_name) {
        case "clickup":
          return await executeClickupSteps(this.otherData);
        case "asana":
          return await executeAsanaSteps(this.otherData);
        default:
          return false;
      }
    }
  }
}

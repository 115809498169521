import queryString from 'query-string';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import GoHighLevel from '../../../assets/img/GoHighLevel.png';
import HighLevel from '../../../assets/img/highlevel.png';
import arrowRight from '../../../assets/img/icons/arrow-right.svg';
import logo from '../../../assets/img/logo/logo.svg';
import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import {
  CardTitle,
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
  Container,
  ContentHeader,
  FirstWordRound,
  PageTitle,
  TableText,
} from '../../../styledComponents/common';
import {
  IdleTimeCollapseContent,
  IdleTimeDescription,
  IdleTimeInput,
  OrgSettingsSection,
  ScreenshotFrequencyButton,
  SettingPropertySection,
  ToggleButton,
  ToggleButtonRound,
  WarningDiv,
} from '../../Settings/ActivitySettings/activitySettingsStyles';

const GHLSettings = props => {
  const {
    selectedOrganization,
    getIntegrationsFeatures,
    updateIntegrationsFeatures,
    featuresData,
    isLoadingFeatures,
  } = props;

  const customValues = {
    customer: 'Customer  ➤  Contact',
    job: 'Jobs  ➤  Task',
    calendar: 'Calendar  ➤  Schedule',
    schedule: 'Schedule  ➤  Appointment',
  };

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      getIntegrationsFeatures({
        organization_id: selectedOrganization.id,
        name: 'GHL',
      });
    }
  }, [selectedOrganization]);

  const updateFeatureFSToHL = (value, feature) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      feature &&
      feature.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        id: feature.id,
        name: 'GHL',
        fieldservicely_create: value,
        fieldservicely_update: value,
        fieldservicely_delete: value,
      };

      updateIntegrationsFeatures(payload);
    }
  };

  const updateFeatureHLToFS = (value, feature) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      feature &&
      feature.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        id: feature.id,
        name: 'GHL',
        create_from_webhook: value,
        update_from_webhook: value,
        delete_from_webhook: value,
      };
      updateIntegrationsFeatures(payload);
      // console.log('payload ', payload);
    }
  };

  return (
    <div className="content">
      <PageTitle>HighLevel</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => props.history.push('/user/integrations')}
          subTitle="Back to all Integrations"
        />
      </ContentHeader>
      <Container style={{ padding: `30px` }}>
        {selectedOrganization &&
        (selectedOrganization.role === 'owner' ||
          selectedOrganization.role === 'admin') &&
        selectedOrganization.ghl_id ? (
          <>
            <CardTitle>HighLevel Settings</CardTitle>
            <Container style={{ padding: `30px` }}>
              {isLoadingFeatures ? (
                <ComponentCircleLoader />
              ) : (
                featuresData &&
                featuresData
                  // .filter(feature =>
                  //   ['customer', 'appointment'].includes(feature.feature_name),
                  // ) // Filter data for the "customer" and "appointment" feature
                  .map((feature, index) => (
                    <Fragment key={index}>
                      <div style={{ marginTop: index > 0 ? '30px' : 0 }}>
                        <CommonText name title margin="10px 0 10px 0">
                          {customValues[feature.feature_name]}
                        </CommonText>
                        <SettingPropertySection margin="10px 0 0 0">
                          <CommonGrid columns="35% 65%" gap="2em">
                            <CommonText label fontWeight="500" margin="0">
                              Field Servicely to HighLevel
                            </CommonText>
                            <CommonGrid
                              columns="36px 30px"
                              gap="10px"
                              justifyContent="start"
                            >
                              <ToggleButton
                                active={feature.fieldservicely_create}
                                onClick={() =>
                                  updateFeatureFSToHL(
                                    !feature.fieldservicely_create,
                                    feature,
                                  )
                                }
                              >
                                <ToggleButtonRound
                                  active={feature.fieldservicely_create}
                                />
                              </ToggleButton>
                              <CommonText
                                fontWeight="500"
                                fontSize="14px"
                                margin="0"
                              >
                                {feature.fieldservicely_create ? 'On' : 'Off'}
                              </CommonText>
                            </CommonGrid>
                          </CommonGrid>
                        </SettingPropertySection>
                        <SettingPropertySection margin="7px 0 0 0">
                          <CommonGrid columns="35% 65%" gap="2em">
                            <CommonText $label fontWeight="500" margin="0">
                              HighLevel to Field Servicely
                            </CommonText>
                            <CommonGrid
                              columns="36px 30px"
                              gap="10px"
                              justifyContent="start"
                            >
                              <ToggleButton
                                active={feature.create_from_webhook}
                                onClick={() =>
                                  updateFeatureHLToFS(
                                    !feature.create_from_webhook,
                                    feature,
                                  )
                                }
                              >
                                <ToggleButtonRound
                                  active={feature.create_from_webhook}
                                />
                              </ToggleButton>
                              <CommonText
                                fontWeight="500"
                                fontSize="14px"
                                margin="0"
                              >
                                {feature.create_from_webhook ? 'On' : 'Off'}
                              </CommonText>
                            </CommonGrid>
                          </CommonGrid>
                        </SettingPropertySection>
                      </div>
                    </Fragment>
                  ))
              )}
            </Container>
          </>
        ) : (
          <CommonText>
            You need to install fieldservicely from your Go High Level portal in
            order to use the benefits of this integration
          </CommonText>
        )}
      </Container>
    </div>
  );
};
export default GHLSettings;

import {
  ENABLE_INTEGRATION_WORKFLOW,
  GET_ALL_INTEGRATIONS_LIST,
  GET_ENABLED_INTEGRATIONS_LIST,
  GET_INTEGRATIONS_FEATURES,
  GET_INTEGRATION_ENABLED_WORKFLOWS_LIST,
  GET_INTEGRATION_WORKFLOWS_LIST,
  RUN_INTEGRATION_WORKFLOW,
  UPDATE_INTEGRATIONS_FEATURES,
} from '../../modules/constants';

export function getIntegrationsFeatures(payload) {
  return {
    type: GET_INTEGRATIONS_FEATURES,
    payload,
  };
}

export function updateIntegrationsFeatures(payload) {
  return {
    type: UPDATE_INTEGRATIONS_FEATURES,
    payload,
  };
}

export function getAllIntegrationsList(payload) {
  return {
    type: GET_ALL_INTEGRATIONS_LIST,
    payload,
  };
}

export function getIntegrationWorkflowsList(payload) {
  return {
    type: GET_INTEGRATION_WORKFLOWS_LIST,
    payload,
  };
}

export function getEnabledIntegrationsList(payload) {
  return {
    type: GET_ENABLED_INTEGRATIONS_LIST,
    payload,
  };
}

export function getIntegrationEnabledWorkflowsList(payload) {
  return {
    type: GET_INTEGRATION_ENABLED_WORKFLOWS_LIST,
    payload,
  };
}

export function enableIntegrationWorkflow(payload) {
  return {
    type: ENABLE_INTEGRATION_WORKFLOW,
    payload,
  };
}

export function runIntegrationWorkflow(payload) {
  return {
    type: RUN_INTEGRATION_WORKFLOW,
    payload,
  };
}

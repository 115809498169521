import styled from 'styled-components';

// search with button
export const SearchWithButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${({ height, theme }) => height || theme.input.minHeight};
  width: ${({ width }) => width || '100%'};
  padding: 3px 3px 3px 10px;
  border-radius: ${({ theme }) => theme.input.borderRadius};
  background-color: ${({ bgColor, theme }) =>
    bgColor ? bgColor : theme.input.bgColor.default};
  border: 1px solid
    ${({ borderColor, theme }) =>
      borderColor ? borderColor : theme.input.borderColor.default};
  &:hover {
    border-color: ${({ hoverBorderColor, theme, error }) =>
      !error && (hoverBorderColor || theme.input.borderColor.hover)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
  &:focus-within {
    border-color: ${({ focusBorderColor, theme, error }) =>
      !error && (focusBorderColor || theme.input.borderColor.focus)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
`;
export const SearchWithButtonInput = styled.input`
  width: ${({ width }) => width || 'calc(100% - 63px)'};
  background-color: ${({ theme }) => theme.colors.main.white};
  border: none;
  color: ${({ textColor, theme }) => textColor || theme.input.textColor};
  font-size: ${({ fontSize, theme }) => fontSize || theme.input.fontSize};
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: ${({ fontSize, theme }) =>
      fontSize || theme.input.placeholder.size};
    color: ${({ theme }) => theme.input.placeholder.color};
  }
  :-ms-input-placeholder {
    font-size: ${({ fontSize, theme }) =>
      fontSize || theme.input.placeholder.size};
    color: ${({ theme }) => theme.input.placeholder.color};
  }
  &:focus {
    outline: none;
  }
`;
export const SearchButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ size }) => size || '32px'};
  width: ${({ size }) => size || '32px'};
  background-color: ${({ theme }) => theme.colors.main.primary};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.hover.primary};
  }
`;

export const ClearSearchButton = styled.div`
  height: ${({ size }) => size || '15px'};
  width: ${({ size }) => size || '15px'};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  background-color: ${({ theme }) => theme.colors.main.grayMedium};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.hover.grayMedium};
  }
`;

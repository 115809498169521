import styled from 'styled-components';

export const TabLeftGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || 'auto 80%'};
  justify-content: space-between;
  grid-column-gap: 30px;
  margin: ${({ margin }) => margin || '0'};
  @media (max-width: 800px) {
    grid-column-gap: 10px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    justify-content: start;
    grid-row-gap: 15px;
  }
`;

// ----- MEMBERS TABLE -----

export const MemberNameDiv = styled.div`
  cursor: pointer;
`;

export const MembersTableHeadContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 120px 300px 150px;
  column-gap: 10px;
  justify-content: space-between;
  padding: 0 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  @media (max-width: 1350px) {
    grid-template-columns: 1fr 1fr 120px 300px 150px;
  }
`;
export const MembersTableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 120px 300px 150px;
  column-gap: 10px;
  justify-content: space-between;
  padding: 0 15px;
  :nth-of-type(even) {
    background-color: #f8f9fc;
  }
  @media (max-width: 1350px) {
    grid-template-columns: 1fr 1fr 120px 300px 150px;
  }
`;
export const UserSettings = styled.div`
  display: grid;
  grid-template-columns: 15px auto;
  gap: 5px 8px;
  align-items: center;
  justify-content: start;
`;
export const TeamSection = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 6px;
  justify-content: start;
  align-content: center;
  padding: 5px 0;
`;
export const InviteHeadSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 180px;
  grid-column-gap: 12px;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  @media (max-width: 1024px) {
    grid-template-columns: 280px 15%;
  }
  @media (max-width: 767px) {
    grid-template-columns: 280px 150px;
  }
`;
export const InviteTableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 180px;
  grid-column-gap: 12px;
  justify-content: space-between;
  padding: 0 30px;
  :nth-of-type(even) {
    background-color: #f8f9fc;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 280px 15%;
  }
  @media (max-width: 767px) {
    grid-template-columns: 280px 150px;
  }
`;
export const InviteProjectSection = styled.div`
  display: flex;
  flex-flow: row wrap;
  grid-gap: 10px;
  align-items: center;
  justify-content: start;
`;

export const RadioButton = styled.div`
  display: flex;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid;
  border-color: ${({ active, theme }) =>
    active ? theme.colors.main.success : theme.colors.main.textMain};
  background-color: ${({ active, theme }) =>
    active ? theme.colors.main.success : theme.colors.main.white};
  cursor: pointer;
`;

// ----- MEMBER DETAILS -----
export const ArrowBox = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  border-radius: 6px;
  background-color: white;
  width: 40px;
  height: 40px;
  border: 1px solid #e4e7eb;
`;
export const PaddingBox = styled.div`
  padding: 0 30px;
  @media (max-width: 767px) {
    padding: 0 15px;
  }
`;
export const UpperTitleButtonSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  grid-gap: ${({ gap }) => gap || '20px 15px'};
  padding: ${({ padding }) => padding || '0 30px'};
  @media (max-width: 767px) {
    grid-template-columns: auto;
    justify-content: start;
    margin-bottom: 15px;
  }
`;
export const SingleSection = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: start;
  gap: 10px;
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0 30px'};
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;
export const PersonSection = styled.div`
  display: grid;
  grid-template-columns: auto 200px;
  grid-column-gap: 15px;
  justify-content: space-between;
  align-content: center;
  padding: 10px 0 15px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
`;
export const ActivityCard = styled.div`
  background-color: ${({ theme }) => theme.colors.main.whiteBg};
  border-radius: ${({ theme }) => theme.borderRadii.card};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  padding: 20px;
  min-width: 600px;
`;
export const FlexSection = styled.div`
  display: flex;
  flex-direction: row;
  grid-column-gap: ${props => (props.gap ? props.gap : `20px`)};
  justify-content: flex-start;
  align-items: ${props => props.alignItems && props.alignItems};
`;
export const PesonActivitySection = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.earned ? `auto auto auto auto` : `auto auto auto`};
  grid-column-gap: 40px;
  justify-content: space-between;
  align-content: center;
  padding: 20px 0 0;
  @media (max-width: 767px) {
    grid-template-columns: auto;
    grid-row-gap: 25px;
  }
`;
export const ActivityTextSection = styled.div`
  display: grid;
  grid-row-gap: ${({ gap }) => gap || `6px`};
  margin: ${({ margin }) => margin || '0'};
`;
export const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #4e85f3;
  margin: auto 0;
`;
export const TextSwitch = styled.div`
  font-size: ${({ theme }) => theme.font.pageText.size};
  color: ${({ index, tabSelect, theme }) =>
    index == tabSelect
      ? theme.colors.main.primary
      : theme.colors.main.labelText};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: ${({ index, tabSelect, theme }) =>
      index !== tabSelect && theme.colors.main.textSecondary};
  }
`;

export const TextLink = styled.p`
  color: #4e85f3;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin: 0;
`;
export const ButtonFlexContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  grid-gap: 12px;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 15px 0px 20px;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const PaymentConfigTitle = styled.div`
  color: ${({ theme }) => theme.colors.main.textSecondary};
  padding-bottom: 7px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.border.primary};
  /* border-radius: 4px; */
  margin: 30px auto 0 30px;
  position: relative;
  top: 1px;
`;
export const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ bgColor, theme }) =>
    bgColor || theme.colors.border.secondaryLight};
  margin-bottom: 20px;
`;
export const AddPayConfigTextSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 12px 30px;
  justify-content: start;
`;
export const AddPayConfigMainSection = styled.div`
  display: grid;
  grid-template-columns: 190px 220px 100px;
  grid-gap: 20px 20px;
  /* justify-content: space-between; */
  justify-content: flex-start;
  max-width: 1100px;
  margin: 15px 0 0;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    justify-content: start;
  }
`;
export const MemberInvitationText = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #2f394e;
  margin: ${props => (props.margin ? props.margin : `3.5rem 0  1rem 0`)};
`;
export const MemberInvitationContainer = styled.div`
  display: grid;
  grid-template-columns: 280px 380px auto;
  grid-gap: 10px 7%;
  justify-content: start;
  max-width: 1100px;
  margin-top: 20px;
  @media (max-width: 1200px) {
    grid-template-columns: 280px 380px auto;
  }
  @media (max-width: 1050px) {
    grid-gap: 30px 15px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
export const RedStar = styled.span`
  color: #fc5768;
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;

  // may be 14 px, no font weight and line-height
`;
export const PlusText = styled.div`
  display: grid;
  justify-content: start;
  align-items: center;
  grid-template-columns: 25px auto;
  color: #20bead;
  font-size: 16px;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
`;

export const ModalButtonSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: end;
  grid-column-gap: 12px;
  padding-top: 30px;
`;

export const InviteLink = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

// timesheet approval settings badge
export const TimesheetApprovalSettingsBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  padding: 0 8px;
  border-radius: ${({ theme }) => theme.borderRadii.button};
  background-color: ${({ theme, active }) =>
    active ? theme.colors.main.success : theme.colors.main.grayMedium};
  color: ${({ theme }) => theme.colors.main.white};
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

// Add Member
export const UploadButtonContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  justify-content: start;
  align-items: start;
`;
export const UploadImageButton = styled.button`
  height: 70px;
  border-radius: ${({ theme }) => theme.borderRadii.button};
  border: 1px solid ${({ theme }) => theme.button.blueButton.bgColor};
  background-color: #4d94fb33;
  color: ${({ theme }) => theme.colors.main.primary};
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  padding: 0 20px;
`;

export const EmailBox = styled.div`
  width: 40%;
  height: 180px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  color: ${({ textColor, theme }) => textColor || theme.input.textColor};
  font-size: ${({ fontSize, theme }) => fontSize || theme.input.fontSize};
  overflow-y: auto; /* Enable vertical scroll */

  &:hover {
    border-color: ${({ hoverBorderColor, theme, error }) =>
      !error && (hoverBorderColor || theme.input.borderColor.hover)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
  &:focus {
    outline: none;
    border-color: ${({ focusBorderColor, theme, error }) =>
      !error && (focusBorderColor || theme.input.borderColor.focus)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
`;

export const EmailEntry = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 7px;
  margin-bottom: 5px;
  padding: 2% 5px;
  background-color: #dee3eb;
  border-radius: 5px;
  font-size: 12px;

  button {
    background-color: #ffffff;
    color: #495872;
    border: none;
    border-radius: 50%;
    margin-left: 7px;
    cursor: pointer;
    font-size: 10px;
  }
`;

export const InputEmail = styled.input`
  border: none;
  outline: none;
  cursor: text;
  width: 100%;
  /* padding: 5px 10px; */
  box-sizing: border-box;
  font-size: inherit;
  color: inherit;
`;

export const DottedRoundedBorder = styled.div`
  /* Set height and width */
  height: ${props => props.height || '200px'};
  width: ${props => props.width || '40%'};

  /* Set dotted rounded border */
  border: 2px dotted #c2cce1;
  border-radius: 10px;

  /* Center content */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* Add other styles as needed */
  &:hover {
    border-color: ${({ hoverBorderColor, theme, error }) =>
      !error && (hoverBorderColor || theme.input.borderColor.hover)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
  &:focus {
    outline: none;
    border-color: ${({ focusBorderColor, theme, error }) =>
      !error && (focusBorderColor || theme.input.borderColor.focus)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
`;

export const BottomRow = styled.div`
  /* Add styles for the bottom row */
  display: flex;
  justify-content: space-between;
  margin-top: 30px; /* Adjust as needed */
  width: 100%; /* Make sure the row spans the full width */
`;

export const BottomLeft = styled.div`
  align-self: flex-start;
`;

export const BottomRight = styled.div`
  align-self: flex-end;
`;
export const ValidEmailContainer = styled.div`
  border: 2px solid #d1f0db; /* Red border */
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 40%;
  background-color: #d1f0db;
`;

export const InvalidEmailContainer = styled.div`
  border: 2px solid #ed545c; /* Red border */
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 40%;
`;

export const CommonFlexMember = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  gap: ${({ gap }) => gap ?? `20px`};
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'}; // Add flex-wrap property
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  padding: ${({ padding }) => padding || '0'};
  padding-top: ${({ paddingTop }) => paddingTop || '0'};
  margin: ${({ margin }) => margin || '0'};
  background-color: ${({ bgColor }) => bgColor};
`;

import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import {
  PricingPlanContainer,
  PricingPlanHeader,
  HeaderTitle,
  PricingCardContainer,
  SinglePricingCard,
  TopSectionContainer,
  PlanNameAndTagContainer,
  PlanName,
  PlanNameTag,
  PlanPriceContainer,
  PlanPrice,
  PlanPriceText,
  PlanDescription,
  PlanFeaturesContainer,
  SinglePlanFeature,
  FeatureTickIcon,
  FeatureText,
  TypeTogglerContainer,
  TypeText,
  PlanSelectButton,
  PlusAddonDiv,
  EverythingInPrev,
  ArrowLeft,
  NotificationText,
  MoneyBackTag,
  ComparePlansLink,
} from "./pricingPlanStyles";
import { CommonFlex } from "../../styledComponents/common";

import { planFeaturesShort } from "./planFeatures";

import PlanToggleSwitch from "./PlanToggleSwitch";

import tickGreen from "../../assets/img/onboardingImg/tick-green.svg";
import tickBlack from "../../assets/img/onboardingImg/tick-black.svg";
import tickPurple from "../../assets/img/onboardingImg/tick-purple.svg";
import tickBlue from "../../assets/img/onboardingImg/tick-blue.svg";

import arrowLeft from "../../assets/img/onboardingImg/left-arrow.svg";
import { WhiteButton } from "styledComponents/buttons";

const planPriceMonthly = {
  standard: 15,
  elite: 20,
};
const planPriceYearly = {
  standard: 9,
  elite: 12,
};

const PlanTypeToggler = (props) => {
  return (
    <TypeTogglerContainer>
      <TypeText active={props.planType && props.planType === "monthly"}>
        Monthly
      </TypeText>
      <PlanToggleSwitch toggleAction={props.togglePlanType} />
      <TypeText active={props.planType && props.planType === "yearly"}>
        Yearly
      </TypeText>
    </TypeTogglerContainer>
  );
};

const PricingPlan = ({
  isOpen,
  toggle,
  popupText,
  popupTitle,
  backRoute,
  history,
  onlyElite,
}) => {
  const [planInterval, setPlanInterval] = useState("monthly");

  const togglePlanInterval = () => {
    if (planInterval === "monthly") {
      setPlanInterval("yearly");
    } else {
      setPlanInterval("monthly");
    }
  };

  const handleBack = () => {
    if (backRoute) {
      toggle();
      history.push(backRoute);
    } else {
      toggle();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ maxWidth: `760px`, paddingTop: `0` }}
      backdrop="static"
      backdropClassName="pricing-plan-modal-backdrop"
    >
      <ModalBody style={{ padding: `30px` }}>
        <PricingPlanContainer>
          <CommonFlex justifyContent="space-between" margin="0 0 7px 0">
            <NotificationText>
              {popupText ? popupText : "you've reached the limit"}
            </NotificationText>
            <MoneyBackTag>
              {" "}
              <span>✅</span> 100% money back guarantee
            </MoneyBackTag>
          </CommonFlex>
          <HeaderTitle>
            {popupTitle ? popupTitle : "Upgrade your plan"}
          </HeaderTitle>
          <PlanTypeToggler
            planType={planInterval}
            togglePlanType={togglePlanInterval}
          />
          <PricingCardContainer onlyElite={onlyElite}>
            {!onlyElite && (
              <SinglePricingCard plan="standard">
                <TopSectionContainer>
                  <PlanNameAndTagContainer>
                    <PlanName plan="standard">STANDARD</PlanName>
                    <PlanNameTag plan="standard">
                      Geofenced Attendance
                    </PlanNameTag>
                  </PlanNameAndTagContainer>
                  <PlanPriceContainer>
                    <PlanPrice>
                      $
                      {planInterval === "yearly"
                        ? planPriceYearly.standard
                        : planPriceMonthly.standard}
                    </PlanPrice>
                    <PlanPriceText>per user per month</PlanPriceText>
                  </PlanPriceContainer>
                  <PlanSelectButton
                    plan="standard"
                    onClick={() => history.push("/user/subscription")}
                  >
                    Upgrade to Standard
                  </PlanSelectButton>
                </TopSectionContainer>
                <PlanFeaturesContainer>
                  {planFeaturesShort.standard.map((item, index) => (
                    <SinglePlanFeature key={index}>
                      <FeatureTickIcon src={tickBlue} alt="" />
                      <FeatureText>{item}</FeatureText>
                    </SinglePlanFeature>
                  ))}
                </PlanFeaturesContainer>
              </SinglePricingCard>
            )}
            <SinglePricingCard plan="elite">
              <TopSectionContainer>
                <PlanNameAndTagContainer>
                  <PlanName plan="elite">ELITE</PlanName>
                  <PlanNameTag plan="elite">Field, Remote & Hybrid</PlanNameTag>
                </PlanNameAndTagContainer>
                <PlanPriceContainer>
                  <PlanPrice>
                    $
                    {planInterval === "yearly"
                      ? planPriceYearly.elite
                      : planPriceMonthly.elite}
                  </PlanPrice>
                  <PlanPriceText>per user per month</PlanPriceText>
                </PlanPriceContainer>
                <PlanSelectButton
                  plan="elite"
                  onClick={() => history.push("/user/subscription")}
                >
                  Upgrade to Elite
                </PlanSelectButton>
              </TopSectionContainer>
              <EverythingInPrev plan="elite">
                <ArrowLeft src={arrowLeft} alt="" />
                <FeatureText style={{ marginLeft: "8px" }}>
                  Everything in Standard
                </FeatureText>
              </EverythingInPrev>
              <PlusAddonDiv>Plus Add On:</PlusAddonDiv>
              <PlanFeaturesContainer>
                {planFeaturesShort.elite.map((item, index) => (
                  <SinglePlanFeature key={index}>
                    <FeatureTickIcon plan="elite" src={tickBlue} alt="" />
                    <FeatureText>{item}</FeatureText>
                  </SinglePlanFeature>
                ))}
              </PlanFeaturesContainer>
            </SinglePricingCard>
          </PricingCardContainer>
          <CommonFlex justifyContent="space-between" margin="20px 0 0 0">
            <WhiteButton onClick={() => handleBack()}>Back</WhiteButton>
            <ComparePlansLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.fieldservicely.com/pricing"
            >
              Compare all Plans
            </ComparePlansLink>
            <div />
          </CommonFlex>
        </PricingPlanContainer>
      </ModalBody>
    </Modal>
  );
};

export default PricingPlan;

import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../../../assets/img/avatar_5.svg';
import LeftArrow_3 from '../../../../assets/img/icons/LeftArrow_3.svg';
import RightArrow_3 from '../../../../assets/img/icons/RightArrow_3.svg';
import ProjectDropdown from '../../../../components/CommonFilter/ProjectDropdown';
import AddSingleMemberDropdown from '../../../../components/CustomDropdown/AddSingleMemberDropdown';
import FilterDropDownStyle from '../../../../components/DropdownStyle/FilterDropDownStyle';
import Dropdown from '../../../../components/ReactSelectDropdown';
import { filterOption } from '../../../../components/ReactSelectDropdown/ReactSelectDropdown';
import ClearFilterButton from '../../../../components/Search/clearFilterButton';
import DatePicker from '../../../../components/SingleDatePicker/SingleDatePicker';
import {
  FilterButton,
  SecondaryButton,
} from '../../../../styledComponents/buttons';
import {
  CommonFlex,
  FilterLabel,
  IconArrow,
  InputWithLabelSection,
} from '../../../../styledComponents/common';
import { RouteMapFilterContainer } from './filterStyles';

const RouteMapFilter = ({
  selectedOrganization,

  getOrganizationMembersShortList,
  getOfficesShortList,

  orgMembersShortList,
  officesShortList,
  selectOffice,
  officeDetails,

  selectedMember,
  setSelectedMember,

  selectedOffice,
  setSelectedOffice,

  selectedDate,
  setSelectedDate,

  getRouteMapLocations,
  getSingleEmployeeClockInOutList,
  getTaskCheckInOutList,

  profile,
}) => {
  const { t } = useTranslation();
  // const [selectedDate, setSelectedDate] = useState(moment());
  const [memberOptions, setMemberOptions] = useState([]);

  const [officeOptions, setOfficeOptions] = useState([]);

  const isToday = selectedDate.isSame(moment(), 'day');

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      const role = localStorage.getItem('selected_organization_role');
      if (role === 'admin' || role === 'owner') {
        getOfficesShortList({
          organization_id: selectedOrganization.id,
        });
        getOrganizationMembersShortList({
          organization_id: selectedOrganization.id,
        });
      }
      // else {
      //   getOrganizationMembersShortList({
      //     organization_id: selectedOrganization.id,
      //   });
      // }
    }
  }, [selectedOrganization]);

  useEffect(() => {
    const role = localStorage.getItem('selected_organization_role');
    const user_id = localStorage.getItem('user_id');
    const user_name = localStorage.getItem('user_fullName');
    const email = localStorage.getItem('email');
    if (
      (role === 'member' || role === 'employee') &&
      profile &&
      profile.profileData.id
    ) {
      setSelectedMember([
        {
          // id: profile.profileData.id,
          id: user_id,
          name: user_name,
          email: email,
          avatar: profile.profileData.thumbnail,
          phone: profile.profileData.phone,
        },
      ]);
    }
  }, [profile]);

  useEffect(() => {
    const role = localStorage.getItem('selected_organization_role');
    if (role === 'member' || role === 'employee') {
      if (selectedMember && selectedMember[0]) {
        selectMember(selectedMember[0]);
      }
    }
  }, [selectedMember]);

  useEffect(() => {
    if (officesShortList && officesShortList.length > 0) {
      // setSelectedOffice({
      //   label: officesShortList[0].name,
      //   value: officesShortList[0].id,
      //   ...officesShortList[0],
      // });
      const list = [
        {
          label: `(${t('all_offices')})`,
          value: null,
        },
      ];
      officesShortList.forEach(office => {
        list.push({
          label: office.name,
          value: office.id,
          ...office,
        });
      });
      setOfficeOptions(list);
      list?.length > 0 && setSelectedOffice(list[0]);
    } else if (officesShortList && officesShortList.length === 0) {
      setOfficeOptions([]);
    }
  }, [officesShortList]);

  useEffect(() => {
    if (
      selectedOffice &&
      !selectedOffice?.value &&
      orgMembersShortList?.length > 0
    ) {
      selectMember(orgMembersShortList[0]);
    } else if (officeDetails) {
      console.log(
        '🚀 ~ file: RouteMapFilter.jsx:141 ~ useEffect ~ officeDetails:',
        officeDetails,
      );
      const filteredMembers = orgMembersShortList.filter(member => {
        return officeDetails.employees?.some(
          employee => employee.user.id === member.id,
        );
      });
      setMemberOptions(filteredMembers);
      if (filteredMembers.length > 0) {
        selectMember(filteredMembers[0]);
      } else clearSelectedMember();
    }
  }, [officeDetails, selectedOffice]);

  const selectMember = member => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        start: moment(selectedDate).startOf('day').unix(),
        end: moment(selectedDate).endOf('day').unix(),
        employee: member.id,
      };
      getRouteMapLocations(payload);
      getSingleEmployeeClockInOutList(payload);
      getTaskCheckInOutList(payload);

      setSelectedMember(member);
    }
  };
  const clearSelectedMember = () => {
    // if (selectedOrganization && selectedOrganization.id) {
    //   const payload = {
    //     organization_id: selectedOrganization.id,
    //     date: moment(selectedDate).format("YYYY-MM-DD"),
    //   };
    //   if (selectedProject && selectedProject.value) {
    //     payload.project_id = selectedProject.value;
    //   }
    //   getRouteMapLocations(payload);
    // }
    setSelectedMember(null);
  };

  const singleDateChange = direction => {
    setSelectedDate(prevDate => {
      const newDate =
        direction === 'right'
          ? moment(prevDate).add(1, 'days')
          : moment(prevDate).subtract(1, 'days');
      selectDate(newDate);
      return newDate;
    });
  };

  const selectToday = () => {
    selectDate(moment());
  };

  const selectDate = date => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        start: moment(date).startOf('day').unix(),
        end: moment(date).endOf('day').unix(),
      };
      if (selectedMember && selectedMember.id) {
        payload.employee = selectedMember.id;
      }
      getRouteMapLocations(payload);
      getSingleEmployeeClockInOutList(payload);
      getTaskCheckInOutList(payload);

      setSelectedDate(date);
    }
  };

  return (
    <RouteMapFilterContainer columns="360px 360px 57px 90px">
      {(localStorage.getItem('selected_organization_role') === 'admin' ||
        localStorage.getItem('selected_organization_role') === 'owner') && (
        <>
          <InputWithLabelSection>
            <FilterLabel>{t('office')}</FilterLabel>
            <Dropdown
              isSearchable
              isClearable={selectedOffice?.value}
              value={selectedOffice}
              placeholder="Select office..."
              onChange={e => {
                e
                  ? selectOffice(e)
                  : selectOffice({
                      label: `(${t('all_offices')})`,
                      value: null,
                    });
              }}
              options={officeOptions}
              styles={FilterDropDownStyle({ height: '40px' })}
              filterOption={filterOption}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: props => {
                  return <ClearFilterButton {...props} />;
                },
              }}
            />
          </InputWithLabelSection>
          <InputWithLabelSection>
            <FilterLabel>{t('date')}</FilterLabel>
            <CommonFlex gap="2px">
              <DatePicker
                id="routeMapFilterDate"
                date={selectedDate}
                onDateChange={selectDate}
                dateDisplayFormat={'DD/MM/YYYY'}
                placeholder={'Select date...'}
                allowPreviousDates
                disableFutureDates
              />
              <CommonFlex gap="0px" margin="1.5px 0 0 0">
                <IconArrow
                  onClick={() => {
                    singleDateChange('left');
                  }}
                  padding="5px"
                  size="40px"
                  src={LeftArrow_3}
                />
                <IconArrow
                  margin="0 0 0 2px"
                  onClick={() => {
                    if (!isToday) {
                      singleDateChange('right');
                    }
                  }}
                  padding="5px"
                  size="40px"
                  src={RightArrow_3}
                  disabled={isToday}
                />
              </CommonFlex>
              <div style={{ margin: '0 0 0 14px' }}>
                <FilterButton
                  type="gray"
                  padding="10px"
                  onClick={() => selectToday()}
                >
                  Today
                </FilterButton>
              </div>
            </CommonFlex>
          </InputWithLabelSection>
          <InputWithLabelSection>
            <FilterLabel>{t('assignee')}</FilterLabel>
            <div style={{ width: `40px` }}>
              <AddSingleMemberDropdown
                assigneeManage={selectedMember}
                updateState={selectMember}
                membersList={
                  !selectedOffice ||
                  (selectedOffice && selectedOffice.label === '(All Offices)')
                    ? orgMembersShortList
                    : memberOptions
                }
                clearAssignee={() => clearSelectedMember()}
              />
            </div>
          </InputWithLabelSection>
        </>
      )}

      {(localStorage.getItem('selected_organization_role') === 'member' ||
        localStorage.getItem('selected_organization_role') === 'employee') && (
        <>
          <InputWithLabelSection>
            <FilterLabel>{t('date')}</FilterLabel>

            <CommonFlex gap="2px">
              <DatePicker
                id="routeMapFilterDate"
                date={selectedDate}
                onDateChange={selectDate}
                dateDisplayFormat={'DD/MM/YYYY'}
                placeholder={'Select date...'}
                allowPreviousDates
                disableFutureDates
              />
              <CommonFlex gap="0px" margin="1.5px 0 0 0">
                <IconArrow
                  onClick={() => {
                    singleDateChange('left');
                  }}
                  padding="5px"
                  size="40px"
                  src={LeftArrow_3}
                />
                <IconArrow
                  margin="0 0 0 2px"
                  onClick={() => {
                    if (!isToday) {
                      singleDateChange('right');
                    }
                  }}
                  padding="5px"
                  size="40px"
                  src={RightArrow_3}
                  disabled={isToday}
                />
              </CommonFlex>
              <div style={{ margin: '0 0 0 14px' }}>
                <FilterButton
                  type="gray"
                  padding="10px"
                  onClick={() => selectToday()}
                >
                  Today
                </FilterButton>
              </div>
            </CommonFlex>
          </InputWithLabelSection>
          <InputWithLabelSection>
            <FilterLabel>{t('assignee')}</FilterLabel>
            <div style={{ width: `40px` }}>
              <AddSingleMemberDropdown
                assigneeManage={selectedMember}
                updateState={selectMember}
                membersList={selectedMember}
                clearAssignee={() => clearSelectedMember()}
              />
            </div>
          </InputWithLabelSection>
        </>
      )}

      {/* <InputWithLabelSection>
        <div style={{ height: "21px" }} />
        <SecondaryButton onClick={() => selectToday()}>Today</SecondaryButton>
      </InputWithLabelSection> */}
    </RouteMapFilterContainer>
  );
};

export default RouteMapFilter;

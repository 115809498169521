//Effects
import { all, put, select, takeLatest } from 'redux-saga/effects';

import {
  ADD_OFFICE_LOCATION,
  ADD_OFFICE_LOCATION_FAILURE,
  ADD_OFFICE_LOCATION_SUCCESS,
  ADD_OFFICE_MEMBER,
  ADD_OFFICE_MEMBER_FAILURE,
  ADD_OFFICE_MEMBER_SUCCESS,
  ADD_OR_UPDATE_OFFICE_INFO,
  ADD_OR_UPDATE_OFFICE_INFO_FAILURE,
  ADD_OR_UPDATE_OFFICE_INFO_SUCCESS,
  ADD_OR_UPDATE_OFFICE_LOCATION,
  ADD_OR_UPDATE_OFFICE_LOCATION_FAILURE,
  ADD_OR_UPDATE_OFFICE_LOCATION_SUCCESS,
  CREATE_OFFICE,
  CREATE_OFFICE_FAILURE,
  CREATE_OFFICE_SUCCESS,
  DELETE_OFFICE,
  DELETE_OFFICE_FAILURE,
  DELETE_OFFICE_SUCCESS,
  GET_OFFICES_DETAILS,
  GET_OFFICES_DETAILS_FAILURE,
  GET_OFFICES_DETAILS_SUCCESS,
  GET_OFFICES_LIST,
  GET_OFFICES_LIST_FAILURE,
  GET_OFFICES_LIST_SHORT,
  GET_OFFICES_LIST_SHORT_FAILURE,
  GET_OFFICES_LIST_SHORT_SUCCESS,
  GET_OFFICES_LIST_SUCCESS,
  GET_OFFICE_MEMBERS_LIST,
  GET_OFFICE_MEMBERS_LIST_FAILURE,
  GET_OFFICE_MEMBERS_LIST_SUCCESS,
  REMOVE_OFFICE_MEMBER,
  REMOVE_OFFICE_MEMBER_FAILURE,
  REMOVE_OFFICE_MEMBER_SUCCESS,
  UPDATE_OFFICE,
  UPDATE_OFFICE_FAILURE,
  UPDATE_OFFICE_LOCATION,
  UPDATE_OFFICE_LOCATION_FAILURE,
  UPDATE_OFFICE_LOCATION_SUCCESS,
  UPDATE_OFFICE_MEMBER,
  UPDATE_OFFICE_MEMBER_FAILURE,
  UPDATE_OFFICE_MEMBER_SUCCESS,
  UPDATE_OFFICE_SUCCESS,
} from '../../modules/constants';
//Service
import { defaultApi } from '../../utils/axiosApi';

export function* handleGetOfficesList({ payload }) {
  const { organization_id, page, name } = payload;
  try {
    // const url = `/albait/offices/${page ? `?page=${page}` : ''}${
    //   office_name
    //     ? page
    //       ? `&name=${office_name}`
    //       : `?name=${office_name}`
    //     : ''
    // }`;
    const url = `${organization_id}/field-service/offices/?page=${page}${
      name ? `&name=${name}` : ''
    }`;
    const response = yield defaultApi(url, 'GET', null);
    yield put({
      type: GET_OFFICES_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_OFFICES_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* handleGetOfficesShortList({ payload }) {
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/field-service/offices/short/`;
    const response = yield defaultApi(url, 'GET', null);
    yield put({
      type: GET_OFFICES_LIST_SHORT_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_OFFICES_LIST_SHORT_FAILURE,
      payload: err,
    });
  }
}

export function* handleCreateOffice({ payload }) {
  const { organization_id, ...data } = payload;
  try {
    const url = `${organization_id}/field-service/offices/`;
    const response = yield defaultApi(url, 'POST', data);
    yield put({
      type: CREATE_OFFICE_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: CREATE_OFFICE_FAILURE,
      payload: err,
    });
  }
}

export function* handleDeleteOffice({ payload }) {
  try {
    const { organization_id, office_id } = payload;
    let url = `${organization_id}/field-service/offices/${office_id}/`;
    let method = 'DELETE';
    const response = yield defaultApi(url, method);
    yield put({
      type: DELETE_OFFICE_SUCCESS,
      payload: { data: response, office_id, notify: method },
    });
  } catch (err) {
    yield put({
      type: DELETE_OFFICE_FAILURE,
      payload: err,
    });
  }
}

export function* handleUpdateOffice({ payload }) {
  const { office_id, ...data } = payload;
  try {
    const url = `/albait/offices/${office_id}/`;
    const response = yield defaultApi(url, 'PUT', data);
    yield put({
      type: UPDATE_OFFICE_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: UPDATE_OFFICE_FAILURE,
      payload: err,
    });
  }
}

export function* getOfficeMembersListRequest({ payload }) {
  try {
    const { organization_id, office_id, page, name } = payload;
    const url = `${organization_id}/field-service/offices/${office_id}/${
      page ? `?page=${page}` : ``
    }${name ? (page ? `&name=${name}` : `?name=${name}`) : ``}`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_OFFICE_MEMBERS_LIST_SUCCESS,
      payload: { data: response, notify: 'GET' },
    });
  } catch (err) {
    yield put({
      type: GET_OFFICE_MEMBERS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getOfficeDetailsRequest({ payload }) {
  try {
    const { organization_id, office_id } = payload;
    const url = `${organization_id}/field-service/offices/${office_id}/`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_OFFICES_DETAILS_SUCCESS,
      payload: { data: response, notify: 'GET' },
    });
  } catch (err) {
    yield put({
      type: GET_OFFICES_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* addOfficeMemberRequest({ payload }) {
  try {
    let { organization_id, office_id, ...user_id } = payload;
    let url = `${organization_id}/field-service/offices/${office_id}/employees/`;
    let method = 'POST';
    const response = yield defaultApi(url, method, user_id);
    // if (response.status === 201 && getMemberData && details.user_id) {
    //   let memberDetailsPayload = {
    //     organization_id,
    //     user_id: details.user_id,
    //   };
    //   yield call(getMemberDetails, { payload: memberDetailsPayload });
    // }
    yield put({
      type: ADD_OFFICE_MEMBER_SUCCESS,
      payload: { data: response, notify: 'POST' },
    });
  } catch (err) {
    yield put({
      type: ADD_OFFICE_MEMBER_FAILURE,
      payload: err,
    });
  }
}

export function* removeOfficeMemberRequest({ payload }) {
  try {
    let { organization_id, office_id, user_id } = payload;
    let url = `${organization_id}/field-service/offices/${office_id}/employees/${user_id}/`;
    let method = 'DELETE';
    const response = yield defaultApi(url, method);
    yield put({
      type: REMOVE_OFFICE_MEMBER_SUCCESS,
      payload: {
        data: response,
        notify: 'DELETE',
      },
      userData: { office_Id: office_id, userId: user_id },
    });
  } catch (err) {
    yield put({
      type: REMOVE_OFFICE_MEMBER_FAILURE,
      payload: err,
    });
  }
}

export function* updateOfficeMemberRequest({ payload }) {
  try {
    let { organization_id, office_id, member_id, ...details } = payload;
    let url = `${organization_id}/field-service/offices/${office_id}/employees/${member_id}/`;
    let method = 'PATCH';
    const response = yield defaultApi(url, method, details);
    // yield call(getTeamMembersListRequest, { payload });
    yield put({
      type: UPDATE_OFFICE_MEMBER_SUCCESS,
      payload: { data: response, notify: 'PATCH' },
    });
  } catch (err) {
    yield put({
      type: UPDATE_OFFICE_MEMBER_FAILURE,
      payload: err,
    });
  }
}

export function* handleAddOrUpdateOfficeLocation({ payload }) {
  try {
    const { organizationID, office_id, ...newPayload } = payload;
    const url = `${organizationID}/field-service/offices/${office_id}/`;
    let method = 'PATCH';

    const response = yield defaultApi(url, method, newPayload);

    yield put({
      type: ADD_OR_UPDATE_OFFICE_LOCATION_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: ADD_OR_UPDATE_OFFICE_LOCATION_FAILURE,
      payload: err,
    });
  }
}
export function* handleAddOrUpdateOfficeInfo({ payload }) {
  try {
    const { organizationID, office_id, ...newPayload } = payload;
    const url = `${organizationID}/field-service/offices/${office_id}/`;
    let method = 'PATCH';

    const response = yield defaultApi(url, method, newPayload);

    yield put({
      type: ADD_OR_UPDATE_OFFICE_INFO_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: ADD_OR_UPDATE_OFFICE_INFO_FAILURE,
      payload: err,
    });
  }
}

export function* addOfficeLocationRequest({ payload }) {
  try {
    let { organizationID, office_id, ...new_payload } = payload;
    let url = `${organizationID}/field-service/offices/${office_id}/office-locations/`;
    let method = 'POST';
    const response = yield defaultApi(url, method, new_payload);
    yield put({
      type: ADD_OFFICE_LOCATION_SUCCESS,
      payload: { data: response, notify: 'POST' },
    });
  } catch (err) {
    yield put({
      type: ADD_OFFICE_LOCATION_FAILURE,
      payload: err,
    });
  }
}

export function* updateOfficeLocationRequest({ payload }) {
  try {
    let { organizationID, office_id, location_id, ...new_payload } = payload;
    let url = `${organizationID}/field-service/offices/${office_id}/office-locations/${location_id}/`;
    let method = 'PATCH';
    const response = yield defaultApi(url, method, new_payload);
    yield put({
      type: UPDATE_OFFICE_LOCATION_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: UPDATE_OFFICE_LOCATION_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    // Get tasks list
    takeLatest(GET_OFFICES_LIST, handleGetOfficesList),
    takeLatest(GET_OFFICES_LIST_SHORT, handleGetOfficesShortList),
    takeLatest(CREATE_OFFICE, handleCreateOffice),
    takeLatest(UPDATE_OFFICE, handleUpdateOffice),
    takeLatest(GET_OFFICE_MEMBERS_LIST, getOfficeMembersListRequest),
    takeLatest(ADD_OFFICE_MEMBER, addOfficeMemberRequest),
    takeLatest(REMOVE_OFFICE_MEMBER, removeOfficeMemberRequest),
    takeLatest(DELETE_OFFICE, handleDeleteOffice),
    takeLatest(UPDATE_OFFICE_MEMBER, updateOfficeMemberRequest),
    takeLatest(GET_OFFICES_DETAILS, getOfficeDetailsRequest),
    takeLatest(ADD_OR_UPDATE_OFFICE_LOCATION, handleAddOrUpdateOfficeLocation),
    takeLatest(ADD_OR_UPDATE_OFFICE_INFO, handleAddOrUpdateOfficeInfo),
    takeLatest(ADD_OFFICE_LOCATION, addOfficeLocationRequest),
    takeLatest(UPDATE_OFFICE_LOCATION, updateOfficeLocationRequest),
  ]);
}

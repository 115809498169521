import { connect } from 'react-redux';

import Integrations from './IntegrationsNew';
import {
  getAllIntegrationsList,
  getEnabledIntegrationsList,
} from './integrationsActions';

const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,

  // integrations
  isLoading: state.integrations.isLoading,
  allIntegrationsList: state.integrations.allIntegrationsList,
  enabledIntegrationsList: state.integrations.enabledIntegrationsList,
});

const mapDispatchToProps = dispatch => ({
  getAllIntegrationsList: payload => dispatch(getAllIntegrationsList(payload)),
  getEnabledIntegrationsList: payload =>
    dispatch(getEnabledIntegrationsList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);

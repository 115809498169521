import { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import moment from "moment";
import {
  CardTitle,
  CommonText,
} from "../../styledComponents/common";
import { WhiteButton } from "../../styledComponents/buttons";
import {
  ModalGreenSection,
  PreviewDetailsElement,
  ModalButtonSection,
  PreviewDetailsDivider,
} from "../../styledComponents/invoice";
import Loader from "react-spinners/PulseLoader";

const ViewPaymentDetailsModal = ({
  isOpen,
  toggle,
  isLoading,
  organization_id,
  invoice_id,
  payment_id,
  getRecordPaymentInvoice,
  getRecordPaymentInfo,
  invoice_number,
  deleteRecordPaymentInvoice,
  onRecordPaymentDelete,
}) => {
  useEffect(() => {
    if (isOpen) {
      const payload = {
        organization_id: organization_id,
        invoice_id: invoice_id,
        payment_id: payment_id,
      };
      getRecordPaymentInvoice(payload);
    }
  }, [isOpen]);

  const handleRecordPaymentDelete = () => {
    const payload = {
      organization_id: organization_id,
      invoice_id: invoice_id,
      payment_id: payment_id,
    };
    deleteRecordPaymentInvoice(payload);
    onRecordPaymentDelete();
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `7vh`, maxWidth: `460px` }}
    >
      <ModalBody style={{ padding: `30px 0 15px 0` }}>
        <div style={{ padding: `0 30px` }}>
          <CardTitle fontSize="16px" margin="0 0 15px 0">
            Payment Details
          </CardTitle>
          {isLoading? (
            <Loader/>
          ) : (
            <>
              <ModalGreenSection details>
                <PreviewDetailsElement>
                  <CommonText $label>Invoice Number</CommonText>
                  <CommonText title>{invoice_number}</CommonText>
                </PreviewDetailsElement>
                <PreviewDetailsDivider />
                <PreviewDetailsElement>
                  <CommonText $label>Amount</CommonText>
                  <CommonText title>
                    {getRecordPaymentInfo &&
                      getRecordPaymentInfo.id &&
                      getRecordPaymentInfo.amount}
                  </CommonText>
                </PreviewDetailsElement>
              </ModalGreenSection>

              <CommonText $label style={{ marginBottom: `10px` }}>
                Payment Date
              </CommonText>
              <CommonText title>
                {getRecordPaymentInfo &&
                  getRecordPaymentInfo.id &&
                  moment(getRecordPaymentInfo.date).format("ddd, MMM D, YYYY")}
              </CommonText>
              {getRecordPaymentInfo &&
                getRecordPaymentInfo.id &&
                getRecordPaymentInfo.notes && (
                  <>
                    <CommonText $label style={{ margin: `20px 0 10px 0` }}>
                      Note
                    </CommonText>
                    <CommonText title>{getRecordPaymentInfo.notes}</CommonText>
                  </>
                )}
            </>
          )}
        </div>
        <ModalButtonSection>
          <WhiteButton
            type="delete"
            onClick={() => handleRecordPaymentDelete()}
          >
            Delete Record
          </WhiteButton>
          <WhiteButton type="cancel" onClick={toggle}>
            Close
          </WhiteButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default ViewPaymentDetailsModal;

import { useState, useEffect } from "react";
import api from "../../utils/api";
import base64 from "base-64";
import queryString from "query-string";
import moment from "moment";
import styled from "styled-components";
import { css } from "@emotion/core";
import PulseLoader from "react-spinners/PulseLoader";
import { createNotification } from "../../modules/notificationManager";

const customCss = css`
  margin: 5px;
  text-align: center;
`;

const InvitationContainer = styled.div`
  height: 100%;
  background: #fcfdfe;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingText = styled.p`
  font-size: 24px;
`;

const TextLoader = (props) => {
  return (
    <div className="sweet-loading">
      <PulseLoader
        size={8}
        color={"#2f394e"}
        loading={props.loading}
        css={customCss}
      />
    </div>
  );
};

const AcceptJoinRequest = (props) => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (props.location.search) {
      const queryParsed = queryString.parse(props.location.search);
      if (queryParsed) {
        let key = queryParsed.key;
        try {
          let JWS = JSON.parse(base64.decode(key.split(".")[0]));
          let payload = JSON.parse(base64.decode(key.split(".")[1]));
          let organization_id =
            payload && payload.organization ? payload.organization : "";
          if (!JWS) {
            setLoading(false);
            setError("Invalid Link!");
          } else if (JWS) {
            if (JWS.alg && JWS.alg !== "HS256") {
              setLoading(false);
              setError("Invalid Link!");
            } else if (JWS.typ && JWS.typ !== "JWS") {
              setLoading(false);
              setError("Invalid Link!");
            }
          }
          if (organization_id) {
            api({
              url: `${organization_id}/members/accept-request/`,
              method: "POST",
              data: { key },
            })
              .then((response) => {
                if (response.status === 201 && response.data) {
                  setLoading(false);
                  setError(false);
                  setSuccess(true);
                  setTimeout(() => {
                    props.history.push("/user/members");
                  }, 1500);
                }
              })
              .catch((err) => {
                setLoading(false);
                setError("Sorry! Could not accept request. Please try again.");
              });
          }
        } catch (err) {
          setLoading(false);
          setError("Invalid Link!");
        }
      }
    }
  }, []);
  return (
    <InvitationContainer>
      <LoadingText>
        {loading
          ? `Processing Join Request`
          : error
          ? error
          : success
          ? `Request Accepted Successfully`
          : `Something went wrong in the process. Please try again.`}
      </LoadingText>
      {loading && <TextLoader loading />}
    </InvitationContainer>
  );
};

export default AcceptJoinRequest;

import styled from 'styled-components';

export const CardIcon = styled.img`
  margin: ${props => (props.margin ? props.margin : '0 auto')};
  height: ${props => (props.height ? props.height : '50px')};
`;

export const AllIntegrationsContainer = styled.div`
  margin: 30px 0px 40px;
`;
export const AllIntegrationsDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 180px);
  grid-gap: 20px;
  justify-content: start;
`;

export const IntegrationCardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 40px;
`;

export const IntegrationCard = styled.div`
  display: grid;
  max-width: 180px;
  padding: 20px;
  border-radius: ${({ theme }) => theme.borderRadii.card};
  background-color: ${({ theme }) => theme.colors.main.white};
  text-align: center;
  justify-items: center;
`;
export const BoldText = styled.p`
  font-size: ${props => (props.size ? props.size : '14px')};
  font-weight: ${props => (props.weight ? props.weight : '600')};
  color: ${props => (props.color ? props.color : '#2f394e')};
  opacity: ${props => (props.opacity ? props.opacity : '1')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0px')};
`;
export const PointerText = styled(BoldText)`
  cursor: pointer;
`;
export const NormalText = styled(BoldText)`
  font-size: 12px;
  font-weight: 400;
  opacity: 0.7;
`;
export const HeaderText = styled.span`
  font-weight: 600;
  font-size: 18px;
  color: #2f394e;
  opacity: 1;
`;
export const SemiText = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
`;
export const ConnectText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ConnectButton = styled.div`
  background-color: ${({ theme }) => theme.colors.main.white};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  width: max-content;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  margin: 10px 0 0;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.main.textSecondary};
  &:hover {
    color: ${({ theme }) => theme.colors.main.white};
    background-color: ${({ theme }) => theme.colors.main.primary};
    border: 1px solid ${({ theme }) => theme.colors.main.primary};
  }
`;
export const IntegrationsTableView = styled.div`
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #c2cce1;
  opacity: 1;
  margin-top: 40px;
  padding-bottom: 80px;
`;
export const IntegrationsTableHeader = styled.div`
  padding: 22px 0px 24px 34px;
`;
export const IntegrationsTableRow = styled.div`
  background-color: ${props =>
    props.color ? 'rgba(248, 249, 252, 1)' : 'rgba(255, 255, 255, 1)'};
  padding: 26px 135px 24px 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Integration = styled.div`
  display: flex;
  align-items: center;
`;
export const ActionDropDown = styled.div`
  min-width: 175px;
`;

export const BackButtonContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
`;
export const BackButton = styled.div`
  background-color: #fcfdfe;
  border-radius: 6px;
  border: 1px solid #e4e7eb;
  width: 40px;
  height: 40px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
export const AsanaDetails = styled.div`
  background-color: #ffffff;
  border: 1px solid #c2cce1;
  border-radius: 10px;
  margin-top: 40px;
  padding: 30px 0px 160px 0px;
`;
export const IntegrationSync = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const AutoSync = styled.div`
  display: flex;
  align-items: center;
`;
export const AutoSyncText = styled.div`
  margin-left: 10px;
`;
export const AsanaApployeProjects = styled.div``;
export const AuthenticateDeleteButton = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  column-gap: 4%;
  max-height: 50px;
`;
export const AsanaApployeName = styled.div``;
export const AsanaPrjects = styled.div``;
export const ApployeProjects = styled.div``;
export const ReauthenticateButton = styled.div`
  background-color: #20bead;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 30px;
  cursor: pointer;
`;
export const DeleteButton = styled.div`
  background-color: #fcfdfe;
  border: 1px solid #e4e7eb;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
export const AsanaApploye = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: 33% 33%;
`;
export const AsanaApployeIntegraion = styled(AsanaApploye)`
  margin: 40px 0px 30px 40px;
  grid-template-columns: 24% 20%;
`;
export const ProjectsName = styled.div`
  display: grid;
  grid-template-columns: 33% 67%;
  margin-top: 15px;
`;
export const IntegrationProjects = styled.div`
  display: flex;
  align-items: center;
`;
export const SyncTimeDate = styled.div`
  display: grid;
  grid-template-columns: 44% 50%;
  column-gap: 5%;
  align-items: center;
`;
export const ProjectName = styled.div`
  min-width: 90%;
  height: 46px;
  background-color: #fcfdfe;
  border: 1px solid #e4e7eb;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 20px;
`;
export const LargeLine = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : '#e4e7eb')};
  height: 1px;
  width: ${props => (props.width ? props.width : '5%')};
`;
export const IntegrationsDetails = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  margin: 0px 30px 40px 40px;
`;
export const IntegrationAssignMembers = styled.div`
  width: 69%;
  display: grid;
  grid-template-columns: 33% 33%;
  margin: 40px 0px 40px 40px;
`;
export const AssignMembers = styled.div`
  display: flex;
`;
export const ConfigureIntegrationContainer = styled.div`
  margin-top: 40px;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #c2cce1;
  padding-top: 24px;
`;
export const ConnectProjectMember = styled.div`
  margin-left: 40px;
  display: flex;
`;
export const ConnectProject = styled.div`
  cursor: pointer;
`;
export const ConnectMember = styled.div`
  cursor: pointer;
  margin-left: 30px;
`;
export const IntegrationWorkplace = styled.div`
  margin: 40px 0px 0px 40px;
`;
export const Workplace = styled.div`
  margin: 11px 0px 330px;
  display: flex;
`;
export const SelectedWorkplace = styled(Workplace)`
  margin: 11px 0px 40px;
`;
export const WorkplaceOption = styled.div`
  width: 23%;
`;
export const NextButton = styled.div`
  background-color: #ffffff;
  border: 1px solid #20bead;
  border-radius: 6px;
  opacity: 1;
  padding: 8px 35px 13px;
  height: 38px;
  margin-left: 20px;
  cursor: pointer;
`;
export const SaveButton = styled.div`
  height: 40px;
  background-color: #20bead;
  border-radius: 6px;
  margin: 0px 12.7% 0px 24.6%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
export const IntegrationProjectSaveSync = styled.div`
  display: grid;
  grid-template-columns: 20% 4% 20% 11% 5.5% 39.5%;
  margin: 0px 0px 30px 40px;
  align-items: center;
`;
export const SelectProjectOption = styled.div``;
export const SyncText = styled.div`
  display: flex;
  gap: 20px;
  margin-right: 30px;
`;
export const AddMoreProject = styled.div`
  display: flex;
  margin: 0px 0px 231px 40px;
  cursor: pointer;
  width: 158px;
`;

//Effects
import { all, put, select, takeLatest } from 'redux-saga/effects';

import {
  CREATE_SHIFT,
  CREATE_SHIFT_FAILURE,
  CREATE_SHIFT_SUCCESS,
  DELETE_SHIFT,
  DELETE_SHIFT_FAILURE,
  DELETE_SHIFT_SUCCESS,
  EDIT_SHIFT,
  EDIT_SHIFT_ASSIGNEES_ADD,
  EDIT_SHIFT_ASSIGNEES_ADD_FAILURE,
  EDIT_SHIFT_ASSIGNEES_ADD_SUCCESS,
  EDIT_SHIFT_ASSIGNEES_REMOVE,
  EDIT_SHIFT_ASSIGNEES_REMOVE_FAILURE,
  EDIT_SHIFT_ASSIGNEES_REMOVE_SUCCESS,
  EDIT_SHIFT_FAILURE,
  EDIT_SHIFT_SUCCESS,
  GET_JOB_SHIFTS_LIST,
  GET_JOB_SHIFTS_LIST_FAILURE,
  GET_JOB_SHIFTS_LIST_SUCCESS,
  GET_OFFICE_SHIFTS_LIST,
  GET_OFFICE_SHIFTS_LIST_FAILURE,
  GET_OFFICE_SHIFTS_LIST_SUCCESS,
  GET_SHIFT_DETAILS,
  GET_SHIFT_DETAILS_FAILURE,
  GET_SHIFT_DETAILS_SUCCESS,
} from '../../modules/constants';
//Service
import { defaultApi } from '../../utils/axiosApi';
import details from '../IntegrationsNew/Details';

export function* handleGetOfficeShiftsList({ payload }) {
  const { organization_id, start_date, end_date, assignee_id } = payload;
  try {
    const url = `${organization_id}/office_schedules/?start_time=${start_date}&end_time=${end_date}${
      assignee_id ? `&assignee_id=${assignee_id}` : ''
    }`;
    const response = yield defaultApi(url, 'GET', payload);
    yield put({
      type: GET_OFFICE_SHIFTS_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_OFFICE_SHIFTS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* handleGetJobShiftsList({ payload }) {
  const {
    organization_id,
    paginate,
    start_date,
    end_date,
    page,
    assignee_id,
    job_name,
    status,
    work_order_id,
    approval_status,
  } = payload;
  try {
    const url = `${organization_id}/workorder/job_work_order_schedules/?paginate=${paginate}${
      start_date ? `&start_time=${start_date}` : ""
    }${end_date ? `&end_time=${end_date}` : ""}${assignee_id ? `&assignee_id=${assignee_id}` : ""}${
      page ? `&page=${page}` : ""
    }${job_name ? `&name=${job_name}` : ""}${
      status ? `&status=${status}` : ""
    }${work_order_id ? `&work_order_id=${work_order_id}` : ""}${
      approval_status ? `&approval_status=${approval_status}` : ""
    }`;
    const response = yield defaultApi(url, 'GET', payload);
    yield put({
      type: GET_JOB_SHIFTS_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_JOB_SHIFTS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* handleCreateShift({ payload }) {
  try {
    const { organization_id, ...details } = payload;
    const url = `${organization_id}/office_schedules/`;
    const method = 'POST';
    const response = yield defaultApi(url, method, details);
    yield put({
      type: CREATE_SHIFT_SUCCESS,
      payload: { data: response, notify: 'POST' },
    });
  } catch (err) {
    yield put({
      type: CREATE_SHIFT_FAILURE,
      payload: err,
    });
  }
}

export function* handleGetShiftDetails({ payload }) {
  try {
    const { organization_id, shift_id } = payload;
    const url = `${organization_id}/schedules/${shift_id}/`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_SHIFT_DETAILS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_SHIFT_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* handleEditShift({ payload }) {
  try {
    const { organization_id, shift_id, ...details } = payload;
    const url = `${organization_id}/schedules/${shift_id}/`;
    const response = yield defaultApi(url, 'PUT', details);
    yield put({
      type: EDIT_SHIFT_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: EDIT_SHIFT_FAILURE,
      payload: err,
    });
  }
}

export function* handleEditShiftAssigneesAdd({ payload }) {
  try {
    const { organization_id, shift_id, ...details } = payload;
    const url = `${organization_id}/schedules/${shift_id}/assignees/`;
    const method = 'POST';
    const response = yield defaultApi(url, method, details);
    yield put({
      type: EDIT_SHIFT_ASSIGNEES_ADD_SUCCESS,
      payload: { data: response, notify: 'POST' },
    });
  } catch (err) {
    yield put({
      type: EDIT_SHIFT_ASSIGNEES_ADD_FAILURE,
      payload: err,
    });
  }
}

export function* handleEditShiftAssigneesRemove({ payload }) {
  try {
    const { organization_id, shift_id, user_id } = payload;
    const url = `${organization_id}/schedules/${shift_id}/assignees/${user_id}`;
    const method = 'DELETE';
    const response = yield defaultApi(url, method);
    yield put({
      type: EDIT_SHIFT_ASSIGNEES_REMOVE_SUCCESS,
      payload: { data: response, notify: 'DELETE' },
    });
  } catch (err) {
    yield put({
      type: EDIT_SHIFT_ASSIGNEES_REMOVE_FAILURE,
      payload: err,
    });
  }
}

export function* handleDeleteShift({ payload }) {
  try {
    const { organization_id, shift_id, ...details } = payload;
    const url = `${organization_id}/office_schedules/${shift_id}/`;
    const method = 'DELETE';
    const response = yield defaultApi(url, method, details);
    yield put({
      type: DELETE_SHIFT_SUCCESS,
      payload: { data: response, notify: 'DELETE' },
    });
  } catch (err) {
    yield put({
      type: DELETE_SHIFT_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    // Get shifts list
    takeLatest(GET_OFFICE_SHIFTS_LIST, handleGetOfficeShiftsList),
    takeLatest(GET_JOB_SHIFTS_LIST, handleGetJobShiftsList),
    // Get shift details
    takeLatest(GET_SHIFT_DETAILS, handleGetShiftDetails),
    // Create shift
    takeLatest(CREATE_SHIFT, handleCreateShift),
    //edit shift
    takeLatest(EDIT_SHIFT, handleEditShift),
    //edit shift
    takeLatest(EDIT_SHIFT_ASSIGNEES_ADD, handleEditShiftAssigneesAdd),
    //edit shift
    takeLatest(EDIT_SHIFT_ASSIGNEES_REMOVE, handleEditShiftAssigneesRemove),
    //Delete shift
    takeLatest(DELETE_SHIFT, handleDeleteShift),
  ]);
}

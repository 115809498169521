import moment from 'moment';
import react, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';

import tick from '../../../assets/img/onboardingImg/tick-black.svg';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import AddMultipleAssigneeDropdown from '../../../components/CustomDropdown/AddMultipleAssigneeDropdown';
import CreateProjectDropdown from '../../../components/DropdownStyle/CreateProjectDropdown';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import Select from '../../../components/ReactSelectDropdown';
import { filterOption } from '../../../components/ReactSelectDropdown/ReactSelectDropdown';
import SingleDatePicker from '../../../components/SingleDatePicker/SingleDatePicker';
import { PrimaryButton, WhiteButton } from '../../../styledComponents/buttons';
import {
  CardTitle,
  CommonFlex,
  CommonGrid,
  CommonText,
  Container,
  ContentHeader,
  FormAlert,
  InputField,
  InputLabel,
  InputWithLabelSection,
  PageTitle,
} from '../../../styledComponents/common';
import {
  AdditionalContainer,
  AdditionalOptionSection,
  AssigneeSection,
} from '../../../styledComponents/createProject';
import {
  ToggleButton,
  ToggleButtonRound,
} from '../../../styledComponents/invoice';
import { UserSettings } from '../../../styledComponents/members';
import { RedStar } from '../../../styledComponents/members';
import { checkHtml, checkUrl, validateEmail } from '../../../utils/helper';
import { CommDropdownComp } from '../../Tasks/AddAssigneeDropdown';
import TimeRangeComponent from '../../Timesheet/Daily/TimeRangeComponent';
import {
  AdditionalOptionText,
  RepeatSection,
  ThemeBox,
  ThemesContainer,
  WeekDaysContainer,
  WeekdayBox,
} from '../CreateShift/createShiftStyles';
import { RadioButton } from '../DeleteShiftPopup';

const AdditionalOption = props => (
  <AdditionalOptionSection active={props.active}>
    <AdditionalOptionText> {props.text} </AdditionalOptionText>
  </AdditionalOptionSection>
);

const EditShift = ({
  selectedOrganization,
  memberShortListIsLoading,
  detailsIsLoading,
  editIsLoading,
  editAssigneesIsLoading,

  orgMembersShortList,
  shiftDetails,
  editedAssigneeList,
  editCallback,
  jobShortList,

  getOrganizationMembersShortList,
  getShiftDetails,
  editShiftAssigneesAdd,
  editShiftAssigneesRemove,
  editShift,

  isOpen,
  toggle,
  selectedStartDate,
  shift,
  onEditChange,
}) => {
  const { t, i18n } = useTranslation();
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [isRepeat, setIsRepeat] = useState(false);
  const [selectedRepeatType, setSelectedRepeatType] = useState('');
  const repeatOptions = [
    {
      label: 'Day',
      value: 'daily',
    },
    {
      label: 'Week',
      value: 'weekly',
    },
    {
      label: 'Month',
      value: 'monthly',
    },
  ];
  const [activeDays, setActiveDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const weekParse = {
    monday: 0,
    tuesday: 1,
    wednesday: 2,
    thursday: 3,
    friday: 4,
    saturday: 5,
    sunday: 6,
  };
  const weekArray = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];
  const [endDate, setEndDate] = useState(null);
  const [selectedJob, setSelectedJob] = useState('');
  const [jobOptions, setJobOptions] = useState([]);
  const [themeColor, setThemeColor] = useState('');
  const [editType, setEditType] = useState('single');
  const [saveErrors, setSaveErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id && isOpen) {
      getOrganizationMembersShortList({
        organization_id: selectedOrganization.id,
        office_id: null,
      });
      getShiftDetails({
        organization_id: selectedOrganization.id,
        shift_id: shift.id,
      });
    }
    return () => {
      clearState();
    };
  }, [selectedOrganization, isOpen]);

  useEffect(() => {
    if (shiftDetails && Object.keys(shiftDetails).length > 0) {
      if (shiftDetails.assignees && shiftDetails.assignees.length > 0) {
        let newArr = [];
        shiftDetails.assignees.forEach(assignee => {
          newArr.push({
            ...assignee,
            name:
              `${assignee.first_name} ${assignee.last_name}`.trim() ||
              assignee.first_name,
          });
        });
        setSelectedAssignees(newArr);
      }
      if (shiftDetails.start_date) {
        setStartDate(moment(shiftDetails.start_date));
      }
      if (shiftDetails.start_time) {
        setStartTime(moment(shiftDetails.start_time).format('HH:mm:ss'));
      }
      if (shiftDetails.end_time) {
        setEndTime(moment(shiftDetails.end_time).format('HH:mm:ss'));
      }
      if (
        shiftDetails.job &&
        shiftDetails.job.length > 0 &&
        shiftDetails.job[0].id
      ) {
        setSelectedJob({
          label: shiftDetails.job[0].name,
          value: shiftDetails.job[0].id,
        });
      }
      if (shiftDetails.theme_color) {
        setThemeColor(shiftDetails.theme_color);
      }
      if (shiftDetails.recurring) {
        setIsRepeat(true);
        if (shiftDetails.schedule_type) {
          repeatOptions.forEach(option => {
            if (option.value === shiftDetails.schedule_type) {
              setSelectedRepeatType(option);
            }
          });
        }
        if (shiftDetails.end_date) {
          setEndDate(moment(shiftDetails.end_date));
        }
        if (
          shiftDetails.schedule_type === 'weekly' &&
          shiftDetails.days_of_week &&
          shiftDetails.days_of_week.length > 0
        ) {
          let tempActiveDays = { ...activeDays };
          let tempArray = [];
          shiftDetails.days_of_week.forEach(day => {
            tempArray.push(weekArray[day]);
          });
          tempArray.forEach(element => {
            tempActiveDays[element] = true;
          });
          setActiveDays(tempActiveDays);
        }
      }
    }
  }, [shiftDetails]);

  useEffect(() => {
    if (jobShortList && jobShortList.length > 0) {
      let jobOptions = [];
      jobShortList.forEach(job => {
        jobOptions.push({
          label: job.name,
          value: job.id,
        });
      });
      setJobOptions(jobOptions);
    }
  }, [jobShortList]);

  const handleAssignOrRemoveMember = (user, action) => {
    if (user && user.id) {
      let errors = { ...saveErrors };
      delete errors['selectedAssignees'];
      setSaveErrors(errors);
      let newArr = [...selectedAssignees];
      if (action === 'remove') {
        newArr = newArr.filter(member => member.id !== user.id);
      } else {
        newArr.push(user);
      }
      setSelectedAssignees(newArr);
    }
  };

  const handleAssignMember = user => {
    handleAssignOrRemoveMember(user, 'assign');
    if (user && user.id) {
      editShiftAssigneesAdd({
        organization_id: selectedOrganization.id,
        shift_id: shiftDetails.id,
        user_id: user.id,
      });
    }
  };

  const handleRemoveMember = user => {
    handleAssignOrRemoveMember(user, 'remove');
    if (user && user.id) {
      editShiftAssigneesRemove({
        organization_id: selectedOrganization.id,
        shift_id: shiftDetails.id,
        user_id: user.id,
      });
    }
  };

  const onStartDateSelect = date => {
    setStartDate(date);
  };

  const handleStartTime = time => {
    if (time) {
      let errors = { ...saveErrors };
      delete errors['timeDifference'];
      setSaveErrors(errors);
      const start_time = time.replace(/ /g, '');
      setStartTime(moment(start_time, 'h:mmA').format('HH:mm:ss'));
    } else {
      setStartTime('');
    }
  };
  const handleEndTime = time => {
    if (time) {
      let errors = { ...saveErrors };
      delete errors['timeDifference'];
      setSaveErrors(errors);
      const end_time = time.replace(/ /g, '');
      setEndTime(moment(end_time, 'h:mmA').format('HH:mm:ss'));
    } else {
      setEndTime('');
    }
  };

  const onRepeatTypeChange = e => {
    let errors = { ...saveErrors };
    delete errors['selectedRepeatType'];
    setSaveErrors(errors);
    setSelectedRepeatType(e);
  };

  const onEndDateSelect = date => {
    let errors = { ...saveErrors };
    delete errors['endDate'];
    setSaveErrors(errors);
    setEndDate(date);
  };

  const onActiveDaysSelect = day => {
    let errors = { ...saveErrors };
    delete errors['activeDays'];
    setSaveErrors(errors);
    let tempActiveDays = { ...activeDays };
    tempActiveDays[day] = !tempActiveDays[day];
    setActiveDays(tempActiveDays);
  };

  const onJobChange = e => {
    setSelectedJob(e);
  };

  const handleThemeColor = color => {
    setThemeColor(color);
  };

  const handleFinish = () => {
    toggle();
  };

  const clearState = () => {
    setSelectedAssignees([]);
    setStartDate(moment(new Date()));
    setStartTime('');
    setEndTime('');
    setIsRepeat(false);
    setSelectedRepeatType('');
    setActiveDays({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    });
    setEndDate(null);
    setSelectedJob('');
    setThemeColor('');
    setEditType('single');
    setSaveErrors({});
    setIsSubmitted(false);
    setPage(1);
  };

  const onChangeEditType = (type) => {
    if(type === "single"){
      setEditType("single");
    }
  }

  const handleSave = () => {
    if (page === 1 && isRepeat && checkError()) {
      setPage(2);
    } else if (
      (page === 1 && !isRepeat && checkError()) ||
      (page === 2 && selectedOrganization && selectedOrganization.id)
    ) {
      let assigneeIds = [];
      if (selectedAssignees && selectedAssignees.length > 0) {
        selectedAssignees.forEach(element => {
          assigneeIds.push(element.id);
        });
      }

      let payload = {
        organization_id: selectedOrganization.id,
        shift_id: shiftDetails.id,
        start_date: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
        start_time: moment(
          startDate.format('YYYY-MM-DD') + ' ' + startTime,
          'YYYY-MM-DD h:mmA',
        ).format('YYYY-MM-DDTHH:mm:ss'),
        end_time: moment(
          startDate.format('YYYY-MM-DD') + ' ' + endTime,
          'YYYY-MM-DD h:mmA',
        ).format('YYYY-MM-DDTHH:mm:ss'),
        recurring: isRepeat,
      };
      if (!isRepeat) {
        payload.end_date = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');
      }
      if (isRepeat) {
        payload.schedule_type = selectedRepeatType.value;
        payload.end_date = endDate.format('YYYY-MM-DDTHH:mm:ss');
      }
      if (isRepeat && selectedRepeatType.value === 'weekly') {
        let tempActiveDaysArray = [];
        for (let key in activeDays) {
          if (activeDays[key]) {
            tempActiveDaysArray.push(weekParse[key]);
          }
        }
        payload.days_of_week = tempActiveDaysArray;
      }
      if (selectedJob && selectedJob.value) {
        payload.job_id = selectedJob.value;
      }
      if (themeColor) {
        payload.theme_color = themeColor;
      }
      editShift(payload);
      setIsSubmitted(true);
      onEditChange(true);
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;

    if (selectedAssignees.length === 0) {
      formIsValid = false;
      saveErrors['selectedAssignees'] = 'Assignee is required';
    }
    if (!startDate) {
      formIsValid = false;
    }
    if (!startTime || !endTime) {
      formIsValid = false;
    }
    if (
      moment(startDate.format('YYYY-MM-DD') + ' ' + endTime).diff(
        moment(startDate.format('YYYY-MM-DD') + ' ' + startTime),
      ) < 1
    ) {
      formIsValid = false;
      saveErrors['timeDifference'] = 'End time must be after start time';
    } else if (
      moment(startDate.format('YYYY-MM-DD') + ' ' + endTime).diff(
        moment(startDate.format('YYYY-MM-DD') + ' ' + startTime),
      ) < 1800000
    ) {
      formIsValid = false;
      saveErrors['timeDifference'] =
        'Minmium 30 minutes time difference required';
    }
    if (isRepeat && !selectedRepeatType) {
      formIsValid = false;
      saveErrors['selectedRepeatType'] = 'Shift repeat type must be set';
    }
    if (isRepeat && !endDate) {
      formIsValid = false;
      saveErrors['endDate'] = 'Ending date is must';
    }
    if (isRepeat && selectedRepeatType && selectedRepeatType.value === 'weekly') {
      let haveActiveDays = false;
      Object.keys(activeDays).forEach(key => {
        if (activeDays[key] === true) {
          haveActiveDays = true;
        }
      });
      if (!haveActiveDays) {
        formIsValid = false;
        saveErrors['activeDays'] = 'At least one day needs to be selected';
      }
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const ThemeColorComp = ({ color }) => (
    <ThemeBox color={color} onClick={() => handleThemeColor(color)}>
      {themeColor === color && <img src={tick} alt="" width="18px" />}
    </ThemeBox>
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ maxWidth: `660px` }}
      backdrop="static"
    >
      <ModalBody style={{ padding: `40px`, minHeight: `800px` }}>
        <CardTitle>{t('edit_shift')}</CardTitle>
        {detailsIsLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            {page === 1 ? (
              <CommonGrid columns="auto" gap="25px" justifyContent="start">
                <AssigneeSection>
                  <InputLabel>
                    {t('assignee ')}
                    <RedStar>*</RedStar>
                  </InputLabel>

                  <AddMultipleAssigneeDropdown
                    numberOfVisibleMembers={5}
                    isLoading={memberShortListIsLoading}
                    assigneesList={selectedAssignees}
                    membersList={orgMembersShortList}
                    handleAssign={handleAssignMember}
                    handleRemove={handleRemoveMember}
                  />

                  {saveErrors && saveErrors['selectedAssignees'] && (
                    <FormAlert>{saveErrors['selectedAssignees']}</FormAlert>
                  )}
                </AssigneeSection>

                <CommonGrid columns="auto 210px" alignItem="start">
                  <InputWithLabelSection>
                    <InputLabel>
                      {isRepeat ? (
                        <>{t('shift_start_date')}</>
                      ) : (
                        <>{t('select_date')}</>
                      )}{' '}
                      <RedStar>*</RedStar>
                    </InputLabel>
                    <SingleDatePicker
                      dateDisplayFormat="DD/MM/YYYY"
                      placeholder="Select Date"
                      date={startDate ? startDate : null}
                      onDateChange={onStartDateSelect}
                      allowPreviousDates
                      disabled={isRepeat}
                    />
                  </InputWithLabelSection>
                  <InputWithLabelSection>
                    <CommonGrid columns="45% 7% 43%" gap="5px">
                      <InputLabel>
                        {t('start_time')}
                        <RedStar> *</RedStar>
                      </InputLabel>
                      <InputLabel>-</InputLabel>
                      <InputLabel>
                        {t('end_time')}
                        <RedStar> *</RedStar>
                      </InputLabel>
                    </CommonGrid>
                    <TimeRangeComponent
                      setStartTime={handleStartTime}
                      setEndTime={handleEndTime}
                      initialStartTime={
                        shiftDetails &&
                        shiftDetails.start_time &&
                        moment(shiftDetails.start_time).format('HH:mm')
                      }
                      initialEndTime={
                        shiftDetails &&
                        shiftDetails.end_time &&
                        moment(shiftDetails.end_time).format('HH:mm')
                      }
                      startTimeError={
                        saveErrors &&
                        saveErrors['timeDifference'] !== undefined &&
                        saveErrors['timeDifference'] &&
                        saveErrors['timeDifference']
                      }
                      endTimeError={
                        saveErrors &&
                        saveErrors['timeDifference'] !== undefined &&
                        saveErrors['timeDifference'] &&
                        saveErrors['timeDifference']
                      }
                    />
                    {saveErrors &&
                      saveErrors['timeDifference'] !== undefined &&
                      saveErrors['timeDifference'] && (
                        <FormAlert>{saveErrors['timeDifference']}</FormAlert>
                      )}
                  </InputWithLabelSection>
                </CommonGrid>

                <div>
                  <CommonGrid justifyContent="start" alignItem="center">
                    <ToggleButton
                      onClick={() => setIsRepeat(!isRepeat)}
                      active={isRepeat}
                    >
                      <ToggleButtonRound active={isRepeat} />
                    </ToggleButton>
                    <InputLabel>{t('shift_repeat')}</InputLabel>
                  </CommonGrid>

                  {isRepeat && (
                    <RepeatSection>
                      <InputWithLabelSection>
                        <InputLabel>
                          {t('every')} <RedStar>*</RedStar>
                        </InputLabel>
                        <Select
                          value={selectedRepeatType}
                          options={repeatOptions}
                          placeholder={t('select_type')}
                          onChange={e => {
                            onRepeatTypeChange(e);
                          }}
                          isDisabled
                          styles={CreateProjectDropdown({
                            error: saveErrors['selectedRepeatType'],
                          })}
                        />

                        {saveErrors &&
                          saveErrors['selectedRepeatType'] !== undefined &&
                          saveErrors['selectedRepeatType'] && (
                            <FormAlert>
                              {saveErrors['selectedRepeatType']}
                            </FormAlert>
                          )}
                      </InputWithLabelSection>

                      {selectedRepeatType &&
                        selectedRepeatType.value === 'weekly' && (
                          <>
                            <InputWithLabelSection>
                              <InputLabel
                                error={
                                  saveErrors &&
                                  saveErrors['activeDays'] !== undefined &&
                                  saveErrors['activeDays'] &&
                                  saveErrors['activeDays']
                                }
                              >
                                {t('on_these_days')} <RedStar>*</RedStar>
                              </InputLabel>
                              <WeekDaysContainer>
                                <WeekdayBox
                                  active={activeDays.monday}
                                  onClick={() => onActiveDaysSelect('monday')}
                                >
                                  {t('mon')}
                                </WeekdayBox>
                                <WeekdayBox
                                  active={activeDays.tuesday}
                                  onClick={() => onActiveDaysSelect('tuesday')}
                                >
                                  {t('tue')}
                                </WeekdayBox>
                                <WeekdayBox
                                  active={activeDays.wednesday}
                                  onClick={() =>
                                    onActiveDaysSelect('wednesday')
                                  }
                                >
                                  {t('wed')}
                                </WeekdayBox>
                                <WeekdayBox
                                  active={activeDays.thursday}
                                  onClick={() => onActiveDaysSelect('thursday')}
                                >
                                  {t('thu')}
                                </WeekdayBox>
                                <WeekdayBox
                                  active={activeDays.friday}
                                  onClick={() => onActiveDaysSelect('friday')}
                                >
                                  {t('fri')}
                                </WeekdayBox>
                                <WeekdayBox
                                  active={activeDays.saturday}
                                  onClick={() => onActiveDaysSelect('saturday')}
                                >
                                  {t('sat')}
                                </WeekdayBox>
                                <WeekdayBox
                                  active={activeDays.sunday}
                                  onClick={() => onActiveDaysSelect('sunday')}
                                >
                                  {t('sun')}
                                </WeekdayBox>
                              </WeekDaysContainer>
                            </InputWithLabelSection>
                            {saveErrors &&
                              saveErrors['activeDays'] !== undefined &&
                              saveErrors['activeDays'] && (
                                <FormAlert>
                                  {saveErrors['activeDays']}
                                </FormAlert>
                              )}
                          </>
                        )}

                      <InputWithLabelSection>
                        <InputLabel>
                          {t('ending_on')} <RedStar>*</RedStar>
                        </InputLabel>
                        <SingleDatePicker
                          dateDisplayFormat="DD/MM/YYYY"
                          placeholder="Select End Date"
                          date={endDate ? endDate : null}
                          onDateChange={onEndDateSelect}
                          disablePreviousDatesFrom={endDate}
                          error={
                            saveErrors &&
                            saveErrors['endDate'] !== undefined &&
                            saveErrors['endDate'] &&
                            saveErrors['endDate']
                          }
                        />
                        {saveErrors &&
                          saveErrors['endDate'] !== undefined &&
                          saveErrors['endDate'] && (
                            <FormAlert>{saveErrors['endDate']}</FormAlert>
                          )}
                      </InputWithLabelSection>
                    </RepeatSection>
                  )}
                </div>

                <InputWithLabelSection maxWidth="270px">
                  <InputLabel>{t('select_job')}</InputLabel>
                  <Select
                    isSearchable
                    value={selectedJob}
                    options={jobOptions}
                    placeholder={t('select_job')}
                    onChange={e => {
                      onJobChange(e);
                    }}
                    styles={CreateProjectDropdown()}
                    filterOption={filterOption}
                  />
                </InputWithLabelSection>

                <InputWithLabelSection>
                  <InputLabel>{t('choose_theme')}</InputLabel>
                  <ThemesContainer>
                    <ThemeColorComp color="#D4F4EE" />
                    <ThemeColorComp color="#D2E4FE" />
                    <ThemeColorComp color="#D9D7FC" />
                    <ThemeColorComp color="#FDF3E4" />
                  </ThemesContainer>
                </InputWithLabelSection>
              </CommonGrid>
            ) : (
              <CommonGrid
                columns="auto"
                margin="25px 0 0 0"
                justifyContent="start"
              >
                <CardTitle fontSize="20px">Apply Changes to</CardTitle>
                <UserSettings>
                  <RadioButton
                    active={editType === 'single'}
                    onClick={() => onChangeEditType('single')}
                  />
                  <CommonText name fontSize="15px">
                    This shift only
                  </CommonText>
                  {/* <RadioButton
                    active={editType === 'future'}
                    onClick={() => onChangeEditType('recurring')}
                  />
                  <CommonText name fontSize="15px">
                    {' '}
                    This shift and all future shifts
                  </CommonText> */}
                </UserSettings>
              </CommonGrid>
            )}
            <CommonGrid
              columns="auto"
              margin="25px 0 0 0"
              justifyContent="start"
            >
              <CommonFlex gap="12px">
                <WhiteButton
                  width="140px"
                  type="cancel"
                  onClick={() => handleFinish()}
                >
                  {t('cancel')}
                </WhiteButton>
                <PrimaryButton
                  width="140px"
                  disabled={
                    selectedAssignees.length === 0 || !startTime || !endTime
                  }
                  onClick={() => handleSave()}
                >
                  {/* {inviteMemberLoading ? (
                  <ButtonTextLoader
                    loadingText="Creating Shift"
                    fontSize="13px"
                  />
                ) : (
                  "Create Shift"
                )} */}
                  {page === 1 && isRepeat ? t('proceed') : t('edit_shift')}
                </PrimaryButton>
              </CommonFlex>
            </CommonGrid>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default EditShift;

import { connect } from 'react-redux';

import { getOrganizationMembersList,  getOrganizationMembersShortList, } from '../../Projects/projectsActions';
// Actions
import {
  clearOfficeCreatedUpdated,
  clearOfficeDetails,
  createOffice,
  getOfficeMembersList,
  setOfficeDetails,
  updateOffice,
  addOfficeMember,
  removeOfficeMember,
  updateOfficeMember,
  getOfficeDetails,
  updateOfficeInfo,
  addOfficeLocation,
  clearCreatedOfficeId,
} from '../officesActions';
// Component
import AddOffice from './AddOffice';

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  orgMembersList: state.project.orgMembersList,
  officeMembersList: state.office.officeMembersList,
  orgMembersShortList: state.project.orgMembersShortList,
  officeMemberListLoading: state.office.officeMemberListLoading,
  memberShortListIsLoading: state.project.memberShortListIsLoading,
  officeMemberIsLoading: state.office.officeMemberIsLoading,
  isLoading: state.office.isLoading,
  createdOfficeId: state.office.createdOfficeId,
  isOfficeLimitFull : state.office.isOfficeLimitFull,
  officeDetails: state.office.officeDetails,
  officeCreateUpdateSuccess: state.office.officeCreateUpdateSuccess,
  addOrUpdateOfficeLoading: state.office.addOrUpdateOfficeLoading,
  loadingMemberId: state.office.loadingMemberId,
  officeLocation: state.office.officeLocation,
  officeLocationCreateUpdateSuccess: state.office.officeLocationCreateUpdateSuccess,
  addOrUpdateOfficeLocalMapLoading: state.office.addOrUpdateOfficeLocalMapLoading,
  createdOfficeLocationInfo: state.office.createdOfficeLocationInfo,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getOrganizationMembersList: payload =>
    dispatch(getOrganizationMembersList(payload)),
    getOrganizationMembersShortList: params =>
    dispatch(getOrganizationMembersShortList(params)),
  getOfficeMembersList: payload => dispatch(getOfficeMembersList(payload)),
  addOfficeMember: payload => dispatch(addOfficeMember(payload)),
  removeOfficeMember: payload => dispatch(removeOfficeMember(payload)),
  setOfficeDetails: params => dispatch(setOfficeDetails(params)),
  clearOfficeCreatedUpdated: () => dispatch(clearOfficeCreatedUpdated()),
  createOffice: payload => dispatch(createOffice(payload)),
  updateOffice: payload => dispatch(updateOffice(payload)),
  clearOfficeDetails: () => dispatch(clearOfficeDetails()),
  updateOfficeMember: payload => dispatch(updateOfficeMember(payload)),
  getOfficeDetails: payload => dispatch(getOfficeDetails(payload)),
  updateOfficeInfo: payload => dispatch(updateOfficeInfo(payload)),
  addOfficeLocation: payload => dispatch(addOfficeLocation(payload)),
  clearCreatedOfficeId: () => dispatch(clearCreatedOfficeId()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(AddOffice);

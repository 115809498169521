import { connect } from "react-redux";

// Actions
import {
  addClientLocation,
  getClientList,
  getClientLocation,
  getClientShortList,
  updateClientLocation,
} from "../../ClientsNew/clientsActions";
import {
  addJobAssignee,
  getJobDetails,
  removeJobAssignee,
} from "../../Jobs/jobsActions";
import { getOrganizationMembersShortList } from "../../Projects/projectsActions";
import {
  clearWorkOrderBilling,
  clearWorkOrderBudget,
  createJobRequest,
  createWorkOrderBill,
  createWorkOrderBudget,
  createWorkOrderRequest,
  getJobsUnderWorkOrderRequest,
  getWorkOrder,
  getWorkOrderBill,
  getWorkOrderBudget,
  updateJobRequest,
  updateWorkOrder,
  updateWorkOrderBill,
  updateWorkOrderBudget,
} from "../workOrderActions";
import { getJobShiftsList } from "../../ShiftSchedule/shiftScheduleActions";
import EditWorkOrder from "./EditWorkOrder";

// Store props to map with current state
const mapStateToProps = (state) => ({
  clientList: state.client.clientList,
  clientShortList: state.client.clientShortList,
  selectedOrganization: state.organization.selectedOrganization,
  createWorkOrderIsLoading: state.workorder.createWorkOrderIsLoading,
  createOrUpdateJobIsLoading: state.workorder.createOrUpdateJobIsLoading,
  createWorkOrderIsLoading: state.workorder.createWorkOrderIsLoading,
  getJobsUnderWorkOrderIsLoading:
    state.workorder.getJobsUnderWorkOrderIsLoading,
  jobsUnderWorkOrderList: state.workorder.jobsUnderWorkOrderList,
  totalJobsCount: state.workorder.totalJobsCount,
  pageSizeJobs: state.workorder.pageSizeJobs,
  UpdateWorkOrderLoading: state.workorder.UpdateWorkOrderLoading,
  clientLocationList: state.client.clientLocationList,
  getclientLocalMapLoading: state.client.getclientLocalMapLoading,
  addOrUpdateclientLocalMapLoading:
    state.client.addOrUpdateclientLocalMapLoading,
  createdClientLocationInfo: state.client.createdClientLocationInfo,
  createdJobId: state.workorder.createdJobId,
  createdJob: state.workorder.createdJob,
  jobUpdate: state.workorder.jobUpdate,
  getWorkOrderIsLoading: state.workorder.getWorkOrderIsLoading,
  workOrderDetails: state.workorder.workOrderDetails,
  // jobs
  isLoadingJobs: state.schedule.jobListIsLoading,
  totaljobsCount: state.schedule.totaljobsCount,
  pageSizeJob: state.schedule.pageSizeJob,
  jobList: state.schedule.jobShiftsList,

  //bill and budget
  saveBillingLoading: state.workorder.saveBillingLoading,
  WorkOrderBilling: state.workorder.WorkOrderBilling,
  getBillingLoading: state.workorder.getBillingLoading,
  isBillAddOrUpdate: state.workorder.isBillAddOrUpdate,

  saveBudgetLoading: state.workorder.saveBudgetLoading,
  workOrderBudget: state.workorder.workOrderBudget,
  getBudgetLoading: state.workorder.getBudgetLoading,
  isBudgetAddOrUpdate: state.workorder.isBudgetAddOrUpdate,

  // shift section
  memberShortListIsLoading: state.project.memberShortListIsLoading,
  orgMembersList: state.project.orgMembersList,
  orgMembersShortList: state.project.orgMembersShortList,

  // job Details
  getJobIsLoading: state.jobs.getJobIsLoading,
  jobDetails: state.jobs.jobDetails,
  //assignees
  JobMemberIsLoading: state.jobs.JobMemberIsLoading,
  loadingjobId: state.jobs.loadingjobId,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getClientList: (details) => dispatch(getClientList({ ...details })),
  getClientShortList: (details) => dispatch(getClientShortList({ ...details })),
  createWorkOrderRequest: (details) =>
    dispatch(createWorkOrderRequest({ ...details })),
  createJobRequest: (details) => dispatch(createJobRequest({ ...details })),
  getJobsList: (details) => dispatch(getJobShiftsList({ ...details })),
  getJobsUnderWorkOrderRequest: (details) =>
    dispatch(getJobsUnderWorkOrderRequest({ ...details })),
  updateJobRequest: (details) => dispatch(updateJobRequest({ ...details })),
  updateWorkOrder: (details) => dispatch(updateWorkOrder({ ...details })),
  getClientLocation: (details) => dispatch(getClientLocation({ ...details })),
  addClientLocation: (details) => dispatch(addClientLocation({ ...details })),
  updateClientLocation: (details) =>
    dispatch(updateClientLocation({ ...details })),
  getWorkOrder: (details) => dispatch(getWorkOrder({ ...details })),
  createWorkOrderBill: (details) =>
    dispatch(createWorkOrderBill({ ...details })),
  getWorkOrderBill: (details) => dispatch(getWorkOrderBill({ ...details })),
  updateWorkOrderBill: (details) =>
    dispatch(updateWorkOrderBill({ ...details })),
  createWorkOrderBudget: (details) =>
    dispatch(createWorkOrderBudget({ ...details })),
  getWorkOrderBudget: (details) => dispatch(getWorkOrderBudget({ ...details })),
  updateWorkOrderBudget: (details) =>
    dispatch(updateWorkOrderBudget({ ...details })),
  clearWorkOrderBilling: () => dispatch(clearWorkOrderBilling()),
  clearWorkOrderBudget: () => dispatch(clearWorkOrderBudget()),
  getOrganizationMembersShortList: (params) =>
    dispatch(getOrganizationMembersShortList(params)),
  getJobDetails: (details) => dispatch(getJobDetails({ ...details })),
  addJobAssignee: (details) => dispatch(addJobAssignee({ ...details })),
  removeJobAssignee: (details) => dispatch(removeJobAssignee({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(EditWorkOrder);

import ReactWeekPicker from 'components/ReactWeekPicker/ReactWeekPicker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LeftArrow_3 from '../../assets/img/icons/LeftArrow_3.svg';
import RightArrow_3 from '../../assets/img/icons/RightArrow_3.svg';
import CommonFilter from '../../components/CommonFilter/commonFilterContainer';
import AddSingleMemberDropdown from '../../components/CustomDropdown/AddSingleMemberDropdown';
import CreateProjectDropdown from '../../components/DropdownStyle/CreateProjectDropdown';
import DateRangePicker from '../../components/ReactDateRangePicker/ReactDateRangePicker';
import Select from '../../components/ReactSelectDropdown';
import ClearFilterButton from '../../components/Search/clearFilterButton';
import DatePicker from '../../components/SingleDatePicker/SingleDatePicker';
import TimezoneDropdown from '../../components/TimezoneDropdown/TimezoneDropdown';
import { FilterButton } from '../../styledComponents/buttons';
import {
  CommonFlex,
  FilterLabel,
  IconArrow,
  InputWithLabelSection,
} from '../../styledComponents/common';
import { FilterContainer } from '../../styledComponents/timesheet';

const TimesheetFilter = props => {
  const { t } = useTranslation();
  const [officeOptions, setOfficeOptions] = useState([
    {
      label: `(${t('all_offices')})`,
      value: null,
    },
  ]);
  const [officeMembers, setOfficeMembers] = useState([]);
  const {
    selectedOrganization,
    getOrganizationMembersList,
    organizationMembersList,
    selectedMember,
    selectMember,
    clearMember,
    selectedDate,
    onDateChange,
    //custom timeSheet
    startDate,
    endDate,
    dateLabel,
    onDateSelect,
    changeDateRange,
    //Office
    officeDetails,
    getOfficesShortList,
    officesShortList,
    selectedOffice,
    selectOffice,

    selectedOfficeMembers,
    page,
    selectedProject,
    selectProject,
    selectedTask,
    selectTask,
    selectLastWeek,
    selectCurrentWeek,
    selectLastMonth,
    selectTimezone,
    selectedTimezone,
    singleDateChange,
    singleDateChangeDaily,
    showWeekPickerFooter = true,
  } = props;

  useEffect(() => {
    if (selectedOfficeMembers) {
      selectedOfficeMembers(officeMembers);
    }
  }, [officeMembers]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      getOfficesShortList({ organization_id: selectedOrganization.id });
      getOrganizationMembersList({
        page: 1,
        organization_id: selectedOrganization.id,
        office_id: null,
      });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (officeDetails) {
      const filteredMembers = organizationMembersList.filter(member => {
        return officeDetails.employees?.some(
          employee => employee.user.id === member.id,
        );
      });
      setOfficeMembers(filteredMembers);
    }
  }, [officeDetails]);

  useEffect(() => {
    if (officesShortList && officesShortList.length === 1) {
      getOrganizationMembersList({
        page: 1,
        organization_id: selectedOrganization.id,
        office_id: officesShortList[0].id,
      });
    }
    if (officesShortList && officesShortList.length > 1) {
      let newArr = [
        {
          label: `(${t('all_offices')})`,
          value: null,
        },
      ];
      officesShortList.forEach((office, i) => {
        newArr.push({
          label: office.name,
          value: office.id,
        });
      });
      setOfficeOptions(newArr);
    }
  }, [officesShortList]);

  useEffect(() => {
    if (
      selectedOffice &&
      selectedOffice.value &&
      officesShortList &&
      officesShortList.length > 1
    ) {
      getOrganizationMembersList({
        page: 1,
        organization_id: selectedOrganization.id,
        office_id: selectedOffice.value,
      });
    } else if (
      selectedOrganization?.id &&
      ((selectedOffice && selectedOffice.value === null) || !selectedOffice)
    ) {
      getOrganizationMembersList({
        page: 1,
        organization_id: selectedOrganization.id,
        office_id: null,
      });
    }
  }, [selectedOffice]);

  const onMemberSelect = person => {
    selectMember(person);
  };

  return (
    <div>
      <FilterContainer
        admin={
          localStorage.getItem('selected_organization_role') === 'admin' ||
          localStorage.getItem('selected_organization_role') === 'owner'
        }
        isMonthly={props.page && props.page === 'monthly'}
      >
        {(localStorage.getItem('selected_organization_role') === 'admin' ||
          localStorage.getItem('selected_organization_role') === 'owner') && (
          <>
            <InputWithLabelSection style={{ minWidth: `240px` }}>
              <FilterLabel>{t('office')}</FilterLabel>
              <Select
                isSearchable
                isClearable={selectedOffice?.value}
                value={selectedOffice}
                options={officeOptions}
                placeholder="Select Office..."
                onChange={e => {
                  e
                    ? selectOffice(e)
                    : selectOffice({
                        label: `(${t('all_offices')})`,
                        value: null,
                      });
                }}
                styles={CreateProjectDropdown()}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: props => {
                    return <ClearFilterButton {...props} />;
                  },
                }}
              />
            </InputWithLabelSection>
            <InputWithLabelSection>
              <FilterLabel>{t('assignee')}</FilterLabel>
              <div style={{ width: `40px` }}>
                <AddSingleMemberDropdown
                  assigneeManage={selectedMember}
                  updateState={onMemberSelect}
                  membersList={
                    (selectedOffice &&
                      selectedOffice.label === '(All Offices)') ||
                    !selectedOffice
                      ? organizationMembersList
                      : officeMembers
                  }
                  clearAssignee={() => clearMember()}
                />
              </div>
            </InputWithLabelSection>
          </>
        )}

        {props.page &&
          (props.page === 'weekly' || props.page === 'biweekly') && (
            <InputWithLabelSection>
              <FilterLabel>Select Date Range</FilterLabel>
              <ReactWeekPicker
                startDate={startDate ? moment(startDate) : null}
                startDateId="report-date-range-start-date"
                endDate={endDate ? moment(endDate) : null}
                endDateId="report-date-range-end-date"
                onDatesChange={changeDateRange}
                dateDisplayFormat="DD/MM/YYYY"
                onDateSelect={onDateSelect}
                selectLastWeek={selectLastWeek}
                selectCurrentWeek={selectCurrentWeek}
                showFooter={showWeekPickerFooter}
              />
            </InputWithLabelSection>
          )}

        {props.page && props.page !== 'monthly' && props.page === 'daily' && (
          <CommonFlex gap="4px" alignItems="end">
            <InputWithLabelSection>
              <FilterLabel>{t('select_date')}</FilterLabel>
              <DatePicker
                id="createIssuedDate"
                date={selectedDate}
                onDateChange={onDateChange}
                dateDisplayFormat={'DD/MM/YYYY'}
                placeholder={'Select date'}
                // allowPreviousDates
                disableCurrentAndFutureDates
              // disableFutureDates
              />
            </InputWithLabelSection>
            <IconArrow
              onClick={() => {
                singleDateChangeDaily('left');
              }}
              padding="5px"
              size="39px"
              src={LeftArrow_3}
            />
            <IconArrow
              onClick={() => {
                moment(selectedDate).isBefore(moment().subtract(1, 'day')) &&
                  singleDateChangeDaily('right');
              }}
              disabled={!moment(selectedDate).isBefore(moment().subtract(1, 'day'))}
              padding="5px"
              size="39px"
              src={RightArrow_3}
            />
          </CommonFlex>
        )}

        {props.page && props.page === 'custom' && (
          <CommonFlex gap="4px" alignItems="end">
            <InputWithLabelSection>

              <>
                <FilterLabel>
                  {t('date_range')}
                  {` (${dateLabel})`}
                </FilterLabel>
                <DateRangePicker
                  startDate={props.startDate ? props.startDate : null}
                  startDateId="custom-date-range-start-date"
                  endDate={props.endDate ? props.endDate : null}
                  endDateId="custom-date-range-start-date"
                  onDatesChange={props.changeDateRange}
                  dateDisplayFormat="DD/MM/YYYY"
                  onDateSelect={props.onDateSelect}
                  selectLastWeek={props.selectLastWeek}
                  selectLastMonth={props.selectLastMonth}
                />
              </>
            </InputWithLabelSection>
            <IconArrow
              onClick={() => {
                singleDateChange('left');
              }}
              padding="5px"
              size="39px"
              src={LeftArrow_3}
            />
            <IconArrow
              onClick={() => {
                moment(endDate).isBefore(moment().subtract(1, 'day')) &&
                  singleDateChange('right');
              }}
              disabled={!moment(endDate).isBefore(moment().subtract(1, 'day'))}
              padding="5px"
              size="39px"
              src={RightArrow_3}
            />
          </CommonFlex>
        )}
      </FilterContainer>
    </div>
  );
};

export default TimesheetFilter;

import AssignButtonLoader from 'components/AssignButtonLoader/AssignButtonLoader';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { call } from 'redux-saga/effects';

import Avatar5 from '../../../assets/img/avatar_5.svg';
import alert from '../../../assets/img/icons/alert.svg';
import Call from '../../../assets/img/icons/call.svg';
import Chat from '../../../assets/img/icons/chat.svg';
import CrossIcon from '../../../assets/img/icons/cross_black.svg';
import Bean from '../../../assets/img/icons/delete.svg';
import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import AddProjectDropdown from '../../../components/CustomDropdown/AddProjectDropdown';
import AddTeamDropdown from '../../../components/CustomDropdown/AddTeamDropdown';
import MembersDropdownStyle from '../../../components/DropdownStyle/MembersDropdownStyle';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../../components/NoDataComponent/NoDataComponent';
import LinearActivityBar from '../../../components/ProgressBar/LinearActivityBar';
import Select from '../../../components/ReactSelectDropdown';
import ToggleButton from '../../../components/ToggleButton/ToggleButton';
import CommonTooltip from '../../../components/Tooltip/CommonTooltip';
import InfoTooltip from '../../../components/Tooltip/InfoTooltip';
import TaskActionTooltip from '../../../components/Tooltip/TaskActionTooltip';
import {
  ButtonIcon,
  ColoredButton,
  PrimaryButton,
  WhiteButton,
  WhiteButtonWithIcon,
} from '../../../styledComponents/buttons';
import { HoveredSection, RoundHover } from '../../../styledComponents/clients';
import {
  CardTitle,
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
  Container,
  ContentHeader,
  DividerLine,
  InputField,
  InputLabel,
  InputWithLabelSection,
  PageTitle,
  TableText,
  TextInputSection,
} from '../../../styledComponents/common';
import {
  ActivityCard,
  ActivityTextSection,
  AddPayConfigMainSection,
  AddPayConfigTextSection,
  ButtonFlexContainer,
  CommonFlexMember,
  Dot,
  FlexSection,
  Line,
  PaddingBox,
  PaymentConfigTitle,
  PersonSection,
  PesonActivitySection,
  SingleSection,
  TextLink,
  TextSwitch,
  UpperTitleButtonSection,
  WeeklyLimitSection,
} from '../../../styledComponents/members';
import { TooltipContainerWorkOrder } from '../../../styledComponents/workOrder';
import { currencySymbolMap } from '../../../utils/currencies';
import { getHours, toHHMMSS } from '../../../utils/helper';
import {
  ApprovalSettingsSection,
  CommImageNameComp,
  GeofenceStatusSection,
} from '../CommonComponents';
import MemberDeleteModal from '../MemberDeleteModal';

const MemberDetails = props => {
  const [weeklyData, setWeeklyData] = useState(null);
  const [monthlyData, setMonthlyData] = useState(null);
  const [payPeriodValue, setPayPeriodValue] = useState({
    value: '1',
    label: 'Weekly',
  });
  const [payTypeValue, setPayTypeValue] = useState({
    value: '1',
    label: 'Hourly Payment',
  });
  const RoleOptions = [
    //{ value: "owner", label: "Owner" },
    { value: 'admin', label: 'Admin' },
    { value: 'member', label: 'Member' },
  ];
  const [selectedRole, setSelectedRole] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [isNumberChanged, setIsNumberChanged] = useState(false);
  const [requireTimesheetApproval, setRequireTimesheetApproval] =
    useState(false);
  const payPeriodOptions = [
    { value: '1', label: 'Weekly' },
    { value: '2', label: 'Monthly' },
  ];
  const payTypeOptions = [
    { value: '1', label: 'Hourly Payment' },
    { value: '2', label: 'Fixed Payment' },
  ];
  const [editWeeklyLimit, setEditWeeklyLimit] = useState(false);
  const [weeklyLimitValue, setWeeklyLimitValue] = useState('');
  const [payRateValue, setPayRateValue] = useState('');
  const [billRateValue, setBillRateValue] = useState('');
  const [isRateChange, setIsRateChange] = useState(false);
  const [tabSelect, setTabSelect] = useState('week');
  const colorList = [
    { color: '#9A96F9' },
    { color: '#73C0FF' },
    { color: '#F9828B' },
    { color: '#F9D059' },
  ];
  const [modal, setModal] = useState(false);
  const [projectIndex, setProjectIndex] = useState(-1);
  const [teamIndex, setTeamIndex] = useState(-1);
  const [tooltip, setTooltip] = useState(false);
  const [showLimitSection, setShowLimitSection] = useState(false);

  const {
    isLoading,
    history,
    location,
    selectedOrganization,
    clearMemberDetailsData,
    getMemberDetails,
    getMemberWeeklyEarning,
    getMemberMonthlyEarning,
    weeklyEarning,
    monthlyEarning,
    removeMember,
    updateMember,
    addTeam,
    assignProject,
    setPaymentConfiguration,
    deleteProjectAssignee,
    removeTeamMember,
    deleteMemberLoading,
    updateMemberLoading,
    updateIsLoading,
    profileData,
    teamMemberIsLoading,
    teamListIsLoading,
    getTeamShortList,
    teamList,
    projectAssigneeIsLoading,
    projectListIsLoading,
    getProjectShortList,
    projectList,
    paymentConfigurationLoading,
    updateMemberwiseTimesheetApprovalSettings,
  } = props;

  useEffect(() => {
    return () => {
      clearMemberDetailsData();
    };
  }, []);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      if (location.search) {
        const queryParsed = queryString.parse(location.search);
        let payload = {
          organization_id: selectedOrganization.id,
          user_id: queryParsed.id,
        };
        getMemberDetails(payload);
        // getMemberWeeklyEarning(payload);
        // getMemberMonthlyEarning(payload);
      }
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (props.memberDetails && Object.keys(props.memberDetails).length > 0) {
      setSelectedRole({
        value: props.memberDetails.role,
        label: `${props.memberDetails.role
          .charAt(0)
          .toUpperCase()}${props.memberDetails.role.slice(1)}`,
      });
      setContactNumber(props.memberDetails.user.phone || '');
      setRequireTimesheetApproval(
        props.memberDetails.require_timesheet_approval,
      );
      const details = {
        id: props.memberDetails.id,
        userId: props.memberDetails.user.id,
        name: `${props.memberDetails.user.first_name}${
          props.memberDetails.user.last_name
            ? ` ${props.memberDetails.user.last_name}`
            : ``
        }`,
        email: props.memberDetails.user.email,
        phone: props.memberDetails.user.phone,
        timezone: props.memberDetails.user.timezone,
        avatar: props.memberDetails.user.avatar
          ? props.memberDetails.user.avatar
          : Avatar5,
        role: {
          value: props.memberDetails.role,
          label: `${props.memberDetails.role
            .charAt(0)
            .toUpperCase()}${props.memberDetails.role.slice(1)}`,
        },
        teams: props.memberDetails.teams,
        offices: props.memberDetails.offices,
        assignedProjects: props.memberDetails.projects,
        payment: props.memberDetails.payment
          ? props.memberDetails.payment
          : null,
        requireTimesheetApproval:
          props.memberDetails.require_timesheet_approval,
        geofenceStatus: props.memberDetails.user.geofence_time_clock,
        limitTracking: props.memberDetails.limit_tracking,
        weeklyLimit: props.memberDetails.weekly_tracking_limit,
      };
      if (props.memberDetails.limit_tracking) {
        setShowLimitSection(true);
      }
      if (props.memberDetails.payment) {
        if (props.memberDetails.payment.pay_rate) {
          setPayRateValue(
            (props.memberDetails.payment.pay_rate / 100).toFixed(2),
          );
        }
        if (props.memberDetails.payment.bill_rate) {
          setBillRateValue(
            (props.memberDetails.payment.bill_rate / 100).toFixed(2),
          );
        }
      }
    }
  }, [props.memberDetails]);

  useEffect(() => {
    if (profileData && !updateIsLoading) {
      setIsNumberChanged(false);
    }
  }, [profileData]);

  useEffect(() => {
    if(!paymentConfigurationLoading){
      setIsRateChange(false);
    }
  }, [paymentConfigurationLoading]);

  useEffect(() => {
    if (weeklyEarning && weeklyEarning !== null) {
      setWeeklyData(weeklyEarning);
    }
  }, [weeklyEarning]);

  useEffect(() => {
    if (monthlyEarning && monthlyEarning !== null) {
      setMonthlyData(monthlyEarning);
    }
  }, [monthlyEarning]);

  // useEffect(() => {
  //   if (editWeeklyLimit && props.memberDetails && props.memberDetails.weeklyLimit) {
  //     setWeeklyLimitValue(Math.floor(memberDetails.weeklyLimit / 3600));
  //   } else if (!editWeeklyLimit) {
  //     setWeeklyLimitValue('');
  //   }
  // }, [editWeeklyLimit]);

  const toggleDeleteModal = () => {
    setModal(!modal);
  };

  const handleDeleteMember = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      props.memberDetails &&
      props.memberDetails.user &&
      props.memberDetails.user.id
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        user_id: props.memberDetails.user.id,
      };
      removeMember(payload);
      setTimeout(() => {
        toggleDeleteModal();
        history.push('/user/members');
      }, 300);
    }
  };

  const onRoleChange = e => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      props.memberDetails &&
      props.memberDetails.user &&
      props.memberDetails.user.id &&
      props.memberDetails.role
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        user_id: props.memberDetails.user.id,
      };
      setSelectedRole(e);
      if (
        (props.memberDetails.role === 'admin' ||
          props.memberDetails.role === 'office manager') &&
        e.value === 'member'
      ) {
        payload.is_admin = false;
        updateMember(payload);
      } else if (
        (props.memberDetails.role === 'member' ||
          props.memberDetails.role === 'office manager') &&
        e.value === 'admin'
      ) {
        payload.is_admin = true;
        updateMember(payload);
      }
    }
  };

  const onContactChange = phone => {
    setContactNumber(phone);
    setIsNumberChanged(true);
  };

  const saveContact = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      props.memberDetails &&
      !props.updateIsLoading &&
      props.memberDetails.profile_id &&
      (props.memberDetails.profile_id ===
        localStorage.getItem('user_profile') ||
        props.memberDetails.role !== 'owner')
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        user_id: props.memberDetails.profile_id,
        phone: contactNumber,
      };
      props.profileOperation(payload);
    }
  };

  const onTabSwitch = value => {
    setTabSelect(value);
  };

  const onWeeklyLimitChange = e => {
    const { value } = e.target;

    setWeeklyLimitValue(value);
  };

  const onPayPeriodChange = e => {
    setPayPeriodValue(e);
  };
  const onPayTypeChange = e => {
    setPayTypeValue(e);
  };

  const onPayRateChange = e => {
    const { value } = e.target;
    setPayRateValue(value);
    setIsRateChange(true);
  };
  const onBillRateChange = e => {
    const { value } = e.target;
    setBillRateValue(value);
    setIsRateChange(true);
  };

  const handleAddTeam = team => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      team &&
      team.id &&
      props.memberDetails &&
      props.memberDetails.user &&
      props.memberDetails.user.id
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        team_id: team.id,
        user_id: props.memberDetails.user.id,
        getMemberData: true,
      };
      addTeam(payload);
    }
  };

  const handleAssignProject = project => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      props.memberDetails &&
      props.memberDetails.user &&
      props.memberDetails.user.id &&
      project &&
      project.id
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        project_id: project.id,
        user_id: props.memberDetails.user.id,
        getMemberData: true,
      };
      assignProject(payload);
    }
  };

  const cancelFunction = () => {
  };

  const handleSavePayment = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      props.memberDetails &&
      props.memberDetails.user &&
      props.memberDetails.user.id &&
      payRateValue
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        user_id: props.memberDetails.user.id,
        pay_rate: parseInt(payRateValue * 100),
        currency:
          (selectedOrganization.configuration &&
            selectedOrganization.configuration.currency) ||
          'USD',
      };
      if (billRateValue) {
        payload.bill_rate = parseInt(billRateValue * 100);
      }
      setPaymentConfiguration(payload);
    }
  };

  const handleSaveWeeklyLimit = limitTracking => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      props.memberDetails &&
      props.memberDetails.user &&
      props.memberDetails.user.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        user_id: props.memberDetails.user.id,
      };
      if (limitTracking) {
        payload.limit_tracking = true;
        payload.weekly_tracking_limit = weeklyLimitValue * 3600;
      } else if (!limitTracking) {
        payload.limit_tracking = false;
        payload.weekly_tracking_limit = 0;
      }
      updateMember(payload);
      setTimeout(() => {
        setEditWeeklyLimit(false);
      }, 400);
    }
  };

  const onProjectHover = index => {
    setProjectIndex(index);
  };

  const onTeamHover = index => {
    setTeamIndex(index);
  };

  const tooltipChange = index => {
    setTooltip(index);
  };

  const handleDeleteProject = projectId => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      projectId &&
      props.memberDetails &&
      props.memberDetails.user &&
      props.memberDetails.user.id
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        project_id: projectId,
        user_id: props.memberDetails.user.id,
      };
      deleteProjectAssignee(payload);
    }
  };

  const handleDeleteTeam = teamId => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      teamId &&
      props.memberDetails &&
      props.memberDetails.user &&
      props.memberDetails.user.id
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        team_id: teamId,
        user_id: props.memberDetails.user.id,
        fromMember: true,
      };
      removeTeamMember(payload);
    }
  };

  const updateGeofenceStatus = (memberId, value) => {
    if (selectedOrganization && selectedOrganization.id && memberId) {
      const payload = {
        organization_id: selectedOrganization.id,
        user_id: memberId,
        geofence_time_clock: value,
      };
      props.setGeofenceStatus(payload);
    }
  };

  const updateTimesheetApprovalSettings = (memberId, value) => {
    if (selectedOrganization && selectedOrganization.id && memberId) {
      setRequireTimesheetApproval(value);
      const payload = {
        organization_id: selectedOrganization.id,
        settings_id: memberId,
        require_timesheet_approval: value,
      };
      updateMemberwiseTimesheetApprovalSettings(payload);
    }
  };

  return (
    <div className="content">
      <PageTitle>Members</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => history.push('/user/members')}
          subTitle="Back to all members"
        />
      </ContentHeader>
      <Container gap="20px" style={{ padding: `30px 0` }}>
        {isLoading ? (
          <ComponentCircleLoader />
        ) : props.memberDetails &&
          Object.keys(props.memberDetails).length > 0 ? (
          <>
            <UpperTitleButtonSection>
              <CardTitle>Member Details</CardTitle>
              {selectedOrganization &&
                selectedOrganization.role !== 'member' && (
                  <UpperTitleButtonSection gap="0" padding="0">
                    {selectedRole.value !== 'owner' && (
                      <WhiteButtonWithIcon
                        type="delete"
                        fontSize="14px"
                        onClick={() => toggleDeleteModal()}
                      >
                        <ButtonIcon src={Bean} alt="" width="18px" />
                        Delete Member
                      </WhiteButtonWithIcon>
                    )}

                    <MemberDeleteModal
                      isOpen={modal}
                      toggle={toggleDeleteModal}
                      handleDelete={handleDeleteMember}
                      isLoading={deleteMemberLoading}
                    />
                  </UpperTitleButtonSection>
                )}
            </UpperTitleButtonSection>

            <SingleSection>
              <PersonSection>
                <CommImageNameComp
                  imgSource={
                    props.memberDetails.user && props.memberDetails.user.avatar
                      ? props.memberDetails.user.avatar
                      : Avatar5
                  }
                  name={`${props.memberDetails.user.first_name}${
                    props.memberDetails.user.last_name
                      ? ` ${props.memberDetails.user.last_name}`
                      : ``
                  }`}
                  email={props.memberDetails.user.email}
                  size="70px"
                  fontSize="26px"
                  index={0}
                  color={props.memberDetails.user.color}
                />
                <SingleSection>
                  <CommonText $label>Role</CommonText>
                  {selectedOrganization &&
                  (selectedOrganization.role === 'owner' ||
                    selectedOrganization.role === 'admin') &&
                  props.memberDetails.role !== 'owner' ? (
                    <>
                      {updateMemberLoading ? (
                        <AssignButtonLoader
                          padding="9px 10px"
                          borderColor="rgb(228, 231, 235)"
                          width="150px"
                        />
                      ) : (
                        <Select
                          isSearchable
                          value={selectedRole}
                          options={RoleOptions}
                          onChange={e => {
                            onRoleChange(e);
                          }}
                          styles={MembersDropdownStyle({
                            minHeight: '40px',
                            minWidth: '150px',
                          })}
                        />
                      )}
                    </>
                  ) : (
                    <CommonText>{selectedRole.label}</CommonText>
                  )}
                </SingleSection>
              </PersonSection>
            </SingleSection>
            <SingleSection>
              <CommonText $label>Contact Number</CommonText>
              <CommonFlexMember>
                <CommonFlex gap="4px">
                  <ButtonIcon src={Call} alt="" width="18px" />

                  <PhoneInput
                    enableSearch
                    country={'us'}
                    value={contactNumber}
                    onChange={phone => onContactChange(phone)}
                    placeholder={contactNumber ? '' : 'Add phone number'}
                    inputClass="phone-number-input"
                    buttonClass="phone-number-flag-dropdown"
                  />
                </CommonFlex>
                <PrimaryButton
                  fontSize="12px"
                  height="36px"
                  disabled={
                    !contactNumber ||
                    (contactNumber && contactNumber.length < 5) ||
                    !isNumberChanged
                  }
                  onClick={() => saveContact()}
                >
                  {props.updateIsLoading ? (
                    <ButtonTextLoader loadingText="Saving" fontSize="13px" />
                  ) : props.memberDetails.user &&
                    props.memberDetails.user.phone ? (
                    'Edit'
                  ) : (
                    'Add'
                  )}
                </PrimaryButton>
              </CommonFlexMember>
            </SingleSection>

            <SingleSection>
              <CommonText $label>Offices</CommonText>
              <CommonFlex style={{ flexWrap: 'wrap', gridRowGap: '10px' }}>
                {props.memberDetails.offices.map((office, id) => (
                  <WhiteButtonWithIcon
                    key={id}
                    fontSize="12px"
                    onClick={() =>
                      history.push(`/user/office-details?id=${office.id}`)
                    }
                  >
                    {office.name}
                  </WhiteButtonWithIcon>
                ))}
              </CommonFlex>
            </SingleSection>

            <SingleSection margin="0 0 10px">
              <ApprovalSettingsSection
                margin="0"
                hasTooltip
                memberId={props.memberDetails.id}
                active={requireTimesheetApproval}
                handleChange={updateTimesheetApprovalSettings}
                isAdmin={
                  selectedOrganization && selectedOrganization.role !== 'member'
                }
              />
            </SingleSection>

            <SingleSection>
              <ActivityTextSection>
                <CommonText $label>Payment</CommonText>
                {props.memberDetails && props.memberDetails.payment ? (
                  <CommonText>
                    {props.memberDetails.payment.currency} (
                    {currencySymbolMap[props.memberDetails.payment.currency]}){' '}
                    {(props.memberDetails.payment.pay_rate / 100).toFixed(2)} /
                    hour
                  </CommonText>
                ) : (
                  <CommonText>Payment not set</CommonText>
                )}
              </ActivityTextSection>
            </SingleSection>
            {selectedOrganization && selectedOrganization.role !== 'member' && (
              <SingleSection>
                <AddPayConfigMainSection>
                  <TextInputSection>
                    <InfoTooltip
                      mainText="Pay Rate"
                      toolTipText="Pay rate is used to manage your employee's payroll"
                      title
                      label
                    />
                    <CommonGrid columns="60px auto" gap="10px">
                      <CommonText>
                        {selectedOrganization &&
                          selectedOrganization.configuration &&
                          selectedOrganization.configuration.currency &&
                          `${selectedOrganization.configuration.currency} (${
                            currencySymbolMap[
                              selectedOrganization.configuration.currency
                            ]
                          })`}
                      </CommonText>
                      <InputField
                        height="36px"
                        style={{ width: '105px' }}
                        type="number"
                        placeholder="00"
                        value={payRateValue}
                        onChange={e => onPayRateChange(e)}
                      />
                    </CommonGrid>
                  </TextInputSection>

                  <CommonFlex gap="10px">
                    <TextInputSection>
                      <InfoTooltip
                        mainText="Bill Rate"
                        toolTipText="Bill rate is used to invoice your client"
                        top="-40px"
                        title
                        label
                      />
                      <CommonGrid columns="60px auto" gap="10px">
                        <CommonText>
                          {selectedOrganization &&
                            selectedOrganization.configuration &&
                            selectedOrganization.configuration.currency &&
                            `${selectedOrganization.configuration.currency} (${
                              currencySymbolMap[
                                selectedOrganization.configuration.currency
                              ]
                            })`}
                        </CommonText>
                        <InputField
                          height="36px"
                          style={{ width: '105px' }}
                          type="number"
                          placeholder="00"
                          value={billRateValue}
                          onChange={e => onBillRateChange(e)}
                        />
                      </CommonGrid>
                    </TextInputSection>

                    {isRateChange &&
                      parseInt(billRateValue) <= parseInt(payRateValue) && (
                    <div
                      onMouseEnter={() => tooltipChange(true)}
                      onMouseLeave={() => tooltipChange(false)}
                    >
                      <CommonImage
                        style={{ marginTop: '28px' }}
                        size="22px"
                        src={alert}
                      />
                      {tooltip && (
                        <TooltipContainerWorkOrder
                          style={{
                            marginTop: '60px',
                            marginLeft: '-154px',
                          }}
                        >
                          <CommonText
                            fontSize="12px"
                            fontWeight="530"
                            color="#fff"
                          >
                            Check whether pay rate and bill rate are ok.
                          </CommonText>
                        </TooltipContainerWorkOrder>
                      )}
                    </div>
                    )}
                  </CommonFlex>

                  <PrimaryButton
                    style={{ alignSelf: "flex-end", height: "36px", minHeight: "36px", padding: "0" }}
                    onClick={() => handleSavePayment()}
                    disabled={ !isRateChange }
                  >
                    {paymentConfigurationLoading ? (
                      <ButtonTextLoader
                        loadingText="Saving"
                        fontSize="13px"
                      />
                    ) : (
                      'Update'
                    )}
                  </PrimaryButton>
                </AddPayConfigMainSection>
                {/* <ButtonSection>
                    <Button save={false} onClick={() => cancelFunction()}>
                      Cancel
                    </Button>
                    <Button save onClick={() => handleSave()}>
                      Save
                    </Button>
                  </ButtonSection> */}
              </SingleSection>
            )}

            <SingleSection margin="20px 0">
              <GeofenceStatusSection
                margin="0"
                hasTooltip
                memberId={props.memberDetails.user.id}
                active={props.memberDetails.user.geofence_time_clock}
                handleChange={updateGeofenceStatus}
                notMember={
                  selectedOrganization && selectedOrganization.role !== 'member'
                }
              />
            </SingleSection>
            <SingleSection margin="20px 0 10px">
              <PrimaryButton
                width="150px"
                onClick={() => history.push('/user/members')}
                padding="0 30px"
              >
                Save
              </PrimaryButton>
            </SingleSection>

            {/* <DividerLine margin="25px 0 0" /> */}
          </>
        ) : (
          <NoDataComponent title="Sorry! could not fetch member details. Please try again!" />
        )}
      </Container>
    </div>
  );
};

export default MemberDetails;

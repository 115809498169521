import AddMultipleAssigneeDropdown from 'components/CustomDropdown/AddMultipleAssigneeDropdown';
import moment from 'moment';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';

import durationIcon from '../../../assets/img/icons/clock-in-duration.svg';
import tick from '../../../assets/img/onboardingImg/tick-black.svg';
import CreateProjectDropdown from '../../../components/DropdownStyle/CreateProjectDropdown';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import Select from '../../../components/ReactSelectDropdown';
import DatePicker from '../../../components/SingleDatePicker/SingleDatePicker';
import InfoTooltip from '../../../components/Tooltip/InfoTooltip';
import {
  PrimaryButton,
  TabButton,
  TabButtonContainer,
  WhiteButton,
} from '../../../styledComponents/buttons';
import {
  ColoredText,
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
  Container,
  ContentHeader,
  FormAlert,
  InputField,
  InputLabel,
  InputTextArea,
  InputWithLabelSection,
  PageTitle,
  RedStar,
} from '../../../styledComponents/common';
import {
  ModalButtonSection,
  RadioButton,
} from '../../../styledComponents/members';
import { formatDurationToHoursMinutes } from '../../../utils/helper';
import {
  AssigneeSection,
  InputSection,
  TimeRangeSection,
} from '../../Jobs/CreateJob/createTaskStyles';
import {
  DurationContainer,
  DurationText,
  MonthDateBox,
  MonthDateContainer,
  RepeatSection,
  ScheduleText,
  ThemeBox,
  ThemesContainer,
  WeekDaysContainer,
  WeekNumberBox,
  WeekdayBox,
} from '../../ShiftSchedule/CreateShift/createShiftStyles';
import TimeRangeComponent from '../../Timesheet/Daily/TimeRangeComponent';

const ShiftModalCreateJob = ({
  isOpen,
  toggle,
  selectedOrganization,
  orgMembersShortList,
  getOrganizationMembersShortList,
  memberShortListIsLoading,

  startDate,
  setStartDate,

  requiredStartDate,
  requiredEndDate,

  assignees,
  setAssignees,

  setRequiredStartDate,
  setRequiredEndDate,

  shiftPayload,
  setShiftPayload,

  setRecurringJobDescription,
}) => {
  const { t, i18n } = useTranslation();
  // const [startDate, setStartDate] = useState(moment(new Date()));
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [isRepeat, setIsRepeat] = useState(false);
  const [selectedRepeatType, setSelectedRepeatType] = useState('');
  const [defaultRepeatOptions, setDefaultRepeatOptions] = useState([]);
  const [interval, setInterval] = useState(1);
  const [activeDays, setActiveDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const weekParse = {
    monday: 0,
    tuesday: 1,
    wednesday: 2,
    thursday: 3,
    friday: 4,
    saturday: 5,
    sunday: 6,
  };
  const [isWeekViewOfMonth, setIsWeekViewOfMonth] = useState(false);
  const [activeMonthDates, setActiveMonthDates] = useState({});
  const [activeMonthDays, setActiveMonthDays] = useState({});
  const [scheduleDescription, setScheduleDescription] = useState('');
  const [endDate, setEndDate] = useState(null);
  const [selectedTaskType, setSelectedTaskType] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);
  const radiusOptions = [20, 50, 100];
  const [statusOptions, setStatusOptions] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [workOrderOptions, setWorkOrderOptions] = useState([]);
  const [clientId, setClientId] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);

  const [addLocationButton, setAddLocationButton] = useState(false);
  const [themeColor, setThemeColor] = useState('');
  const scrollAssignee = useRef(null);
  const scrollDescription = useRef(null);
  const scrollTime = useRef(null);
  const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const dateText = {
    1: '1st',
    2: '2nd',
    3: '3rd',
    21: '21st',
    22: '22nd',
    23: '23rd',
    31: '31st',
    'Last Day': 'last',
  };

  const jobStatusOptions = [
    {
      value: 'unscheduled',
      label: t('unscheduled'),
    },
    {
      value: 'ready to dispatch',
      label: t('ready_to_dispatch'),
    },
  ];
  const [saveErrors, setSaveErrors] = useState({});

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id && isOpen) {
      getOrganizationMembersShortList({
        organization_id: selectedOrganization.id,
      });
      let defaultOptions = [];
      defaultOptions.push({
        label: `Daily`,
        value: 'daily',
      });
      defaultOptions.push({
        label: `Weekly on ${startDate.format('dddd')}`,
        value: 'weekly',
      });
      defaultOptions.push({
        label: `Bi-Weekly on ${startDate.format('dddd')}`,
        value: 'biweekly',
      });
      defaultOptions.push({
        label: `Monthly on ${startDate.format('D')} of the month`,
        value: 'monthly',
      });
      defaultOptions.push({
        label: `Custom Weekly...`,
        value: 'custom_weekly',
      });
      defaultOptions.push({
        label: `Custom Monthly...`,
        value: 'custom_monthly',
      });
      setDefaultRepeatOptions(defaultOptions);

      let tempActiveMonthDates = {};
      for (let i = 1; i <= 31; i++) {
        tempActiveMonthDates[i] = false;
      }
      tempActiveMonthDates['Last Day'] = false;
      setActiveMonthDates(tempActiveMonthDates);

      let tempActiveMonthDays = {};
      for (let i = 0; i <= 27; i++) {
        tempActiveMonthDays[i] = false;
      }
      setActiveMonthDays(tempActiveMonthDays);
    }
  }, [selectedOrganization, isOpen]);

  useEffect(() => {
    if (
      interval &&
      interval > 0 &&
      selectedRepeatType &&
      selectedRepeatType.value
    ) {
      let typeText = '';
      let activeDaysText = '';
      let finalText = '';

      if (
        selectedRepeatType.value === 'weekly' ||
        selectedRepeatType.value === 'biweekly' ||
        selectedRepeatType.value === 'monthly'
      ) {
        finalText = selectedRepeatType.label;
        setScheduleDescription(finalText);
      } else if (selectedRepeatType.value === 'daily') {
        interval === 1
          ? (finalText = 'Daily ')
          : (finalText = `Every ${interval} days `);
        setScheduleDescription(finalText);
      } else if (selectedRepeatType.value === 'custom_weekly') {
        interval === 1
          ? (typeText = 'Weekly ')
          : (typeText = `Every ${interval} weeks `);
        let tempActiveDaysArray = [];
        for (let key in activeDays) {
          if (activeDays[key]) {
            tempActiveDaysArray.push(
              key.charAt(0).toUpperCase() + key.slice(1),
            );
          }
        }
        if (tempActiveDaysArray.length === 1) {
          activeDaysText = `on ${tempActiveDaysArray[0]}.`;
        } else if (tempActiveDaysArray.length > 1) {
          let lastDay = tempActiveDaysArray.pop();
          activeDaysText = `on ${tempActiveDaysArray.join(
            ', ',
          )} and ${lastDay}.`;
        }
        finalText = typeText + activeDaysText;
        setScheduleDescription(finalText);
      } else if (selectedRepeatType.value === 'custom_monthly') {
        activeDaysText = 'on the ';
        interval === 1
          ? (typeText = 'Monthly ')
          : (typeText = `Every ${interval} months `);
        if (isWeekViewOfMonth) {
          for (let i = 0; i < 7; i++) {
            for (let n = 0; n < 4; n++) {
              if (activeMonthDays[n * 7 + i]) {
                activeDaysText += `${findDateText(n + 1)} ${Object.keys(weekParse)[i].charAt(0).toUpperCase() +
                  Object.keys(weekParse)[i].slice(1)
                  } and `;
              }
            }
          }
          activeDaysText = activeDaysText.slice(0, -5) + '.';
          finalText = typeText + activeDaysText;
          setScheduleDescription(finalText);
        } else {
          let tempActiveDaysArray = [];
          for (let key in activeMonthDates) {
            if (activeMonthDates[key]) {
              tempActiveDaysArray.push(findDateText(key));
            }
          }
          if (tempActiveDaysArray.length === 1) {
            activeDaysText = `on ${tempActiveDaysArray[0]} day of the month.`;
          } else if (tempActiveDaysArray.length > 1) {
            let lastDay = tempActiveDaysArray.pop();
            activeDaysText = `on ${tempActiveDaysArray.join(
              ', ',
            )} and ${lastDay} day of the month.`;
          }
          finalText = typeText + activeDaysText;
          setScheduleDescription(finalText);
        }
      }
    }
  }, [
    selectedRepeatType,
    interval,
    activeDays,
    isWeekViewOfMonth,
    activeMonthDates,
    activeMonthDays,
  ]);

  useEffect(() => {
    if (startDate) {
      let defaultOptions = [];
      defaultOptions.push({
        label: `Daily`,
        value: 'daily',
      });
      defaultOptions.push({
        label: `Weekly on ${startDate.format('dddd')}`,
        value: 'weekly',
      });
      defaultOptions.push({
        label: `Bi-Weekly on ${startDate.format('dddd')}`,
        value: 'biweekly',
      });
      defaultOptions.push({
        label: `Monthly on ${startDate.format('D')} of the month`,
        value: 'monthly',
      });
      defaultOptions.push({
        label: `Custom Weekly...`,
        value: 'custom_weekly',
      });
      defaultOptions.push({
        label: `Custom Monthly...`,
        value: 'custom_monthly',
      });
      setDefaultRepeatOptions(defaultOptions);

      let tempActiveDays = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      };
      let tempActiveMonthDates = {};
      for (let i = 1; i <= 31; i++) {
        tempActiveMonthDates[i] = false;
      }
      tempActiveMonthDates['Last Day'] = false;

      switch (selectedRepeatType && selectedRepeatType.value) {
        case 'weekly':
          tempActiveDays[startDate.format('dddd').toLocaleLowerCase()] = true;
          setActiveDays(tempActiveDays);
          setSelectedRepeatType({
            label: `Weekly on ${startDate.format('dddd')}`,
            value: 'weekly',
          });
          break;
        case 'biweekly':
          tempActiveDays[startDate.format('dddd').toLocaleLowerCase()] = true;
          setActiveDays(tempActiveDays);
          setSelectedRepeatType({
            label: `Bi-Weekly on ${startDate.format('dddd')}`,
            value: 'biweekly',
          });
          break;
        case 'monthly':
          tempActiveMonthDates[
            startDate.format('D').toLocaleLowerCase()
          ] = true;
          setActiveMonthDates(tempActiveMonthDates);
          setSelectedRepeatType({
            label: `Monthly on ${startDate.format('D')} of the month`,
            value: 'monthly',
          });
          break;
        default:
      }
    }
  }, [startDate]);

  useEffect(() => { }, [startTime, endTime]);

  const ThemeColorComp = ({ color }) => (
    <ThemeBox color={color} onClick={() => handleThemeColor(color)}>
      {themeColor === color && <img src={tick} alt="" width="18px" />}
    </ThemeBox>
  );

  const handleThemeColor = color => {
    setThemeColor(color);
  };

  const handleAssignOrRemoveMember = (user, action) => {
    if (user && user.id) {
      let newArr = [...assignees];
      if (action === 'remove') {
        newArr = newArr.filter(member => member.id !== user.id);
      } else {
        newArr.push(user);
      }
      setAssignees(newArr);
    }
  };

  const handleAssignMember = user => {
    handleAssignOrRemoveMember(user, 'assign');
  };

  const handleRemoveMember = user => {
    handleAssignOrRemoveMember(user, 'remove');
  };

  const onDateChange = date => {
    setStartDate(date);
  };

  const onRepeatTypeChange = e => {
    let errors = { ...saveErrors };
    delete errors['selectedRepeatType'];
    setSaveErrors(errors);
    let tempActiveDays = {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    };
    let tempActiveMonthDates = {};
    for (let i = 1; i <= 31; i++) {
      tempActiveMonthDates[i] = false;
    }
    tempActiveMonthDates['Last Day'] = false;

    switch (e.value) {
      case 'weekly':
        tempActiveDays[startDate.format('dddd').toLocaleLowerCase()] = true;
        setActiveDays(tempActiveDays);
        setInterval(parseInt(1));
        break;
      case 'biweekly':
        tempActiveDays[startDate.format('dddd').toLocaleLowerCase()] = true;
        setActiveDays(tempActiveDays);
        setInterval(parseInt(2));
        break;
      case 'monthly':
        tempActiveMonthDates[startDate.format('D').toLocaleLowerCase()] = true;
        setActiveMonthDates(tempActiveMonthDates);
        setInterval(parseInt(1));
        break;
      default:
    }
    setSelectedRepeatType(e);
  };

  const onMonthDateSelect = date => {
    let errors = { ...saveErrors };
    delete errors['activeMonthDates'];
    setSaveErrors(errors);
    let tempActiveMonthDates = { ...activeMonthDates };
    tempActiveMonthDates[date] = !tempActiveMonthDates[date];
    setActiveMonthDates(tempActiveMonthDates);
  };

  const onMonthDaySelect = day => {
    let errors = { ...saveErrors };
    delete errors['activeMonthDays'];
    setSaveErrors(errors);
    let tempActiveMonthDays = { ...activeMonthDays };
    tempActiveMonthDays[day] = !tempActiveMonthDays[day];
    setActiveMonthDays(tempActiveMonthDays);
  };

  const findDateText = date => {
    if (dateText[date]) {
      return dateText[date];
    } else {
      return `${date}th`;
    }
  };

  const onEndDateSelect = date => {
    let errors = { ...saveErrors };
    delete errors['endDate'];
    setSaveErrors(errors);
    setEndDate(date);
  };

  const onActiveDaysSelect = day => {
    let errors = { ...saveErrors };
    delete errors['activeDays'];
    setSaveErrors(errors);
    let tempActiveDays = { ...activeDays };
    tempActiveDays[day] = !tempActiveDays[day];
    setActiveDays(tempActiveDays);
  };

  const onIntervalChange = value => {
    let errors = { ...saveErrors };
    delete errors['interval'];
    setSaveErrors(errors);
    setInterval(parseInt(value));
  };

  const handleStartTime = time => {
    if (time) {
      let errors = { ...saveErrors };
      delete errors['timeDifference'];
      setSaveErrors(errors);
      const start_time = time.replace(/ /g, '');
      setStartTime(moment(start_time, 'h:mmA').format('HH:mm:ss'));
    } else {
      setStartTime('');
    }
  };
  const handleEndTime = time => {
    if (time) {
      let errors = { ...saveErrors };
      delete errors['timeDifference'];
      setSaveErrors(errors);
      const end_time = time.replace(/ /g, '');
      setEndTime(moment(end_time, 'h:mmA').format('HH:mm:ss'));
    } else {
      setEndTime('');
    }
  };

  const handleDisable = () => {
    let isValid = false;
    if (assignees.length > 0) {
      if (!isRepeat) {
        isValid = true;
      } else if (
        isRepeat &&
        selectedRepeatType &&
        selectedRepeatType.value &&
        endDate
      ) {
        isValid = true;
      }
    }
    return !isValid;
  };

  const handleSave = () => {
    if (checkError()) {
      const start_date = moment(
        startDate.format('YYYY-MM-DD') + ' ' + startTime,
      );
      const end_date = moment(startDate.format('YYYY-MM-DD') + ' ' + endTime);

      setRequiredStartDate(moment(start_date).format('YYYY-MM-DDTHH:mm:ss'));
      setRequiredEndDate(moment(end_date).format('YYYY-MM-DDTHH:mm:ss'));

      if (selectedOrganization && selectedOrganization.id) {
        let assigneeIds = [];
        if (assignees && assignees.length > 0) {
          assignees.forEach(element => {
            assigneeIds.push(element.id);
          });
        }
        let payload = {
          assignees: assigneeIds,
        };

        if (themeColor) {
          payload.theme_color = themeColor;
        }

        payload.required_start_date = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');
        payload.start_time = moment(
          startDate.format('YYYY-MM-DD') + ' ' + startTime,
          'YYYY-MM-DD h:mmA',
        ).format();
        payload.end_time = moment(
          startDate.format('YYYY-MM-DD') + ' ' + endTime,
          'YYYY-MM-DD h:mmA',
        ).format();
        payload.recurring = isRepeat;
        if (!isRepeat) {
          payload.required_end_date = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');
        }
        if (isRepeat) {
          if (
            selectedRepeatType.value === 'biweekly' ||
            selectedRepeatType.value === 'custom_weekly'
          ) {
            payload.schedule_type = 'weekly';
          } else if (selectedRepeatType.value === 'custom_monthly') {
            payload.schedule_type = 'monthly';
          } else {
            payload.schedule_type = selectedRepeatType.value;
          }
          payload.interval = interval;
          payload.required_end_date = endDate.format('YYYY-MM-DDTHH:mm:ss');
        }
        if (
          isRepeat &&
          (selectedRepeatType.value === 'weekly' ||
            selectedRepeatType.value === 'biweekly' ||
            selectedRepeatType.value === 'custom_weekly')
        ) {
          let tempActiveDaysArray = [];
          for (let key in activeDays) {
            if (activeDays[key]) {
              tempActiveDaysArray.push(weekParse[key]);
            }
          }
          payload.days_of_week = tempActiveDaysArray;
        } else if (
          isRepeat &&
          (selectedRepeatType.value === 'monthly' ||
            (selectedRepeatType.value === 'custom_monthly' &&
              !isWeekViewOfMonth))
        ) {
          let tempMonthDatesArray = [];
          for (let i = 1; i <= 31; i++) {
            if (activeMonthDates[i]) {
              tempMonthDatesArray.push(i);
            }
          }
          payload.days_of_month = tempMonthDatesArray;
          if (activeMonthDates['Last Day']) {
            payload.is_last_day_month = true;
          }
          payload.is_weekdays_of_month = false;
        } else if (
          isRepeat &&
          selectedRepeatType.value === 'custom_monthly' &&
          isWeekViewOfMonth
        ) {
          let tempMonthDaysArray = [];
          for (let i = 0; i <= 27; i++) {
            if (activeMonthDays[i]) {
              tempMonthDaysArray.push(i);
            }
          }
          payload.weekdays_of_month = tempMonthDaysArray;
          payload.is_weekdays_of_month = true;
        }

        setShiftPayload(payload);
        setRecurringJobDescription(scheduleDescription);
        setIsSubmitted(true);
      }
      toggle();
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let position = '';
    let formIsValid = true;

    if (assignees.length === 0) {
      formIsValid = false;
      saveErrors['assignees'] = 'Assignee is required';
    }

    if (!startDate) {
      formIsValid = false;
    }
    if (!startTime || !endTime) {
      formIsValid = false;
      position = 'time';
    }
    if (
      moment(startDate.format('YYYY-MM-DD') + ' ' + endTime).diff(
        moment(startDate.format('YYYY-MM-DD') + ' ' + startTime),
      ) < 1
    ) {
      formIsValid = false;
      saveErrors['timeDifference'] = 'End time must be after start time';
      position = 'time';
    } else if (
      moment(startDate.format('YYYY-MM-DD') + ' ' + endTime).diff(
        moment(startDate.format('YYYY-MM-DD') + ' ' + startTime),
      ) < 1800000
    ) {
      formIsValid = false;
      saveErrors['timeDifference'] =
        'Minmium 30 minutes time difference required';
      position = 'time';
    }
    if (
      moment(startDate.format('YYYY-MM-DD') + ' ' + startTime).diff(moment()) <
      1
    ) {
      formIsValid = false;
      saveErrors['timeDifference'] = "Past time can't be set";
      position = 'time';
    }
    if (isRepeat && !selectedRepeatType) {
      formIsValid = false;
      saveErrors['selectedRepeatType'] = 'Shift repeat type must be set';
    }
    if (isRepeat && !endDate) {
      formIsValid = false;
      saveErrors['endDate'] = 'Ending date is must';
    }
    if (
      isRepeat &&
      selectedRepeatType &&
      selectedRepeatType.value === 'custom_weekly'
    ) {
      let haveActiveDays = false;
      Object.keys(activeDays).forEach(key => {
        if (activeDays[key] === true) {
          haveActiveDays = true;
        }
      });
      if (!haveActiveDays) {
        formIsValid = false;
        saveErrors['activeDays'] = 'At least one day needs to be selected';
      }
    }
    if (isRepeat && selectedRepeatType && interval < 1) {
      formIsValid = false;
      saveErrors['interval'] = 'Interval is required';
    } else if (
      isRepeat &&
      selectedRepeatType &&
      ((selectedRepeatType.value === 'daily' && interval > 150) ||
        (selectedRepeatType.value === 'custom_weekly' && interval > 25) ||
        (selectedRepeatType.value === 'custom_monthly' && interval > 10))
    ) {
      formIsValid = false;
      saveErrors['interval'] = `Interval maximum limit is ${selectedRepeatType.value === 'daily'
          ? `150 for daily`
          : selectedRepeatType.value === 'custom_weekly'
            ? `25 for weekly`
            : selectedRepeatType.value === 'custom_monthly' && `10 for monthly`
        } schedule`;
    }
    if (
      isRepeat &&
      selectedRepeatType &&
      selectedRepeatType.value === 'custom_monthly' &&
      !isWeekViewOfMonth
    ) {
      let haveActiveDays = false;
      Object.keys(activeMonthDates).forEach(key => {
        if (activeMonthDates[key] === true) {
          haveActiveDays = true;
        }
      });
      if (!haveActiveDays) {
        formIsValid = false;
        saveErrors['activeMonthDates'] =
          'At least one day needs to be selected';
      }
    }
    if (
      isRepeat &&
      selectedRepeatType &&
      selectedRepeatType.value === 'custom_monthly' &&
      isWeekViewOfMonth
    ) {
      let haveActiveDays = false;
      Object.keys(activeMonthDays).forEach(key => {
        if (activeMonthDays[key] === true) {
          haveActiveDays = true;
        }
      });
      if (!haveActiveDays) {
        formIsValid = false;
        saveErrors['activeMonthDays'] = 'At least one day needs to be selected';
      }
    }

    setSaveErrors(saveErrors);
    // if (position) {
    //   handleScroll(position);
    // }
    return formIsValid;
  };

  const handleScroll = position => {
    if (position === 'jobName') {
      scrollAssignee.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    } else if (position === 'taskDescription') {
      scrollDescription.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    } else if (position === 'time') {
      scrollTime.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const clearState = () => {
    setAssignees([]);
    setStartDate(moment(new Date()));
    setStartTime('');
    setEndTime('');
    setIsRepeat(false);
    setSelectedRepeatType('');
    setDefaultRepeatOptions([]);
    setInterval(1);
    setActiveDays({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    });
    setIsWeekViewOfMonth(false);
    setActiveMonthDates({});
    setActiveMonthDays({});
    setScheduleDescription('');
    setEndDate(null);
    setThemeColor('');
    setSaveErrors({});
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: '700px' }}>
      <ModalBody style={{ padding: `40px`, minHeight: `800px` }}>
        <Container gap="20px" marginTop="0" borderColor="transparent">
          <PageTitle fontSize="16px">Add Shift</PageTitle>
          <InputWithLabelSection>
            <InputLabel>
              {t('assignee')} <RedStar>*</RedStar>
            </InputLabel>
            <AddMultipleAssigneeDropdown
              numberOfVisibleMembers={5}
              isLoading={memberShortListIsLoading}
              assigneesList={assignees}
              membersList={orgMembersShortList}
              handleAssign={handleAssignMember}
              handleRemove={handleRemoveMember}
            />

            {saveErrors && saveErrors['assignees'] && (
              <FormAlert>{saveErrors['assignees']}</FormAlert>
            )}
          </InputWithLabelSection>

          <InputSection>
            <InputWithLabelSection style={{ paddingBottom: `15px` }}>
              <InputLabel>
                {isRepeat ? (
                  <>{t('shift_start_date')}</>
                ) : (
                  <>{t('select_date')}</>
                )}{' '}
                <RedStar>*</RedStar>
              </InputLabel>
              <DatePicker
                id="createIssuedDate"
                date={startDate}
                onDateChange={onDateChange}
                dateDisplayFormat={'DD/MM/YYYY'}
                placeholder={'Select date'}
              />
            </InputWithLabelSection>
            <InputWithLabelSection>
              <TimeRangeSection>
                <InputLabel>
                  {t('start_time')}
                  <RedStar>*</RedStar>
                </InputLabel>
                <InputLabel>
                  {t('end_time')}
                  <RedStar>*</RedStar>
                </InputLabel>
              </TimeRangeSection>
              <TimeRangeComponent
                setStartTime={handleStartTime}
                setEndTime={handleEndTime}
                initialStartTime={
                  requiredStartDate && moment(requiredStartDate).format('HH:mm')
                }
                initialEndTime={
                  requiredEndDate && moment(requiredEndDate).format('HH:mm')
                }
                startTimeError={
                  saveErrors &&
                  saveErrors['timeDifference'] !== undefined &&
                  saveErrors['timeDifference'] &&
                  saveErrors['timeDifference']
                }
                endTimeError={
                  saveErrors &&
                  saveErrors['timeDifference'] !== undefined &&
                  saveErrors['timeDifference'] &&
                  saveErrors['timeDifference']
                }
              />
              {saveErrors &&
                saveErrors['timeDifference'] &&
                saveErrors['timeDifference'] !== undefined && (
                  <FormAlert>{saveErrors['timeDifference']}</FormAlert>
                )}
              <DurationContainer>
                <DurationText>
                  <img src={durationIcon} alt="duration icon" width="15px" />{' '}
                  {t('job_duration')}:{' '}
                  {startDate &&
                    formatDurationToHoursMinutes(
                      moment(
                        startDate.format('YYYY-MM-DD') + ' ' + endTime,
                      ).diff(
                        moment(
                          startDate.format('YYYY-MM-DD') + ' ' + startTime,
                        ),
                      ) / 1000,
                    )}
                </DurationText>
              </DurationContainer>
            </InputWithLabelSection>

            <div style={{ gridColumn: `1 / span 2` }}>
              <CommonGrid justifyContent="start" alignItem="center">
                <TabButtonContainer columns="auto auto">
                  <TabButton
                    onClick={() => setIsRepeat(false)}
                    selected={isRepeat}
                    index={false}
                  >
                    Single Schedule
                  </TabButton>
                  <TabButton
                    onClick={() => setIsRepeat(true)}
                    selected={isRepeat}
                    index={true}
                  >
                    Recurring Schedule
                  </TabButton>
                </TabButtonContainer>
              </CommonGrid>

              {isRepeat && (
                <RepeatSection>
                  <InputWithLabelSection>
                    <InputLabel>
                      {t('repeats')} <RedStar>*</RedStar>
                    </InputLabel>
                    <Select
                      value={selectedRepeatType}
                      options={defaultRepeatOptions}
                      placeholder={t('select_type')}
                      onChange={e => {
                        onRepeatTypeChange(e);
                      }}
                      styles={CreateProjectDropdown({
                        error: saveErrors['selectedRepeatType'],
                      })}
                    />

                    {saveErrors &&
                      saveErrors['selectedRepeatType'] !== undefined &&
                      saveErrors['selectedRepeatType'] && (
                        <FormAlert>
                          {saveErrors['selectedRepeatType']}
                        </FormAlert>
                      )}
                  </InputWithLabelSection>

                  {selectedRepeatType &&
                    (selectedRepeatType.value === 'daily' ||
                      selectedRepeatType.value === 'custom_weekly' ||
                      selectedRepeatType.value === 'custom_monthly') && (
                      <InputWithLabelSection>
                        <CommonFlex gap="10px">
                          <InputLabel>Every</InputLabel>
                          <InputField
                            height="40px"
                            width="70px"
                            type="number"
                            value={interval}
                            onChange={e => onIntervalChange(e.target.value)}
                            onWheel={e => e.target.blur()}
                            style={
                              saveErrors &&
                              saveErrors[`interval`] && {
                                border: `1px solid #fe5969`,
                                backgroundColor: `rgba(252, 87, 104, 0.05)`,
                              }
                            }
                          />
                          <InputLabel>
                            {selectedRepeatType &&
                              selectedRepeatType.value === 'daily'
                              ? 'day(s)'
                              : selectedRepeatType.value === 'custom_weekly'
                                ? 'week(s)'
                                : selectedRepeatType.value === 'custom_monthly' &&
                                'month(s)'}
                          </InputLabel>
                        </CommonFlex>
                        {saveErrors &&
                          saveErrors['interval'] !== undefined &&
                          saveErrors['interval'] && (
                            <FormAlert>{saveErrors['interval']}</FormAlert>
                          )}
                      </InputWithLabelSection>
                    )}

                  {selectedRepeatType &&
                    selectedRepeatType.value === 'custom_weekly' && (
                      <>
                        <InputWithLabelSection>
                          <InputLabel
                            error={
                              saveErrors &&
                              saveErrors['activeDays'] !== undefined &&
                              saveErrors['activeDays'] &&
                              saveErrors['activeDays']
                            }
                          >
                            {t('on_these_days')} <RedStar>*</RedStar>
                          </InputLabel>
                          <WeekDaysContainer>
                            <WeekdayBox
                              active={activeDays.monday}
                              onClick={() => onActiveDaysSelect('monday')}
                            >
                              {t('mon')}
                            </WeekdayBox>
                            <WeekdayBox
                              active={activeDays.tuesday}
                              onClick={() => onActiveDaysSelect('tuesday')}
                            >
                              {t('tue')}
                            </WeekdayBox>
                            <WeekdayBox
                              active={activeDays.wednesday}
                              onClick={() => onActiveDaysSelect('wednesday')}
                            >
                              {t('wed')}
                            </WeekdayBox>
                            <WeekdayBox
                              active={activeDays.thursday}
                              onClick={() => onActiveDaysSelect('thursday')}
                            >
                              {t('thu')}
                            </WeekdayBox>
                            <WeekdayBox
                              active={activeDays.friday}
                              onClick={() => onActiveDaysSelect('friday')}
                            >
                              {t('fri')}
                            </WeekdayBox>
                            <WeekdayBox
                              active={activeDays.saturday}
                              onClick={() => onActiveDaysSelect('saturday')}
                            >
                              {t('sat')}
                            </WeekdayBox>
                            <WeekdayBox
                              active={activeDays.sunday}
                              onClick={() => onActiveDaysSelect('sunday')}
                            >
                              {t('sun')}
                            </WeekdayBox>
                          </WeekDaysContainer>
                        </InputWithLabelSection>
                        {saveErrors &&
                          saveErrors['activeDays'] !== undefined &&
                          saveErrors['activeDays'] && (
                            <FormAlert>{saveErrors['activeDays']}</FormAlert>
                          )}
                      </>
                    )}

                  {selectedRepeatType &&
                    selectedRepeatType.value === 'custom_monthly' && (
                      <>
                        <InputWithLabelSection style={{ minWidth: `400px` }}>
                          <CommonFlex>
                            <CommonFlex
                              gap="10px"
                              margin="0 auto 0 0"
                              style={{ cursor: `pointer` }}
                              onClick={() => setIsWeekViewOfMonth(false)}
                            >
                              <RadioButton active={!isWeekViewOfMonth} />
                              <CommonText paddingLeft="45px" fontWeight="500">
                                Dates of Month
                              </CommonText>
                            </CommonFlex>
                            <CommonFlex
                              gap="10px"
                              margin="0 auto 0 0"
                              style={{ cursor: `pointer` }}
                              onClick={() => setIsWeekViewOfMonth(true)}
                            >
                              <RadioButton active={isWeekViewOfMonth} />
                              <CommonText fontWeight="500">
                                Week Days of Month
                              </CommonText>
                            </CommonFlex>
                          </CommonFlex>
                        </InputWithLabelSection>

                        {!isWeekViewOfMonth ? (
                          <>
                            <CommonFlex>
                              <MonthDateContainer>
                                {activeMonthDates &&
                                  Object.keys(activeMonthDates).length > 0 &&
                                  Object.keys(activeMonthDates).map(
                                    (date, i) => (
                                      <MonthDateBox
                                        key={i}
                                        active={activeMonthDates[date]}
                                        style={{
                                          width: date === 'Last Day' && '105px',
                                        }}
                                        onClick={() => onMonthDateSelect(date)}
                                      >
                                        {date}
                                      </MonthDateBox>
                                    ),
                                  )}
                              </MonthDateContainer>
                            </CommonFlex>
                            {saveErrors &&
                              saveErrors['activeMonthDates'] !== undefined &&
                              saveErrors['activeMonthDates'] && (
                                <FormAlert>
                                  {saveErrors['activeMonthDates']}
                                </FormAlert>
                              )}
                          </>
                        ) : (
                          <>
                            <CommonFlex gap="10px">
                              <CommonGrid gap="10px" columns="35px">
                                <WeekNumberBox>1st</WeekNumberBox>
                                <WeekNumberBox>2nd</WeekNumberBox>
                                <WeekNumberBox>3rd</WeekNumberBox>
                                <WeekNumberBox>4th</WeekNumberBox>
                              </CommonGrid>
                              <MonthDateContainer>
                                {activeMonthDays &&
                                  Object.keys(activeMonthDays).length > 0 &&
                                  Object.keys(activeMonthDays).map(
                                    (day, index) => (
                                      <MonthDateBox
                                        key={index}
                                        active={activeMonthDays[day]}
                                        onClick={() => onMonthDaySelect(day)}
                                      >
                                        {weekDays[day % 7]}
                                      </MonthDateBox>
                                    ),
                                  )}
                              </MonthDateContainer>
                            </CommonFlex>
                            {saveErrors &&
                              saveErrors['activeMonthDays'] !== undefined &&
                              saveErrors['activeMonthDays'] && (
                                <FormAlert>
                                  {saveErrors['activeMonthDays']}
                                </FormAlert>
                              )}
                          </>
                        )}
                      </>
                    )}

                  {scheduleDescription &&
                    selectedRepeatType &&
                    (selectedRepeatType.value === 'custom_weekly' ||
                      selectedRepeatType.value === 'custom_monthly') && (
                      <ScheduleText>*{scheduleDescription}</ScheduleText>
                    )}

                  <InputWithLabelSection>
                    <InputLabel>
                      {t('ending_on')} <RedStar>*</RedStar>
                    </InputLabel>
                    <DatePicker
                      dateDisplayFormat="DD/MM/YYYY"
                      placeholder="Select End Date"
                      date={endDate ? endDate : null}
                      onDateChange={onEndDateSelect}
                      disablePreviousDatesFrom={startDate}
                      error={
                        saveErrors &&
                        saveErrors['endDate'] !== undefined &&
                        saveErrors['endDate'] &&
                        saveErrors['endDate']
                      }
                    />
                    {saveErrors &&
                      saveErrors['endDate'] !== undefined &&
                      saveErrors['endDate'] && (
                        <FormAlert>{saveErrors['endDate']}</FormAlert>
                      )}
                  </InputWithLabelSection>
                </RepeatSection>
              )}
            </div>

            <InputWithLabelSection>
              <InputLabel>{t('choose_theme')}</InputLabel>
              <ThemesContainer>
                <ThemeColorComp color="#D4F4EE" />
                <ThemeColorComp color="#D2E4FE" />
                <ThemeColorComp color="#D9D7FC" />
                <ThemeColorComp color="#FDF3E4" />
              </ThemesContainer>
            </InputWithLabelSection>
          </InputSection>

          <ModalButtonSection
            style={{ justifyContent: 'start', paddingLeft: `10px` }}
          >
            <WhiteButton type="cancel" onClick={toggle}>
              Close
            </WhiteButton>
            <PrimaryButton
              disabled={handleDisable()}
              onClick={() => handleSave()}
            >
              {/* {createOrUpdateJobIsLoading ? (
              <ButtonTextLoader
                loadingText={shiftAction === 'Update' ? 'Updating' : 'Adding'}
                fontSize="13px"
              />
            ) : shiftAction === 'Update' ? (
              'Update Shift'
            ) : (
              'Add Shift'
            )} */}
              Add Shift
            </PrimaryButton>
          </ModalButtonSection>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default ShiftModalCreateJob;

import {
  ADD_CLIENT_LOCATION,
  ADD_CLIENT_LOCATION_FAILURE,
  ADD_CLIENT_LOCATION_SUCCESS,
  CLEAR_CLIENT_LOCATIONS,
  CLEAR_CREATED_CUSTOMER_INFO,
  CREATE_CLIENT,
  CREATE_CLIENT_FAILURE,
  CREATE_CLIENT_SUCCESS,
  CREATE_CUSTOMER_BULK,
  CREATE_CUSTOMER_BULK_FAILURE,
  CREATE_CUSTOMER_BULK_SUCCESS,
  DELETE_CLIENT,
  DELETE_CLIENT_FAILURE,
  DELETE_CLIENT_LOCATION,
  DELETE_CLIENT_LOCATION_FAILURE,
  DELETE_CLIENT_LOCATION_SUCCESS,
  DELETE_CLIENT_SUCCESS,
  GET_CLIENTS_LIST,
  GET_CLIENTS_LIST_FAILURE,
  GET_CLIENTS_LIST_SUCCESS,
  GET_CLIENTS_SHORT_LIST,
  GET_CLIENTS_SHORT_LIST_FAILURE,
  GET_CLIENTS_SHORT_LIST_SUCCESS,
  GET_CLIENT_DETAILS,
  GET_CLIENT_DETAILS_FAILURE,
  GET_CLIENT_DETAILS_SUCCESS,
  GET_CLIENT_LOCATIONS,
  GET_CLIENT_LOCATIONS_FAILURE,
  GET_CLIENT_LOCATIONS_SUCCESS,
  GET_GHL_CONTACT_LIST,
  GET_GHL_CONTACT_LIST_FAILURE,
  GET_GHL_CONTACT_LIST_SUCCESS,
  LOGIN_PROFILE_CREATED_RESET,
  UPDATE_CLIENT,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_LOCATION,
  UPDATE_CLIENT_LOCATION_FAILURE,
  UPDATE_CLIENT_LOCATION_SUCCESS,
  UPDATE_CLIENT_SUCCESS,
} from '../../modules/constants';
import { createNotification } from '../../modules/notificationManager';

//GHL sync member

export const ghlSyncContactRequestHandler = (state, action) => {
  return {
    ...state,
    ghlSyncContactIsLoading: true,
  };
};

export const ghlSyncContactRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    ghlSyncContactIsLoading: false,
    ghlContactList: data.data,
  };
};

export const ghlSyncContactRequestFailureHandler = (state, action) => {
  return {
    ...state,
    ghlSyncContactIsLoading: false,
    ghlContactList: [],
  };
};

export const clientListRequestHandler = (state, action) => {
  return {
    ...state,
    duplicateEmail: false,
    isLoading: true,
  };
};
export const clientListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      totalClientsCount: data.data.count,
      pageSize: data.data.page_size,
      clientList: data.data.results,
      bulkCustomerCreated: false,
    };
  }
  return {
    ...state,
    isLoading: false,
    totalClientsCount: null,
    pageSize: null,
    clientList: [],
  };
};
export const clientListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    totalClientsCount: null,
    pageSize: null,
    clientList: [],
  };
};

//Client Short List

export const clientShortListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const clientShortListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      clientShortList: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    clientShortList: [],
  };
};
export const clientShortListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    clientShortList: [],
  };
};

//client details
export const clientDetailsRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const clientDetailsRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      clientDetails: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    clientDetails: null,
  };
};
export const clientDetailsRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    clientDetails: null,
  };
};

//Create client
export const createClientHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
    duplicateEmail: false,
    createdClientInfo: null,
  };
};
export const createClientSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (state.clientList) {
    const clientshortdic = {
      id: data.data.id,
      name: data.data.name,
    };
    const list = [data.data, ...state.clientList];
    const shortList = [clientshortdic, ...state.clientShortList];
    createNotification('success', 'Customer created.', 2000);
    return {
      ...state,
      isLoading: false,
      clientList: list,
      clientShortList: shortList,
      createdClientId: data.data.id,
      createdClientInfo: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    createdClientId: null,
    createdClientInfo: null,
  };
};
export const createClientFailureHandler = (state, action) => {
  const errMsg =
    action?.payload?.error?.response?.data &&
    action?.payload?.error?.response?.data[0];
  action?.payload?.showToast &&
    createNotification(
      'error',
      errMsg ? errMsg : 'Could not create customer. Please try again!',
      3000,
    );
  return {
    ...state,
    isLoading: false,
    duplicateEmail:
      !action?.payload?.showToast &&
      action?.payload?.error?.response?.data &&
      action?.payload?.error?.response?.data[0] === 'Email already in use.',
    createdClientId: null,
    createdClientInfo: null,
  };
};

//Create Customer Bulk
export const createCustomerBulkHandler = (state, action) => {
  return {
    ...state,
    isBulkCustomerLoading: true,
  };
};

export const createCustomerBulkSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;

  if (data && data.status === 201) {
    createNotification('success', 'Customers created.', 2000);
    return {
      ...state,
      isBulkCustomerLoading: false,
      bulkCustomerCreated: true,
    };
  }

  return {
    ...state,
    isBulkCustomerLoading: false,
    bulkCustomerCreated: false,
  };
};

export const createCustomerBulkFailureHandler = (state, action) => {
  createNotification(
    'error',
    'Could not create customers. Please try again!',
    3000,
  );
  return {
    ...state,
    isBulkCustomerLoading: false,
    bulkCustomerCreated: false,
  };
};

// Update client
export const updateClientHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    clientUpdateLoading: true,
    updatingClientId: payload.client_id,
  };
};

export const updateClientSuccessHandler = (state, action) => {
  const {
    payload: { data, clientType },
  } = action;

  // if (data.status === 200 && data.data) {
  //   createNotification('success', 'Client information updated successfully', 3000);

  //   return {
  //     ...state,
  //   clientUpdateLoading: false,
  //   };
  // }

  const newClientList = state.clientList.map(client => {
    if (client.id === action.payload.data.data.id) {
      return action.payload.data.data;
    }
    return client;
  });
  if (!data?.data) {
    return state; // If no data, return the current state
  }

  if (!data.data.is_active) {
    createNotification('success', 'Customer archived.', 2000);
  } else if (clientType === 'archived') {
    createNotification('success', 'Customer unarchived.', 2000);
  } else {
    createNotification('success', 'Customer updated.', 2000);
  }

  return {
    ...state,
    clientList: newClientList,
    clientUpdateLoading: false,
    updatingClientId: null,
  };
};

export const updateClientFailureHandler = (state, action) => {
  createNotification('error', 'Could not update. Please try again!', 3000);
  return {
    ...state,
    clientUpdateLoading: false,
    updatingClientId: null,
  };
};

// Delete client
export const deleteClientHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    clientDeleteLoading: true,
    deletingClientId: payload.client_id,
  };
};
export const deleteClientSuccessHandler = (state, action) => {
  const {
    payload: { client_id },
  } = action;
  if (state.clientList && state.clientList.length) {
    createNotification('success', 'Customer deleted.', 2000);
    return {
      ...state,
      clientDeleteLoading: false,
      deletingClientId: null,
      clientList: state.clientList.filter(client => client.id !== client_id),
    };
  }
  return {
    ...state,
    clientDeleteLoading: false,
    deletingClientId: null,
  };
};
export const deleteClientFailureHandler = (state, action) => {
  createNotification(
    'error',
    'Could not delete customer. Please try again!',
    3000,
  );
  return {
    ...state,
    clientDeleteLoading: false,
    deletingClientId: null,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

export const addClientLocationRequest = (state, action) => {
  return {
    ...state,
    addOrUpdateclientLocalMapLoading: true,
  };
};

export const addClientLocationRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    createNotification('success', 'Customer location added.', 2000);
    const currentList = JSON.parse(JSON.stringify(data.data));
    return {
      ...state,
      addOrUpdateclientLocalMapLoading: false,
      createdClientLocationInfo: currentList,
      clientLocationList: [...state.clientLocationList, currentList],
    };
  }
  return {
    ...state,
    addOrUpdateclientLocalMapLoading: false,
    createdClientLocationInfo: null,
  };
};

export const addClientLocationRequestFailure = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification('error', 'There was an error. Please try again!', 3000);
    return {
      ...state,
      addOrUpdateclientLocalMapLoading: false,
      createdClientLocationInfo: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification(
      'error',
      'Sorry! could not add Customer location. Please try again.',
      2000,
    );
  }
  return {
    ...state,
    addOrUpdateclientLocalMapLoading: false,
    createdClientLocationInfo: null,
  };
};

//get client location
export const getClientLocationRequest = (state, action) => {
  return {
    ...state,
    getclientLocalMapLoading: true,
  };
};
export const getClientLocationRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      clientLocationList: data.data,
      getclientLocalMapLoading: false,
    };
  }
  return {
    ...state,
    clientLocationList: data,
    getclientLocalMapLoading: false,
  };

  // return {
  //   ...state,
  //   clientLocationList: [],
  //   getclientLocalMapLoading: false,
  // };
};
export const getClientLocationRequestFailure = (state, action) => {
  // createNotification(
  //   'error',
  //   'Failed to load customer locations. Please try again!',
  //   3000,
  // );
  return {
    ...state,
    clientLocationList: [],
    getclientLocalMapLoading: false,
  };
};

// update client location
export const updateClientLocationRequest = (state, action) => {
  return {
    ...state,
    addOrUpdateclientLocalMapLoading: true,
    clientLocationCreateUpdateSuccess: false,
  };
};

export const updateClientLocationRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200 && data.data) {
    createNotification('success', 'Customer location updated.', 3000);
    const currentList = JSON.parse(JSON.stringify(state.clientLocationList));
    const details = JSON.parse(JSON.stringify(data.data));
    const index = state.clientLocationList.findIndex(
      location => location.id === details.id,
    );
    currentList[index] = details;
    return {
      ...state,
      addOrUpdateclientLocalMapLoading: false,
      clientLocationCreateUpdateSuccess: true,
      clientLocationList: currentList,
      locationDetails: { ...details },
    };
  }
  return {
    ...state,
    addOrUpdateclientLocalMapLoading: false,
  };
};

export const updateClientLocationRequestFailure = (state, action) => {
  createNotification(
    'error',
    'Could not update customer location. Please try again!',
    3000,
  );
  return {
    ...state,
    addOrUpdateclientLocalMapLoading: false,
  };
};

export const deleteClientLocationRequest = (state, action) => {
  return {
    ...state,
    clientLocationDeleteLoading: true,
  };
};

export const deleteClientLocationRequestSuccess = (state, action) => {
  const {
    payload: { data, location_id },
  } = action;

  if (data && data.status === 204) {
    createNotification('success', 'Location deleted.', 3000);
    return {
      ...state,
      clientLocationDeleteLoading: false,
      clientLocationList: state.clientLocationList.filter(
        location => location.id !== location_id,
      ),
    };
  }
  return {
    ...state,
    clientLocationDeleteLoading: false,
  };
};

export const deleteClientLocationRequestFailure = (state, action) => {
  createNotification(
    'error',
    'Could not delete location. Please try again!',
    3000,
  );
  return {
    ...state,
    clientLocationDeleteLoading: false,
  };
};

export const clearClientLocationsHandler = (state, action) => {
  return {
    ...state,
    clientLocationList: [],
  };
};

export const clearCreatedCustomerInfoRequestHandler = (state, action) => {
  return {
    ...state,
    createdClientInfo: null,
    createdClientId: null,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [ADD_CLIENT_LOCATION]: addClientLocationRequest,
  [ADD_CLIENT_LOCATION_SUCCESS]: addClientLocationRequestSuccess,
  [ADD_CLIENT_LOCATION_FAILURE]: addClientLocationRequestFailure,

  [UPDATE_CLIENT_LOCATION]: updateClientLocationRequest,
  [UPDATE_CLIENT_LOCATION_SUCCESS]: updateClientLocationRequestSuccess,
  [UPDATE_CLIENT_LOCATION_FAILURE]: updateClientLocationRequestFailure,

  [DELETE_CLIENT_LOCATION]: deleteClientLocationRequest,
  [DELETE_CLIENT_LOCATION_SUCCESS]: deleteClientLocationRequestSuccess,
  [DELETE_CLIENT_LOCATION_FAILURE]: deleteClientLocationRequestFailure,

  [GET_CLIENT_LOCATIONS]: getClientLocationRequest,
  [GET_CLIENT_LOCATIONS_SUCCESS]: getClientLocationRequestSuccess,
  [GET_CLIENT_LOCATIONS_FAILURE]: getClientLocationRequestFailure,

  [GET_CLIENTS_LIST]: clientListRequestHandler,
  [GET_CLIENTS_LIST_SUCCESS]: clientListRequestSuccessHandler,
  [GET_CLIENTS_LIST_FAILURE]: clientListRequestFailureHandler,

  [GET_GHL_CONTACT_LIST]: ghlSyncContactRequestHandler,
  [GET_GHL_CONTACT_LIST_SUCCESS]: ghlSyncContactRequestSuccessHandler,
  [GET_GHL_CONTACT_LIST_FAILURE]: ghlSyncContactRequestFailureHandler,

  [GET_CLIENTS_SHORT_LIST]: clientShortListRequestHandler,
  [GET_CLIENTS_SHORT_LIST_SUCCESS]: clientShortListRequestSuccessHandler,
  [GET_CLIENTS_SHORT_LIST_FAILURE]: clientShortListRequestFailureHandler,

  [GET_CLIENT_DETAILS]: clientDetailsRequestHandler,
  [GET_CLIENT_DETAILS_SUCCESS]: clientDetailsRequestSuccessHandler,
  [GET_CLIENT_DETAILS_FAILURE]: clientDetailsRequestFailureHandler,

  [CREATE_CLIENT]: createClientHandler,
  [CREATE_CLIENT_SUCCESS]: createClientSuccessHandler,
  [CREATE_CLIENT_FAILURE]: createClientFailureHandler,

  [CREATE_CUSTOMER_BULK]: createCustomerBulkHandler,
  [CREATE_CUSTOMER_BULK_SUCCESS]: createCustomerBulkSuccessHandler,
  [CREATE_CUSTOMER_BULK_FAILURE]: createCustomerBulkFailureHandler,

  [UPDATE_CLIENT]: updateClientHandler,
  [UPDATE_CLIENT_SUCCESS]: updateClientSuccessHandler,
  [UPDATE_CLIENT_FAILURE]: updateClientFailureHandler,

  [DELETE_CLIENT]: deleteClientHandler,
  [DELETE_CLIENT_SUCCESS]: deleteClientSuccessHandler,
  [DELETE_CLIENT_FAILURE]: deleteClientFailureHandler,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,

  [CLEAR_CLIENT_LOCATIONS]: clearClientLocationsHandler,
  [CLEAR_CREATED_CUSTOMER_INFO]: clearCreatedCustomerInfoRequestHandler,
};

// default initial state
const initialState = {
  isLoading: false,
  clientUpdateLoading: false,
  updatingClientId: null,
  clientDeleteLoading: false,
  clientLocationDeleteLoading: false,
  deletingClientId: null,
  redirect: false,
  clientList: [],
  clientShortList: [],
  clientDetails: null,
  createdClientInfo: null,
  createdClientId: null,

  clientLocationList: [],
  addOrUpdateclientLocalMapLoading: false,
  clientLocationCreateUpdateSuccess: false,
  getclientLocalMapLoading: false,
  createdClientLocationInfo: null,

  locationDetails: null,

  bulkCustomerCreated: false,
  isBulkCustomerLoading: false,

  //ghl contact
  ghlSyncContactIsLoading: false,
  ghlContactList: [],

  // pagination
  totalClientsCount: null,
  pageSize: null,

  duplicateEmail: false,
};

export default function clientReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

import { connect } from 'react-redux';

// Actions
import { getOrganizationMembersShortList } from '../../Projects/projectsActions';
import { createShift } from '../shiftScheduleActions';
// Component
import CreateShift from './CreateShift';
import {
  getOfficesShortList,
} from '../../OfficesNew/officesActions';

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  orgMembersShortList: state.project.orgMembersShortList,
  memberShortListIsLoading: state.project.memberShortListIsLoading,
  createIsLoading: state.schedule.createIsLoading,
  createCallback: state.schedule.createCallback,
  isLoadingJobs: state.jobs.isLoadingJobs,
  jobShortList: state.jobs.jobShortList,
  officesShortList: state.office.officesShortList,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getOrganizationMembersShortList: params =>
    dispatch(getOrganizationMembersShortList(params)),
  createShift: params => dispatch(createShift(params)),
  getOfficesShortList: params => dispatch(getOfficesShortList(params)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(CreateShift);

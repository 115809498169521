import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import BackButtonComponent from '../../../components/BackButtonComponent/BackButtonComponent';
import ButtonTextLoader from '../../../components/Loaders/ButtonTextLoader';
import ComponentCircleLoader from '../../../components/Loaders/ComponentCircleLoader';
import { PrimaryButton, WhiteButton } from '../../../styledComponents/buttons';
import {
  AddClientContainer,
  AddClientContainerNew,
} from '../../../styledComponents/clients';
import {
  CardTitle,
  CommonFlex,
  Container,
  ContentHeader,
  FormAlert,
  InputField,
  InputLabel,
  InputWithLabelSection,
  PageTitle,
} from '../../../styledComponents/common';
import { RedStar } from '../../../styledComponents/members';
import { checkHtml, checkUrl, validateEmail } from '../../../utils/helper';
import { DataAndMapContainer } from '../../FieldServices/JobSites/jobSitesStyles';

const EditClient = props => {
  const [clientId, setClientId] = useState('');
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [officeLocation, setOfficeLocation] = useState('');
  const [saveErrors, setSaveErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      if (props.location.search) {
        const queryParsed = queryString.parse(props.location.search);
        setClientId(queryParsed.id);
        let payload = {
          organization_id: props.selectedOrganization.id,
          client_id: queryParsed.id,
        };
        props.getClientDetails(payload);
      }
    }
  }, [props.selectedOrganization]);

  useEffect(() => {
    if (props.clientDetails && props.clientDetails !== null) {
      setClientName(props.clientDetails.name);
      if (props.clientDetails.email) {
        setClientEmail(props.clientDetails.email);
      } else if (!props.clientDetails.email) {
        setClientEmail('');
      }
      if (props.clientDetails.phone_number) {
        setPhoneNumber(props.clientDetails.phone_number);
      } else if (!props.clientDetails.phone_number) {
        setPhoneNumber('');
      }
      if (props.clientDetails.office_location) {
        setOfficeLocation(props.clientDetails.office_location);
      } else if (!props.clientDetails.office_location) {
        setOfficeLocation('');
      }
    }
  }, [props.clientDetails]);

  const onClientNameChange = e => {
    const { value } = e.target;
    setClientName(value);
    if (saveErrors && saveErrors['clientName']) {
      delete saveErrors['clientName'];
    }
  };
  const onEmailChange = e => {
    const { value } = e.target;
    setClientEmail(value);
    if (saveErrors && saveErrors['clientEmail']) {
      delete saveErrors['clientEmail'];
    }
  };
  const onAddressChange = e => {
    const { value } = e.target;
    setOfficeLocation(value);
  };

  const cancelFunction = () => {
    props.history.push('/user/customers');
  };

  const handleSave = () => {
    if (checkError()) {
      if (props.selectedOrganization && props.selectedOrganization.id) {
        let payload = {
          organization_id: props.selectedOrganization.id,
          client_id: clientId,
          name: clientName,
          //phone_number: phoneNumber ? `${phoneNumber}` : null,
          phone_number: phoneNumber
            ? phoneNumber[0] === '+'
              ? `${phoneNumber}`
              : `+${phoneNumber}`
            : null,
          email: clientEmail ? clientEmail : null,
          // office_location: officeLocation ? officeLocation : null,
        };
        props.updateClient(payload);
        setTimeout(() => {
          props.history.push('/user/customers');
        }, 1200);
      }
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!clientName.trim()) {
      formIsValid = false;
      saveErrors['clientName'] = t('please_enter_customer_name');
    } else if (clientName.length > 256) {
      formIsValid = false;
      saveErrors['clientName'] = t(
        'please_enter_customer_name_within_256_characters',
      );
    } else if (checkUrl(clientName)) {
      formIsValid = false;
      saveErrors['clientName'] = t('client_name_can_not_contain_url');
    } else if (checkHtml(clientName)) {
      formIsValid = false;
      saveErrors['clientName'] = t('invalid_client_name');
    }
    if (!validateEmail(clientEmail)) {
      formIsValid = false;
      saveErrors['clientEmail'] = t('please_enter_a_valid_email_address');
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  return (
    <div className="content">
      <PageTitle>{t('customers')}</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => props.history.push('/user/customers')}
          subTitle={t('back_to_all_customers')}
        />
      </ContentHeader>
      <Container style={{ padding: `30px` }}>
        {props.isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            <CardTitle>{t('customer_details')}</CardTitle>
            <DataAndMapContainer columns="35% 65%">
              <AddClientContainerNew>
                <InputWithLabelSection>
                  <InputLabel>
                    {t('customer_name')} <RedStar>*</RedStar>
                  </InputLabel>
                  <InputField
                    type="text"
                    placeholder={t('write_customer_name')}
                    value={clientName}
                    onChange={e => onClientNameChange(e)}
                    error={
                      saveErrors &&
                      saveErrors['clientName'] &&
                      saveErrors['clientName'] !== undefined
                    }
                  />
                  {saveErrors &&
                    saveErrors['clientName'] &&
                    saveErrors['clientName'] !== undefined && (
                      <FormAlert margin="0 0 0 5px">
                        {saveErrors['clientName']}
                      </FormAlert>
                    )}
                </InputWithLabelSection>
                <InputWithLabelSection>
                  <InputLabel>{t('email_address')}</InputLabel>
                  <InputField
                    type="text"
                    placeholder={t('write_email')}
                    value={clientEmail}
                    onChange={e => onEmailChange(e)}
                    error={
                      saveErrors &&
                      saveErrors['clientEmail'] &&
                      saveErrors['clientEmail'] !== undefined
                    }
                  />
                  {saveErrors &&
                    saveErrors['clientEmail'] &&
                    saveErrors['clientEmail'] !== undefined && (
                      <FormAlert margin="0 0 0 5px">
                        {saveErrors['clientEmail']}
                      </FormAlert>
                    )}
                </InputWithLabelSection>

                <InputWithLabelSection>
                  <InputLabel>{t('phone_number')}</InputLabel>
                  <PhoneInput
                    enableSearch
                    country={'us'}
                    value={phoneNumber}
                    onChange={phone => setPhoneNumber(phone)}
                    inputClass="phone-number-input"
                    buttonClass="phone-number-flag-dropdown"
                  />
                </InputWithLabelSection>

                {/* <InputWithLabelSection>
                  <InputLabel>Office Location</InputLabel>
                  <InputField
                    type="text"
                    placeholder="Write official address..."
                    value={officeLocation}
                    onChange={(e) => onAddressChange(e)}
                  />
                </InputWithLabelSection> */}

                <CommonFlex gap="15px" margin="20px 0 0 0">
                  <WhiteButton type="cancel" onClick={() => cancelFunction()}>
                    {t('cancel')}
                  </WhiteButton>
                  <PrimaryButton
                    padding={props.clientUpdateLoading ? '0 15px' : '0 35px'}
                    onClick={
                      !props.clientUpdateLoading ? () => handleSave() : ''
                    }
                  >
                    {props.clientUpdateLoading ? (
                      <ButtonTextLoader loadingText="Saving" fontSize="13px" />
                    ) : (
                      t('update')
                    )}
                  </PrimaryButton>
                </CommonFlex>
              </AddClientContainerNew>
              <></>
            </DataAndMapContainer>
          </>
        )}
      </Container>
    </div>
  );
};

export default EditClient;

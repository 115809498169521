import { useState, useEffect, Fragment } from "react";
import moment from "moment";
import {
  CommonText,
  CardTitle,
  TableText,
  Container,
  RoundMiniImage,
} from "../../styledComponents/common";
import { WhiteButton } from "../../styledComponents/buttons";
import {
  AssigneesManageImgTextGrid,
  AssigneesManageRoundImage,
} from "../../styledComponents/createProject";
import {
  UpperTableSection,
  UpperColorRecognitionSection,
  UpperColorRecognition,
  ColoredBar,
  ButtonContainer,
  PayrollInfoTable,
  FixedAmountHistoryContainer,
  FixedAmountHistorySingleItemContainer,
  FixedAmountUpperSection,
  FixedAmountNoteSection,
} from "../../styledComponents/payroll";
import { TableItem } from "../../styledComponents/teams";

import { toHHMMSS } from "../../utils/helper";
import { currencySymbolMap } from "../../utils/currencies";

import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";

import { PayrollSummaryHistory } from "./PayrollSummary";

import avatar5 from "../../assets/img/avatar_5.svg";
import { CommImageNameComp } from "custom_modules/Members/CommonComponents";

const HourlyBasisHistory = (props) => {
  return (
    <Container padding="30px 0">
      {props.isLoading ? (
        <ComponentCircleLoader />
      ) : props.hourlyHistory && props.hourlyHistory.length > 0 ? (
        <>
          <UpperTableSection>
            <CardTitle>
              {props.selectedProject && props.selectedProject.value
                ? props.selectedProject.label
                : "All Projects"}
            </CardTitle>
            <UpperColorRecognitionSection>
              {/* <UpperColorRecognition>
                <ColoredBar green />
                <CommonText name>Payment Done</CommonText>
              </UpperColorRecognition> */}
              <UpperColorRecognition>
                <ColoredBar />
                <CommonText>Marked as Paid</CommonText>
              </UpperColorRecognition>
            </UpperColorRecognitionSection>
          </UpperTableSection>

          <PayrollSummaryHistory
            type="hourly"
            startDate={props.startDate}
            endDate={props.endDate}
            paymentData={props.paymentHistory}
          />

          {props.hourlyHistory.map((payrollItem, i) => {
            if (payrollItem.memberList && payrollItem.memberList.length > 0) {
              return (
                <Fragment key={i}>
                  <ButtonContainer index={i}>
                    <WhiteButton
                      type="cancel"
                      bgColor="#f8f9fc"
                      borderColor="#c2cce1"
                      cursor="auto"
                    >
                      {payrollItem.date}
                    </WhiteButton>
                  </ButtonContainer>
                  <PayrollInfoTable header hourly>
                    <TableItem>
                      <UpperColorRecognition>
                        <div></div>
                        <TableText>Member Name</TableText>
                      </UpperColorRecognition>
                    </TableItem>
                    <TableItem>
                      <TableText>Time Worked</TableText>
                    </TableItem>
                    <TableItem>
                      <TableText>Pay Rate</TableText>
                    </TableItem>
                    <TableItem>
                      <TableText>Total Pay</TableText>
                    </TableItem>
                    <TableItem>
                      <TableText>Paid by at</TableText>
                    </TableItem>
                    <></>
                  </PayrollInfoTable>
                  {payrollItem.memberList.map((member, index) => (
                    <PayrollInfoTable key={index} hourly>
                      <TableItem>
                        <UpperColorRecognition>
                          <ColoredBar green={member.paid} />
                          <CommImageNameComp
                            name={member.name}
                            imgSource={member.avatar}
                            size="36px"
                            fontSize="13px"
                            index={index}
                            color={member.color}
                          />
                        </UpperColorRecognition>
                      </TableItem>
                      <TableItem>
                        <TableText name> {member.timeWorked} </TableText>
                      </TableItem>
                      <TableItem>
                        <TableText name>
                          {`${currencySymbolMap[member.currency]} ${
                            member.payRate
                          }`}
                        </TableText>
                      </TableItem>
                      <TableItem>
                        <TableText name>
                          {`${currencySymbolMap[member.currency]} ${
                            member.totalPay
                          }`}
                        </TableText>
                      </TableItem>
                      <TableItem>
                        <TableText name>{member.paidBy}</TableText>
                      </TableItem>
                      {/* <TableItem buttonPadding>
                        {!member.paid && (
                          <CommonColorButton padding="11px 17px">
                            Send Payment
                          </CommonColorButton>
                        )}
                      </TableItem> */}
                    </PayrollInfoTable>
                  ))}
                </Fragment>
              );
            }
          })}
        </>
      ) : (
            <NoDataComponent title="No payment history found." />
      )}
    </Container>
  );
};

const FixedAmountHistory = (props) => {
  return (
    <Container padding="30px 0">
      {props.isLoading ? (
        <ComponentCircleLoader />
      ) : props.onetimeHistory && props.onetimeHistory.length > 0 ? (
        <>
          <UpperTableSection>
            <CardTitle>
              {props.selectedProject && props.selectedProject.value
                ? props.selectedProject.label
                : "All Projects"}
            </CardTitle>
          </UpperTableSection>

          <PayrollSummaryHistory
            type="fixed"
            startDate={props.startDate}
            endDate={props.endDate}
            paymentData={props.paymentHistory}
          />

          {props.onetimeHistory.map((payrollItem, i) => {
            if (payrollItem.memberList && payrollItem.memberList.length > 0) {
              return (
                <Fragment key={i}>
                  <ButtonContainer index={i}>
                    <WhiteButton
                      type="cancel"
                      bgColor="#f8f9fc"
                      borderColor="#c2cce1"
                      cursor="auto"
                    >
                      {payrollItem.date}
                    </WhiteButton>
                  </ButtonContainer>
                  <FixedAmountHistoryContainer>
                    {payrollItem.memberList.map((member, index) => (
                      <FixedAmountHistorySingleItemContainer key={index}>
                        <FixedAmountUpperSection>
                          <CommImageNameComp
                            imgSource={member.avatar}
                            name={member.name}
                            size="36px"
                            fontSize="13px"
                            index={index}
                            color={member.color}
                          />
                          <CommonText fontSize="16px" title>{`${
                            currencySymbolMap[member.currency]
                          } ${member.amount}`}</CommonText>
                          <UpperColorRecognition
                            style={{ justifySelf: "flex-end" }}
                          >
                            <ColoredBar green />
                            <CommonText>Payment Done</CommonText>
                          </UpperColorRecognition>
                        </FixedAmountUpperSection>
                        <FixedAmountNoteSection>
                          <CommonText $label>Note</CommonText>
                          <CommonText>{member.note}</CommonText>
                        </FixedAmountNoteSection>
                      </FixedAmountHistorySingleItemContainer>
                    ))}
                  </FixedAmountHistoryContainer>
                </Fragment>
              );
            }
          })}
        </>
      ) : (
            <NoDataComponent title="No payroll history found." />
      )}
    </Container>
  );
};

// const CommImageNameComp = (props) => (
//   <AssigneesManageImgTextGrid>
//     <AssigneesManageRoundImage imageSize={props.size}>
//       <RoundMiniImage src={props.imgSource} alt="" imageSize={props.size} />
//     </AssigneesManageRoundImage>
//     <TableText name>{props.name}</TableText>
//   </AssigneesManageImgTextGrid>
// );

const PayrollHistoryTable = (props) => {
  const [hourlyHistory, setHourlyHistory] = useState([]);
  const [onetimeHistory, setOnetimeHistory] = useState([]);

  useEffect(() => {
    return () => {
      props.setHistoryType("hourly_basis");
    };
  }, []);

  useEffect(() => {
    if (props.hourlyPaymentHistory && props.hourlyPaymentHistory.length > 0) {
      let history = [];
      getDates().map((date, index) => {
        let object = {};
        object.id = index;
        object.date = moment(date.date).format("ddd, MMMM DD, YYYY");
        let list = [];
        props.hourlyPaymentHistory.map((item) => {
          const member = props.membersList.find(
            (member) => member.id === item.paid_to.id
          );
          if (moment(date.date).format("YYYY-MM-DD") === item.paid_for_date) {
            list.push({
              id: item.id,
              name: item.paid_to.name,
              avatar: item.paid_to.avatar ? item.paid_to.avatar : avatar5,
              color: member?.color,
              timeWorked: toHHMMSS(item.working_duration),
              payRate: (item.pay_rate / 100).toFixed(2),
              totalPay: (item.total_pay / 100).toFixed(2),
              currency: item.currency,
              paidBy: `${item.paid_by.name}, ${moment(item.paid_at).format(
                "ddd DD MMM, YYYY"
              )}`,
            });
          }
        });
        object.memberList = list;
        history.push(object);
      });
      setHourlyHistory(history);
    } else if (
      props.hourlyPaymentHistory &&
      props.hourlyPaymentHistory.length === 0
    ) {
      setHourlyHistory([]);
    }
  }, [props.hourlyPaymentHistory]);

  useEffect(() => {
    if (props.onetimePaymentHistory && props.onetimePaymentHistory.length > 0) {
      let history = [];
      getDates().map((date, index) => {
        let object = {};
        object.id = index;
        object.date = moment(date.date).format("ddd, MMMM DD, YYYY");
        let list = [];
        props.onetimePaymentHistory.map((item) => {
          const member = props.membersList.find(
            (member) => member.id === item.paid_to.id
          );
          if (moment(date.date).format("YYYY-MM-DD") === item.paid_at) {
            list.push({
              id: item.id,
              name: item.paid_to.name,
              avatar: item.paid_to.avatar ? item.paid_to.avatar : avatar5,
              color: member?.color,
              amount: (item.paid_amt / 100).toFixed(2),
              currency: item.currency,
              note: item.note,
            });
          }
        });
        object.memberList = list;
        history.push(object);
      });
      setOnetimeHistory(history);
    } else if (
      props.onetimePaymentHistory &&
      props.onetimePaymentHistory.length === 0
    ) {
      setOnetimeHistory([]);
    }
  }, [props.onetimePaymentHistory]);

  useEffect(() => {
    let presentState;
    if (props.historyType && props.historyType === "hourly_basis") {
      presentState = [...hourlyHistory];
      const sortedList = presentState.slice().reverse();
      setHourlyHistory([...sortedList]);
    } else {
      presentState = [...onetimeHistory];
      const sortedList = presentState.slice().reverse();
      setOnetimeHistory([...sortedList]);
    }
  }, [props.sortType]);

  const getDates = () => {
    let dates = [];
    let startDate = props.startDate;
    while (moment(startDate).isSameOrBefore(moment(props.endDate))) {
      dates.push({
        date: startDate,
      });
      startDate = moment(startDate).add(1, "days");
    }
    return dates;
  };
  if (props.historyType && props.historyType === "hourly_basis") {
    return (
      <HourlyBasisHistory
        // hourlyHistoryList={hourlyHistoryList}
        startDate={props.startDate}
        endDate={props.endDate}
        paymentHistory={props.hourlyPaymentHistory}
        hourlyHistory={hourlyHistory}
        selectedOrganization={props.selectedOrganization}
        selectedProject={props.selectedProject}
        isLoading={props.isLoading}
      />
    );
  } else {
    return (
      <FixedAmountHistory
        startDate={props.startDate}
        endDate={props.endDate}
        paymentHistory={props.onetimePaymentHistory}
        onetimeHistory={onetimeHistory}
        selectedOrganization={props.selectedOrganization}
        selectedProject={props.selectedProject}
        isLoading={props.isLoading}
      />
    );
  }
};

export default PayrollHistoryTable;

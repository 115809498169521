export const colors = {
  textMain: "#222",
  textSecondary: "#3D4D69",
  labelText: "#3D4D69",
  placeholderText: "rgba(61, 77, 105, 0.7)",
  panelBackground: "#eaf0f5",
  greenBackground: "#5fbb722b",
  blueBackground: "#d2e4ff",

  primary: "#2266EF",
  secondary: "#F3F5F9",
  primaryLight: "#2266EF1A",
  primaryDisabled: "#dce2ef",

  success: "#4AB37B",
  successMedium: "#6ab36e",
  successLight: "#CCEBDA",
  successText: "#087641",

  warning: "#EA9F48",
  warningMedium: "#fad59d",
  warningLight: "#fdf3e4",

  error: "#EF717D",
  errorMedium: "#ffacb4",
  errorLight: "#fff6f7",
  errorDisabled: "#ffc7cc",

  purple: "#6f7aff",
  purpleMedium: "#9a96f9",
  purpleLight: "#d9d7fc",

  blue: "#4d94fb",
  blueMedium: "#98c3ff",
  blueLight: "#d2e4fe",
  blueTag: "#4d94ff40",

  violet: "#6776CB",

  gray: "#7f9dbf", // mark as paid
  grayMedium: "#B0CBD6", // save as draft
  grayLight: "#CDE2EA",
  grayHover: "#8DADBA",

  sand: "#AF9B89",
  sandMedium: "#CCBAA9",
  sandLight: "#F4E3D4",

  // plan gray color
  planGray: "#6686a2",
  playGrayLight: "#eaf2f9",

  blackButton: "#465065",
  focusShadow: "#F5F5F5",

  white: "#fff",
  whiteBg: "#fcfdfe",
  // job status
  unscheduled: '#ea9f48',
  readyToDispatch: '#606CFF',

  green: '#18B54D',
  blueText: '#2266EF',

  greyLodder: '#7B7B7B'
};

export const hoverColors = {
  primary: "#1158E5",
  error: "#ed5160",
  warning: "#f09b16",
  purpleMedium: "#8985ea",
  blue: "#3f86f0",
  gray: "#6d8caf",
  grayMedium: "#8ea1b5",
  planGray: "#617f9a",
  blackButton: "#1f2939",
};

export const borderColors = {
  primary: colors.primary,
  secondary: "#c2cce1",
  secondaryLight: "#e4e7eb",
  blackButton: "#1f2939",
  error: colors.error,
};

export const hoverBorderColors = {
  secondaryLight: "#434343b3",
};

import { connect } from "react-redux";

import {
  addClientLocation,
  getClientList,
  getClientLocation,
  getClientShortList,
  updateClientLocation,
} from "../../ClientsNew/clientsActions";
import { getOrganizationMembersShortList } from "../../Projects/projectsActions";
import {
  createJobRequest,
  getWorkOrderShortList,
} from "../../WorkOrderNew/workOrderActions";
import {
  addTaskLocation,
  createTask,
  getTaskLocation,
  getTaskType,
} from "../../Jobs/tasksActions";
// Component
import CreateJobPopup from "./CreateJobPopup";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  orgMembersList: state.project.orgMembersList,
  orgMembersShortList: state.project.orgMembersShortList,
  memberShortListIsLoading: state.project.memberShortListIsLoading,
  isLoading: state.task.isLoading,
  addTaskLocationLoading: state.task.addTaskLocationLoading,
  createdTaskInfo: state.task.createdTaskInfo,
  locationsList: state.task.locationsList,
  createdTaskLocationInfo: state.task.createdTaskLocationInfo,
  taskTypeIsLoading: state.task.taskTypeIsLoading,
  taskTypeList: state.task.taskTypeList,
  //workordershort
  workOrderShortList: state.workorder.workOrderShortList,
  //Customer Location
  clientLocationList: state.client.clientLocationList,
  getclientLocalMapLoading: state.client.getclientLocalMapLoading,
  addOrUpdateclientLocalMapLoading:
    state.client.addOrUpdateclientLocalMapLoading,
  createdClientLocationInfo: state.client.createdClientLocationInfo,
  //job
  createdJob: state.workorder.createdJob,
  createOrUpdateJobIsLoading: state.workorder.createOrUpdateJobIsLoading,
  isJobLimitFull: state.workorder.isJobLimitFull,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getOrganizationMembersShortList: (params) =>
    dispatch(getOrganizationMembersShortList(params)),
  createTask: (params) => dispatch(createTask(params)),
  getTaskLocation: (params) => dispatch(getTaskLocation(params)),
  addTaskLocation: (params) => dispatch(addTaskLocation(params)),
  getTaskType: (params) => dispatch(getTaskType(params)),
  getWorkOrderShortList: (params) => dispatch(getWorkOrderShortList(params)),
  getClientLocation: (details) => dispatch(getClientLocation({ ...details })),
  addClientLocation: (details) => dispatch(addClientLocation({ ...details })),
  updateClientLocation: (details) =>
    dispatch(updateClientLocation({ ...details })),
  createJobRequest: (details) => dispatch(createJobRequest({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(CreateJobPopup);

import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';

import ButtonTextLoader from '../../components/Loaders/ButtonTextLoader';
import { PrimaryButton, WhiteButton } from '../../styledComponents/buttons';
import {
  CardTitle,
  CommonGrid,
  InputField,
  InputLabel,
  InputWithLabelSection,
  RedStar,
} from '../../styledComponents/common';
import { FormAlert } from '../../styledComponents/createProject';
import { ModalButtonSection } from '../../styledComponents/invoice';
import { validateEmail } from '../../utils/helper';

const CustomFormAlert = styled(FormAlert)`
  margin-top: -15px;
`;
const AddClientPopup = ({
  isOpen,
  toggle,
  selectedOrganization,
  createClient,
  isLoading,
}) => {
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [officeLocation, setOfficeLocation] = useState('');
  const [saveErrors, setSaveErrors] = useState({});

  const onClientNameChange = e => {
    setSaveErrors({});
    const { value } = e.target;
    setClientName(value);
  };

  const onEmailChange = e => {
    setSaveErrors({});
    const { value } = e.target;
    setClientEmail(value);
  };

  const onAddressChange = e => {
    setSaveErrors({});
    const { value } = e.target;
    setOfficeLocation(value);
  };

  const cancelFunction = () => {
    clearState();
    toggle();
  };

  const clearState = () => {
    setClientName('');
    setClientEmail('');
    setPhoneNumber('');
    setOfficeLocation('');
    setSaveErrors({});
  };

  const handleSave = () => {
    if (checkError()) {
      if (selectedOrganization && selectedOrganization.id) {
        let payload = {
          organization_id: selectedOrganization.id,
          name: clientName,
          phone_number: phoneNumber ? phoneNumber : null,
          email: clientEmail ? clientEmail : null,
          office_location: officeLocation ? officeLocation : null,
        };
        createClient(payload);
        setTimeout(() => {
          clearState();
          toggle();
        }, 400);
      }
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!clientName || clientName === '') {
      formIsValid = false;
      saveErrors['clientName'] = 'Customer name is required';
    }
    if (!clientEmail || clientEmail === '') {
      formIsValid = false;
      saveErrors['clientEmailRequired'] = 'Customer email is required';
    } else if (!validateEmail(clientEmail)) {
      formIsValid = false;
      saveErrors['clientEmail'] = 'Please enter a valid email';
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `5vh`, maxWidth: `450px` }}
    >
      <ModalBody style={{ padding: `30px 0 15px 0` }}>
        <CommonGrid columns="1fr" padding="0 30px" gap="10px">
          <CardTitle margin="0 0 5px 0" fontSize="16px">
            Add A New Customer
          </CardTitle>
          <InputWithLabelSection>
            <InputLabel>
              Customer name <RedStar>*</RedStar>
            </InputLabel>
            <InputField
              type="text"
              placeholder="Write Customer name..."
              style={{ marginBottom: `10px` }}
              value={clientName}
              autoFocus
              onChange={e => onClientNameChange(e)}
            />
            {saveErrors &&
              saveErrors['clientName'] &&
              saveErrors['clientName'] !== undefined && (
                <CustomFormAlert>{saveErrors['clientName']}</CustomFormAlert>
              )}
          </InputWithLabelSection>

          <InputWithLabelSection>
            <InputLabel>
              Email Address<RedStar>*</RedStar>
            </InputLabel>
            <InputField
              type="text"
              placeholder="Write email..."
              style={{ marginBottom: `10px` }}
              value={clientEmail}
              onChange={e => onEmailChange(e)}
            />
            {saveErrors &&
              saveErrors['clientEmailRequired'] &&
              saveErrors['clientEmailRequired'] !== undefined && (
                <CustomFormAlert>
                  {saveErrors['clientEmailRequired']}
                </CustomFormAlert>
              )}
            {saveErrors &&
              saveErrors['clientEmail'] &&
              saveErrors['clientEmail'] !== undefined && (
                <CustomFormAlert>{saveErrors['clientEmail']}</CustomFormAlert>
              )}
          </InputWithLabelSection>
          <InputWithLabelSection>
            <InputLabel>Phone Number</InputLabel>
            <PhoneInput
              enableSearch
              country={'us'}
              value={phoneNumber}
              style={{ marginBottom: `10px` }}
              onChange={phone => setPhoneNumber(phone)}
              inputClass="phone-number-input"
              buttonClass="phone-number-flag-dropdown"
            />
          </InputWithLabelSection>
          <InputWithLabelSection>
            <InputLabel>Office Location</InputLabel>
            <InputField
              type="text"
              placeholder="Write official address..."
              style={{ marginBottom: `10px` }}
              value={officeLocation}
              onChange={e => onAddressChange(e)}
            />
          </InputWithLabelSection>
        </CommonGrid>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={() => cancelFunction()}>
            Cancel
          </WhiteButton>
          <PrimaryButton
            padding={isLoading ? '0 15px' : '0 35px'}
            onClick={() => handleSave()}
          >
            {isLoading ? (
              <ButtonTextLoader loadingText="Saving" fontSize="13px" />
            ) : (
              'Save'
            )}
          </PrimaryButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default AddClientPopup;

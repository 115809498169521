import { toHHMMSS } from "../../../utils/helper";
export const getHours = (time) => {
  let hours = time.split(":");
  return `${hours[0]} ${parseInt(hours[0]) <= 1 ? "hour" : "hours"} ${
    hours[1]
  } ${parseInt(hours[1]) <= 1 ? "minute" : "minutes"}`;
};

export const getDuration = (duration) => {
  // let hour = parseInt(duration.split(":")[0]);
  // let minutes = parseInt(duration.split(":")[1]);

  // return `${hour} ${hour < 2 ? "hour" : "hours"} ${minutes} minutes`;
  return getHours(toHHMMSS(duration));
};

export const getDurationInMinutes = (duration) => {
  // let hour = parseInt(duration.split(":")[0]);
  // let minutes = parseInt(duration.split(":")[1]);
  // let time = hour * 60 + minutes;
  const time = Math.floor(duration / 60);

  return time;
};

export const getZeroHourTime = (duration, format = "short") => {
  if (format === "long") {
    if (duration < 3600) {
      let minutes = getDurationInMinutes(duration);

      return `${minutes === 0 ? 1 : minutes} ${
        minutes <= 1 ? "minute" : "minutes"
      }`;
    }

    return getDuration(duration);
  } else if (format == "short") {
    let minutes = getDurationInMinutes(duration);
    if (duration < 3600) {
      return `${minutes === 0 ? 1 : minutes}m`;
    } else {
      let hour = Math.floor(minutes / 60);
      let remainingMinutes = minutes % 60;
      return `${hour}h ${remainingMinutes}m`;
    }
  } else throw new Error("Wrong Format is Selected");
};
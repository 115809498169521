import { CommImageNameComp } from 'custom_modules/Members/CommonComponents';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../assets/img/avatar_5.svg';
import arrowWhiteIcon from '../../assets/img/icons/arrow_right_white.svg';
import Chat from '../../assets/img/icons/chat.svg';
import delete2 from '../../assets/img/icons/delete2.svg';
import duplicate from '../../assets/img/icons/duplicate-new.svg';
import Pencil from '../../assets/img/icons/pencil.svg';
import DropdownLoader from '../../components/DropdownLoader/DropdownLoader';
import FilterDropDownStyle from '../../components/DropdownStyle/FilterDropDownStyle';
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent';
import Select from '../../components/ReactSelectDropdown';
import CommonTooltip from '../../components/Tooltip/CommonTooltip';
import {
  ActionSection,
  BudgetCollapseSection,
  ClientDeadlineGrid,
  ListUpperSection,
  ProjectDuplicateDiv,
  ProjectDuplicateIcon,
  ProjectItemCount,
  ProjectListItem,
  ProjectListSection,
  ProjectTableContainer,
  ProjectTitleDiv,
  TableItem,
  TableRowAndBudgetCollapseContainer,
} from '../../styledComponents/ProjectsNew';
import { ButtonIcon, PrimaryButton } from '../../styledComponents/buttons';
import {
  HoveredSection2,
  InnerDivTooltip,
  RoundHover,
  ToolTipContainer,
  TooltipText,
} from '../../styledComponents/clients';
import {
  ColoredText,
  CommonText,
  Container,
  FirstWordRound,
  TableText,
} from '../../styledComponents/common';
import { AssigneesManageImgTextGrid } from '../../styledComponents/createProject';
import {
  IntegrationIcon,
  IntegrationIconDiv,
} from '../../styledComponents/tasks';
import {
  DeadlineText,
  EditProjectButton,
  ExpandIcon,
  ProjectExpandButton,
  ProjectListStatusBadge,
} from '../Projects/projectsStyles';
import { CommFirstRoundLetterComp } from '../Teams/CommonComponents';
import ViewLess from './ViewLess';

const WorkOrdersList = props => {
  const [integrationTooltip, setIntegrationTooltip] = useState(null);
  const [selectIndex, setSelectIndex] = useState(-1);
  const [toolTip, setToolTip] = useState(-1);
  const { t } = useTranslation();
  const {
    selectedOrganization,
    WorkOrderList,
    expandedWorkOrderId,
    setExpandedWorkOrderId,
    workOrderBudgetAndBillableIsLoading,
    workOrderBudgetAndBillable,
    isLoading,
    workOrderType,
    history,
    toggleDeleteModal,
    duplicateWorkOrder,
  } = props;

  useEffect(() => {
    if (WorkOrderList && WorkOrderList.length > 0) {
      setExpandedWorkOrderId(WorkOrderList[0].id);
    }
  }, [WorkOrderList]);

  const handleDuplicateWorkOrder = workorder_id => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        workOrder_id: workorder_id,
      };
      duplicateWorkOrder(payload);
    }
  };

  const handleDeleteProject = workorder_id => {
    toggleDeleteModal(workorder_id);
  };

  const toolTipChange = (value, index) => {
    setToolTip(value);
    setSelectIndex(index);
  };

  const handleExpandBudgetSection = work_order => {
    if (expandedWorkOrderId && expandedWorkOrderId === work_order) {
      setExpandedWorkOrderId(null);
    } else {
      setExpandedWorkOrderId(work_order);
    }
  };

  return (
    <Container style={{ paddingBottom: '20px' }}>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : (
        WorkOrderList &&
        WorkOrderList.length > 0 && (
          <>
            <ProjectTableContainer
              heading
              style={{
                gridTemplateColumns:
                  '1.2fr 0.8fr minmax(100px, 200px) minmax(100px, 200px) 100px',
              }}
            >
              <TableItem>
                <TableText>{t('work_orders')}</TableText>
              </TableItem>

              <TableItem>
                <TableText>{t('customer')}</TableText>
              </TableItem>

              <TableItem>
                <TableText>{t('status')}</TableText>
              </TableItem>

              <TableItem>
                <TableText>{t('actions')}</TableText>
              </TableItem>
            </ProjectTableContainer>
            {WorkOrderList.map((work_order, index) => (
              <TableRowAndBudgetCollapseContainer key={work_order.id}>
                <ProjectTableContainer
                  style={{
                    gridTemplateColumns:
                      '1.2fr 0.8fr minmax(100px, 200px) minmax(100px, 200px) 100px',
                  }}
                >
                  <TableItem
                    style={{
                      gridTemplateColumns: 'auto auto',
                      alignItems: 'center',
                      gridColumnGap: '10px',
                    }}
                    onClick={() =>
                      history.push(
                        `/user/details-work-order?id=${work_order.id}`,
                      )
                    }
                  >
                    <TableText
                      hoverPointer
                      hoverUnderline
                      name
                      fontWeight={
                        expandedWorkOrderId &&
                        expandedWorkOrderId === work_order.id &&
                        '600'
                      }
                    >
                      {work_order.name}
                    </TableText>
                    {work_order.integrations &&
                      work_order.integrations.length > 0 &&
                      work_order.integrations.map((integration, index) => {
                        return (
                          <IntegrationIconDiv
                            key={index}
                            onMouseEnter={() =>
                              setIntegrationTooltip(work_order.id)
                            }
                            onMouseLeave={() => setIntegrationTooltip(null)}
                          >
                            <IntegrationIcon
                              src={`https://cdn.apploye.com/integrations/icons/${integration}.svg`}
                              alt={integration}
                            />
                            {integrationTooltip &&
                              integrationTooltip === work_order.id && (
                                <CommonTooltip
                                  tooltipText={`${integration
                                    .charAt(0)
                                    .toUpperCase()}${integration.slice(1)}`}
                                  left="-32px"
                                  top="-30px"
                                  width="82px"
                                  height="26px"
                                />
                              )}
                          </IntegrationIconDiv>
                        );
                      })}
                  </TableItem>

                  <TableItem
                    hoverPointer
                    hoverUnderline
                    onClick={() =>
                      props.history.push(
                        `/user/customer-details?id=${work_order.client.id}`,
                      )
                    }
                  >
                    {work_order.client && (
                      <CommImageNameComp
                        imgSource={avatar5}
                        name={work_order.client.name}
                        size="36px"
                        fontSize="13px"
                        index={index}
                        color={work_order.client.color}
                        hoverUnderline
                      />
                    )}
                  </TableItem>

                  <TableItem>
                    <ProjectListStatusBadge
                      statusProjectListStatusBadge={work_order.status}
                      style={{ width: '180%' }}
                    >
                      {t(work_order.status)}
                    </ProjectListStatusBadge>
                  </TableItem>

                  <TableItem>
                    <HoveredSection2>
                      <RoundHover
                        onMouseEnter={() => toolTipChange(1, index)}
                        onMouseLeave={() => toolTipChange(-1)}
                        onClick={() =>
                          props.history.push(
                            `/user/edit-work-order?id=${work_order.id}`,
                          )
                        }
                      >
                        <img src={Pencil} alt="pencil" width="15px" />
                        {selectIndex === index && toolTip === 1 && (
                          <ToolTipContainer>
                            <InnerDivTooltip>
                              <img src={Chat} alt="chat" width="64px" />
                              <TooltipText>{t('edit')}</TooltipText>
                            </InnerDivTooltip>
                          </ToolTipContainer>
                        )}
                      </RoundHover>

                      <RoundHover
                        onMouseEnter={() => toolTipChange(2, index)}
                        onMouseLeave={() => toolTipChange(-1)}
                        onClick={() => handleDuplicateWorkOrder(work_order.id)}
                      >
                        <img src={duplicate} alt="file" width="13px" />
                        {selectIndex === index && toolTip === 2 && (
                          <ToolTipContainer>
                            <InnerDivTooltip>
                              <img src={Chat} alt="chat" width="64px" />
                              <TooltipText>{t('duplicate')}</TooltipText>
                            </InnerDivTooltip>
                          </ToolTipContainer>
                        )}
                      </RoundHover>

                      <RoundHover
                        onMouseEnter={() => toolTipChange(3, index)}
                        onMouseLeave={() => toolTipChange(-1)}
                        onClick={() => handleDeleteProject(work_order.id)}
                      >
                        <ButtonIcon src={delete2} alt="" />
                        {selectIndex === index && toolTip === 3 && (
                          <ToolTipContainer>
                            <InnerDivTooltip>
                              <img src={Chat} alt="chat" width="64px" />
                              <TooltipText>{t('delete')}</TooltipText>
                            </InnerDivTooltip>
                          </ToolTipContainer>
                        )}
                      </RoundHover>
                    </HoveredSection2>
                  </TableItem>
                  {work_order.managed && (
                    <>
                      <TableItem rightAlign>
                        <ProjectExpandButton
                          onClick={() =>
                            handleExpandBudgetSection(work_order.id)
                          }
                        >
                          <ExpandIcon
                            src={arrowWhiteIcon}
                            alt=""
                            open={
                              expandedWorkOrderId &&
                              expandedWorkOrderId === work_order.id
                            }
                          />
                        </ProjectExpandButton>
                      </TableItem>
                    </>
                  )}
                </ProjectTableContainer>

                {work_order.managed && (
                  <BudgetCollapseSection
                    open={
                      expandedWorkOrderId &&
                      expandedWorkOrderId === work_order.id
                    }
                  >
                    {expandedWorkOrderId &&
                    expandedWorkOrderId === work_order.id ? (
                      workOrderBudgetAndBillableIsLoading ? (
                        <DropdownLoader
                          loading
                          size={10}
                          padding="40px 0 0"
                          animated
                        />
                      ) : workOrderBudgetAndBillable &&
                        workOrderBudgetAndBillable.id &&
                        workOrderBudgetAndBillable.id === work_order.id ? (
                        <ViewLess
                          selectedOrganization={selectedOrganization}
                          work_order={workOrderBudgetAndBillable}
                        />
                      ) : null
                    ) : null}
                  </BudgetCollapseSection>
                )}
              </TableRowAndBudgetCollapseContainer>
            ))}
          </>
        )
      )}
      {!isLoading && WorkOrderList && WorkOrderList.length === 0 && (
        <NoDataComponent title="No work orders found." />
      )}
    </Container>
  );
};

export default WorkOrdersList;

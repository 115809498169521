import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";

import avatar5 from "../../assets/img/avatar_5.svg";
import crossIcon from "../../assets/img/icons/cross_black.svg";
import deleteIcon from "../../assets/img/icons/delete_white.svg";
import editIcon from "../../assets/img/icons/edit-white-shift.svg";
import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";
import RoundImageNameComp from "../../components/RoundImageName/RoundImageNameComp";
import {
  ColoredButton,
  PrimaryButton,
  SecondaryButton,
  WhiteButton,
} from "../../styledComponents/buttons";
import {
  CardTitle,
  CommonGrid,
  CommonText,
  InputLabel,
  StyledLink,
} from "../../styledComponents/common";
import { formatDurationToHoursMinutes, getFullName } from "../../utils/helper";
import { CommImageNameComp } from "custom_modules/Members/CommonComponents";

const SlotContainer = styled.div`
  display: grid;
  gap: 15px;
  margin-top: 20px;
`;

const ColoredCircle = styled.div`
  position: absolute;
  top: -12px;
  right: ${(props) => (props.edit ? `52px` : `15px`)};
  display: none;
  z-index: 5;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => (props.edit ? `#7F9DBF` : `#FE5969`)};
  align-items: center;
  padding: 8px;
  cursor: pointer;
`;

const TimeDiv = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadii.button};
  display: grid;
  grid-template-columns: 1fr;
  font-size: ${({ theme }) => theme.font.inputLabel.size};
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  background: ${({ bgColor }) => bgColor};
  padding: 15px;
  &:hover ${ColoredCircle} {
    display: grid;
  }
`;
const TextSection = styled.div`
  display: grid;
  gap: 5px;
`;
const ClosePopup = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  cursor: pointer;
`;
const ButtonContainer = styled.div`
  display: flex;
  gap: 30px;
  justify-content: space-between;
  margin-top: 15px;
`;
const MemberBox = styled.div`
  display: flex;
  gap: 12px;
  padding: 4px 0;
  justify-content: flex-start;
`;

const ShowMorePopup = ({
  selectedOrganization,
  isOpen,
  toggle,
  shifts,
  selectedDate,
  deleteToggle,
  editToggle,
  setSelectedShift,
  history,
}) => {
  const handleShiftDelete = (shift) => {
    setSelectedShift(shift);
    toggle();
    setTimeout(deleteToggle, 300);
  };

  const handleShiftEdit = (shift) => {
    setSelectedShift(shift);
    toggle();
    setTimeout(editToggle, 300);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `15vh`, maxWidth: `500px`, maxHeight: `620px` }}
    >
      <ModalBody
        style={{ padding: `20px`, maxHeight: `620px`, overflow: `auto` }}
      >
        <CardTitle margin="0 0 20px 10px">
          {" "}
          All Shifts of{" "}
          {selectedDate && moment(selectedDate).format("D MMM YYYY")}{" "}
        </CardTitle>
        <ClosePopup onClick={() => toggle()}>
          <img src={crossIcon} alt="" width="30px" />
        </ClosePopup>
        {shifts && shifts.length > 0 && (
          <SlotContainer>
            {shifts.map((shift, index) => (
              <TimeDiv
                bgColor={
                  shift && shift.theme_color ? shift.theme_color : `#D2E4FE`
                }
                key={index}
              >
                <TextSection>
                  <CardTitle fontSize="14px" fontWeight="600">
                    {shift.name} <span style={{ margin: `0 10px` }}> | </span>{" "}
                    <span style={{ fontWeight: `600` }}>Type: </span>
                    {shift.work_order && shift.work_order.id ? "Job" : "Office"}
                  </CardTitle>
                  <CommonText name fontSize="13px">
                    {" "}
                    <span style={{ fontWeight: `600` }}>Slot: </span>
                    {shift.start_time &&
                      moment(shift.start_time).format("h:mm a")}{" "}
                    -{" "}
                    {shift.end_time && moment(shift.end_time).format("h:mm a")}{" "}
                    <span style={{ margin: `0 5px` }}> | </span>{" "}
                    <span style={{ fontWeight: `600` }}>Duration: </span>
                    {shift.start_time &&
                      shift.end_time &&
                      formatDurationToHoursMinutes(
                        moment
                          .duration(
                            moment(shift.end_time).diff(shift.start_time)
                          )
                          .asSeconds()
                      )}
                  </CommonText>
                  {shift.work_order && shift.work_order.id && (
                    <>
                      <CommonText name fontSize="13px">
                        <span style={{ fontWeight: `600` }}>Work Order: </span>
                        {shift.work_order.name}
                        <span style={{ margin: `0 5px` }}> | </span>
                        <span style={{ fontWeight: `600` }}>Customer: </span>
                        {shift.work_order.client_name &&
                          shift.work_order.client_name}
                      </CommonText>
                    </>
                  )}
                  <MemberBox>
                    {shift.assignees &&
                      shift.assignees.length > 0 &&
                      shift.assignees.map((assignee, i) => (
                        <div key={i}>
                          {assignee.avatar ? (
                            <RoundImageNameComp
                              imgSource={
                                assignee.avatar ? assignee.avatar : avatar5
                              }
                              name={assignee ? getFullName(assignee) : "User"}
                              imageSize="30px"
                              fontSize="13px"
                              fill
                            />
                          ) : (
                            <CommImageNameComp 
                              imgSource={assignee.avatar || avatar5}
                              name={`${assignee.first_name} ${assignee.last_name}`}
                              size="36px"
                              fontSize="13px"
                              index={i}
                              color={assignee.color}
                            />
                          )}
                        </div>
                      ))}
                    {shift.work_order && shift.work_order.id && (
                      <PrimaryButton
                        padding="0 13px"
                        height="32px"
                        color="#434343"
                        style={{ marginLeft: `auto` }}
                        onClick={() =>
                          history.push(
                            `/user/job-details?id=${shift.id}&workOrder_id=${shift.work_order.id}`
                          )
                        }
                      >
                        Job Details
                      </PrimaryButton>
                    )}
                  </MemberBox>
                </TextSection>
                {/* <ColoredCircle edit onClick={() => handleShiftEdit(shift)}>
                  <img src={editIcon} alt="edit icon" width="100%" />
                </ColoredCircle> */}
                {selectedOrganization &&
                selectedOrganization.role &&
                (selectedOrganization.role === "admin" ||
                  selectedOrganization.role === "owner") &&
                (!shift.work_order ||
                  (shift.status &&
                    (shift.status === "unscheduled" ||
                      shift.status === "ready to dispatch" ||
                      shift.status === "totally missed"))) ? (
                  <ColoredCircle onClick={() => handleShiftDelete(shift)}>
                    <img src={deleteIcon} alt="delete icon" width="100%" />
                  </ColoredCircle>
                ) : null}
              </TimeDiv>
            ))}
          </SlotContainer>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ShowMorePopup;

export const planFeatures = {
  lite: [
    "Office -1",
    "Scheduling - 1 Daily",
    "Job - 1",
    "Live Location Tracking - 5 employees",
    "Timesheets - daily and weekly",
    "Payroll - Weekly",
    "Customers - Unlimited",
    "Work Orders - Unlimited",
    "Invoicing",
    "Organizations",
    "Job approval",
    "Evidence upload - 1",
    "Job Notes - Unlimited",
  ],
  standard: [
    "Dashboard",
    "Office",
    "Scheduling",
    "Route Map Tracking",
    "Live Location Tracking",
    "Timesheets",
    "Timesheets Approval",
    "Payroll",
    "Members",
    "Integrations",
    "Reporting",
  ],
  elite: [
    "Customers",
    "Work Orders",
    "Jobs",
    "Job Approval",
    "Photo Evidence Upload",
    "Audio Evidence Recording",
    "Job Notes - Unlimited",
  ],
};

export const PlanPrice = {
  lite: 0,
  standard_mon: 15,
  standard_ann: 9,
  elite_mon: 20,
  elite_ann: 12,
}


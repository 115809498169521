//Effects
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

//Constants
import {
  CREATE_JOB,
  CREATE_JOB_FAILURE,
  CREATE_JOB_SUCCESS,
  CREATE_WORK_ORDER,
  CREATE_WORK_ORDER_BILL,
  CREATE_WORK_ORDER_BILL_FAILURE,
  CREATE_WORK_ORDER_BILL_SUCCESS,
  CREATE_WORK_ORDER_BUDGET,
  CREATE_WORK_ORDER_BUDGET_FAILURE,
  CREATE_WORK_ORDER_BUDGET_SUCCESS,
  CREATE_WORK_ORDER_FAILURE,
  CREATE_WORK_ORDER_SUCCESS,
  DELETE_WORK_ORDER,
  DELETE_WORK_ORDER_FAILURE,
  DELETE_WORK_ORDER_JOB,
  DELETE_WORK_ORDER_JOB_FAILURE,
  DELETE_WORK_ORDER_JOB_SUCCESS,
  DELETE_WORK_ORDER_SUCCESS,
  DUPLICATE_WORK_ORDER,
  DUPLICATE_WORK_ORDER_FAILURE,
  DUPLICATE_WORK_ORDER_SUCCESS,
  GET_JOBS_UNDER_WORKORDER,
  GET_JOBS_UNDER_WORKORDER_FAILURE,
  GET_JOBS_UNDER_WORKORDER_SUCCESS,
  GET_JOB_DETAILS,
  GET_JOB_DETAILS_FAILURE,
  GET_JOB_DETAILS_SUCCESS,
  GET_WORKORDER_BUDGET_AND_BILLABLE,
  GET_WORKORDER_BUDGET_AND_BILLABLE_FAILURE,
  GET_WORKORDER_BUDGET_AND_BILLABLE_SUCCESS,
  GET_WORK_ORDER,
  GET_WORK_ORDER_BILL,
  GET_WORK_ORDER_BILL_FAILURE,
  GET_WORK_ORDER_BILL_SUCCESS,
  GET_WORK_ORDER_BUDGET,
  GET_WORK_ORDER_BUDGET_FAILURE,
  GET_WORK_ORDER_BUDGET_SUCCESS,
  GET_WORK_ORDER_FAILURE,
  GET_WORK_ORDER_SUCCESS,
  UPDATE_JOB,
  UPDATE_JOB_FAILURE,
  UPDATE_JOB_SUCCESS,
  UPDATE_WORK_ORDER,
  UPDATE_WORK_ORDER_BILL,
  UPDATE_WORK_ORDER_BILL_FAILURE,
  UPDATE_WORK_ORDER_BILL_SUCCESS,
  UPDATE_WORK_ORDER_BUDGET,
  UPDATE_WORK_ORDER_BUDGET_FAILURE,
  UPDATE_WORK_ORDER_BUDGET_SUCCESS,
  UPDATE_WORK_ORDER_FAILURE,
  UPDATE_WORK_ORDER_SUCCESS,
  WORK_ORDER_LIST,
  WORK_ORDER_LIST_FAILURE,
  WORK_ORDER_LIST_SUCCESS,
  WORK_ORDER_SHORT,
  WORK_ORDER_SHORT_FAILURE,
  WORK_ORDER_SHORT_SUCCESS,
} from '../../modules/constants';
import { defaultApi } from '../../utils/axiosApi';

export function* getWorkOrderShortList({ payload }) {
  try {
    const { organization_id } = payload;
    let url = `${organization_id}/workorder/short/`;
    const response = yield defaultApi(url, 'GET');
    yield put({
      type: WORK_ORDER_SHORT_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: WORK_ORDER_SHORT_FAILURE,
      payload: err,
    });
  }
}

export function* createWorkOrderBillingRequest({ payload }) {
  try {
    const { organization_id, workorder_id, ...details } = payload;
    const url = `/${organization_id}/workorder/${workorder_id}/billable/`;
    const method = 'POST';
    const response = yield defaultApi(url, method, details);
    yield put({
      type: CREATE_WORK_ORDER_BILL_SUCCESS,
      payload: { data: response, notify: 'POST' },
    });
  } catch (err) {
    yield put({
      type: CREATE_WORK_ORDER_BILL_FAILURE,
      payload: err,
    });
  }
}

export function* getWorkOrderBillingRequest({ payload }) {
  try {
    const { organization_id, workorder_id } = payload;
    const url = `${organization_id}/workorder/${workorder_id}/billable/`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_WORK_ORDER_BILL_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: GET_WORK_ORDER_BILL_FAILURE,
      payload: err,
    });
  }
}

export function* updateWorkOrderBillingRequest({ payload }) {
  try {
    const { organization_id, workorder_id, ...details } = payload;
    const url = `/${organization_id}/workorder/${workorder_id}/billable/`;
    const method = 'PUT';
    const response = yield defaultApi(url, method, details);
    yield put({
      type: UPDATE_WORK_ORDER_BILL_SUCCESS,
      payload: { data: response.data, notify: 'PUT' },
    });
  } catch (err) {
    yield put({
      type: UPDATE_WORK_ORDER_BILL_FAILURE,
      payload: err,
    });
  }
}

//Budget
export function* createWorkOrderBudgetRequest({ payload }) {
  try {
    const { organization_id, workorder_id, ...details } = payload;
    const url = `${organization_id}/workorder/${workorder_id}/budget/`;
    const method = 'POST';
    const response = yield defaultApi(url, method, details);
    yield put({
      type: CREATE_WORK_ORDER_BUDGET_SUCCESS,
      payload: { data: response, notify: 'POST' },
    });
  } catch (err) {
    yield put({
      type: CREATE_WORK_ORDER_BUDGET_FAILURE,
      payload: err,
    });
  }
}

export function* getWorkOrderBudgetRequest({ payload }) {
  try {
    const { organization_id, workorder_id } = payload;
    const url = `${organization_id}/workorder/${workorder_id}/budget/`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_WORK_ORDER_BUDGET_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: GET_WORK_ORDER_BUDGET_FAILURE,
      payload: err,
    });
  }
}

export function* updateWorkOrderBudgetRequest({ payload }) {
  try {
    const { organization_id, workorder_id, ...details } = payload;
    const url = `${organization_id}/workorder/${workorder_id}/budget/`;
    const method = 'PUT';
    const response = yield defaultApi(url, method, details);

    yield put({
      type: UPDATE_WORK_ORDER_BUDGET_SUCCESS,
      payload: { data: response.data, notify: 'PUT' },
    });
  } catch (err) {
    yield put({
      type: UPDATE_WORK_ORDER_BUDGET_FAILURE,
      payload: err,
    });
  }
}

export function* createWorkOrderRequest({ payload }) {
  try {
    const { organization_id, ...details } = payload;
    const url = `${organization_id}/workorder/`;
    const method = 'POST';
    const response = yield defaultApi(url, method, details);
    yield put({
      type: CREATE_WORK_ORDER_SUCCESS,
      payload: { data: response, notify: 'POST' },
    });
  } catch (err) {
    yield put({
      type: CREATE_WORK_ORDER_FAILURE,
      payload: err,
    });
  }
}

export function* getWorkOrderRequest({ payload }) {
  try {
    const { organization_id, workOrder_id } = payload;
    const url = `${organization_id}/workorder/${workOrder_id}/`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_WORK_ORDER_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: GET_WORK_ORDER_FAILURE,
      payload: err,
    });
  }
}

export function* getWorkOrderListRequest({ payload }) {
  try {
    const { organization_id, page, name, client_id } = payload;
    const url = `${organization_id}/workorder/${page ? `?page=${page}` : ''}
      ${name ? `${page ? `&` : '?'}name=${name}` : ''}${
      client_id ? `${page || name ? `&` : '?'}client=${client_id}` : ''
    }`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: WORK_ORDER_LIST_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: WORK_ORDER_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* updateWorkOrderRequest({ payload }) {
  try {
    const { organization_id, workOrder_id, ...details } = payload;
    const url = `${organization_id}/workorder/${workOrder_id}/`;
    const method = 'PATCH';
    const response = yield defaultApi(url, method, details);
    yield put({
      type: UPDATE_WORK_ORDER_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: UPDATE_WORK_ORDER_FAILURE,
      payload: err,
    });
  }
}

export function* createJobRequest({ payload }) {
  try {
    const { organization_id, workOrder_id, ...details } = payload;
    const url = `${organization_id}/workorder/${workOrder_id}/job_with_schedule/`;
    const method = 'POST';
    const response = yield defaultApi(url, method, details);
    yield put({
      type: CREATE_JOB_SUCCESS,
      payload: { data: response, notify: 'POST' },
    });
  } catch (err) {
    yield put({
      type: CREATE_JOB_FAILURE,
      payload: err,
    });
  }
}

export function* deleteWorkOrderJobRequest({ payload }) {
  try {
    const { organization_id, work_order_id, job_id, ...details } = payload;
    const url = `${organization_id}/workorder/${work_order_id}/job_with_schedule/${job_id}/`;
    const method = 'DELETE';
    const response = yield defaultApi(url, method, details);
    yield put({
      type: DELETE_WORK_ORDER_JOB_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: DELETE_WORK_ORDER_JOB_FAILURE,
      payload: err,
    });
  }
}

export function* updateJobRequest({ payload }) {
  try {
    const { organization_id, workOrder_id, job_id, ...details } = payload;
    const url = `${organization_id}/workorder/${workOrder_id}/jobs/${job_id}/`;
    const method = 'PATCH';
    const response = yield defaultApi(url, method, details);
    yield put({
      type: UPDATE_JOB_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: UPDATE_JOB_FAILURE,
      payload: err,
    });
  }
}

export function* getJobsUnderWorkOrderRequest({ payload }) {
  try {
    const { organization_id, workOrder_id } = payload;
    const url = `${organization_id}/workorder/${workOrder_id}/jobs/`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_JOBS_UNDER_WORKORDER_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: GET_JOBS_UNDER_WORKORDER_FAILURE,
      payload: err,
    });
  }
}

export function* deleteWorkOrderRequest({ payload }) {
  try {
    const { organization_id, workorder_id } = payload;
    let url = `${organization_id}/workorder/${workorder_id}/`;
    let method = 'DELETE';
    const response = yield defaultApi(url, method);
    yield put({
      type: DELETE_WORK_ORDER_SUCCESS,
      payload: { data: response, workorder_id, notify: method },
    });
  } catch (err) {
    yield put({
      type: DELETE_WORK_ORDER_FAILURE,
      payload: err,
    });
  }
}

export function* getDuplicateWorkOrderRequest({ payload }) {
  try {
    const { organization_id, workOrder_id } = payload;
    const url = `${organization_id}/workorder/${workOrder_id}/duplicate/`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: DUPLICATE_WORK_ORDER_SUCCESS,
      payload: { data: response, workOrder_id, notify: method },
    });
  } catch (err) {
    yield put({
      type: DUPLICATE_WORK_ORDER_FAILURE,
      payload: err,
    });
  }
}

export function* getWorkOrderBudgetAndBillableRequest({ payload }) {
  try {
    const { organization_id, workorder_id } = payload;
    const url = `${organization_id}/workorder/${workorder_id}/budget-expense/`;
    const method = 'GET';
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_WORKORDER_BUDGET_AND_BILLABLE_SUCCESS,
      payload: { data: response, notify: method },
    });
  } catch (err) {
    yield put({
      type: GET_WORKORDER_BUDGET_AND_BILLABLE_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    //create
    takeLatest(CREATE_WORK_ORDER, createWorkOrderRequest),
    takeLatest(GET_WORK_ORDER, getWorkOrderRequest),
    takeLatest(CREATE_JOB, createJobRequest),
    takeLatest(DELETE_WORK_ORDER_JOB, deleteWorkOrderJobRequest),
    takeLatest(GET_JOBS_UNDER_WORKORDER, getJobsUnderWorkOrderRequest),
    takeLatest(UPDATE_JOB, updateJobRequest),
    takeLatest(UPDATE_WORK_ORDER, updateWorkOrderRequest),
    takeLatest(WORK_ORDER_LIST, getWorkOrderListRequest),
    takeLatest(WORK_ORDER_SHORT, getWorkOrderShortList),
    takeLatest(DELETE_WORK_ORDER, deleteWorkOrderRequest),
    takeLatest(DUPLICATE_WORK_ORDER, getDuplicateWorkOrderRequest),
    takeLatest(
      GET_WORKORDER_BUDGET_AND_BILLABLE,
      getWorkOrderBudgetAndBillableRequest,
    ),
    //billing
    takeLatest(CREATE_WORK_ORDER_BILL, createWorkOrderBillingRequest),
    takeLatest(GET_WORK_ORDER_BILL, getWorkOrderBillingRequest),
    takeLatest(UPDATE_WORK_ORDER_BILL, updateWorkOrderBillingRequest),
    //budget
    takeLatest(CREATE_WORK_ORDER_BUDGET, createWorkOrderBudgetRequest),
    takeLatest(GET_WORK_ORDER_BUDGET, getWorkOrderBudgetRequest),
    takeLatest(UPDATE_WORK_ORDER_BUDGET, updateWorkOrderBudgetRequest),
  ]);
}

import {
  ADD_OFFICE_LOCATION,
  ADD_OFFICE_LOCATION_FAILURE,
  ADD_OFFICE_LOCATION_SUCCESS,
  ADD_OFFICE_MEMBER,
  ADD_OFFICE_MEMBER_FAILURE,
  ADD_OFFICE_MEMBER_SUCCESS,
  ADD_OR_UPDATE_OFFICE_INFO,
  ADD_OR_UPDATE_OFFICE_INFO_FAILURE,
  ADD_OR_UPDATE_OFFICE_INFO_SUCCESS,
  ADD_OR_UPDATE_OFFICE_LOCATION,
  ADD_OR_UPDATE_OFFICE_LOCATION_FAILURE,
  ADD_OR_UPDATE_OFFICE_LOCATION_SUCCESS,
  CLEAR_OFFICE_CREATED_UPDATED,
  CLEAR_OFFICE_DETAILS,
  CLEAR_OFFICE_ID,
  CREATE_OFFICE,
  CREATE_OFFICE_FAILURE,
  CREATE_OFFICE_SUCCESS,
  DELETE_OFFICE,
  DELETE_OFFICE_FAILURE,
  DELETE_OFFICE_SUCCESS,
  GET_OFFICES_DETAILS,
  GET_OFFICES_DETAILS_FAILURE,
  GET_OFFICES_DETAILS_SUCCESS,
  GET_OFFICES_LIST,
  GET_OFFICES_LIST_FAILURE,
  GET_OFFICES_LIST_SHORT,
  GET_OFFICES_LIST_SHORT_FAILURE,
  GET_OFFICES_LIST_SHORT_SUCCESS,
  GET_OFFICES_LIST_SUCCESS,
  GET_OFFICE_MEMBERS_LIST,
  GET_OFFICE_MEMBERS_LIST_FAILURE,
  GET_OFFICE_MEMBERS_LIST_SUCCESS,
  LOGIN_PROFILE_CREATED_RESET,
  REMOVE_OFFICE_MEMBER,
  REMOVE_OFFICE_MEMBER_FAILURE,
  REMOVE_OFFICE_MEMBER_SUCCESS,
  SET_OFFICE_DETAILS,
  UPDATE_OFFICE,
  UPDATE_OFFICE_FAILURE,
  UPDATE_OFFICE_MEMBER,
  UPDATE_OFFICE_MEMBER_FAILURE,
  UPDATE_OFFICE_MEMBER_SUCCESS,
  UPDATE_OFFICE_SUCCESS,
} from '../../modules/constants';
import { createNotification } from '../../modules/notificationManager';

// get offices list
export const getOfficesListRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const getOfficesListRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      totalOfficesCount: data.data.count,
      pageSize: data.data.page_size,
      officesList: data.data.results,
      officeDetails: null,
      officeLocation: null,
    };
  }
  return {
    ...state,
    isLoading: false,
    totalOfficesCount: null,
    pageSize: null,
    officesList: [],
  };
};
export const getOfficesListRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    totalOfficesCount: null,
    pageSize: null,
    officesList: [],
  };
};

// Delete office
export const deleteOfficeHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    officeDeleteLoading: true,
    deletingOfficeId: payload.office_id,
  };
};

export const deleteOfficeSuccessHandler = (state, action) => {
  const {
    payload: { data, office_id },
  } = action;
  if (data && data.status === 204) {
    createNotification('success', 'Office deleted.', 3000);
    if (state.officesList && state.officesList.length) {
      return {
        ...state,
        officeDeleteLoading: false,
        deletingOfficeId: null,
        officesList: state.officesList.filter(
          office => office.id !== office_id,
        ),
      };
    }
  }

  return {
    ...state,
    officeDeleteLoading: false,
    deletingOfficeId: null,
  };
};

export const deleteOfficeFailureHandler = (state, action) => {
  createNotification('error', 'Could not delete. Please try again!', 3000);
  return {
    ...state,
    officeDeleteLoading: false,
    deletingOfficeId: null,
  };
};

// get offices short list
export const getOfficesShortListRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const getOfficesShortListRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      officesShortList: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    officesShortList: [],
  };
};
export const getOfficesShortListRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    officesShortList: [],
  };
};

// create office
export const createOfficeRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    officeCreateUpdateSuccess: false,
    isOfficeLimitFull: false,
  };
};
export const createOfficeRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 201 && data.data) {
    createNotification('success', 'Office created.', 3000);
    const details = JSON.parse(JSON.stringify(data.data));
    return {
      ...state,
      isLoading: false,
      officeCreateUpdateSuccess: true,
      officesList: [details, ...state.officesList],
      createdOfficeId: data.data.id,

      // officeDetails: { ...details },
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const createOfficeRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (
    response &&
    response.status === 403 &&
    response.data &&
    response.data.error &&
    response.data.error === "office creation limit is over for free plan"
  ) {
    createNotification(
      "error",
      `You can create only one Office in free plan`,
      5000
    );
    return {
      ...state,
      isLoading: false,
      officeCreateUpdateSuccess: false,
      createdOfficeId: null,
      isOfficeLimitFull: true,
    };
  }
  else {
    createNotification(
      'error',
      'Could not create office. Please try again!',
      3000,
    );
    return {
      ...state,
      isLoading: false,
    };
  }
};

// update office
export const updateOfficeRequest = (state, action) => {
  return {
    ...state,
    addOrUpdateOfficeLoading: true,
    officeCreateUpdateSuccess: false,
  };
};
export const updateOfficeRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200 && data.data) {
    createNotification('success', 'Office updated.', 3000);
    const currentList = JSON.parse(JSON.stringify(state.officesList));
    const details = JSON.parse(JSON.stringify(data.data));
    const index = state.officesList.findIndex(
      office => office.id === details.id,
    );
    currentList[index] = details;
    return {
      ...state,
      addOrUpdateOfficeLoading: false,
      officeCreateUpdateSuccess: true,
      officesList: currentList,
      officeDetails: { ...details },
    };
  }
  return {
    ...state,
    addOrUpdateOfficeLoading: false,
  };
};
export const updateOfficeRequestFailure = (state, action) => {
  createNotification(
    'error',
    'Could not update office. Please try again!',
    3000,
  );
  return {
    ...state,
    addOrUpdateOfficeLoading: false,
  };
};

//office details
export const officeDetailsRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const officeDetailsRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      officeDetails: data.data,
      officeLocation: data.data.location,
      officeMembersList: data.data.employees,
    };
  }
  return {
    ...state,
    isLoading: false,
    officeDetails: null,
  };
};
export const officeDetailsRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    officeDetails: null,
  };
};

// set office details
export const setOfficeDetails = (state, action) => {
  const { payload } = action;
  if (payload && payload.id) {
    return {
      ...state,
      officeDetails: { ...payload },
    };
  }
  return {
    ...state,
  };
};

// clear office details
export const clearOfficeDetails = (state, action) => {
  return {
    ...state,
    officeDetails: null,
  };
};

// clear office created or updated
export const clearOfficeCreatedUpdated = (state, action) => {
  return {
    ...state,
    officeCreateUpdateSuccess: false,
  };
};

export const resetStateBeforeLogout = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

export const officeMembersListRequestHandler = (state, action) => {
  return {
    ...state,
    officeMemberListLoading: true,
  };
};
export const officeMembersListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      officeMemberListLoading: false,
      officeMembersCount: data.data.count,
      officeMembersPageSize: data.data.page_size,
      officeMembersList: data.data.results,
    };
  }
  return {
    ...state,
    officeMemberListLoading: false,
    officeMembersCount: null,
    officeMembersPageSize: null,
    officeMembersList: [],
  };
};
export const officeMembersListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    officeMemberListLoading: false,
    officeMembersCount: null,
    officeMembersPageSize: null,
    officeMembersList: [],
  };
};

export const addOfficeMemberRequestHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    officeMemberIsLoading: true,
    loadingMemberId: payload.user_id,
  };
};

export const addOfficeMemberRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    if (state.officeDetails) {
      const details = { ...state.officeDetails };
      details.employees = [...details.employees, data.data];
      createNotification('success', 'Member assigned.', 2000);
      return {
        ...state,
        officeMemberIsLoading: false,
        loadingMemberId: null,
        officeMembersList: [...state.officeMembersList, data.data],
        officeDetails: details,
      };
    }
    return {
      ...state,
      officeMemberIsLoading: false,
      loadingMemberId: null,
      officeMembersList: [...state.officeMembersList, data.data],
    };
  }
  return {
    ...state,
    officeMemberIsLoading: false,
  };
};

export const addOfficeMemberRequestFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification('error', 'Something went wrong', 3000);
    return {
      ...state,
      officeMemberIsLoading: false,
      loadingMemberId: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification('error', 'Failed!! please try again', 2000);
  }
  return {
    ...state,
    officeMemberIsLoading: false,
    loadingMemberId: null,
  };
};

export const removeOfficeMemberRequestHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    officeMemberIsLoading: true,
    loadingMemberId: payload.user_id,
  };
};

export const removeOfficeMemberRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
    userData,
  } = action;
  if (data && data.status === 204 && userData && userData.userId) {
    createNotification('success', 'Member unassigned.', 2000);

    if (state.officeDetails) {
      const details = { ...state.officeDetails };
      const filteredList = details.employees.filter(
        member => member.id !== userData.userId,
      );
      details.employees = [...filteredList];
      return {
        ...state,
        officeMembersList: state.officeMembersList.filter(
          member => member.id !== userData.userId,
        ),
        officeDetails: details,
        officeMemberIsLoading: false,
        loadingMemberId: null,
      };
    }
    return {
      ...state,
      officeMembersList: state.officeMembersList.filter(
        member => member.id !== userData.userId,
      ),
      officeMemberIsLoading: false,
      loadingMemberId: null,
    };
  }
  return {
    ...state,
    officeMemberIsLoading: false,
    loadingMemberId: null,
  };
};

export const removeOfficeMemberRequestFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification('error', 'Something went wrong', 3000);
    return {
      ...state,
      officeMemberIsLoading: false,
      loadingMemberId: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification('error', 'Failed!! please try again', 2000);
  }
  return {
    ...state,
    officeMemberIsLoading: false,
    loadingMemberId: null,
  };
};

export const updateOfficeMemberRequestHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    officeMemberIsLoading: true,
    loadingMemberId: payload.member_id,
  };
};
export const updateOfficeMemberRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    const membersList = JSON.parse(JSON.stringify(state.officeMembersList));
    membersList.forEach(member => {
      if (data.data.can_manage && member.can_manage) {
        member.can_manage = false;
      }
      if (member.id === data.data.id) {
        createNotification(
          'success',
          data.data.can_manage ? 'Manager updated.' : 'Manager removed.',
          2000,
        );

        member.can_manage = data.data.can_manage;
      }
    });
    return {
      ...state,
      officeMemberIsLoading: false,
      loadingMemberId: null,
      officeMembersList: [...membersList],
    };
  }
  return {
    ...state,
    officeMemberIsLoading: false,
    loadingMemberId: null,
  };
};
export const updateOfficeMemberRequestFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification('error', 'Something went wrong', 3000);
    return {
      ...state,
      officeMemberIsLoading: false,
      loadingMemberId: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification('error', 'Failed!! please try again', 2000);
  }
  return {
    ...state,
    officeMemberIsLoading: false,
    loadingMemberId: null,
  };
};

// add or update office location
export const addOrUpdateOfficeLocationRequest = (state, action) => {
  return {
    ...state,
    addOrUpdateOfficeLocalMapLoading: true,
    officeLocationCreateUpdateSuccess: false,
  };
};

export const addOrUpdateOfficeLocationRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200 && data.data) {
    createNotification('success', 'Location updated.', 3000);
    return {
      ...state,
      addOrUpdateOfficeLocalMapLoading: false,
      officeLocationCreateUpdateSuccess: true,
      officeLocation: data.data.location,
    };
  }
  return {
    ...state,
    addOrUpdateOfficeLocalMapLoading: false,
  };
};

export const updateClientLocationRequestFailure = (state, action) => {
  createNotification('error', 'Could not update. Please try again!', 3000);
  return {
    ...state,
    addOrUpdateOfficeLocalMapLoading: false,
  };
};

// add or update office info
export const addOrUpdateOfficeInfoRequest = (state, action) => {
  return {
    ...state,
    addOrUpdateOfficeLocalMapLoading: true,
    officeLocationCreateUpdateSuccess: false,
  };
};

export const addOrUpdateOfficeInfoRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200 && data.data) {
    createNotification('success', 'Office updated.', 3000);
    return {
      ...state,
      addOrUpdateOfficeLocalMapLoading: false,
      officeLocationCreateUpdateSuccess: true,
      officeLocation: data.data.location,
    };
  }
  return {
    ...state,
    addOrUpdateOfficeLocalMapLoading: false,
  };
};

export const addOrUpdateOfficeInfoRequestFailure = (state, action) => {
  createNotification('error', 'Could not update. Please try again!', 3000);
  return {
    ...state,
    addOrUpdateOfficeLocalMapLoading: false,
  };
};

export const addOfficeLocationRequest = (state, action) => {
  return {
    ...state,
    addOrUpdateOfficeLocalMapLoading: true,
  };
};

export const addOfficeLocationRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    createNotification('success', 'Location added.', 2000);
    const currentList = JSON.parse(JSON.stringify(data.data));
    return {
      ...state,
      addOrUpdateOfficeLocalMapLoading: false,
      officeLocation: data.data,
      officeLocationList: [...state.officeLocationList, currentList],
    };
  }
  return {
    ...state,
    addOrUpdateOfficeLocalMapLoading: false,
    officeLocation: null,
  };
};

export const addOfficeLocationRequestFailure = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification('error', 'There was an error. Please try again!', 3000);
    return {
      ...state,
      addOrUpdateOfficeLocalMapLoading: false,
      officeLocation: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification(
      'error',
      'Sorry! could not add Office location. Please try again.',
      2000,
    );
  }
  return {
    ...state,
    addOrUpdateOfficeLocalMapLoading: false,
    officeLocation: null,
  };
};

export const clearCreatedOfficeIdHandler = (state, action) => {
  return {
    ...state,
    createdOfficeId: null,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [ADD_OR_UPDATE_OFFICE_LOCATION]: addOrUpdateOfficeLocationRequest,
  [ADD_OR_UPDATE_OFFICE_LOCATION_SUCCESS]:
    addOrUpdateOfficeLocationRequestSuccess,
  [ADD_OR_UPDATE_OFFICE_LOCATION_FAILURE]: updateClientLocationRequestFailure,
  [ADD_OR_UPDATE_OFFICE_INFO]: addOrUpdateOfficeInfoRequest,
  [ADD_OR_UPDATE_OFFICE_INFO_SUCCESS]: addOrUpdateOfficeInfoRequestSuccess,
  [ADD_OR_UPDATE_OFFICE_INFO_FAILURE]: addOrUpdateOfficeInfoRequestFailure,

  [GET_OFFICES_LIST]: getOfficesListRequest,
  [GET_OFFICES_LIST_SUCCESS]: getOfficesListRequestSuccess,
  [GET_OFFICES_LIST_FAILURE]: getOfficesListRequestFailure,

  [ADD_OFFICE_LOCATION]: addOfficeLocationRequest,
  [ADD_OFFICE_LOCATION_SUCCESS]: addOfficeLocationRequestSuccess,
  [ADD_OFFICE_LOCATION_FAILURE]: addOfficeLocationRequestFailure,

  [GET_OFFICES_LIST_SHORT]: getOfficesShortListRequest,
  [GET_OFFICES_LIST_SHORT_SUCCESS]: getOfficesShortListRequestSuccess,
  [GET_OFFICES_LIST_SHORT_FAILURE]: getOfficesShortListRequestFailure,

  [DELETE_OFFICE]: deleteOfficeHandler,
  [DELETE_OFFICE_SUCCESS]: deleteOfficeSuccessHandler,
  [DELETE_OFFICE_FAILURE]: deleteOfficeFailureHandler,

  [GET_OFFICE_MEMBERS_LIST]: officeMembersListRequestHandler,
  [GET_OFFICE_MEMBERS_LIST_SUCCESS]: officeMembersListRequestSuccessHandler,
  [GET_OFFICE_MEMBERS_LIST_FAILURE]: officeMembersListRequestFailureHandler,

  [GET_OFFICES_DETAILS]: officeDetailsRequestHandler,
  [GET_OFFICES_DETAILS_SUCCESS]: officeDetailsRequestSuccessHandler,
  [GET_OFFICES_DETAILS_FAILURE]: officeDetailsRequestFailureHandler,

  [CREATE_OFFICE]: createOfficeRequest,
  [CREATE_OFFICE_SUCCESS]: createOfficeRequestSuccess,
  [CREATE_OFFICE_FAILURE]: createOfficeRequestFailure,

  [ADD_OFFICE_MEMBER]: addOfficeMemberRequestHandler,
  [ADD_OFFICE_MEMBER_SUCCESS]: addOfficeMemberRequestSuccessHandler,
  [ADD_OFFICE_MEMBER_FAILURE]: addOfficeMemberRequestFailureHandler,

  [REMOVE_OFFICE_MEMBER]: removeOfficeMemberRequestHandler,
  [REMOVE_OFFICE_MEMBER_SUCCESS]: removeOfficeMemberRequestSuccessHandler,
  [REMOVE_OFFICE_MEMBER_FAILURE]: removeOfficeMemberRequestFailureHandler,

  [UPDATE_OFFICE]: updateOfficeRequest,
  [UPDATE_OFFICE_SUCCESS]: updateOfficeRequestSuccess,
  [UPDATE_OFFICE_FAILURE]: updateOfficeRequestFailure,

  [UPDATE_OFFICE_MEMBER]: updateOfficeMemberRequestHandler,
  [UPDATE_OFFICE_MEMBER_SUCCESS]: updateOfficeMemberRequestSuccessHandler,
  [UPDATE_OFFICE_MEMBER_FAILURE]: updateOfficeMemberRequestFailureHandler,

  [SET_OFFICE_DETAILS]: setOfficeDetails,

  [CLEAR_OFFICE_DETAILS]: clearOfficeDetails,

  [CLEAR_OFFICE_CREATED_UPDATED]: clearOfficeCreatedUpdated,

  [LOGIN_PROFILE_CREATED_RESET]: resetStateBeforeLogout,

  [CLEAR_OFFICE_ID]: clearCreatedOfficeIdHandler,
};

// default initial state
const initialState = {
  isLoading: false,
  officesList: [],
  officesShortList: [],

  officeMembersList: [],
  officeMembersCount: null,
  officeMembersPageSize: null,

  officeDetails: null,
  addOrUpdateOfficeLoading: false,
  officeMemberListLoading: false,

  officeCreateUpdateSuccess: false,

  createdOfficeId: null,
  isOfficeLimitFull: false,

  officeMemberIsLoading: false,
  loadingMemberId: null,

  officeDeleteLoading: false,
  deletingOfficeId: null,

  //location modal
  addOrUpdateOfficeLocalMapLoading: false,
  officeLocationCreateUpdateSuccess: false,
  officeLocation: null,

  createdOfficeLocationInfo: null,
  officeLocationList: [],

  // pagination
  totalOfficesCount: null,
  pageSize: null,
};

export default function officesReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import DownloadIcon from "../../assets/img/icons/download-curved.svg";
import Plus from "../../assets/img/icons/plus_white.svg";
import BottomPagination from "../../components/Pagination/BottomPagination/BottomPagination";
import TopPagination from "../../components/Pagination/TopPagination/TopPagination";
import SearchWithButton from "../../components/Search/SearchWithButton";
import PricingPlan from "../../components/PricingPlan/PricingPlan";
import { handleSetFilters, setPathName } from "../../filterReducer";
import {
  ButtonIcon,
  ColoredButtonWithIcon,
  TabButton,
  TabButtonContainer,
} from "../../styledComponents/buttons";
import { TabLeftGrid } from "../../styledComponents/clients";
import {
  CommonGrid,
  FilterLabel,
  HeaderContainer,
  InputField,
  InputWithLabelSection,
  PageTitle,
} from "../../styledComponents/common";
import ClientsCards from "./ClientsCards";
import ImportCustomerModal from "./ImportCustomerModal";

const Clients = ({
  ghlSyncContactIsLoading,
  ghlContactList,
  getGHLContactList,
  filters,
  selectedOrganization,
  clientList: propClientList,
  bulkCustomerCreated,
  createCustomerBulk,
  isBulkCustomerLoading,
  getClientList,
  updateClient,
  deleteClient,
  history,
  totalClientsCount,
  pageSize,
  isLoading,
  clientUpdateLoading,
  updatingClientId,
  clientDeleteLoading,
  deletingClientId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [clientList, setClientList] = useState([]);
  const [clientLocationList, setClientLocationList] = useState([]);
  const [clientType, setClientType] = useState(
    filters?.pathname === "/user/customers" && filters?.clientType
      ? filters?.clientType
      : "active"
  );
  const [searchTerm, setSearchTerm] = useState(
    filters?.pathname === "/user/customers" && filters?.currentSearchedTerm
      ? filters?.currentSearchedTerm
      : ""
  );
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState(
    filters?.pathname === "/user/customers" && filters?.currentSearchedTerm
      ? filters?.currentSearchedTerm
      : ""
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCustomerAddModalOpen, setIsCustomerAddModalOpen] = useState(false);
  //pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [pricingPopup, setPricingPopup] = useState(false);

  useEffect(() => {
    dispatch(
      setPathName({
        pathname: location.pathname,
        currentSearchedTerm,
        clientType,
      })
    );
  }, [currentSearchedTerm, clientType]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
    }
    if (
      selectedOrganization &&
      selectedOrganization.plan &&
      selectedOrganization.plan.name === "Standard"
    ) {
      pricingPopupToggle();
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (propClientList && propClientList.length > 0) {
      setClientList(propClientList);
    } else if (propClientList && propClientList.length === 0) {
      setClientList([]);
    }
  }, [propClientList]);

  useEffect(() => {
    fetchClientList();
  }, [
    currentSearchedTerm,
    clientType,
    currentPageNumber,
    selectedOrganization,
  ]);

  useEffect(() => {
    bulkCustomerCreated && handleClearSearch();
  }, [bulkCustomerCreated]);

  const fetchClientList = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        page: currentPageNumber,
        name: currentSearchedTerm
          ? encodeURIComponent(currentSearchedTerm.toLowerCase().trim())
          : undefined,
        clientType: clientType,
      };
      getClientList(payload);
    }
  };

  const handleSearchByName = () => {
    if (searchTerm && searchTerm.trim()) {
      setCurrentSearchedTerm(searchTerm);
    } else {
      setCurrentSearchedTerm("");
    }
    setCurrentPageNumber(1);
  };

  const handleClearSearch = () => {
    setCurrentPageNumber(1);
    setSearchTerm("");
    setCurrentSearchedTerm("");
  };

  const onTabSwitch = (value) => {
    setClientType(value);
  };
  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const toggle = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };
  const customerAddToggle = () => {
    setIsCustomerAddModalOpen(!isCustomerAddModalOpen);
  };

  const handleClientArchive = (clientId, action, PageNumber) => {
    if (selectedOrganization && selectedOrganization.id && clientId) {
      let payload = {
        organization_id: selectedOrganization.id,
        client_id: clientId,
        is_active: action === "archive" ? false : true,
        getList: true,
        page: PageNumber,
        clientType: clientType,
      };
      updateClient(payload);
    }
  };

  const handleClientDelete = (clientId) => {
    if (selectedOrganization && selectedOrganization.id) {
      let payload = {
        organization_id: selectedOrganization.id,
        client_id: clientId,
      };
      deleteClient(payload);
      setIsDeleteModalOpen(!isDeleteModalOpen);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
  };

  const pricingPopupToggle = () => {
    setPricingPopup(!pricingPopup);
  };

  const ghlContactsFetch = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      getGHLContactList(payload);
    }
  };

  return (
    <div className="content">
      <PricingPlan
        isOpen={pricingPopup}
        toggle={pricingPopupToggle}
        popupText="You don't have permission to this feature."
        popupTitle="Upgrade to Elite to access this feature"
        history={history}
        backRoute="/user/dashboard"
        onlyElite
      />
      <PageTitle>{t("customers")}</PageTitle>
      <ImportCustomerModal
        isOpen={isCustomerAddModalOpen}
        toggle={customerAddToggle}
        selectedOrganization={
          selectedOrganization && selectedOrganization.id
            ? selectedOrganization
            : ""
        }
        createCustomerBulk={createCustomerBulk}
        bulkCustomerCreated={bulkCustomerCreated}
        isLoading={isBulkCustomerLoading}
      />
      <HeaderContainer>
        <TabLeftGrid>
          <InputWithLabelSection>
            <FilterLabel>{t("type")}</FilterLabel>
            <TabButtonContainer>
              <TabButton
                onClick={() => onTabSwitch("active")}
                selected={clientType}
                index="active"
              >
                {t("active")}
              </TabButton>
              <TabButton
                onClick={() => onTabSwitch("archived")}
                selected={clientType}
                index="archived"
              >
                {t("archive")}
              </TabButton>
            </TabButtonContainer>
          </InputWithLabelSection>

          <SearchWithButton
            itemName={t("customer")}
            searchTermName={t("customer_info")}
            searchInput={searchTerm}
            onInputChange={onSearchTermChange}
            handleSearch={handleSearchByName}
            handleClearSearch={handleClearSearch}
          />
        </TabLeftGrid>
        <CommonGrid
        // columns={
        //   selectedOrganization && selectedOrganization.ghl_id
        //     ? '1fr 1fr 1fr'
        //     : '1fr 1fr'
        // }
        >
          {/* {selectedOrganization &&
          (selectedOrganization.role === 'owner' ||
            selectedOrganization.role === 'admin') &&
          selectedOrganization.ghl_id ? (
            <ColoredButtonWithIcon
              style={{ background: '#606CFF' }}
              onClick={() => ghlContactsFetch()}
            >
              <ButtonIcon src={DownloadIcon} alt="" />
              Sync Customer
            </ColoredButtonWithIcon>
          ) : null} */}

          <ColoredButtonWithIcon
            style={{ background: "#606CFF" }}
            onClick={() => customerAddToggle()}
          >
            <ButtonIcon src={DownloadIcon} alt="" />
            {t("import_customers")}
          </ColoredButtonWithIcon>

          <ColoredButtonWithIcon
            onClick={() => history.push("/user/add-customer")}
          >
            <ButtonIcon src={Plus} alt="" />
            {t("add_customer")}
          </ColoredButtonWithIcon>
        </CommonGrid>
      </HeaderContainer>

      {!isLoading && totalClientsCount && totalClientsCount > 0 && pageSize ? (
        <TopPagination
          itemName={t("customers")}
          totalCount={totalClientsCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}

      <ClientsCards
        searchTerm={searchTerm}
        clientList={clientList}
        clientType={clientType}
        history={history}
        modalToggle={toggle}
        isModalOpen={isDeleteModalOpen}
        handleArchive={handleClientArchive}
        handleDelete={handleClientDelete}
        isLoading={isLoading}
        clientUpdateLoading={clientUpdateLoading}
        updatingClientId={updatingClientId}
        clientDeleteLoading={clientDeleteLoading}
        deletingClientId={deletingClientId}
        PageNumber={currentPageNumber}
      />

      {!isLoading && totalClientsCount && totalClientsCount > 0 && pageSize ? (
        <BottomPagination
          totalCount={totalClientsCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}
    </div>
  );
};

export default Clients;

import { getWorkOrderRequest } from 'custom_modules/WorkOrderNew/workOrderActions';
import { connect } from 'react-redux';

// Actions
import {
  createClient,
  getClientShortList,
} from '../../ClientsNew/clientsActions';
import {
  clearInvoiceLinkedProject,
  createInvoice,
  createInvoiceUpdateSendLog,
  getInvoiceNumber,
  invoiceLinkProject,
  sendInvoiceEmail,
} from '../invoiceActions';
import CreateInvoice from './CreateInvoice';

// Store props to map with current state
const mapStateToProps = state => ({
  selectedOrganization: state.organization.selectedOrganization,
  isLoading: state.invoice.isLoading,
  createInvoiceLoading: state.invoice.createInvoiceLoading,
  linkProjectLoading: state.invoice.linkProjectLoading,
  sendMailLoading: state.invoice.sendMailLoading,
  clientIsLoading: state.client.isLoading,
  clientShortList: state.client.clientShortList,
  projectsList: state.project.projectShortList,
  createdInvoiceInfo: state.invoice.createdInvoiceInfo,
  createdClientId: state.client.createdClientId,
  invoiceNumber: state.invoice.invoiceNumber,
  profileData: state.profile.profileData,
  sendInvoiceEmailInfo: state.invoice.sendInvoiceEmailInfo,
  log: state.invoice.updateInvoiceSendLog,
  linkedProjectState: state.invoice.linkedProjectState,
  isInvoiceLimitFull: state.invoice.isInvoiceLimitFull,
  WorkOrderList: state.workorder.WorkOrderList,
  invoiceNumberAlreadyExists: state.invoice.invoiceNumberAlreadyExists,
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  getClientShortList: details => dispatch(getClientShortList({ ...details })),
  createInvoice: details => dispatch(createInvoice({ ...details })),
  getInvoiceNumber: details => dispatch(getInvoiceNumber({ ...details })),
  sendInvoiceEmail: details => dispatch(sendInvoiceEmail({ ...details })),
  createInvoiceUpdateSendLog: details =>
    dispatch(createInvoiceUpdateSendLog({ ...details })),
  createClient: details => dispatch(createClient({ ...details })),
  getWorkOrderRequest: details => dispatch(getWorkOrderRequest({ ...details })),
  invoiceLinkProject: details => dispatch(invoiceLinkProject({ ...details })),
  clearInvoiceLinkedProject: () => dispatch(clearInvoiceLinkedProject()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoice);

import styled, { css } from "styled-components";

export const InputSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(250px, 330px));
  gap: ${({gap}) => gap || `30px 5%`};
  justify-content: start;
  align-items: start;
`;
export const AssigneeSection = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: auto;
  gap: 10px;
`;
export const TimeRangeSection = styled.div`
  display: grid;
  grid-template-columns: 45% 45%;
  gap: 5%;
  justify-content: space-between;
  align-items: start;
`;
export const AddTaskLocationSection = styled.div`
  display: grid;
  grid-template-columns: 350px 1fr;
`;
export const AddTaskLocationInfoContainer = styled.div`
    /* display: grid;
    gap: 30px; */
    padding: 20px;
`

import { CommImageNameComp } from 'custom_modules/Members/CommonComponents';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import avatar5 from '../../assets/img/avatar_5.svg';
import Chat from '../../assets/img/icons/chat.svg';
import Bean from '../../assets/img/icons/delete.svg';
import File from '../../assets/img/icons/file.svg';
import Pencil from '../../assets/img/icons/pencil.svg';
import Unarchive from '../../assets/img/icons/unarchive.svg';
import DotLoader from '../../components/DropdownLoader/DropdownLoader';
import ComponentCircleLoader from '../../components/Loaders/ComponentCircleLoader';
import NoDataComponent from '../../components/NoDataComponent/NoDataComponent';
import InfoTooltip from '../../components/Tooltip/InfoTooltip';
import { ButtonIcon } from '../../styledComponents/buttons';
import {
  ClientLocationsTableHeaderContainer,
  ClientLocationsTableRowContainer,
  HoveredSection2,
  InnerDivTooltip,
  RoundHover,
  ToolTipContainer,
  TooltipText,
} from '../../styledComponents/clients';
// import { useParams } from 'react-router-dom';
import {
  CommonText,
  Container,
  FirstWordRound,
  TableText,
} from '../../styledComponents/common';
import { TableItem } from '../../styledComponents/teams';
import { CommFirstRoundLetterComp } from '../Teams/CommonComponents';
import DeleteClientModal from './DeleteClientModal';

const FirstRoundLetterComp = ({ text, backColor }) => {
  const colorList = [
    { color: '#789CFF' },
    { color: '#2C3E50' },
    { color: '#76C794' },
    { color: '#F8A14B' },
    { color: '#FE5969' },
  ];
  let selectedIndex = 0;
  if (backColor < 5) {
    selectedIndex = backColor;
  } else {
    selectedIndex = backColor % 5;
  }

  text = text.split(' ');

  return (
    <FirstWordRound
      size="40px"
      fontSize="14px"
      backColor={colorList[selectedIndex].color}
    >
      {`${text[0].slice(0, 1).toUpperCase()}${
        text[1] ? text[1].slice(0, 1).toUpperCase() : ``
      }`}
    </FirstWordRound>
  );
};

const ClientsCards = props => {
  const [clientDeleteId, setClientDeleteId] = useState('');
  const [clientList, setClientList] = useState([]);
  const [selectIndex, setSelectIndex] = useState(-1);
  const [toolTip, setToolTip] = useState(-1);
  // const { page } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (props.clientList && props.clientList.length > 0) {
      let list = [];
      if (props.clientType === 'active') {
        list = props.clientList.filter(client => client.is_active);
      } else if (props.clientType === 'archived') {
        list = props.clientList.filter(client => !client.is_active);
      }
      setClientList(list);
    } else if (props.clientList && props.clientList.length === 0) {
      setClientList([]);
    }
  }, [props.clientList, props.clientType]);

  useEffect(() => {
    if (!props.isModalOpen) {
      setClientDeleteId('');
    }
  }, [props.isModalOpen]);

  const onHoverChange = index => {
    setSelectIndex(index);
  };

  const toolTipChange = (value, index) => {
    setToolTip(value);
    setSelectIndex(index);
  };

  const toggle = id => {
    setClientDeleteId(id);
    props.modalToggle();
  };

  return (
    <Container style={{ paddingBottom: '20px' }}>
      {props.isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          <DeleteClientModal
            id={clientDeleteId ? clientDeleteId : ''}
            isOpen={props.isModalOpen}
            toggle={props.modalToggle}
            handleDelete={props.handleDelete}
          />
          {clientList && clientList.length > 0 && (
            <>
              {/* table code */}
              <ClientLocationsTableHeaderContainer>
                <TableItem>
                  <TableText>{t('customer_name')}</TableText>
                </TableItem>
                {/* <TableItem>
                  <TableText>Service Locations</TableText>
                </TableItem> */}
                <TableItem>
                  <TableText>{t('contact_number')}</TableText>
                </TableItem>
                <TableItem>
                  <TableText>{t('email_address')}</TableText>
                </TableItem>
                {/* <TableItem></TableItem> */}
                <TableItem>
                  <TableText>{t('actions')}</TableText>
                </TableItem>
              </ClientLocationsTableHeaderContainer>
              {clientList.map((client, index) => (
                <>
                  {(props.clientUpdateLoading &&
                    props.updatingClientId === client.id) ||
                  (props.clientDeleteLoading &&
                    props.deletingClientId === client.id) ? (
                    <DotLoader loading />
                  ) : (
                    <ClientLocationsTableRowContainer>
                      <TableItem
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          props.history.push(
                            `/user/customer-details?id=${client.id}`,
                          )
                        }
                      >
                        <CommImageNameComp
                          imgSource={avatar5}
                          name={client.name}
                          size="36px"
                          fontSize="13px"
                          index={index}
                          color={client.color}
                          hoverUnderline
                        />
                      </TableItem>

                      <TableItem
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          props.history.push(
                            `/user/customer-details?id=${client.id}`,
                          )
                        }
                      >
                        <CommonText>{client.phone_number}</CommonText>
                      </TableItem>

                      <TableItem
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          props.history.push(
                            `/user/customer-details?id=${client.id}`,
                          )
                        }
                      >
                        <CommonText>{client.email}</CommonText>
                      </TableItem>
                      {/* <TableItem></TableItem> */}

                      <TableItem>
                        {props.clientType === 'active' ? (
                          <HoveredSection2>
                            <RoundHover
                              onMouseEnter={() => toolTipChange(1, index)}
                              onMouseLeave={() => toolTipChange(-1)}
                              onClick={() =>
                                props.history.push(
                                  `/user/edit-customer?id=${client.id}`,
                                )
                              }
                            >
                              <img src={Pencil} alt="pencil" width="15px" />
                              {selectIndex === index && toolTip === 1 && (
                                <ToolTipContainer>
                                  <InnerDivTooltip>
                                    <img src={Chat} alt="chat" width="64px" />
                                    <TooltipText>{t('edit')}</TooltipText>
                                  </InnerDivTooltip>
                                </ToolTipContainer>
                              )}
                            </RoundHover>

                            <RoundHover
                              onMouseEnter={() => toolTipChange(2, index)}
                              onMouseLeave={() => toolTipChange(-1)}
                              onClick={() =>
                                props.handleArchive(
                                  client.id,
                                  'archive',
                                  props.PageNumber,
                                )
                              }
                            >
                              <img src={File} alt="file" width="13px" />
                              {selectIndex === index && toolTip === 2 && (
                                <ToolTipContainer>
                                  <InnerDivTooltip>
                                    <img src={Chat} alt="chat" width="64px" />
                                    <TooltipText>{t('archive')}</TooltipText>
                                  </InnerDivTooltip>
                                </ToolTipContainer>
                              )}
                            </RoundHover>

                            <RoundHover
                              onMouseEnter={() => toolTipChange(3, index)}
                              onMouseLeave={() => toolTipChange(-1)}
                              onClick={() => toggle(client.id)}
                            >
                              <ButtonIcon src={Bean} alt="" />
                              {selectIndex === index && toolTip === 3 && (
                                <ToolTipContainer>
                                  <InnerDivTooltip>
                                    <img src={Chat} alt="chat" width="64px" />
                                    <TooltipText>{t('delete')}</TooltipText>
                                  </InnerDivTooltip>
                                </ToolTipContainer>
                              )}
                            </RoundHover>
                          </HoveredSection2>
                        ) : (
                          <HoveredSection2 unarchive>
                            <RoundHover
                              onMouseEnter={() => toolTipChange(2, index)}
                              onMouseLeave={() => toolTipChange(-1)}
                              onClick={() =>
                                props.handleArchive(
                                  client.id,
                                  'unarchive',
                                  props.PageNumber,
                                )
                              }
                            >
                              <img src={Unarchive} alt="file" width="15px" />
                              {selectIndex === index && toolTip === 2 && (
                                <ToolTipContainer>
                                  <InnerDivTooltip>
                                    <img src={Chat} alt="chat" width="64px" />
                                    <TooltipText>{t('unarchive')}</TooltipText>
                                  </InnerDivTooltip>
                                </ToolTipContainer>
                              )}
                            </RoundHover>
                            <RoundHover
                              onMouseEnter={() => toolTipChange(3, index)}
                              onMouseLeave={() => toolTipChange(-1)}
                              onClick={() => toggle(client.id)}
                            >
                              <ButtonIcon src={Bean} alt="" />
                              {selectIndex === index && toolTip === 3 && (
                                <ToolTipContainer>
                                  <InnerDivTooltip>
                                    <img src={Chat} alt="chat" width="64px" />
                                    <TooltipText>{t('delete')}</TooltipText>
                                  </InnerDivTooltip>
                                </ToolTipContainer>
                              )}
                            </RoundHover>
                          </HoveredSection2>
                        )}
                      </TableItem>
                    </ClientLocationsTableRowContainer>
                  )}
                </>
              ))}

              {/* table code finishes */}
            </>
          )}
          {!props.isLoading && clientList && clientList.length === 0 && (
            <NoDataComponent
              title={
                props.clientType === 'archived'
                  ? t('no_archived_customers_found')
                  : t('no_active_customers_found')
              }
            />
          )}
        </>
      )}
    </Container>
  );
};

export default ClientsCards;

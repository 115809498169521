import styled from "styled-components";
import Lottie from 'react-lottie';

import NoDataArtwork from "../../assets/img/common/no_data_artwork.svg";
import animationData from './no_data_lottie';
import { useState, useEffect } from "react";


const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding || "10vh 0"};
  min-height: 320px;
  height: 523px;
`;
const ArtworkImage = styled.img`
  height: ${({ imageHeight }) => imageHeight || "194px"};
  width: ${({ imageWidth }) => imageWidth || "214px"};
`;
const NoDataTitle = styled.div`
  font-size: ${({ titleFontSize }) => titleFontSize || "18px"};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  margin-top: ${({ marginTop, subtitle }) =>
    marginTop ? marginTop : subtitle ? "60px" : "30px"};
  color: ${({ color, theme }) => color || theme.colors.main.textSecondary};
  text-align: ${({ textAlign }) => textAlign};
`;
const NoDataSubtitle = styled.div`
  font-size: ${({ subTitleFontSize }) => subTitleFontSize || "14px"};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ color, theme }) => color || theme.colors.main.labelText};
  margin-top: 10px;
`;

const NoDataComponent = (props) => {
  const [showInfo, setShowInfo] = useState(false);
  const {
    title,
    subtitle,
    padding,
    imageSrc,
    imageHeight,
    imageWidth,
    titleFontSize,
    subTitleFontSize,
    textMarginTop,
    textAlign,
    color,
  } = props;

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMaxYMid meet',
    },
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowInfo(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <NoDataContainer padding={padding}>
      {showInfo && (
        // <ArtworkImage
        //   src={imageSrc || NoDataArtwork}
        //   alt=""
        //   imageWidth={imageWidth}
        //   imageHeight={imageHeight}
        // />

        <Lottie options={defaultOptions} height={250} width={250} style={{ cursor: "default" }} />
      )}

      {showInfo && (
      <NoDataTitle
        subtitle={subtitle}
        titleFontSize={titleFontSize}
        color={color}
        marginTop={textMarginTop}
        textAlign={textAlign}
      >
        {title || "No There is no data to be displayed!"}
      </NoDataTitle>
      )}

      {showInfo && subtitle && (
        <NoDataSubtitle subTitleFontSize={subTitleFontSize} color={color}>
          {subtitle}
        </NoDataSubtitle>
      )}
    </NoDataContainer>
  );
};

export default NoDataComponent;

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Plus from '../../assets/img/icons/plus_white.svg';
import FilterDropDownStyle from '../../components/DropdownStyle/FilterDropDownStyle';
import BottomPagination from '../../components/Pagination/BottomPagination/BottomPagination';
import TopPagination from '../../components/Pagination/TopPagination/TopPagination';
import Select from '../../components/ReactSelectDropdown';
import ClearFilterButton from '../../components/Search/clearFilterButton';
import PricingPlan from "../../components/PricingPlan/PricingPlan";
import { setPathName } from '../../filterReducer';
import {
  ButtonIcon,
  ColoredButtonWithIcon,
} from '../../styledComponents/buttons';
import {
  FilterLabel,
  InputField,
  InputWithLabelSection,
  PageTitle,
} from '../../styledComponents/common';
import { FilterContainer } from '../../styledComponents/invoice';
import InvoiceTable from './InvoiceTable';

const Invoice = ({
  selectedOrganization,
  getClientShortList,
  clientShortList,
  getInvoiceList,
  sendInvoiceEmailInfo,
  recordPaymentInfo,
  invoiceList,
  deletedInvoiceCallback,
  history,
  isLoading,
  sendMailLoading,
  recordPaymentLoading,
  paymentDetailsLoading,
  deleteInvoiceLoading,
  recordPaymentInvoice,
  createInvoiceUpdateSendLog,
  updateInvoiceSendLog,
  sendInvoiceEmail,
  profileData,
  getClientDetails,
  clientDetails,
  deleteInvoice,
  noData,
  filters,

  // pagination
  totalInvoicesCount,
  invoicePageSize,
}) => {
  const dispatch = useDispatch();
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(
    filters?.pathname === '/user/invoices' && filters?.selectedClient
      ? filters?.selectedClient
      : {
          label: '(All Customers)',
          value: '',
        },
  );

  const [selectedStatus, setSelectedStatus] = useState(
    filters?.pathname === '/user/invoices' && filters?.selectedStatus
      ? filters?.selectedStatus
      : {
          value: '',
          label: '(All)',
        },
  );
  const statusOptions = [
    { value: '', label: '(All)' },
    { value: 'draft', label: 'Draft' },
    { value: 'sent', label: 'Sent' },
    { value: 'paid', label: 'Paid' },
    { value: 'partial', label: 'Partially Paid' },
  ];
  const [sentState, setSentState] = useState(false);
  const [recordPaymentState, setRecordPaymentState] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [pricingPopup, setPricingPopup] = useState(false);

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const callGetInvoiceList = (background = false) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        page: currentPageNumber,
      };
      background && (payload.background = true);
      if (selectedClient && selectedClient.value) {
        payload.client_id = selectedClient.value;
      }
      if (selectedStatus && selectedStatus.value) {
        payload.status = selectedStatus.value;
      }
      getInvoiceList(payload);
    }
  };

  useEffect(() => {
    callGetInvoiceList();
  }, [selectedOrganization, selectedClient, selectedStatus, currentPageNumber]);

  useEffect(() => {
    dispatch(
      setPathName({
        pathname: location.pathname,
        selectedStatus,
        selectedClient,
      }),
    );
  }, [selectedStatus, selectedClient]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
      const payload = {
        organization_id: selectedOrganization.id,
      };
      getClientShortList(payload);
    }
    if (
      selectedOrganization &&
      selectedOrganization.plan &&
      selectedOrganization.plan.name === "Standard"
    ) {
      pricingPopupToggle();
    }
    if (
      selectedOrganization &&
      selectedOrganization.plan &&
      selectedOrganization.plan.name === "Standard"
    ) {
      pricingPopupToggle();
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (clientShortList && clientShortList.length > 0) {
      const options = [];
      clientShortList.map(item => {
        options.push({
          value: item.id,
          label: item.name,
        });
      });
      options.unshift({ label: '(All Customers)', value: '' });
      setClientOptions(options);
    } else if (clientShortList && clientShortList.length === 0) {
      setClientOptions([]);
    }
  }, [clientShortList]);

  // useEffect(() => {
  //   if (
  //     selectedOrganization &&
  //     selectedOrganization.id &&
  //     sendInvoiceEmailInfo &&
  //     sendInvoiceEmailInfo.log_id &&
  //     sentState
  //   ) {
  //     const payload = {
  //       organization_id: selectedOrganization.id,
  //       background: true,
  //     };
  //     const timer = setTimeout(() => {
  //       getInvoiceList(payload);
  //     }, 4000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [sendInvoiceEmailInfo]);

  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      recordPaymentInfo &&
      recordPaymentInfo.id &&
      recordPaymentState
    ) {
      callGetInvoiceList(true);
    }
  }, [recordPaymentInfo]);

  const handlePageChange = page => {
    setCurrentPageNumber(page);
  };

  const onClientSelect = e => {
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
    }
    setSelectedClient(e);
  };

  // useEffect(() => {
  //   if (
  //     selectedOrganization &&
  //     selectedOrganization.id &&
  //     deletedInvoiceCallback &&
  //     deleted
  //   ) {
  //     const payload = {
  //       organization_id: selectedOrganization.id,
  //     };
  //     getInvoiceList(payload);
  //   }
  // }, [deletedInvoiceCallback]);

  const onStatusSelect = e => {
    setSelectedStatus(e);
  };

  const onSend = () => {
    setSentState(true);
  };

  const onRecordPay = () => {
    setRecordPaymentState(true);
  };

  const onDelete = () => {
    setDeleted(true);
  };

  const pricingPopupToggle = () => {
    setPricingPopup(!pricingPopup);
  };

  return (
    <div className="content">
      <PricingPlan
        isOpen={pricingPopup}
        toggle={pricingPopupToggle}
        popupText="You don't have permission to this feature."
        popupTitle="Upgrade to Elite to access this feature"
        history={history}
        backRoute="/user/dashboard"
        onlyElite
      />
      <PageTitle>Invoices</PageTitle>
      <FilterContainer>
        <InputWithLabelSection>
          <FilterLabel>Customer</FilterLabel>
          <Select
            value={selectedClient}
            isClearable={selectedClient?.value}
            options={clientOptions}
            onChange={e =>
              e
                ? onClientSelect(e)
                : onClientSelect({
                    label: '(All Customers)',
                    value: '',
                  })
            }
            placeholder="Select Customer..."
            styles={FilterDropDownStyle({ height: '40px' })}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: props => {
                return <ClearFilterButton {...props} />;
              },
            }}
          />
        </InputWithLabelSection>
        <InputWithLabelSection>
          <FilterLabel>Status</FilterLabel>
          <Select
            isSearchable
            isClearable={selectedStatus?.value}
            value={selectedStatus}
            options={statusOptions}
            onChange={e => {
              e
                ? onStatusSelect(e)
                : onStatusSelect({ value: '', label: '(All)' });
            }}
            styles={FilterDropDownStyle({ height: '40px' })}
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: props => {
                return <ClearFilterButton {...props} />;
              },
            }}
          />
        </InputWithLabelSection>
        <ColoredButtonWithIcon
          style={{ alignSelf: 'flex-end' }}
          margin="0 0 0 auto"
          onClick={() => history.push('/user/create-invoice')}
        >
          <ButtonIcon src={Plus} />
          Create New Invoice
        </ColoredButtonWithIcon>
      </FilterContainer>

      {totalInvoicesCount && totalInvoicesCount > 0 && invoicePageSize ? (
        <TopPagination
          itemName="invoices"
          totalCount={totalInvoicesCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={invoicePageSize}
        />
      ) : null}

      {selectedOrganization && selectedOrganization.id && profileData && (
        <InvoiceTable
          isLoading={isLoading}
          sendMailLoading={sendMailLoading}
          recordPaymentLoading={recordPaymentLoading}
          paymentDetailsLoading={paymentDetailsLoading}
          deleteInvoiceLoading={deleteInvoiceLoading}
          selectedOrganization={selectedOrganization}
          invoiceList={
            selectedStatus?.value
              ? invoiceList.filter(inv => inv.status === selectedStatus?.value)
              : invoiceList
          }
          history={history}
          recordPaymentInvoice={recordPaymentInvoice}
          createInvoiceUpdateSendLog={createInvoiceUpdateSendLog}
          updateInvoiceSendLog={updateInvoiceSendLog}
          sendInvoiceEmail={sendInvoiceEmail}
          senderName={profileData && profileData.first_name}
          getClientDetails={getClientDetails}
          clientDetails={clientDetails}
          deleteInvoice={deleteInvoice}
          onSend={onSend}
          onRecordPay={onRecordPay}
          onDelete={onDelete}
          noData={noData}
          selectedClient={selectedClient}
          selectedStatus={selectedStatus}
        />
      )}

      {totalInvoicesCount && totalInvoicesCount > 0 && invoicePageSize ? (
        <BottomPagination
          totalCount={totalInvoicesCount}
          currentPage={currentPageNumber}
          onPageChange={page => handlePageChange(page)}
          pageSize={invoicePageSize}
        />
      ) : null}
    </div>
  );
};

export default Invoice;
